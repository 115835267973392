import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExerciseComponent } from './exercise.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { SplitterModule } from 'primeng/splitter';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from './components/sidebar/sidebar.module';
import { WorkspaceModule } from './components/workspace/workspace.module';
import { BottomPanelModule } from './components/bottom-panel/bottom-panel.module';

@NgModule({
  declarations: [
    ExerciseComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    SplitterModule,
    ProgressSpinnerModule,
    SidebarModule,
    WorkspaceModule,
    BottomPanelModule,
  ],
  exports: [
    ExerciseComponent,
  ],
})
export class ExerciseModule { }
