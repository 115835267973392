import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { LambdasService } from 'src/app/lambads.service';
import { MessageService } from 'primeng/api';
import { UserPublicProfile } from 'src/app/models/user-public-profile.model';

@Component({
  selector: 'app-user-connections',
  templateUrl: './user-connections.component.html',
  styleUrls: ['../../styles/settings.scss']
})
export class UserConnectionsComponent implements OnInit{
  public users: UserPublicProfile[] = [];
  
  constructor(
    public auth: AuthService,
    private dynamodb: DynamoDBService,
    private lambdas: LambdasService,
    private messageService: MessageService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getUserConnections();
  }

  public async onUserAdd(user: UserPublicProfile): Promise<void> {
    this.users.push(user);
  }

  public async onUserDelete(user: UserPublicProfile): Promise<void> {
    try {
      await this.deleteUserConnection(user);
      this.removeUserFromArray(user);
    } catch (err) {
      if (err instanceof Error && err.message) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err.message });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete user. Please try again later!' });
      }
    }
  }

  private async getUserConnections(): Promise<void> {
    await Promise.all([
      this.addUsers(this.dynamodb.getCompanyConnections(this.auth.user.companyId)),
      this.addUsers(this.dynamodb.getInvitedUserConnections(this.auth.user.companyId))
    ]);
  }

  private async addUsers(userPromise: Promise<UserPublicProfile[]>): Promise<void> {
    try {
      const users = await userPromise;
      this.users.push(...users);
    } catch (error) {
      console.error('Error retrieving users', error);
      throw error;
    }
  }

  private removeUserFromArray(userToDelete: UserPublicProfile): void {
    this.users = this.users.filter(user => user.email !== userToDelete.email);
  }

  private async deleteUserConnection(user: UserPublicProfile): Promise<void> {
    if (user.inviteID) {
      await this.dynamodb.deleteInvite(user.inviteID, this.auth.user.companyId);
    } else {
      await this.lambdas.deleteUserConnection(user.email).toPromise();
    }
  }
}
