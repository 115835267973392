<div class="wrapper">    
    <div 
      class="logo" 
      [routerLink]="user ? '/dashboard' : '/'">
        <img src="../../assets/img/template/logo.svg" width="35px" height="35px">
        <span class="logo-text">Dev Skill Platform</span>
    </div>

    <div class="user-info-container">
        <img class="user-img" 
             [src]="user.picture.url" 
             routerLink="/settings/profile">
        <div class="user-details">
            <span class="user-name font-styles">
              <input *ngIf="inputControl"
                  class="invisible-input" 
                  type="text"
                  [attr.placeholder]="placeholderValue"
                  [formControl]="inputControl.control"
                  (focus)="removePlaceholder()"
                  (blur)="updateUserName()"
                  (keydown.enter)="updateUserName()">
              <span 
                  *ngIf="!inputControl"
                  class="user-name font-styles">
                  {{user.full_name}}
              </span>
            </span>
            <ng-container *ngIf="user.isInterviewer">
                    <span class="user-other-info font-styles">{{user.companyName}}</span>
                    <span class="user-other-info font-styles capitalize">{{user.role}}</span>
                    <role-toggler *ngIf="user.companyId">
                    </role-toggler>
            </ng-container>
            <ng-container *ngIf="user.isUser">
                    <span class="user-other-info font-styles">{{user.email}}</span>
                    <span 
                      class="user-other-info font-styles"
                      *ngIf="!user.companyId">
                        <a class="font-styles"
                           routerLink="/settings/company">
                            Create Company Account
                        </a>
                    </span>
                    <role-toggler *ngIf="user.companyId">
                    </role-toggler>
            </ng-container>
        </div>
    </div>

    <div class="selection-container">
      <div class="selection-title font-styles">Management</div>
      <div *ngFor="let selection of menuData.allowedSelections">
          <a class="menu-selection" 
           (click)="menuData.toggleSubMenu(selection.label)" 
           [routerLink]="selection.items ? null : selection.url" 
           routerLinkActive="active-selection">
            <i class="material-icons-outlined">{{selection.icon}}</i>
            <span class="selection-text font-styles">{{selection.label}}</span>
          </a>
          <div *ngIf="menuData.menuVisibility[selection.label]" class="sub-menu">
            <a *ngFor="let subSelection of selection.items" 
              class="menu-selection" 
              [routerLink]="subSelection.url" 
              routerLinkActive="active-selection">
              <i class="material-icons-outlined">{{subSelection.icon}}</i>
              <span class="selection-text font-styles">{{subSelection.label}}</span>
            </a>
          </div>
      </div>
    </div>

    <p-divider></p-divider>

    <div class="selection-container">
        <div class="selection-title font-styles">Other</div>
        <ng-container *ngFor="let selection of menuData.otherAllowedSelections">
          <a class="menu-selection"
            (click)="selection.dialogType ? openDialog(selection.dialogType, $event) : null"
            [routerLink]="selection.dialogType ? null : selection.url"
            routerLinkActive="active-selection">
            <i class="material-icons-outlined">{{selection.icon}}</i>
            <span class="selection-text font-styles">{{selection.label}}</span>
          </a>
        </ng-container>
    </div>

    <p-divider></p-divider>
    
    <div class="selection-container">
        <a class="menu-selection"
          (click)="auth.signOut()">
          <i class="material-icons-outlined">logout</i>
          <span class="selection-text font-styles">Sign Out</span>
      </a>
    </div>
</div> 