import { AfterViewInit, Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { InterviewerExercisePlan } from 'src/app/exercise.module';
import { EventType } from '../plans-management.component';
import { environment } from 'src/environments/environment';
import { PlanDataService } from '../plan-data-service/plan-data.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-plan-list',
  templateUrl: './plan-list.component.html',
  styleUrls: ['./plan-list.component.scss']
})

export class PlanListComponent implements OnDestroy, AfterViewInit {
  @Output() planEditorEvent = new EventEmitter<EventType>();
  @ViewChild('scrollViewport') viewport!: CdkVirtualScrollViewport;

  private selectedPlanSubscription: Subscription = new Subscription();
  public imageLink: string = environment.DefaultPlanPicture;

  constructor(
    public planData: PlanDataService,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.selectedPlanSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.subToSelectedPlan();
  }

  private subToSelectedPlan(): void {
    this.selectedPlanSubscription.add(this.planData.selectedPlan$.subscribe(plan => {
      if (plan && plan.name) {
        const index = this.planData.findPlanIndex(plan);
        this.viewport.scrollToIndex(index);
        this.selectedPlanSubscription.unsubscribe();
      }
    }));
  }

  public changeEventType(actionType: EventType, plan: InterviewerExercisePlan | undefined): void {
    if (plan) {
      this.planData.selectPlan(plan);
    }
    this.planEditorEvent.emit(actionType);
  }

  public onScroll(event: Event): void {
    const element = event.target as HTMLElement;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      this.planData.fetchPlans();
    }
  }

  public modifyQuery(plan: InterviewerExercisePlan): void {
    this.router.navigate([], { queryParams: { id: plan.id } });
  }

  public calculateContainerHeight(): string {
    const itemHeight = 132;

    const numberOfItems = this.planData.plans.length;
    const totalHeight = window.innerHeight;

    const maxContainerHeight = totalHeight - 215;
    const maxItemsInPage = Math.ceil(maxContainerHeight / itemHeight);

    if (numberOfItems < maxItemsInPage) {
      return `${numberOfItems * itemHeight - 30}px`;
    }
    return `${maxContainerHeight}px`;
  }
}