<div class="wrapper">
    <div class="container"
      [style.background]="'#FFCBCB'">
        <h3>Create Your Own Exercise</h3>
        <div class="text">Create your own exercises and earn money for sharing these exercises with others.</div>
        <div class="blur">
            <app-help [helpMessage]="helpEnum.ComingSoon"></app-help>
            <h3>Coming Soon...</h3>
            <i class="material-icons-outlined">lock</i>
        </div>
    </div>
    <div class="container"
      [style.background]="'rgba(162, 156, 225, 0.19)'">
        <h3>Exercise Plans</h3>
        <div class="text">Create exercise plans to challenge yourself and improve skills.</div>
        <div class="blur">
            <app-help [helpMessage]="helpEnum.ComingSoon"></app-help>
            <h3>Coming Soon...</h3>
            <i class="material-icons-outlined">lock</i>
        </div>
    </div>
    <div class="container"
      [style.background]="'rgba(255, 227, 113, 0.28)'">
        <h3>Courses</h3>
        <div class="text">Create courses and share them with others, or gain new skills and improve your knowledge with unique courses and exercises.</div>
        <div class="blur">
            <app-help [helpMessage]="helpEnum.ComingSoon"></app-help>
            <h3>Coming Soon...</h3>
            <i class="material-icons-outlined">lock</i>
        </div>
    </div>
</div>