import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminTicketListComponent } from './components/admin-ticket-list/admin-ticket-list.component';
import { SideMenuModule } from '../side-menu/side-menu.module';
import { TableModule } from 'primeng/table';
import { DynamicDialogModule } from 'primeng/dynamicdialog';



@NgModule({
  declarations: [
    AdminTicketListComponent
  ],
  imports: [
    CommonModule,
    SideMenuModule,
    TableModule,
    DynamicDialogModule,
  ]
})
export class AdminPanelModule { }
