<section>
    <div width="100%" height="100%">
        <monaco-tree
          *ngIf="tree"
          (clickFile)="openFile($event)" 
          [width]="'100%'" 
          [height]="'100%'" 
          [tree]="tree.tree"
          [ngClass]="{'blocked-button': !isExerciseStarted}">
        </monaco-tree>
    </div>
</section>