<div class="menu-wrapper"
  [style.visibility]="makeClickable ? 'visible' : 'hidden'">
    <div class="menu-backdrop" 
      [@fade]="menuState" 
      (click)="toggleMenu()" 
      (@fade.done)="onAnimationDone($event)">
    </div>
    <div class="menu" 
      [@slideInOut]="menuState">
        <ng-content></ng-content>
    </div>
</div>

<button 
  class="menu-button p-button-rounded p-button-help" 
  (click)="toggleMenu()"
  pButton
  pRipple>
    <i class="material-icons-outlined">menu</i>
</button>