import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Subscription } from 'rxjs';
import { LambdasService, Response } from '../lambads.service';
import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { FormValidatorsService } from '../form-validators.service';
import { AuthService } from '../auth/auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  public isLinkRegistration = false;
  public isRequestSent = false;
  public isVerification = false;
  public isLoading = false;

  public inviteId: string | null = null;
  public companyId: string | null = null;
  public companyName: string | null = null;
  public verifyError: string | null = null;
  private inviteLinkData: string | undefined = undefined;
  private registrationType: string | null = null;

  private passwordChangesSub: Subscription | null = null;

  public accountFormGroup: FormGroup;
  public verificationFormGroup: FormGroup;

  public queryParams: Params;

  public passwordErrorHandler = GetPasswordErrors;

  constructor(
    private router: Router,
    private lambdas: LambdasService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private validatorService: FormValidatorsService,
    private auth: AuthService,
    private fb: FormBuilder,
  ) {
    this.queryParams = { ...this.route.snapshot.queryParams };

    this.accountFormGroup = this.fb.group({
      email: validatorService.GetEmailFormControl(),
      password: validatorService.GetPasswordFormControl(),
      passwordConfirmation: validatorService.GetRequiredFormControl(),
      termsCheckbox: validatorService.GetCheckboxFormControl(),
    });

    this.verificationFormGroup = this.fb.group({
      verificationCode: validatorService.GetRequiredFormControl(),
    });
  }

  private email(): string {
    return this.accountFormGroup.controls['email'].value;
  }

  private password(): string {
    return this.accountFormGroup.controls['password'].value;
  }

  private verificationCode(): string {
    return this.verificationFormGroup.controls['verificationCode'].value;
  }

  public async ngOnInit(): Promise<void> {
    this.subscribeToPasswordChanges(this.accountFormGroup);

    this.inviteId = this.route.snapshot.queryParamMap.get('invite_id');
    this.companyId = this.route.snapshot.queryParamMap.get('company_id');

    if (await this.auth.isAuthenticated()) {
      await this.router.navigate(['/dashboard'], { queryParams: this.queryParams });
      return;
    }

    this.inviteLinkData = JSON.stringify({ invite_id: this.inviteId, company_id: this.companyId });
  }

  public ngOnDestroy(): void {
    if (this.passwordChangesSub && !this.passwordChangesSub.closed) {
      this.passwordChangesSub.unsubscribe();
    }
  }

  private subscribeToPasswordChanges(formGroup: FormGroup): void {
    const passwordConfirmationControl = formGroup.controls.passwordConfirmation;
    const passwordControl = formGroup.controls.password;

    this.passwordChangesSub = passwordConfirmationControl.valueChanges.subscribe(value => {
      if (passwordControl.value !== value) {
        passwordConfirmationControl.setErrors({ passwordMismatch: true });
      } else {
        passwordConfirmationControl.setErrors(null);
      }
    });
    
    this.passwordChangesSub.add(passwordControl.valueChanges.subscribe(value => {
      if (value !== passwordConfirmationControl.value) {
        passwordConfirmationControl.setErrors({ passwordMismatch: true });
      } else {
        passwordConfirmationControl.setErrors(null);
      }
    }));
  }

  public async register(): Promise<void> {
    if (!this.accountFormGroup.valid) {
      this.checkForms();
      return;
    }
    this.isRequestSent = true;
    this.disableForms();

    try {
      const response = await this.sendRegistrationRequest();
      this.registrationType = this.getRegistrationType(response);
      this.isRequestSent = false;
      this.isVerification = true;
    } catch (err) {
      this.handleError(err);
      this.isVerification = false;
    }
  }

  public async confirmRegistration(): Promise<void> {
    if (!this.verificationFormGroup.valid) {
      this.checkForms();
      return;
    }

    this.isLoading = true;

    const code = this.verificationCode();
    const email = this.email();
    const password = this.password();

    try {
      await this.handleRegistrationType(code, email, password);
      await this.logIn(email, password);
    } catch (err) {
      this.handleError(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async handleRegistrationType(code: string, email: string, password: string) {
    switch (this.registrationType) {
    case 'FORCE_CHANGE_PASSWORD':
      await this.changePassword(code, email, password);
      return;
    default:
      await this.verifyEmail(code, email);
    }
  }

  private async verifyEmail(code: string, email: string): Promise<void> {
    await Auth.confirmSignUp(email, code);
  }

  private async changePassword(code: string, email: string, password: string): Promise<void> {
    const user = await Auth.signIn(email, code);
    await Auth.completeNewPassword(user, password);
  }

  private getRegistrationType(response: Response): string {
    if (response.reg_event_type) {
      return response.reg_event_type;
    }
    return '';
  }

  private handleError(err: unknown) {
    if (err instanceof Error) {
      this.displayErrorMessage(err.message);
    }
    this.enableForms();
    this.isRequestSent = false;
  }

  private async logIn(email: string, password: string): Promise<void> {
    await Auth.signIn(email, password);
    await this.auth.refreshUser(false);
    await this.router.navigate(['/dashboard'], { queryParams: this.queryParams });
  }

  private async sendRegistrationRequest(): Promise<Response> {
    const email = this.email();
    const password = this.password();
    return await this.lambdas.userRegistration(email, password).toPromise();
  }

  private disableForms(): void {
    this.accountFormGroup.controls['email'].disable();
    this.accountFormGroup.controls['password'].disable();
    this.accountFormGroup.controls['passwordConfirmation'].disable();
    this.accountFormGroup.controls['termsCheckbox'].disable();
  }

  private enableForms(): void {
    this.accountFormGroup.controls['email'].enable();
    this.accountFormGroup.controls['password'].enable();
    this.accountFormGroup.controls['passwordConfirmation'].enable();
    this.accountFormGroup.controls['termsCheckbox'].enable();
  }

  public displayErrorMessage(error: string): void {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: error });
  }

  public async googleLogin(): Promise<void> {
    try {
      await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google, customState: this.inviteLinkData });
    } catch (error) {
      this.displayErrorMessage('Failed to sign in with Google');
    }
  }

  public async linedInLogin(): Promise<void> {
    try {
      await Auth.federatedSignIn({ customProvider: 'LinkedIn', customState: this.inviteLinkData });
    } catch (error) {
      this.displayErrorMessage('Failed to sign in with LinkedIn');
    }
  }

  private checkForms(): void {
    if (this.accountFormGroup.invalid) {
      this.accountFormGroup.markAllAsTouched();
      return;
    }

    if (this.verificationFormGroup.invalid) {
      this.verificationFormGroup.markAllAsTouched();
      return;
    }
  }
}

export function GetPasswordErrors(errors: ValidationErrors | null | undefined): string | null {
  if (!errors) return null;
  if (Object.keys(errors).includes('minlength')) {
    return 'Password must be at least 8 characters long';
  } else if (Object.keys(errors).includes('maxlength')) {
    return 'Password must be no more than 32 characters long';
  } else if (Object.keys(errors).includes('notContainOneNumber')) {
    return 'Password must contain at least one number';
  } else if (Object.keys(errors).includes('notContainUppercaseLetter')) {
    return 'Password must contain at least one uppercase letter';
  } else if (Object.keys(errors).includes('notContainLowercaseLetter')) {
    return 'Password must contain at least one lowercase letter';
  } else if (Object.keys(errors).includes('notContainSpecialCharacter')) {
    return 'Password must contain at least one special character';
  } else if (Object.keys(errors).includes('containSpace')) {
    return 'Password cannot contain spaces';
  }
  return null;
}

export interface AmplifyErrorMessage {
  code: string;
  message: string;
}

export interface UserBody {
  password: string;
  email: string;
  provider?: string;
  invite_id?: string;
  company_id?: string;
}