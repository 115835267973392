<div class="wrapper">    
    <img class="logo"
        [routerLink]="user ? '/dashboard' : '/'"
        src="../../assets/img/template/logo.svg">

    <p-avatar 
      styleClass="w-full h-full cursor-pointer mb-4"
      class="user-img" 
      [image]="user.picture.url" 
      routerLink="/settings/profile"
      shape="circle">
    </p-avatar>

    <p-divider></p-divider>

    <div class="selection-container">
        <ng-container *ngFor="let selection of menuData.allowedSelections">
            <a class="menu-selection"
              (click)="menuData.toggleSubMenu(selection.label)" 
              [routerLink]="selection.items ? null : selection.url" 
              routerLinkActive="active-selection">
                <i class="material-icons-outlined">{{selection.icon}}</i>
            </a>
            <ng-container *ngIf="menuData.menuVisibility[selection.label]">
                <i class="material-icons-outlined"
                  *ngIf="selection.items && selection.items.length > 0">
                    keyboard_arrow_down
                </i>
                <a *ngFor="let subSelection of selection.items" 
                  class="menu-selection" 
                  [routerLink]="subSelection.url" 
                  routerLinkActive="active-selection">
                    <i class="material-icons-outlined">{{subSelection.icon}}</i>
                </a>
            </ng-container>
        </ng-container>
    </div>

    <p-divider></p-divider>

    <div class="selection-container">
        <a class="menu-selection"
          *ngFor="let selection of menuData.otherAllowedSelections"
          (click)="selection.dialogType ? openDialog(selection.dialogType, $event) : null"
          [routerLink]="selection.dialogType ? null : selection.url"
          routerLinkActive="active-selection">
            <i class="material-icons-outlined">{{selection.icon}}</i>
        </a>
    </div>

    <p-divider></p-divider>
    
    <div class="selection-container">
        <a class="menu-selection" (click)="auth.signOut()">
            <i class="material-icons-outlined">logout</i>
        </a>
    </div>
</div>
