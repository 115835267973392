import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PaymentState } from '../../payment.component';
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'payment-messages',
  templateUrl: './payment-messages.component.html',
  styleUrls: ['./payment-messages.component.scss']
})
export class PaymentMessagesComponent implements OnChanges {
    @Input() errorMessage: string | null = null;
    @Input() state: PaymentState | null = null;
    @Input() candidateEmail: string | undefined;
    @Input() assignedPlanId: string | undefined;
    @Output() returnEvent = new EventEmitter();

    constructor (
      public auth: AuthService
    ) {}

    public paymentMessage: Message | null = null;

    public ngOnChanges(changes: SimpleChanges) {
      if (changes.errorMessage || changes.state) {
        this.paymentMessage = this.getPaymentMessage();
      }
    }

    public getPaymentMessage(): Message {
      switch (this.state) {
      case PaymentState.ChargedBack:
        return {
          header: 'Payment Chargeback Alert',
          text: 'Your payment has been chargebacked. Please contact your bank or our support for further information.',
          status: 'Failed'
        };
      case PaymentState.Refunded:
        return {
          header: 'Payment Refunded Successfully',
          text: 'Your payment has been successfully refunded to your account. Thank you for using our services.',
          status: 'Pending'
        };
      case PaymentState.Voided:
        return {
          header: 'Payment Voided',
          text: 'Your payment was voided. No funds were taken from your account.',
          status: 'Failed'
        };
      case PaymentState.Abandoned:
        return {
          header: 'Payment Abandoned',
          text: 'Payment was abandoned due to inactivity. Please try again if necessary.',
          status: 'Failed'
        };
      case PaymentState.WaitingForSCA:
        return {
          header: 'Verification Required',
          text: 'Your payment requires additional verification to complete. Please follow the instructions sent to your email or phone.',
          status: 'Pending'
        };
      case PaymentState.SentForProcessing:
        return {
          header: 'Processing Your Payment',
          text: 'Your payment has been sent for processing. We will notify you of the payment status as soon as we receive confirmation.',
          status: 'Pending'
        };
      case PaymentState.Failed:
        return {
          header: 'Payment Processing Failed',
          text: 'Your payment failed to process. Please check your payment details and try again.',
          status: 'Failed'
        };
      case PaymentState.Initial:
        return {
          header: 'Payment Initiated',
          text: 'Your payment has been initiated and is awaiting processing. We will notify you of any status updates.',
          status: 'Pending'
        };
      case PaymentState.Settled:
        return {
          header: 'Payment Successful!',
          text: 'Thank you for your payment! The payment invoice was sent to your registered email.',
          status: 'Success'
        };
      case PaymentState.Done:
        return {
          header: 'Payment Completed Successfully',
          text: 'Your payment has been successfully processed and completed. The payment invoice was sent to your registered email.',
          status: 'Success'
        };
      case PaymentState.Pending:
        return {
          header: 'Payment Pending',
          text: 'Your payment is pending. We will notify you of the payment status as soon as it changes.',
          status: 'Pending'
        };
      case PaymentState.LowPrice:
        return {
          header: 'Congratulations! It’s on Us',
          text: 'The price is so low that you don\'t need to pay a single penny for it!',
          status: 'Success'
        };
      default:
        return {
          header: 'Payment Status Unavailable',
          text: 'Currently, the status of your payment is not available. Please check back later or contact our support team.',
          status: 'Failed'
        };
      }
    }
}

interface Message {
    header: string
    text: string
    status: 'Success' | 'Failed' | 'Pending'
}