<p-confirmDialog 
  #cd
  header="Browser Not Supported"
  [style]="{width: '30vw'}"
  [breakpoints]="{'1440px': '50vw'}"
  [closable]="false"
  [key]="dialogKey">
    <ng-template pTemplate="footer">
        <simple-button (click)="cd.accept()">Ok</simple-button>
    </ng-template>
</p-confirmDialog>