<section>
  <div class="item-container">
    <div>
      <ng-container
       *ngFor="let item of itemsMap | keyvalue">
        <div 
          class="item"
          [ngClass]="item.value.isOpen ? 'active-item' : 'not-active-item'"
          *ngIf="!item.value.isBottom"
          (click)="item.value.open(item.value)">
          <i class="material-icons-outlined"
           [pTooltip]="item.value.tooltip">
            {{item.value.icon}}
          </i>
        </div>
      </ng-container>
    </div>

    <div>
      <ng-container
       *ngFor="let item of itemsMap | keyvalue">
        <div 
          class="item"
          [ngClass]="item.value.isOpen  ? 'active-item' : 'not-active-item'"
          *ngIf="item.value.isBottom">
            <i class="material-icons-outlined"
              (click)="item.value.open(item.value)"
              [pTooltip]="item.value.tooltip"
              [life]="1000000">
              {{item.value.icon}}
            </i>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="explorer-container">
    <app-description 
     [style.display]="isOpen('description') ? 'flex': 'none'">
    </app-description>
    <app-hint 
     [style.display]="isOpen('hints') ? 'flex': 'none'">
    </app-hint>
    <app-file-watcher 
     [style.display]="isOpen('files') ? 'flex': 'none'">
    </app-file-watcher>
    <app-help
     [style.display]="isOpen('help') ? 'flex': 'none'">
    </app-help>
    <settings
     [style.display]="isOpen('settings') ? 'flex': 'none'">
    </settings>
  </div>
</section>