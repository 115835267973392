import { Injectable } from '@angular/core';
import { ICredentials } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { User } from '../user.module';
import { Router } from '@angular/router';
import { LocalStorageItems, LocalStorageService } from '../shared/local-storage.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  user!: User;

  constructor(
    protected router: Router,
    protected localStorageService: LocalStorageService
  ) { }

  public async isAuthenticated(): Promise<boolean> {
    try {
      if (!this.user) {
        const userInfo = await Auth.currentAuthenticatedUser({ bypassCache: false });
        this.user = new User(userInfo, this.localStorageService);
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  public async getUserCredentials(): Promise<ICredentials> {
    return await Auth.currentUserCredentials();
  }

  public async signOut(): Promise<void> {
    try {
      this.localStorageService.setItem(LocalStorageItems.TestMode, false);
      await Auth.signOut({ global: true });
      await this.router.navigate(['/']);
      window.location.reload();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  public async refreshUser(reloadPage: boolean): Promise<void> {
    const updatedCognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    this.user = new User(updatedCognitoUser, this.localStorageService);
    if (reloadPage) {
      window.location.reload();
    }
  }
}
