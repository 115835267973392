<div class="wrapper">
    <app-side-menu></app-side-menu>
    <div class="main-container">
        <div class="info-container">
            <div class="helper">
                <span>Assigned Plans Count: {{assignedPlansData.data.length}}</span>
            </div>
            <app-assigned-plan-info-panel></app-assigned-plan-info-panel>
        </div>
        <app-assigned-plans-list></app-assigned-plans-list>
    </div>
</div>

<app-tool-panel>
    <app-assigned-plans-list></app-assigned-plans-list>
</app-tool-panel>