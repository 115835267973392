import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocsLoaderService {
  constructor(
    private http: HttpClient
  ) { }

  public fetchFileInfos(): Observable<DocumentationInfo[]> {
    return this.http.get<DocumentationInfo[]>('/assets/docs/file-list.json');
  }

  public fetchDocumentation(name: string): Observable<string> {
    return this.http.get(`/assets/docs/${name}`, { responseType: 'text' });
  }
}

export interface DocumentationInfo {
  title: string
  file_name: string
}
