import { Component, OnInit } from '@angular/core';
import { DeviceInfoService } from 'src/app/shared/device-info.service';
import { IBrowser } from 'ua-parser-js';
import { ConfirmationService } from 'primeng/api';
import { LocalStorageItems, LocalStorageService } from 'src/app/shared/local-storage.service';

@Component({
  selector: 'browser-compatibility-alarm',
  templateUrl: './browser-compatibility-alarm.component.html',
  styleUrls: ['./browser-compatibility-alarm.component.scss']
})
export class BrowserCompatibilityAlarmComponent implements OnInit {
  private readonly supportedBrowsers: string[] = ['Chrome', 'Firefox', 'Edge'];
  public readonly dialogKey: string = 'browser-compatibility-dialog';

  private browserAlertDismissed: boolean | null = null;
  public browser: IBrowser | undefined;

  constructor(
    private deviceInfoService: DeviceInfoService,
    private confirmationService: ConfirmationService,
    private localStorageService: LocalStorageService
  ) {
    this.browser = this.deviceInfoService.browser;
    this.browserAlertDismissed = this.localStorageService.getItem(LocalStorageItems.BrowserAlertDismissed);
  }

  public ngOnInit(): void {
    if (!this.browserAlertDismissed)
      this.checkBrowserCompatibility();
  }

  private checkBrowserCompatibility(): void {
    const browserName: string | undefined = this.browser?.name;

    if (!browserName) {
      this.showErrorDialog();
      return;
    }

    const isBrowserSupported = this.supportedBrowsers.includes(browserName);
    if (!isBrowserSupported) {
      this.showWarningDialog(browserName);
      return;
    }
  }

  public showWarningDialog(browserName: string): void {
    this.confirmationService.confirm({
      dismissableMask: false,
      message: `
        We apologize, but your current browser '${browserName}' is not fully supported. 
        Continuing to use this browser may result in a suboptimal experience and potential UI issues on our platform. 
        <br>
        For the best experience, we recommend switching to one of the supported browsers: ${this.supportedBrowsers.join(', ')}.
      `,
      accept: () => this.localStorageService.setItem(LocalStorageItems.BrowserAlertDismissed, true)
    });
  }

  public showErrorDialog(): void {
    this.confirmationService.confirm({
      dismissableMask: false,
      message: `
        We apologize, but your current browser is not recognized and may not be fully supported. 
        Continuing to use this browser may result in a suboptimal experience and potential UI issues on our platform. 
        <br>
        For the best experience, we recommend switching to one of the supported browsers: ${this.supportedBrowsers.join(', ')}.
      `,
      accept: () => this.localStorageService.setItem(LocalStorageItems.BrowserAlertDismissed, true)
    });
  }
}
