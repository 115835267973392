import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';
import { PaymentResponse } from './payment/payment.component';
import { CompanyBody } from './settings/components/company-profile/company-profile.component';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CartResponse } from './models/payment/cart.models';
import { Address } from './models/address.models';
import { RemoveEmptyFields } from './shared/service/format';

@Injectable({
  providedIn: 'root'
})

export class LambdasService {
  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  private get authToken(): string {
    return this.authService.user.signInUserSession.getIdToken().getJwtToken();
  }

  public userRegistration(email: string, password: string): Observable<Response> {
    return this.http.post<Response>(
      environment.RegistrationAPIGatewayURL,
      { email, password })
      .pipe(
        catchError(this.handleError)
      );
  }

  public userDetailsSync(first_name: string, last_name: string, phone_number: string, country_code?: string, picture_link: string = this.authService.user.picture.originalURL): Observable<Response> {
    return this.http.patch<Response>(
      environment.UserDetailsSync,
      RemoveEmptyFields({ first_name, last_name, phone_number, picture_link, country_code }),
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public getPlanPrice(candidate_email: string, assigned_plan_id: string, country_code: string, vat_number = ''): Observable<CartResponse> {
    return this.http.post<CartResponse>(
      environment.GetPlanPrice,
      RemoveEmptyFields({ candidate_email, assigned_plan_id, country_code, vat_number }),
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public cancelAssignedPlan(user_email: string, assigned_plan_id: string): Observable<Response> {
    return this.http.post<Response>(
      environment.DeleteAssignedPlanEndpoint,
      { user_email, assigned_plan_id },
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public rejectAssignedPlan(assigned_plan_id: string): Observable<Response> {
    return this.http.post<Response>(
      environment.RejectAssignedPlanEndpoint,
      { assigned_plan_id },
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public finishAssignedPlan(assigned_plan_id: string): Observable<Response> {
    return this.http.post<Response>(
      environment.FinishAssignedPlanEndpoint,
      { assigned_plan_id },
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public companyRegistration(body: CompanyBody): Observable<Response> {
    return this.http.post<Response>(
      environment.CompanyRegistrationAPIGatewayURL,
      RemoveEmptyFields(body),
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public deleteMember(email: string, company_id: string): Observable<Response> {
    return this.http.post<Response>(
      environment.DeleteMemberAPIGatewayURL,
      { email, company_id },
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public inviteMember(invite_email: string): Observable<Response> {
    return this.http.post<Response>(
      environment.InviteMemberAPIGatewayURL,
      { invite_email },
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public inviteUser(invite_email: string): Observable<Response> {
    return this.http.post<Response>(
      environment.InviteUserAPIGatewayURL,
      { invite_email },
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public deleteUserConnection(userEmail: string): Observable<Response> {
    return this.http.post<Response>(
      environment.DeleteUserAPIGatewayURL,
      { email: userEmail },
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public connectUserToCompany(email: string, invite_id: string, company_id: string): Observable<Response> {
    return this.http.post<Response>(
      environment.AddUserAPIGatewayURL,
      { email, invite_id, company_id },
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public addMember(email: string, invite_id: string, company_id: string): Observable<Response> {
    return this.http.post<Response>(
      environment.AddMemberAPIGatewayURL,
      { email, invite_id, company_id },
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public saveCompanyData(body: CompanyBody): Observable<CheckVatNumberResponse> {
    return this.http.post<CheckVatNumberResponse>(
      environment.SaveCompanyDetailsAPIGatewayURL,
      RemoveEmptyFields(body),
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public validateInviteId(inviteId: string, companyId: string): Observable<Response> {
    return this.http.post<Response>(
      environment.CheckInviteLinkAPIGatewayURL,
      { invite_id: inviteId, company_id: companyId })
      .pipe(
        catchError(this.handleError)
      );
  }

  public saveUserPlan(email: string, plan_id: string, deadline: string): Observable<Response> {
    return this.http.post<Response>(
      environment.SaveUserPlanAPIGatewayURL,
      { email, plan_id, deadline },
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public getPaymentLink(
    customer_url: string,
    payment_description: string,
    assigned_plan_id: string,
    email: string,
    payment_method: string,
    address: Address,
    company_name?: string, 
    vat_number?: string
  ): Observable<PaymentResponse> {
    return this.http.post<PaymentResponse>(
      environment.GetPaymentLinkAPIGatewayURL,
      { customer_url, payment_description, assigned_plan_id, email, payment_method, address: address.toJSON(), company_name, vat_number },
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public getPaymentMethod(assignedPlanId: string): Observable<PaymentResponse> {
    return this.http.post<PaymentResponse>(
      environment.GetPaymentMethodAPIGatewayURL,
      { assigned_plan_id: assignedPlanId },
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public getPaymentStatus(payment_reference: string, assigned_plan_id: string, user_email: string): Observable<PaymentResponse> {
    return this.http.post<PaymentResponse>(
      environment.GetPaymentStatusAPIGatewayURL,
      { payment_reference, assigned_plan_id, user_email },
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  public deleteAccount(): Observable<Response> {
    return this.http.delete<Response>(
      environment.DeleteAccountEndpoint,
      { headers: { Authorization: this.authToken } })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error(error);
    if (error.status === 0) {
      return throwError(new Error('A network error has occurred. Please try again later.'));
    }

    console.error(`Backend returned code ${error.status}, body was: `, error.error);

    return error.error.error ?
      throwError(new Error(error.error.error)) :
      throwError(new Error('Something went wrong, Please try again later.'));
  }
}

export interface Response {
  status: string;
  error: string;
  invite_id?: string;
  invite_type?: string;
  reg_event_type?: string;
  company_name?: string;
  assigned_plan_id?: string;
}

export interface CheckVatNumberResponse {
  status: string;
  is_valid: boolean;
  vat_validator_error: string;
}