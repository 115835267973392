<div class="wrapper">
    <app-side-menu></app-side-menu>
    <div class="container">
        <section>
            <h2>Connections</h2>
            <p-divider></p-divider>
            <div class="text">
                Connect with users via email to collaborate. You will be able to assign interview plans to them after
                acceptance. Be aware that if the invitation is not accepted within two weeks, it will be automatically
                deleted.
            </div>
            <app-user-list 
                userType="connection" 
                [users]="users" 
                (userDeleted)="onUserDelete($event)"
                (userAdded)="onUserAdd($event)">
            </app-user-list>
        </section>
    </div>
</div>