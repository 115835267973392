import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExerciseDataService } from '../../../../services/exercise-data.service';
import { Status } from '../../../../exercise.component';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
  private websocketMessageSub: Subscription = new Subscription();
  public enumStatus = Status;
  public validationStatus: Status = this.enumStatus.NotStarted;

  constructor(
    private _data: ExerciseDataService
  ) { }

  public ngOnInit(): void {
    this.handleWebSocketMessage();
  }

  public ngOnDestroy(): void {
    this.websocketMessageSub.unsubscribe();
  }

  public getStatusStyle(): object {
    switch (this.validationStatus) {
    case this.enumStatus.Failed:
    case this.enumStatus.InstallationError:
      return { 'color': '#DB3737' };
    case this.enumStatus.Completed:
      return { 'color': '#409A06' };
    default:
      return {};
    }
  }

  public getStatus(): string {
    switch (this.validationStatus) {
    case Status.Failed:
      return 'Wrong Answer';
    case Status.Completed:
      return 'Correct Answer';
    case Status.InstallationError:
      return 'Validation Error... Please try again!';
    default:
      return Status.NotStarted;
    }
  }

  private handleWebSocketMessage(): void {
    this.websocketMessageSub = this._data.ws.messages$.subscribe(resp => {
      if (resp.exercise?.validationTestsStatus == Status.Failed) {
        this.validationStatus = Status.Failed;
        return;
      }

      if (resp.exercise?.validationTestsStatus == Status.Completed){
        this.validationStatus = Status.Completed;
        return;
      }

      if (resp.exercise?.validationTestsStatus == Status.InstallationError) {
        this.validationStatus = Status.InstallationError;
        return;
      }
    });
  }
}
