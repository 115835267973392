import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Conversation } from 'src/app/models/chat-model/conversation.model';
import { Message } from 'src/app/models/chat-model/message.model';
import { Ticket } from 'src/app/models/ticket.model';

@Component({
  selector: 'chat-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements AfterViewInit, OnDestroy {
  @ViewChild('scrollContainer') private scrollContainer: ElementRef | undefined;

  @Input() conversation: Conversation | undefined;
  @Input() ticket: Ticket | undefined;
  @Input() height: string | undefined;

  private mutationObserver: MutationObserver | undefined;

  constructor(
    public ref: ElementRef,
  ) { }

  ngAfterViewInit(): void {
    this.scrollToBottom();
    this.observeChildListMutation();
  }

  ngOnDestroy() {
    this.mutationObserver?.disconnect();
  }

  async onDownload(fileName: string) {
    const attachments = await this.ticket?.attachments;
    const attachment = attachments?.find(attachment => attachment.name === fileName);
    attachment?.download();
  }

  public onNewMessage(message: Message): void {
    this.conversation?.addNewMessage(message);
  }

  private scrollToBottom(): void {
    const maxScroll = this.scrollContainer?.nativeElement.scrollHeight;
    this.scrollContainer?.nativeElement.scrollTo({ top: maxScroll, behavior: 'smooth' });
  }

  private observeChildListMutation() {
    this.mutationObserver = new MutationObserver(() => {
      this.scrollToBottom();
    });
    this.mutationObserver.observe(this.scrollContainer?.nativeElement, { childList: true });
  }
}
