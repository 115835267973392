<div class="wrapper">
    <h4 class="title" *ngIf="user.isInterviewer">Exercise Plans</h4>
    <h4 class="title" *ngIf="user.isUser">Plans Assigned To You</h4>
    <div class="container" #planList>
        <ng-container *ngIf="plans !== null; else skeleton">
            <a class="plan" 
              [routerLink]="user.isInterviewer ? '/plans-management' : '/plans'" 
              [queryParams]="{id: plan.id}" 
              *ngFor="let plan of widgetData.data$ | async">
                <img class="plan-img" [src]="plan.picture">
                <div class="plan-info">
                    <h5 class="plan-name">{{plan.name}}</h5>
                    <span #description class="plan-description">
                        {{plan.description}}
                    </span>
                </div>
            </a>

            <a class="plans-management-link" 
              routerLink="/plans-management"
              *ngIf="user.isInterviewer && plans && plans.length > 0">
                Plans Management
            </a>

            <a class="plans-management-link" 
              routerLink="/plans"
              *ngIf="user.isUser && plans && plans.length > 0">
                More Plans
            </a>
        </ng-container>

        <div class="no-plan-container"
          *ngIf="plans && plans.length === 0">
            <ng-container *ngIf="user.isInterviewer">
                <h5 class="small-title">There are no exercise plans</h5>
                <div class="no-plan-message">
                    Click <a routerLink="/plans-management">Plans Management</a> to open the plan management tab, where you can create, modify,
                    or delete plans.
                </div>
            </ng-container>
            <ng-container *ngIf="user.isUser">
                <h5 class="small-title">There are no plans assigned to you</h5>
                <div class="no-plan-message">
                    Please wait for a request from a company that can assign an exercise plan, or wait for a new feature
                    that will allow you to create your own plans.
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #skeleton>
   <div *ngFor="let _ of skeletonArray" class="plan">
        <p-skeleton class="plan-img" width="35px" height="35px"></p-skeleton>
        <div class="plan-info">
            <h5 class="plan-name"><p-skeleton width="15%" height="14px"></p-skeleton></h5>
            <p-skeleton width="80%" height="12px"></p-skeleton>
        </div>
   </div>
</ng-template>