import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Exercise } from '../exercise.module';
import { ExerciseDataService } from './exercise-data-service/exercise-data.service';

@Component({
  selector: 'app-exercise-management',
  templateUrl: './exercise-management.component.html',
  styleUrls: ['./exercise-management.component.css']
})
export class ExerciseManagementComponent  implements OnInit{
  @Input() isSelectableModeActive = false;
  @Output() selectedExercises = new EventEmitter<Exercise[]>();
  @Output() closeExerciseManager = new EventEmitter<boolean>();

  public selectedExercise: Exercise | null = null;
  public toolPanelTrigger = false;
  public menuType: ExerciseListParams = 'filter';

  constructor(
    public exerciseData: ExerciseDataService
  ) { }
  
  ngOnInit(): void {
    this.exerciseData.clearSortedExercises();
  }

  public closeDescription(): void {
    this.menuType = 'filter';
  }

  public openDescription(exercise: Exercise): void {
    this.selectedExercise = exercise;
    this.menuType = 'description';
    this.toolPanelTrigger = !this.toolPanelTrigger;
  }
}

export type ExerciseListParams = 'filter' | 'description'
