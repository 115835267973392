import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceComponent } from './workspace.component';
import { TerminalComponent } from './components/terminal/terminal.component';
import { CodeEditorComponent } from './components/code-editor/code-editor.component';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { PlanCompletionComponent } from './components/plan-completion/plan-completion.component';

@NgModule({
  declarations: [
    WorkspaceComponent,
    TerminalComponent,
    CodeEditorComponent,
    PlanCompletionComponent,
  ],
  imports: [
    CommonModule,
    ConfirmDialogModule,
    MonacoEditorModule,
    FormsModule,
    ProgressSpinnerModule,
    SharedModule
  ],
  exports: [
    WorkspaceComponent,
  ]
})
export class WorkspaceModule { }
