import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { LambdasService } from './lambads.service';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BecomeCompanyMemberComponent } from './dialogs/become-company-member/become-company-member.component';
import { ConnectUserDialogComponent } from './dialogs/connect-user-dialog/connect-user-dialog.component';
import { ComponentType } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  private ref!: DynamicDialogRef;
  private routerSubscription: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private lambdas: LambdasService,
    private message: MessageService,
    private dialogService: DialogService,
  ) { }

  public subscribeToRouteParams(): void {
    let inviteId: string | null = null;
    let companyId: string | null = null;
    let state: string | null = null;
    
    this.routerSubscription = this.router.events.subscribe(async () => {
      if (!this.auth.user) {
        return;
      }
      
      inviteId = this.route.snapshot.queryParamMap.get('invite_id');
      companyId = this.route.snapshot.queryParamMap.get('company_id');
      state = this.route.snapshot.queryParamMap.get('state');
      
      if (inviteId && companyId) {
        this.routerSubscription.unsubscribe();
        await this.handleInvite(inviteId, companyId);
        return;
      }

      if (state) {
        this.routerSubscription.unsubscribe();
        await this.getInviteDataFromState(state);
      }
    });
  }

  public unsubscribeRouteParams(): void {
    this.routerSubscription.unsubscribe();
  }

  public async getInviteDataFromState(state: string): Promise<void> {
    const inviteInfo = this.getInvitationInfo(state);
    if (inviteInfo) {
      await this.handleInvite(inviteInfo.invite_id, inviteInfo.company_id);
    }
  }

  private getInvitationInfo(state: string): InviteInfo | undefined {
    const parts = state.split('-');
    if (parts.length > 1) {
      const encodedState = parts[1];
      const decodedStateString = this.hexToString(encodedState);
      const inviteInfo: InviteInfo = JSON.parse(decodedStateString);
      if (inviteInfo.company_id && inviteInfo.invite_id) {
        return inviteInfo;
      }
    }
    return undefined;
  }

  public async handleInvite(inviteId: string, companyId: string): Promise<void> {
    if (this.auth.user.isInterviewer) {
      return;
    }
 

    try {
      const response = await this.lambdas.validateInviteId(inviteId, companyId).toPromise();
      if (response.company_name && response.invite_type === 'user' && response.status === 'Success') {
        this.openInviteDialog(inviteId, companyId, response.company_name, ConnectUserDialogComponent);
        return;
      }
      if (response.company_name && response.invite_type === 'member' && response.status === 'Success') {
        this.openInviteDialog(inviteId, companyId, response.company_name, BecomeCompanyMemberComponent);
      }
    } catch (err) {
      const error = err as Error;
      this.message.add({ severity: 'error', summary: 'Error', detail: error.message });
    }
  }

  public openInviteDialog(inviteId: string, companyId: string, companyName: string, dialogComponent: ComponentType<unknown>): void {
    this.initializeDialog(inviteId, companyId, companyName, dialogComponent);
    this.handleDialogClosure();
  }

  private initializeDialog(inviteId: string, companyId: string, companyName: string, dialogComponent: ComponentType<unknown>): void {
    this.ref = this.dialogService.open(dialogComponent, {
      data: { inviteId, companyId, companyName },
      showHeader: false,
      width: '460px',
      contentStyle: { 'max-height': '500px', 'overflow': 'auto', 'border-radius': '15px', 'padding-top': '2rem' },
      baseZIndex: 10000
    });
  }

  private handleDialogClosure(): void {
    const sub = this.ref.onClose.subscribe(async (result) => {
      sub.unsubscribe();
      await this.clearQueryParams();
      this.unsubscribeRouteParams();
      if (!result) return;

      this.message.add({ severity: 'success', summary: 'Success', detail: 'You are now member of company' });
      await this.auth.refreshUser(true);
    });
  }

  private async clearQueryParams(): Promise<void> {
    await this.router.navigate([]);
  }

  private hexToString(hex: string): string {
    let str = '';
    for (let i = 0; i < hex.length; i += 2) {
      const hexPair = hex.slice(i, i + 2);
      const charCode = parseInt(hexPair, 16);
      str += String.fromCharCode(charCode);
    }
    return str;
  }
}

export interface InviteInfo {
  invite_id: string;
  company_id: string;
}
