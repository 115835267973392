import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class NameControlService {
  private userNameControl: FormControl;

  constructor() {
    this.userNameControl = new FormControl(
      { value: '', disabled: false },
      [Validators.pattern('^[a-zA-Z]+( [a-zA-Z]+)*$'), Validators.minLength(3)]);
  }

  public get control(): FormControl {
    return this.userNameControl;
  }

  public get isEnabled(): boolean {
    return this.userNameControl.enabled;
  }

  public enableInput(): void {
    this.userNameControl.enable();
  }

  public disableInput(): void {
    this.userNameControl.disable();
  }

  public hasError(): boolean {
    return this.userNameControl.invalid;
  }

  public isEmpty(): boolean {
    return !this.userNameControl.value;
  }

  public clearInput(): void {
    this.userNameControl.reset();
  }

  public getName(): string {
    return this.userNameControl.value ?? '';
  }

  public getErrorMessage(): string {
    if (this.userNameControl.hasError('pattern')) {
      return 'Name can only contain letters with a space between the first and last name. It should not start or end with a space.';
    } else if (this.userNameControl.hasError('minlength')) {
      return 'Name must be at least 3 characters long.';
    }
    return 'Failed to change name, please try again later :)';
  }
}