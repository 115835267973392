import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExerciseDataService } from '../../../../services/exercise-data.service';
import { Status } from '../../../../exercise.component';
import { TerminalService } from 'src/app/terminal.service';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss']
})
export class TerminalComponent implements OnInit, OnDestroy, AfterViewInit {
  private fontSizeEventSub: Subscription = new Subscription();
  private colorEventSub: Subscription = new Subscription();
  private websoccketMessageSub: Subscription = new Subscription();
  private terminalInputEventSub: Subscription = new Subscription();
  private terminalResizeEventSub: Subscription = new Subscription();
  private terminal: TerminalService = new TerminalService(this._data.theme === 'dark');
  private terminalMutationObserver: MutationObserver | null = null;
  constructor(
    private _data: ExerciseDataService,
  ) { }

  ngAfterViewInit(): void {
    this.initTerminalMutationObserver();
  }

  ngOnInit(): void {
    this.handleTerminalResizeEvent();
    this.handleTerminalInputEvent();
    this.handleWebsocketMessage();
    this.handleColorEvent();
    this.handleFontSizeEvent();
  }

  ngOnDestroy(): void {
    this.terminalMutationObserver?.disconnect();
    this.websoccketMessageSub.unsubscribe();
    this.terminalInputEventSub.unsubscribe();
    this.terminalResizeEventSub.unsubscribe();
    this.colorEventSub.unsubscribe();
    this.fontSizeEventSub.unsubscribe();
    this.terminal.dispose();
  }

  private handleColorEvent(): void {
    this.colorEventSub = this._data.theme$.subscribe(event => {
      this.terminal.changeTheme(event);
    });
  }

  private handleFontSizeEvent(): void {
    this.fontSizeEventSub = this._data.fontSize$.subscribe(event => {
      this.terminal.changeFontSize(Number(event));
    });
  }

  private handleTerminalResizeEvent(): void {
    this.terminalResizeEventSub = this.terminal.resizeEvent$.subscribe(event => {
      if (event) {
        this._data.ws.sendMessage(JSON.stringify({ type: 'resize', resize: { width: event.cols, height: event.rows } }));
      }
    });
  }

  private handleTerminalInputEvent(): void {
    this.terminalInputEventSub = this.terminal.inputEvent$.subscribe(event => {
      if (event) {
        this._data.ws.sendMessage(JSON.stringify({ type: 'input', input: event }));
      }
    });
  }

  private initTerminalMutationObserver(): void {
    const terminalDiv = document.getElementById('terminal');
    if (!terminalDiv) return;

    this.terminalMutationObserver = new MutationObserver(() => {
      if (terminalDiv.clientWidth > 0 && this.terminal.proposeDimensions) {
        this.terminal.resize();
        this.terminalMutationObserver?.disconnect();
      }
    });

    this.terminalMutationObserver.observe(terminalDiv, { 
      childList: true, 
      subtree: true,
      characterData: false 
    });
  }

  private handleWebsocketMessage(): void {
    this.websoccketMessageSub = this._data.ws.messages$.subscribe(resp => {
      if (resp.terminal?.output) {
        this.terminal.write(resp.terminal.output);
      }

      if (resp.terminal?.status === Status.Created) {
        this.openTerminal();
      }
    });
  }

  private openTerminal(): void {
    if (this.terminal.isOpen) {
      this.terminal.resetTerminal();
      return;
    }
    this.terminal.create();
  }
}
