import { Component, OnInit } from '@angular/core';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { AuthService } from 'src/app/auth/auth.service';
import { LambdasService } from 'src/app/lambads.service';
import { MessageService } from 'primeng/api';
import { StatsDataService } from '../stats/service/stats-data.service';
import { UserPublicProfile } from 'src/app/models/user-public-profile.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public members: UserPublicProfile[] = [];
  public membersEmailLists: string[] = [];

  constructor(
    private dynamodb: DynamoDBService,
    private lambdas: LambdasService,
    private messageService: MessageService,
    public statsData: StatsDataService,
    public auth: AuthService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.statsData.fetch();
    if (this.auth.user.isInterviewer) {
      this.members = await this.dynamodb.getCompanyMembers(this.auth.user.companyId);
      this.membersEmailLists = this.members.map(user => user.email);
    }
  }

  public async onMemberAdd(member: UserPublicProfile): Promise<void> {
    this.members.push(member);
  }

  public async onMemberDelete(member: UserPublicProfile): Promise<void> {
    try {
      await this.deleteMemberFromDB(member);
      this.removeMemberFromArray(member);
    } catch (err) {
      if (err instanceof Error && err.message) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err.message });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong :(' });
      }
    }
  }

  private removeMemberFromArray(memberToDelete: UserPublicProfile): void {
    this.members = this.members.filter(member => member.email !== memberToDelete.email);
  }

  private async deleteMemberFromDB(member: UserPublicProfile): Promise<void> {
    if (member.inviteID) {
      await this.dynamodb.deleteInvite(member.inviteID, this.auth.user.companyId);
    } else {
      await this.lambdas.deleteMember(member.email, this.auth.user.companyId).toPromise();
    }
  }
}
