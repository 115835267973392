import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  @Input() redCirclePositionX = 0;
  @Input() redCirclePositionY = 0;
  @Input() yellowCirclePositionX = 0;
  @Input() yellowCirclePositionY = 0;
  @Input() statNumber = 0;
  @Input() statDescription = '';
  @Input() routerLink = '';

  private sides = ['top', 'right', 'bottom', 'left'];

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    if (this.redCirclePositionX && this.redCirclePositionY) {
      this.positionManualy('.circle.red', this.redCirclePositionX, this.redCirclePositionY);
    } else {
      this.positionRandomly('.circle.red');
    }

    if (this.yellowCirclePositionX && this.yellowCirclePositionY) {
      this.positionManualy('.circle.yellow', this.yellowCirclePositionX, -this.yellowCirclePositionY);
    } else {
      this.positionRandomly('.circle.yellow');
    }
  }

  positionManualy(selector: string, positionX: number, positionY: number): void {
    const element = this.el.nativeElement.querySelector(selector);
    this.renderer.setStyle(element, 'transform', `translate(${positionX}px, ${positionY}px)`);
  }

  positionRandomly(selector: string): void {
    const element = this.el.nativeElement.querySelector(selector);
    const chosenSide = this.sides[Math.floor(Math.random() * this.sides.length)];

    switch (chosenSide) {
    case 'top':
      this.renderer.setStyle(element, 'top', '0%');
      this.renderer.setStyle(element, 'left', `${Math.random() * 110}%`);
      break;
    case 'right':
      this.renderer.setStyle(element, 'top', `${Math.random() * 110}%`);
      this.renderer.setStyle(element, 'left', '110%');
      break;
    case 'bottom':
      this.renderer.setStyle(element, 'top', '110%');
      this.renderer.setStyle(element, 'left', `${Math.random() * 110}%`);
      break;
    case 'left':
      this.renderer.setStyle(element, 'top', `${Math.random() * 110}%`);
      this.renderer.setStyle(element, 'left', '0%');
      break;
    }
    this.sides = this.sides.filter(side => side !== chosenSide);
  }
}
