import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { inviteType } from '../../user-list.component';
import { LambdasService, Response } from 'src/app/lambads.service';
import { UserPublicProfile } from 'src/app/models/user-public-profile.model';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.scss']
})
export class InviteDialogComponent {
  public title: string | undefined;
  public content: string | undefined;
  public emailControl: FormControl = new FormControl('', [Validators.email]);
  public isLoading = false;
  private inviteType: inviteType | undefined;

  constructor(
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private lambdas: LambdasService,
    private messageService: MessageService,
    private auth: AuthService
  ) {
    this.inviteType = this.config.data.inviteType;

    switch (this.inviteType) {
    case 'member':
      this.renderMemberTemplate();
      return;
    case 'connection':
      this.renderConnectionTemplate();
      return;
    default:
      ref.close();
    }
  }

  private renderConnectionTemplate(): void {
    this.title = 'Invite new user';
    this.content = 'Connect with users via email to collaborate. You will be able to assign interview plans to them after acceptance.';
  }

  private renderMemberTemplate(): void {
    this.title = 'Invite new member';
    this.content = 'Invite a new member to join your company via email. The recipient will receive an invitation message. Please note that the invite link will automatically expire after two weeks or upon acceptance.';
  }

  public async invite(): Promise<void> {
    if (!this.inviteType) {
      return;
    }

    if (this.auth.user.email === this.emailControl.value) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'This email is already attached to this company' });
      return;
    }

    try {
      const response = this.inviteType === 'member' ?
        await this.lambdas.inviteMember(this.emailControl.value).toPromise() :
        await this.lambdas.inviteUser(this.emailControl.value).toPromise();

      this.ref.close(new UserPublicProfile({
        firstName: 'Unknown',
        email: this.emailControl.value,
        inviteId: this.getInviteId(response)
      }));
    } catch (err) {
      const error = err as Error;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
    }
  }

  private getInviteId(response: Response): string {
    if (response.invite_id) {
      return response.invite_id;
    }
    throw new Error(`Failed to invite ${this.inviteType}`);
  }
}
