import { Injectable } from '@angular/core';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { Exercise } from 'src/app/exercise.module';
import { AuthService } from 'src/app/auth/auth.service';
import { WebsocketService } from './websocket.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageItems, LocalStorageService } from 'src/app/shared/local-storage.service';
import { TaskStatus } from 'src/app/status-parser';
import { ExerciseStatus } from 'src/app/models/exercise-status.model';

@Injectable({
  providedIn: 'root'
})
export class ExerciseDataService {
  public ws: WebsocketService = new WebsocketService();
  private exerciseSubject: BehaviorSubject<Exercise | null> = new BehaviorSubject<Exercise | null>(null);
  public exercise$: Observable<Exercise | null> = this.exerciseSubject.asObservable();

  // The initial state of nextExerciseSubject is undefined, indicating that the exercise data has not been fetched yet.
  // Once the next exercise is fetched, it will emit either an ExerciseStatus object or null, depending on the result.
  private nextExerciseSubject: BehaviorSubject<ExerciseStatus | null | undefined> = new BehaviorSubject<ExerciseStatus | null | undefined>(undefined);
  public nextExercise$: Observable<ExerciseStatus | null | undefined> = this.nextExerciseSubject.asObservable();

  private themeSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this._localStorage.getItem(LocalStorageItems.Theme) ?? 'dark');
  public theme$: Observable<string> = this.themeSubject.asObservable();

  private fontSizeSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this._localStorage.getItem(LocalStorageItems.FontSize) ?? '14');
  public fontSize$: Observable<string> = this.fontSizeSubject.asObservable();

  constructor(
    private _dynamoDBService: DynamoDBService,
    private _auth: AuthService,
    private _localStorage: LocalStorageService,
  ) {
    document.body.setAttribute('font-size', this.fontSize);
  }

  get exercise(): Exercise | null {
    return this.exerciseSubject.getValue();
  }

  get theme(): string {
    return this.themeSubject.getValue();
  }

  get fontSize(): string {
    return this.fontSizeSubject.getValue();
  }

  public changeTheme(theme: string): void {
    this.themeSubject.next(theme);
    this._localStorage.setItem(LocalStorageItems.Theme, theme);
    document.body.setAttribute('data-theme', theme);
  }

  public changeFontSize(fontSize: string): void {
    this.fontSizeSubject.next(fontSize);
    this._localStorage.setItem(LocalStorageItems.FontSize, fontSize);
    document.body.setAttribute('font-size', fontSize);
  }

  public async load(exerciseId: string): Promise<void> {
    this.clearExerciseSubjectData();

    const exercise = await this._dynamoDBService.getExercise(exerciseId);
    if (!exercise) {
      throw new Error('Exercise not found');
    }

    this.exerciseSubject.next(exercise);
  }

  public async loadNextExerciseData(assignedPlanId: string, currentExerciseId: string): Promise<void> {
    const exercises = await this._dynamoDBService.getUserExercisePlanStatus(assignedPlanId, this._auth.user.email, [TaskStatus.NOT_STARTED, TaskStatus.RUNNING]);
    const nextExercise = exercises?.find(status => status.exerciseID != currentExerciseId) ?? null;
    this.nextExerciseSubject.next(nextExercise);
  }

  public async createConnection(assignedPlanID: string, exerciseID: string, urlID: string): Promise<WebsocketService> {
    await this.ws.connect(assignedPlanID, exerciseID, urlID);
    return this.ws;
  }

  private clearExerciseSubjectData(): void {
    this.exerciseSubject.next(null);
  }
}
