<p-avatar 
  [image]="newPicture ? newPicture : user.picture.url" 
  (click)="fileInput.click()"
  styleClass="w-8rem h-8rem mb-4 mt-2 cursor-pointer border-1 border-300"
  shape="circle">
</p-avatar>


<input 
  #fileInput
  class="hidden" 
  type="file" 
  accept="image/*"
  (change)="onFileSelected($event)">