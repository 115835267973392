import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'formControl'
})
export class FormControlPipe implements PipeTransform {

  transform(formGroup: FormGroup, controlName: string): FormControl | null {
    return formGroup.get(controlName) as FormControl ?? null;
  }
}
