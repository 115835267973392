import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserCompatibilityAlarmComponent } from './components/warning-window/browser-compatibility-alarm.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    BrowserCompatibilityAlarmComponent
  ],
  imports: [
    CommonModule,
    ConfirmDialogModule,
    SharedModule
  ],
  exports: [
    BrowserCompatibilityAlarmComponent
  ]
})
export class BrowserCompatibilityWarningModule { }
