import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, from } from 'rxjs';
import { delay, takeWhile, concatMap, finalize } from 'rxjs/operators';
import { S3Service } from '../s3.service';
import { TerminalService } from '../terminal.service';
import { Exercise } from '../exercise.module';
import { DynamoDBService } from '../dynamodb.service';
import { Record } from '../s3.service';
import { ExerciseStatus } from '../models/exercise-status.model';
import { AssignedPlan } from '../models/assigned-plan.model';

@Component({
  selector: 'app-replay',
  templateUrl: './replay.component.html',
  styleUrls: ['./replay.component.scss']
})
export class ReplayComponent implements OnInit {
  public companyId: string | null = null;
  public assignedPlanId: string | null = null;
  public exerciseId: string | null = null;
  public userEmail: string | null = null;
  public base64UserEmail: string | null = null;
  public candidateName: string | undefined;

  public exerciseStatus: ExerciseStatus | null = null;
  public exercise: Exercise | null = null;
  public assignedPlan: AssignedPlan | null = null;

  public currentTimestamp = '';

  public isInfoShowed = false;
  public recordsExist = false;
  public startReplay = false;
  public isPaused = true;
  public speed = 1;
  public recordLength = 0;

  public delay = 300;
  public timeIndex = 0;

  public records: Record[] = [];

  public terminal: TerminalService = new TerminalService();

  public error: string | null = null;
  public loadingMsg: string | null = 'Loading...';

  constructor(
    private route: ActivatedRoute,
    private s3: S3Service,
    private dynamoDBService: DynamoDBService,
  ) { }

  async ngOnInit(): Promise<void> {
    document.body.setAttribute('data-theme', 'dark');
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.assignedPlanId = this.route.snapshot.paramMap.get('assignedPlanId');
    this.exerciseId = this.route.snapshot.paramMap.get('exerciseId');
    this.base64UserEmail = this.route.snapshot.paramMap.get('base64Email');

    if (!this.base64UserEmail ||
      !this.companyId ||
      !this.assignedPlanId ||
      !this.exerciseId) {
      this.error = 'Failed to load the replay. Please try again later';
      return;
    }

    try {
      this.userEmail = atob(this.base64UserEmail);
      await Promise.all([
        this.fetchExercise(),
        this.fetchExerciseStatus(),
        this.fetchAssignedPlan(),
        this.fetchRecord()
      ]);
    } catch (err) {
      this.error = 'Records not found. Please try again later';
    }

    try {
      this.recordsExist = true;
      this.recordLength = this.records.length;
      this.loadingMsg = null;
      this.terminal.create();
      this.terminal.cols = this.getMaxWindowWidth(this.records);
    } catch {
      this.error = 'Failed to create terminal. Please try again later';
    }
  }

  private async fetchExercise(): Promise<void> {
    this.exercise = await this.dynamoDBService.getExercise(this.exerciseId!);
  }

  private async fetchExerciseStatus(): Promise<void> {
    this.exerciseStatus = await this.dynamoDBService.getInterviewerExerciseStatus(this.assignedPlanId!, this.userEmail!, this.companyId!, this.exerciseId!);
  }

  private async fetchAssignedPlan(): Promise<void> {
    this.assignedPlan = await this.dynamoDBService.getAssignedPlan(this.userEmail!, this.companyId!, this.assignedPlanId!);
    this.candidateName = this.assignedPlan.candidate.fullNameOrEmail;
  }

  private async fetchRecord(): Promise<void> {
    const records = await this.s3.getRecords(this.companyId!, this.assignedPlanId!, this.exerciseId!, this.base64UserEmail!);
    if (!records) {
      throw Error('No records');
    }
    this.records = records;
  }

  private async writeRecordsToTerminal(records: Record[]): Promise<void> {
    if (this.timeIndex == 0)
      this.terminal.clearTerminal();

    if (records) {
      const source$ = from(records);

      source$.pipe(
        concatMap((record) => {
          this.timeIndex++;
          this.currentTimestamp = record.time.substring(0, 19);
          this.terminal.write(record.terminalOutput);
          return of(record).pipe(delay(this.delay));
        }),
        takeWhile(() => !this.isPaused && this.timeIndex <= this.recordLength),
        finalize(() => {
          if (this.timeIndex == this.recordLength - 1) {
            this.terminal.write('');
          }
          this.isPaused = true;
        })
      ).subscribe();
    }
  }

  public async togglePause(): Promise<void> {
    this.startReplay = true;
    this.isPaused = !this.isPaused;
    if (this.records && !this.isPaused) {
      if (this.timeIndex == this.recordLength - 1)
        this.timeIndex = 0;
      this.writeRecordsToTerminal(this.records.slice(this.timeIndex, -1));
    }
  }

  public changeSpeed(speed: GLfloat): void {
    switch (speed) {
    case 1: {
      if (this.speed < 10) {
        if (this.speed > 1)
          this.speed += 1;
        else
          this.speed *= 2;
      }
      break;
    }
    case -1: {
      if (this.speed > 0.25) {
        if (this.speed > 1)
          this.speed -= 1;
        else
          this.speed /= 2;
      }
      break;
    }
    }
    this.delay = 300 / this.speed;
  }

  public async onSliderChange(): Promise<void> {
    this.fastRecordWrite(this.records.slice(0, this.timeIndex));
  }

  public fastRecordWrite(records: Record[]): void {
    this.terminal.clearTerminal();
    this.terminal.write('\n');
    for (const line of records) {
      this.terminal.write(line.terminalOutput);
    }
  }

  private getMaxWindowWidth(record: Record[]): number {
    return record.reduce((maxWidth, current) => Math.max(maxWidth, current.rows), 0);
  }
}
