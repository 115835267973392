import { Pipe, PipeTransform } from '@angular/core';
import { CustomizeText } from '../../customize-description.service';

@Pipe({
  name: 'markdown'
})
export class MarkdownPipe implements PipeTransform {
  constructor(
        private markdownService: CustomizeText
  ) { }

  transform(rawText: string, dynamicDescription?: string): string {
    return this.markdownService.render(rawText, dynamicDescription);
  }
}