import { Component } from '@angular/core';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MenuDataService } from 'src/app/side-menu/menu-data.service';
import { SupportDialogComponent } from 'src/app/support/components/support-dialog/support-dialog.component';

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    public menuData: MenuDataService,
  ) { }

  public openSubmit(): void {
    this.dialogService.open(SupportDialogComponent, {
      width: '550px',
      baseZIndex: 10000,
      showHeader: true,
      dismissableMask: true,
      header: 'Submit Ticket'
    });
    this.ref.close();
  }

  public closeDialog(): void {
    this.ref.close();
  }
}
