import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { GetCountryNames } from '../../service/countries';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'countries-dropdown',
  templateUrl: './countries-dropdown.component.html',
  styleUrls: ['./countries-dropdown.component.scss']
})
export class CountriesDropdownComponent implements OnInit {
  @ViewChild(NgSelectComponent, { static: false }) ngSelect!: NgSelectComponent;
  @Input() inputControl: FormControl | null = null;
  @Input() isRequired = false;
  @Output() onChange: EventEmitter<void> = new EventEmitter<void>();

  public countries: string[] = [];

  ngOnInit(): void {
    this.countries = GetCountryNames();
  }

  public onDropdownClose(): void {
    this.ngSelect.blur();
  }

  public customSearchFn(input: string, country: string): boolean {
    return country.toLowerCase().startsWith(input.toLowerCase());
  }
}
