import { Component } from '@angular/core';
import { LambdasService } from 'src/app/lambads.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-connect-user-dialog',
  templateUrl: './connect-user-dialog.component.html',
  styleUrls: ['./connect-user-dialog.component.scss']
})
export class ConnectUserDialogComponent {
  public isRequestSent = false;
  public data: { inviteId: string, companyId: string, companyName: string, email: string };

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private lambda: LambdasService,
    private messageService: MessageService
  ) {
    this.data = config.data;
    if (!this.data)  {
      this.data = {
        inviteId: '',
        companyId: '',
        companyName: '',
        email: ''
      };
    }
  }

  public connect(): void {
    this.isRequestSent = true;
    this.lambda.connectUserToCompany(
      this.data.email, 
      this.data.inviteId, 
      this.data.companyId).subscribe({
      next: async () => this.closeDialog(true),
      error: (error) => this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message }),
      complete: () => this.isRequestSent = false
    });
  }

  public closeDialog(result: boolean): void {
    this.ref.close(result);
  }
}
