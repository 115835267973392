<section>
    <div class="setting">
        <span>Theme:</span>
        <p-dropdown 
         [(ngModel)]="currentTheme"
         [options]="themes" 
         optionLabel="name"
         (onChange)="onThemeChange($event)">
        </p-dropdown>
    </div>
    <div class="setting">
        <span>Font size:</span>
        <p-dropdown 
         [(ngModel)]="currentFontSize"
         [options]="fontSize" 
         optionLabel="name"
         (onChange)="onFontSizeChange($event)">
        </p-dropdown>
    </div>
</section>