import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { InviteService } from './invite.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  private popupOpenSubscription: Subscription | null = null;
  private popupCloseSubscription: Subscription | null = null;
  private initializeSubscription: Subscription | null = null;
  private statusChangeSubscription: Subscription | null = null;
  private revokeChoiceSubscription: Subscription | null = null;
  private noCookieLawSubscription: Subscription | null = null;


  constructor(
    private ccService: NgcCookieConsentService,
    private auth: AuthService,
    private inviteService: InviteService
  ) { }

  async ngOnInit(): Promise<void> {
    this.subscribeToCookieEvents();
    this.inviteService.subscribeToRouteParams();
    await this.auth.isAuthenticated();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromCookieEvents();
    this.inviteService.unsubscribeRouteParams();
  }

  private subscribeToCookieEvents(): void {
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe();
    this.popupCloseSubscription = this.ccService.popupClose$.subscribe();
    this.initializeSubscription = this.ccService.initialize$.subscribe();
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe();
    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe();
    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe();
  }

  private unsubscribeFromCookieEvents(): void {
    if (this.popupCloseSubscription) {
      this.popupCloseSubscription.unsubscribe();
    }

    if (this.popupOpenSubscription) {
      this.popupOpenSubscription.unsubscribe();
    }

    if (this.initializeSubscription) {
      this.initializeSubscription.unsubscribe();
    }

    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }

    if (this.revokeChoiceSubscription) {
      this.revokeChoiceSubscription.unsubscribe();
    }

    if (this.noCookieLawSubscription) {
      this.noCookieLawSubscription.unsubscribe();
    }
  }
}


export interface InviteInfo {
  invite_id: string;
  company_id: string;
}