<h2 class="m-0 text-gray-900">{{title}}</h2>
<p> {{content}} </p>

<div class="w-100 flex flex-column mb-4">
    <label>Email</label>
    <app-input-field
        name="email"
        type="text"
        placeholder=""
        [error]="this.emailControl.invalid ? 'Invalid email format' : ''"
        [inputControl]="this.emailControl"
        [isRequired]="true">
    </app-input-field>
</div>

<app-loading-animation *ngIf="isLoading"></app-loading-animation>

<div *ngIf="!isLoading" class="flex justify-content-end">
    <simple-button (click)="ref.close()" class="mr-4">Cancel</simple-button>
    <simple-button (click)="invite()" [disabled]="this.emailControl.invalid">Send</simple-button>
</div>