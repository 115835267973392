import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AssignedPlansDataService } from '../data-service/assigned-plans-data.service';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { Subscription, interval } from 'rxjs';
import { CountTasksByStatus, TaskStatus } from 'src/app/status-parser';
import { HintMessages } from 'src/app/plans-management/plan-editor/help/help.component';
import { AuthService } from 'src/app/auth/auth.service';
import { GetStatusColor, ScrollToTop } from 'src/app/shared/service/ui';
import { User } from 'src/app/user.module';
import { ExerciseStatus } from 'src/app/models/exercise-status.model';
import { AssignedPlan, PlanStatus } from 'src/app/models/assigned-plan.model';
import { UserPublicProfile } from 'src/app/models/user-public-profile.model';
import { takeWhile, tap } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-assigned-plan-info-panel',
  templateUrl: './assigned-plan-info-panel.component.html',
  styleUrls: ['./assigned-plan-info-panel.component.scss']
})
export class AssignedPlanInfoPanelComponent implements OnInit {
  @ViewChild('mainContainer') mainContainer!: ElementRef;

  public readonly dialogKey = 'cancel-plan-dialog';
  public taskStatus = TaskStatus;
  public planStatus = PlanStatus;
  public helpMessage = HintMessages;
  public plan: AssignedPlan | null = null;
  public currentMember: UserPublicProfile | undefined;
  public currentPlanConnection: UserPublicProfile | undefined;
  public exerciseStatuses: ExerciseStatus[] | null = null;
  public circleProgress: Progress[] = [];
  public user: User = this.auth.user;

  private selectedPlanSubscription: Subscription | null = null;
  private tickerSubscription: Subscription | null = null;

  constructor(
    public assignedPlanData: AssignedPlansDataService,
    private dialog: ConfirmationService,
    private dynamodb: DynamoDBService,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.subToSelectedPlan();
  }

  ngOnDestroy(): void {
    this.selectedPlanSubscription?.unsubscribe();
    this.tickerSubscription?.unsubscribe();
  }

  public cancelAssignedPlan(plan: AssignedPlan): void {
    this.dialog.confirm({
      dismissableMask: true,
      key: this.dialogKey,
      header: plan.isNotStarted || plan.isFinished || plan.isRejected ? 'Delete assigned plan' : 'Cancel assigned plan',
      message: plan.isNotStarted || plan.isFinished || plan.isRejected ?
        `Do you want to delete plan the "${plan.planName}"?` :
        `Do you want to cancel plan "${plan.planName}" assigned to user "${plan.candidate.fullName}"?`,
      accept: () => {
        this.assignedPlanData.deleteAssignedPlan(plan);
        ScrollToTop('scrollTo');
      },
    });
  }

  private subToSelectedPlan(): void {
    this.selectedPlanSubscription = this.assignedPlanData.selectEvent.subscribe(async plan => {
      if (!plan) {
        this.plan = null;
        return;
      }
      this.plan = plan;
      this.tickerSubscription?.unsubscribe();
      await this.getExercisePlanStatus(plan);
      this.getPlanMember(plan);
      this.getConnectedUser(plan);
      this.setCircleProgress();
      this.startTimer(plan);
    });
  }

  private startTimer(plan: AssignedPlan): void {
    this.tickerSubscription = interval(1000).pipe(
      tap(() => plan.remainingTime > 0 && plan.remainingTime--),
      takeWhile(() => plan.remainingTime > 0)
    ).subscribe();
  }

  public getStatusColor(statusString?: TaskStatus | PlanStatus): string {
    if (statusString) {
      return GetStatusColor(statusString);
    }
    return TaskStatus.NOT_STARTED;
  }

  private getConnectedUser(plan: AssignedPlan): void {
    const currentConnection = this.assignedPlanData.companyMembersAndConnections?.find(user => user.email === plan.candidate.email);
    if (currentConnection) {
      this.currentPlanConnection = currentConnection;
    } else {
      this.currentPlanConnection = plan.candidate;
    }
  }

  private getPlanMember(plan: AssignedPlan): void {
    const currentMember = this.assignedPlanData.companyMembersAndConnections?.find(user => user.email === plan.interviewer?.email);
    if (currentMember) {
      this.currentMember = currentMember;
    } else {
      this.currentMember = plan.interviewer;
    }
  }

  private async getExercisePlanStatus(plan: AssignedPlan): Promise<void> {
    this.exerciseStatuses = await this.dynamodb.getInterviewerExerciseStatuses(plan.id, plan.candidate.email, plan.interviewerCompanyID);
  }

  private setCircleProgress(): void {
    if (!this.exerciseStatuses) {
      return;
    }

    const exerciseCount = this.exerciseStatuses.length;
    const statuses = this.exerciseStatuses.map(status => status.status!);
    const completedTasks = CountTasksByStatus(statuses, TaskStatus.COMPLETED);
    const runningTasks = CountTasksByStatus(statuses, TaskStatus.RUNNING);
    const notStartedTasks = CountTasksByStatus(statuses, TaskStatus.NOT_STARTED);
    const failedTasks = CountTasksByStatus(statuses, TaskStatus.TIME_IS_OVER) +
      CountTasksByStatus(statuses, TaskStatus.CANCELED);

    this.circleProgress = [
      {
        name: 'Not Started',
        percent: Math.floor(notStartedTasks / exerciseCount * 100),
        outerColor: '#4D4D4D',
        totalTasks: exerciseCount,
        otherTasks: notStartedTasks,
        innerColor: '#F5F5F5'
      },
      {
        name: 'In Progress',
        percent: Math.floor(runningTasks / exerciseCount * 100),
        outerColor: '#7DA7D9',
        totalTasks: exerciseCount,
        otherTasks: runningTasks,
        innerColor: '#c9ecff'
      },
      {
        name: 'Completed',
        percent: Math.floor(completedTasks / exerciseCount * 100),
        outerColor: '#4DAF7C',
        totalTasks: exerciseCount,
        otherTasks: completedTasks,
        innerColor: '#D0F0C0'
      },
      {
        name: 'Failed',
        percent: Math.floor(failedTasks / exerciseCount * 100),
        outerColor: '#FF6B6B',
        totalTasks: exerciseCount,
        otherTasks: failedTasks,
        innerColor: '#FDEDEC'
      },
    ];
  }
}

export interface Progress {
  name: string;
  percent: number;
  totalTasks: number;
  otherTasks: number;
  outerColor: string;
  innerColor: string;
}
