import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  screenSize: 'small' | 'medium' | 'large' = 'large';

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.updateScreenSize(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: Event): void {
    const window = event.target as Window;
    this.updateScreenSize(window.innerWidth);
  }

  private updateScreenSize(width: number): void {
    if (width < 526) {
      this.screenSize = 'small';
    } else if (width >= 526 && width < 1025) {
      this.screenSize = 'medium';
    } else {
      this.screenSize = 'large';
    }
    this.cdr.markForCheck();
  }
}

