import moment from 'moment-mini';

export function UTCDateToLocalDate(date: string): string {
  return moment(date).local().format('YYYY-MM-DD HH:mm');

}

export function LocalDateToUTCDate(date: string): string {
  return moment(date).utc().format();
}
