import { TCountryCode } from 'countries-list';

export class Address {
  private _countryCode: TCountryCode | undefined;
  private _state: string | undefined;
  private _city: string;
  private _postcode: string;
  private _street: string;
  private _attrs: AddressAttributes;

  constructor(
    attr: AddressAttributes
  ) {
    this._countryCode = attr.country_code;
    this._state = attr.state;
    this._city = attr.city;
    this._postcode = attr.postcode;
    this._street = attr.street;
    this._attrs = attr;
  }

  public get countryCode(): TCountryCode | undefined {
    return this._countryCode;
  }

  public get state(): string | undefined {
    return this._state;
  }

  public get city(): string {
    return this._city;
  }

  public get postcode(): string {
    return this._postcode;
  }

  public get street(): string {
    return this._street;
  }

  public toJSON() {
    return this._attrs;
  }
}

interface AddressAttributes {
  country_code: TCountryCode | undefined;
  state: string | undefined;
  city: string;
  postcode: string;
  street: string;
}