import { TCountryCode } from 'countries-list';
import { AuthService } from '../auth/auth.service';
import { ServiceLocator } from '../service-locator.service';
import { GetCountryByCode } from '../shared/service/countries';
import { Picture } from './picture.model';

export class UserPublicProfile {
  constructor(
    attributes: Attributes
  ) {
    this._email = attributes.email;
    this._fullName = attributes.fullName;
    this._firstName = attributes.firstName;
    this._lastName = attributes.lastName;
    this._countryCode = attributes.countryCode as TCountryCode;
    this._phoneNumber = attributes.phoneNumber;
    this._inviteId = attributes.inviteId;
    this._role = attributes.role;
    this._picture = new Picture(attributes.picture);

    this._authService = ServiceLocator.injector.get(AuthService);
  }

  private _email: string;
  private _picture: Picture;
  private _countryCode: TCountryCode | undefined;
  private _fullName: string | undefined;
  private _firstName: string | undefined;
  private _lastName: string | undefined;
  private _phoneNumber: string | undefined;
  private _inviteId: string | undefined;
  private _role: string | undefined;

  private _authService: AuthService;

  public get email(): string {
    return this._email;
  }

  public get fullNameOrEmail(): string {
    return this.fullName ? this.fullName : this._email;
  }

  public get fullName(): string | undefined {
    if (!this.firstName || !this.lastName) {
      return this._fullName;
    }

    if (!this._fullName) {
      this._fullName = `${this._firstName} ${this._lastName}`;
    }

    return this._fullName;
  }

  public get firstName(): string | undefined {
    if (!this._firstName && this._fullName) {
      this._firstName = this._fullName.split(' ')[0];
    }
    return this._firstName;
  }

  public get lastName(): string | undefined {
    if (!this._lastName && this._fullName) {
      this._lastName = this._fullName.split(' ')[1];
    }
    return this._lastName;
  }

  public get picture(): Picture {
    return this._picture;
  }

  public get location(): string | undefined {
    return this._countryCode ? GetCountryByCode(this._countryCode).name : undefined;
  }

  public get phoneNumber(): string | undefined {
    return this._phoneNumber;
  }

  public get inviteID(): string | undefined {
    return this._inviteId;
  }

  public get role(): string | undefined {
    return this._role;
  }

  public get isCurrentUser(): boolean {
    return this._email === this._authService.user.email;
  }

  public get shortName(): string | undefined {
    return this._firstName;
  }
}

interface Attributes {
  email: string;
  firstName?: string;
  lastName?: string;
  picture?: string;
  fullName?: string
  countryCode?: string;
  phoneNumber?: string;
  shortName?: string;
  inviteId?: string;
  role?: string;
}