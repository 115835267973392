<div class="wrapper">
    <div class="header">
        <div 
          class="logo" 
          [routerLink]="user ? '/dashboard' : '/'">
            <img src="../../assets/img/template/logo.svg" width="25px" height="25px">
            <span class="logo-text">Dev Skill Platform</span>
        </div>
        <div class="menu-button" (click)="toggleNavbar()">
            <i class="material-icons-outlined">menu</i>
        </div>
    </div>
    
    <p-sidebar 
      [(visible)]="isSidebarOpen"
      [style]="{width:'100%', height:'calc(100% - 60px)', top: 'unset', background:'#273248'}"
      [showCloseIcon]="false">
        <div class="user-info-container">
            <img class="user-img" 
                 [src]="user.picture.url" 
                 routerLink="/settings/profile">
            <div class="user-details">
                <span class="user-name font-styles">{{user.full_name}}</span>
            </div>
        </div>

        <div class="sidenav-choices">
            <ng-container *ngFor="let selection of menuData.allowedSelections">
                <a class="menu-selection not-active-selection"
                  (click)="menuData.toggleSubMenu(selection.label)" 
                  [routerLink]="selection.items ? null : selection.url" 
                  routerLinkActive="active-selection">
                    <i class="material-icons-outlined">{{selection.icon}}</i>
                    <span class="font-styles">{{selection.label}}</span>
                </a>
                <ng-container *ngIf="menuData.menuVisibility[selection.label]">
                    <a *ngFor="let subSelection of selection.items" 
                        class="menu-selection not-active-selection" 
                        [routerLink]="subSelection.url" 
                        routerLinkActive="active-selection">
                        <i class="material-icons-outlined sub-menu">{{subSelection.icon}}</i>
                        <span class="font-styles">{{subSelection.label}}</span>
                    </a>
                </ng-container>
            </ng-container>
            
            <p-divider></p-divider>

            <a class="menu-selection not-active-selection"
              *ngFor="let selection of menuData.otherAllowedSelections"
              (click)="selection.dialogType ? openDialog() : null"
              [routerLink]="selection.dialogType ? null : selection.url"
              routerLinkActive="active-selection">
                <i class="material-icons-outlined">{{selection.icon}}</i>
                <span class="font-styles">{{selection.label}}</span>
            </a>

            <ng-container *ngIf="showHelp">
                <a routerLink="/documentation" class="menu-selection not-active-selection">
                    <i class="material-icons-outlined">help</i>
                    <span class="font-styles">Documentation</span>
                </a>
                <a routerLink="/contact" class="menu-selection not-active-selection">
                    <i class="material-icons-outlined">mail</i>
                    <span class="font-styles">Our Contacts</span>
                </a>
                <a class="menu-selection not-active-selection" (click)="openSubmit()">
                    <i class="material-icons-outlined">support</i>
                    <span class="font-styles">Submit Ticket</span>
                </a>
                <a routerLink="/tickets" class="menu-selection not-active-selection">
                    <i class="material-icons-outlined">list_alt</i>
                    <span class="font-styles">View Tickets</span>
                </a>
            </ng-container>

            <p-divider></p-divider>
            
            <a class="menu-selection not-active-selection">
                <i class="material-icons-outlined">logout</i>
                <span class="font-styles">Sign Out</span>
            </a>   
        </div>
    </p-sidebar>
</div>
