import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {
  @Input() helpMessage: HintMessages | string | null = null;
}

export enum HintMessages {
  PlanDescription = 'Provide a brief description of the exercise plan. Explain its purpose and what this plan is about.',
  Exercises = 'List the specific exercises that are part of this plan. Click on the "Add Exercises" button to browse the list of exercises.',
  Picture = 'Upload a relevant image that represents the exercise plan. Uploading the image is not necessary.',
  Difficulty = 'Difficulty of these problems is based on a general guideline: Easy problems are designed for beginners, Medium problems for intermediate engineers, and Hard problems for experienced engineers. However, keep in mind that the perception of difficulty can vary from person to person.',
  Tag = 'Tags are related to popular IT tools and technologies. You can use these tags to sort for exercises related to these specific topics.',
  DynamicExercise = 'The "Dynamic Exercise" feature allows you to customize experience by enabling the generation of random data for exercises.',
  LinuxTerminal = 'This option indicates whether the exercise requires you to execute commands in a specialized terminal environment.',
  ExecutionTime = 'The "Execution Time" is an estimated duration required to complete the exercise. If a user runs out of time and cannot complete the exercise, it will result in a "Failed" status for that specific task.',
  AssignedExercises = 'Here, you can view the list of exercise statuses and watch their replays',
  ComingSoon = 'We are continuously improving our platform and will soon introduce new features such as exercise creation, courses, and more.'
}