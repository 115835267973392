import { Component, OnInit } from '@angular/core';
import { LambdasService } from '../lambads.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { MessageService } from 'primeng/api';
import { User } from '../user.module';
import { DynamoDBService } from '../dynamodb.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Address } from '../models/address.models';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  public assignedPlanId: string | undefined;
  public candidateEmail: string | undefined;
  public paymentReference: string | undefined;

  public status: PaymentResponse | null = null;
  public paymentState: PaymentState | null = null;
  public isLoaded = false;
  private queryParamsChanged = new Subject<Params>();
  public user: User = this.authService.user;

  constructor(
    private lambdas: LambdasService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private dynamodb: DynamoDBService,
  ) {
    this.queryParamsChanged.pipe(
      debounceTime(500)
    ).subscribe(async (queryParams: Params) => {
      this.isLoaded = false;

      this.assignedPlanId = queryParams['assigned_plan_id'];
      this.candidateEmail = queryParams['email'];
      this.paymentReference = queryParams['payment_reference'];

      await Promise.all([
        this.fetchPaymentStatus(),
        this.fetchPaymentInfo()
      ]);
      this.isLoaded = true;
    });
  }


  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(params => {
      this.queryParamsChanged.next(params);
    });
  }

  private async fetchPaymentStatus(): Promise<void> {
    if (!this.paymentReference || !this.candidateEmail || !this.assignedPlanId) {
      return;
    }

    try {
      this.status = await this.lambdas.getPaymentStatus(this.paymentReference, this.assignedPlanId, this.candidateEmail).toPromise();
    } catch (err) {
      const error = err as Error;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
    }
  }

  private async fetchPaymentInfo(): Promise<void> {
    if (!this.candidateEmail || !this.assignedPlanId)
      return;

    try {
      const paymentInfo = this.user.isInterviewer ?
        await this.dynamodb.getPaymentInformation(this.user!.companyId, this.candidateEmail, this.assignedPlanId) :
        await this.dynamodb.getUserPaymentInformation(this.candidateEmail, this.assignedPlanId);

      if (!paymentInfo) 
        return;

      if (paymentInfo.isPaymentDone) 
        this.paymentState = PaymentState.Settled;

    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: (err as Error).message
      });
    }
  }

  public async initiatePayment(event: { paymentMethod: PaymentMethod, address: Address, companyName?: string, vatNumber?: string }): Promise<void> {
    try {
      const response = await this.lambdas.getPaymentLink(
        window.location.href,
        '',
        this.assignedPlanId!,
        this.candidateEmail!,
        event.paymentMethod.source,
        event.address,
        event.companyName,
        event.vatNumber
      ).toPromise();

      if (response.payment_link)
        window.location.replace(response.payment_link);

      if (response.status === 'Low Price') {
        this.paymentState = PaymentState.LowPrice;
        return;
      }

      if (response.status === 'Payment was completed earlier or sent for processing')
        this.paymentState = PaymentState.Unknown;

    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: (err as Error).message
      });
    }
  }

  public clearOldData(): void {
    this.status = null;
    this.paymentState = null;
  }
}

export interface PaymentMethod {
  source: string;
  display_name: string;
  logo_url: string;
  payment_link: string;
  country_code: string;
}

export interface PaymentError {
  code: number;
  message: string;
}

export interface PaymentResponse {
  error: PaymentError;
  processing_error: PaymentError
  order_reference: string;
  payment_created_at: string;
  payment_reference: string;
  payment_link: string;
  payment_state: PaymentState;
  standing_amount: number;
  payment_methods: PaymentMethod[];
  status: string;
}

export enum PaymentState {
  ChargedBack = 'chargebacked',
  Refunded = 'refunded',
  Voided = 'voided',
  Abandoned = 'abandoned',
  WaitingForSCA = 'waiting_for_sca',
  SentForProcessing = 'sent_for_processing',
  Failed = 'failed',
  Initial = 'initial',
  Settled = 'settled',
  Unknown = 'unknown',

  Done = 'done',
  Pending = 'pending',
  LowPrice = 'low_price',
}
