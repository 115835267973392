<div class="message-container" *ngIf="message">
  <div class="avatar-container">
    <p-avatar 
    [image]="message.image.url" 
    shape="circle">
    </p-avatar>
  </div>
  <div class="content-container">
    <div class="sender-info">
      <span class="sender-info-user">{{ message.senderName }}, </span>
      <span class="sender-info-time">{{ message.sendTime }}</span>
    </div>
    <div class="text">
      <span class="message">{{ message.messageBody }} </span>
      <div class="attachment" 
      *ngFor="let name of message.attachmentsName">
        <a (click)="onDownload(name)">{{name}}</a>
      </div>
    </div>
  </div>
</div>