<section class="flex w-100 h-100">
    <app-side-menu></app-side-menu>
    <p-table 
      [value]="paymentInfo ?? []"
      [rows]="pageSize"
      [totalRecords]="itemsCount ?? 0"
      [lazy]="true"
      [scrollable]="true"
      [paginator]="true"
      [showFirstLastIcon]="false"
      [showCurrentPageReport]="true"
      [loading]="isLoading"
      [rowsPerPageOptions]="[5,10,20]"
      (onLazyLoad)="loadPage($event.rows, $event.first / $event.rows)"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      dataKey="assignedPlanID" 
      class="w-100 overflow-y-auto">

        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-center text-3xl">
                Payment History
            </div>
        </ng-template>  

        <ng-template pTemplate="header">
            <tr>
                <th style="width: 5rem"></th>
                <th>Last Payment Date</th>
                <th>Price</th>
                <th>Plan Name</th>
                <th>Assigned User</th>
                <th>Status</th>
            </tr>
        </ng-template>
    
        <ng-template 
          pTemplate="body" 
          let-payment 
          let-expanded="expanded">
            <tr>
                <td>
                    <button 
                      pButton 
                      type="button" 
                      [pRowToggler]="payment" 
                      class="p-button-text p-button-rounded p-button-plain" 
                      [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                      *ngIf="payment.paymentReferences.length !== 0">
                    </button>
                </td>
                <td>{{payment.lastPaymentDate | date:'yyyy-MM-dd HH:mm'}}</td>
                <td>{{payment.amount | currency:'EUR'}}</td>
                <td>{{payment.planName}}</td>
                <td>{{payment.userEmail}}</td>
                <td>
                    <p-tag 
                      [value]="payment.status | titlecase"
                      [severity]="determineSeverity(payment.status)">
                    </p-tag>
                </td>
                
            </tr>
        </ng-template>
    
        <ng-template pTemplate="rowexpansion" let-reference>
            <tr>
                <td colspan="7">
                    <div class="p-3">
                        <p-table 
                          [value]="reference.paymentReferences" 
                          dataKey="id">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Date</th>
                                    <th>Payer name</th>
                                    <th>Status</th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-reference>
                                <tr>
                                    <td>{{reference.date | date:'yyyy-MM-dd HH:mm'}}</td>
                                    <td>{{reference.payerName}}</td>
                                    <td>
                                        <p-tag 
                                          [value]="reference.status | titlecase"
                                          [severity]="determineSeverity(reference.status)">
                                        </p-tag>     
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="6">You don't have a payment history :)</td>
            </tr>
        </ng-template>

    </p-table>
</section>