<div class="wrapper">
    <app-side-menu></app-side-menu>
    <div class="container">
        <ng-container *ngIf="isCompanyDataLoaded">
            <section *ngIf="!createAccount && !user.isCompanyExisting && !user.isDemoModeOn">
                <h2>Company Profile</h2>
                <p-divider></p-divider>

                <h3>No Company Account Yet? Create One Now!</h3>
                <div class="text">A company account is an essential tool for
                    business owners, HR professionals, and hiring managers. With this type of account, you
                    can test a candidate's skills and knowledge by assigning them practical tasks. These
                    exercises can give you valuable insights into their problem-solving abilities.
                </div>
                <simple-button (click)="createAccount=!createAccount">
                    Create Company Account
                </simple-button>
            </section>

            <section *ngIf="(createAccount || user.isCompanyExisting) && !user.isDemoModeOn">
                <h2>Company Profile</h2>
                <p-divider></p-divider>

                <h3>Company Details</h3>
                <div class="input-form">
                    <span class="tab-input-form-text">Company Name *</span>
                    <app-input-field
                      [inputControl]="companyDetailsFormGroup | formControl:'companyName'"
                      [isRequired]="true"
                      [highlightOnError]="true">
                    </app-input-field>
                </div>
                <div class="input-form">
                    <span class="tab-input-form-text">Registration Number *</span>
                    <app-input-field
                      [inputControl]="companyDetailsFormGroup | formControl:'registrationNumber'"
                      [isRequired]="true"
                      [highlightOnError]="true">
                    </app-input-field>
                </div>
                <div class="vat-number-container">
                    <div class="input-form">
                        <span class="tab-input-form-text">VAT Number</span>
                        <app-input-field 
                          [inputControl]="companyDetailsFormGroup | formControl:'vatNumber'">
                        </app-input-field>
                    </div>
                    <div *ngIf="!createAccount && isVATSet">
                      <div 
                        class="vat-number-check" 
                        *ngIf="companyDetailsFormGroup.value.isVatValid">
                          <svg class="tick-icon" viewBox="0 0 52 52">
                              <circle class="tick-circle" cx="26" cy="26" r="25" />
                              <path class="tick-line" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                          </svg>
                      </div>
                      <div 
                        class="vat-number-check" 
                        *ngIf="!companyDetailsFormGroup.value.isVatValid">
                          <svg class="cross-icon" viewBox="0 0 52 52">
                              <circle class="cross-circle" cx="26" cy="26" r="25" />
                              <path class="cross-line" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8" />
                          </svg>
                      </div>
                    </div>
                </div>
                <div 
                  class="text"
                  *ngIf="
                   !companyDetailsFormGroup.value.isVatValid &&
                   user.isCompanyExisting &&
                   isVATSet">
                    VAT number you have entered appears to be invalid. Please ensure that you have
                    entered a correct and valid VAT number for your company.
                </div>
                <ng-container *ngIf="createAccount">
                    <div class="input-form">
                        <span class="tab-input-form-text">Referral code</span>
                        <app-input-field
                            [inputControl]="companyDetailsFormGroup | formControl:'referralCode'">
                        </app-input-field>
                    </div>
                    <div class="text">
                      If you have a referral code, please enter it here, or you can skip this step.
                    </div>
                </ng-container>
                <div class="input-form">
                    <span class="tab-input-form-text">Phone Number</span>
                    <app-input-field
                      [inputControl]="companyDetailsFormGroup | formControl:'phoneNumber'">
                    </app-input-field>
                </div>
                <div class="input-form">
                    <span class="tab-input-form-text">Email *</span>
                    <app-input-field
                      [inputControl]="companyDetailsFormGroup | formControl:'email'"
                      [isRequired]="true"
                      [highlightOnError]="true">
                    </app-input-field>
                </div>
                <div class="input-form">
                    <span class="tab-input-form-text">Country *</span>
                    <countries-dropdown
                      [inputControl]="companyDetailsFormGroup | formControl:'country'"
                      [isRequired]="true">
                    </countries-dropdown>
                </div>
                <div class="input-form">
                    <span class="tab-input-form-text">State</span>
                    <app-input-field
                      [inputControl]="companyDetailsFormGroup | formControl:'state'">
                    </app-input-field>
                </div>
                <div class="input-form">
                    <span class="tab-input-form-text">City *</span>
                    <app-input-field
                      [inputControl]="companyDetailsFormGroup | formControl:'city'"
                      [isRequired]="true"
                      [highlightOnError]="true">
                    </app-input-field>
                </div>
                <div class="input-form">
                    <span class="tab-input-form-text">Street *</span>
                    <app-input-field
                      [inputControl]="companyDetailsFormGroup | formControl:'street'"
                      [isRequired]="true"
                      [highlightOnError]="true">
                    </app-input-field>
                </div>
                <div class="input-form">
                    <span class="tab-input-form-text">Postcode *</span>
                    <app-input-field
                      [inputControl]="companyDetailsFormGroup | formControl:'postcode'"
                      [isRequired]="true"
                      [highlightOnError]="true">
                    </app-input-field>
                </div>
                <div class="text">
                    You can easily update and manage your company's essential information. Keep in mind that
                    some of the data you enter here will be visible to users and will also be used to generate
                    invoices. It is crucial to ensure that all information is accurate and up-to-date.
                </div>
                <simple-button
                  *ngIf="user.isCompanyExisting || !isCompanyDataUpdating"
                  (click)="user.isCompanyExisting ? saveCompanyDetails() : registerCompanyAccount()"
                  [disabled]="!companyDetailsFormGroup.valid">
                    {{ user.isCompanyExisting ? "Save Changes" : "Create Account" }}
                </simple-button>
                <app-loading-animation *ngIf="isCompanyDataUpdating">
                </app-loading-animation>
            </section>

            <section *ngIf="user.companyId">
              <h2>Demo Mode</h2>
              <p-divider></p-divider>
              <div class="text">
                <div>
                  Demo Mode allows you to switch to a regular user role, enabling you to interact with the site as a
                  typical user. This feature is useful for testing exercise plans, such as the "Welcome
                  Plan" or any self-assigned plans. You can switch back to the interviewer role at any time.
                </div>
                <p>
                  Currently, demo mode is {{user.isDemoModeOn ? 'On' : 'Off'}}. Toggle the switch to change your role.
                </p>
              </div>
              <role-toggler></role-toggler>
            </section>

            <section *ngIf="user.isCompanyExisting">
                <h2>Trial Period</h2>
                <p-divider></p-divider>
                <div class="text">
                    Your trial period is set to expire on {{trialPeriodEndDate}}. At the end of the trial period, you'll switch
                    to a pay-per-second model. To learn more about our payment structure,
                    <a routerLink="/prices">click here</a>.
                </div>
            </section>

            <section *ngIf="user.isCompanyExisting">
                <h2>Members</h2>
                <p-divider></p-divider>
                <app-user-list 
                  [users]="members" 
                  (userDeleted)="onMemberDelete($event)" 
                  (userAdded)="onMemberAdd($event)">
                </app-user-list>
            </section>
        </ng-container>

        <div *ngIf="!isCompanyDataLoaded" class="loading">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</div>