<section>
  <div class="btn-container">
      <exercise-button
        class="submit-button"
        *ngIf="!isExerciseCompleted"
        [disabled]="isSubmitBtnBlocked"
        (click)="submit()">
          Submit
      </exercise-button>

      <exercise-button
        class="restart-button"
        *ngIf="!isExerciseCompleted"
        [disabled]="!isExerciseStarted"
        (click)="restart()">
          Restart
      </exercise-button>

      <exercise-button
        *ngIf="!isExerciseCompleted"
        [disabled]="!isExerciseStarted"
        (click)="cancel()">
          Cancel
      </exercise-button>
      
      <exercise-button
        (click)="returnToPlanList()">
          Exit
      </exercise-button>

      <exercise-button
        class="next-button"
        *ngIf="isExerciseCompleted && nextExerciseURL"
        (click)="goToNextExercise()">
          Next
      </exercise-button>
  </div>

  <p-confirmDialog #cd 
    [style]="{width: '30vw'}"
    [breakpoints]="{'1440px': '50vw'}"
    [key]=dialogKey>
      <ng-template pTemplate="footer">
          <div>
              <exercise-button class="mr-3" (click)="cd.reject()">No</exercise-button>
              <exercise-button (click)="cd.accept()">Yes</exercise-button>
          </div>
      </ng-template>
  </p-confirmDialog>
</section>

