import { Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopoverComponent implements OnChanges, OnDestroy, OnInit {
  @Input() open = false;
  @Input() connectToElement: Element | null = null;
  // Width of the popover window
  // Default value is 200px 
  @Input() width = '200px';
  // Close the popover window when a user is hovering over it. 
  // Default is false
  @Input() closeOnHover = false;

  @ViewChild('popover', { static: true }) popoverContainer!: ElementRef;
  @ViewChild('triangle', { static: true }) triangle!: ElementRef;

  public isActive = false;
  private observer!: MutationObserver;
  private disconnectTimeout: NodeJS.Timeout | undefined = undefined;

  constructor(
    private renderer: Renderer2,
  ) { }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.connectToElement || changes.open) {
      const change = changes.connectToElement || changes.open;

      if (change.currentValue) {
        this.isActive = true;
        this.connectToParent();
      } else {
        this.disconnectFromParent();
      }
    }
  }

  ngOnInit(): void {
    this.setupTemplateObserver();
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  public onMouseEnter(): void {
    if (this.closeOnHover) {
      this.disconnectFromParent();
    }
  }

  public onMouseLeave(): void {
    this.disconnectFromParent();
  }

  private setupTemplateObserver(): void {
    this.observer = new MutationObserver(() => {
      this.connectToParent();
    });
    this.observer.observe(this.popoverContainer.nativeElement, { childList: true, subtree: true, characterData: true });
  }

  private async disconnectFromParent(): Promise<void> {
    this.isActive = false;
    this.open = false;

    this.disconnectTimeout = setTimeout(() => {
      this.renderer.setStyle(this.popoverContainer.nativeElement, 'left', '0px');
      this.renderer.setStyle(this.triangle.nativeElement, 'left', '0px');
    }, 400);
  }

  private connectToParent(): void {
    if (!this.connectToElement) return;

    if (this.disconnectTimeout) {
      clearTimeout(this.disconnectTimeout);
    }

    this.setLeftPosition();
    const parentPosition = this.connectToElement!.getBoundingClientRect();
    const templateRect = this.popoverContainer.nativeElement.getBoundingClientRect();

    if (parentPosition.top < templateRect.height) {
      this.setBottomPosition();
    } else {
      this.setTopPosition();
    }
  }

  private setTopPosition(): void {
    const triangleOffset = 10;
    const parentPosition = this.connectToElement!.getBoundingClientRect();
    const templateRect = this.popoverContainer.nativeElement.getBoundingClientRect();
    const scrolledTopPosition = parentPosition.top + window.scrollY;

    this.renderer.setStyle(this.popoverContainer.nativeElement, 'top', `${scrolledTopPosition - templateRect.height - triangleOffset}px`);
    this.renderer.setStyle(this.triangle.nativeElement, 'top', `${scrolledTopPosition - triangleOffset - 3}px`);
    this.renderer.setStyle(this.triangle.nativeElement, 'border-color', 'transparent transparent white white');
    this.renderer.setStyle(this.triangle.nativeElement, 'box-shadow', '-3px 3px 3px 0 rgba(180, 180, 180, 0.5)');
  }


  private setBottomPosition(): void {
    const triangleOffset = 15;
    const parentPosition = this.connectToElement!.getBoundingClientRect();

    this.renderer.setStyle(this.popoverContainer.nativeElement, 'top', `${parentPosition.bottom + triangleOffset}px`);
    this.renderer.setStyle(this.triangle.nativeElement, 'top', `${parentPosition.bottom + triangleOffset + 3}px`);
    this.renderer.setStyle(this.triangle.nativeElement, 'border-color', 'white white transparent transparent');
    this.renderer.setStyle(this.triangle.nativeElement, 'box-shadow', '3px -3px 3px 0 rgb(180 180 180 / 50%)');
  }

  private setLeftPosition(): void {
    const parentPosition = this.connectToElement!.getBoundingClientRect();
    const templateRect = this.popoverContainer.nativeElement.getBoundingClientRect();
    const viewportWidth = window.innerWidth;

    const centeredLeft = parentPosition.left + (parentPosition.width / 2) - (templateRect.width / 2);
    let finalLeft = centeredLeft;

    if (centeredLeft < 0) {
      finalLeft = 15;
    } else if (centeredLeft + templateRect.width > viewportWidth) {
      finalLeft = viewportWidth - templateRect.width - 15;
    }

    this.renderer.setStyle(this.popoverContainer.nativeElement, 'left', `${finalLeft}px`);
    this.renderer.setStyle(this.triangle.nativeElement, 'left', `${parentPosition.left + parentPosition.width / 2 - 9}px`);
  }
}
