<div class="title">Connect with {{data.companyName}}</div>
<div class="content">
    <span>
        Please click the 'Accept' button to establish the connection and share your profile data with the company.
    </span>
</div>

<app-loading-animation *ngIf="isRequestSent"></app-loading-animation>
<div *ngIf="!isRequestSent" class="df fd-c ai-c">
    <button class="accept-button" (click)="connect()">
        Accept
    </button>
    <a class="cancel-button" (click)="closeDialog(false)">
        Cancel
    </a>
</div>