import { SecondsToHumanReadable } from 'src/app/shared/service/time';
import { environment } from 'src/environments/environment';

export class CartItem {
  private _id: string;
  private _name: string;
  private _executionTime: number;
  private _price: number;

  private _imagePath: string;
  private _humanReadableExecutionTime: string;

  constructor(
    id: string,
    name: string,
    executionTime: number,
    price: number
  ) {
    this._id = id;
    this._name = name;
    this._executionTime = executionTime;
    this._price = price;

    this._humanReadableExecutionTime = SecondsToHumanReadable(this._executionTime);
    this._imagePath = `${environment.ExercisePicturePath}${id}.jpeg`;
  }

  public get id(): string {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get imagePath(): string {
    return this._imagePath;
  }

  public get price(): number {
    return this._price;
  }

  public get executionTime(): string {
    return this._humanReadableExecutionTime;
  }

  public get executionTimeInSeconds(): number {
    return this._executionTime;
  }
}