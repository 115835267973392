import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlanDataService } from './plan-data-service/plan-data.service';
import { GetQueryParam } from '../shared/service/route';

@Component({
  selector: 'app-plans-management',
  templateUrl: './plans-management.component.html',
  styleUrls: ['./plans-management.component.css'],
})
export class PlansManagementComponent implements OnInit {
  public planEditorEvent: EventType = 'create';
  public isExerciseManagerOpen = false;
  public toolPanelTrigger: boolean | null = null;


  constructor(
    private activatedRoute: ActivatedRoute,
    public planData: PlanDataService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.planData.fetchData();
    const queryParam = GetQueryParam(this.activatedRoute, 'id');
    if (!queryParam) {
      return;
    }
    this.planData.selectPlanById(queryParam);
    this.changePlanEditorEvent('edit');
    this.triggerToolPanel();
  }

  public changePlanEditorEvent(event: EventType): void {
    this.planEditorEvent = event;
  }

  public openExerciseMenu(): void {
    this.isExerciseManagerOpen = true;
    this.triggerToolPanel();
  }

  public closeExerciseMenu(): void {
    this.isExerciseManagerOpen = false;
    this.triggerToolPanel();
  }

  private triggerToolPanel(): void {
    this.toolPanelTrigger = !this.toolPanelTrigger;
  }
}


export type EventType = 'delete' | 'edit' | 'create' | 'assign';