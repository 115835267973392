import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExerciseDataService } from '../../../../services/exercise-data.service';
import { Subscription } from 'rxjs';
import { MessageType, Status, Type } from '../../../../exercise.component';
import { MonacoTreeElement } from 'ngx-monaco-tree';

@Component({
  selector: 'app-file-watcher',
  templateUrl: './file-watcher.component.html',
  styleUrls: ['./file-watcher.component.scss']
})
export class FileWatcherComponent implements OnInit, OnDestroy {
  private websocketMessageSub: Subscription = new Subscription();
  public tree: Tree | null = null;
  public isExerciseStarted = false;

  constructor(
    private _data: ExerciseDataService
  ) { }

  public ngOnInit(): void {
    this.handleWebSocketMessages();

  }

  public ngOnDestroy(): void {
    this.websocketMessageSub.unsubscribe();
  }

  public openFile(path: string): void {
    if (this.tree && this.tree.isDirectory(path)) {
      return;
    }

    if (!this.isExerciseStarted) {
      return;
    }

    this._data.ws.sendMessage(JSON.stringify({ type: MessageType.GetFile, file: { path: path } }));
  }

  private handleWebSocketMessages(): void {
    this.websocketMessageSub = this._data.ws.messages$.subscribe(resp => {
      if (resp.type == Type.InitTree) {
        this.tree = new Tree(resp.init_tree ?? []);
        return;
      }

      if (resp.file_event && resp.file_event.type === FileEventType.Create) {
        this.tree?.AddFile(resp.file_event.tree);
        return;
      }

      if (
        resp.file_event &&
        resp.file_event.tree.path &&
        resp.file_event.type === FileEventType.DeleteFile) {
        this.tree?.DeleteFile(resp.file_event.tree.path);
        return;
      }

      if (resp.exercise?.environmentStatus == Status.Running) {
        this.isExerciseStarted = true;
      }

      if (
        resp.exercise?.validationTestsStatus == Status.Completed ||
        resp.exercise?.environmentStatus == Status.Canceled || 
        resp.loading_message ||
        resp.error) {
        this.isExerciseStarted = false;
      }
    });
  }
}

export interface Node {
  name: string;
  path?: string;
  content?: Node[];
  isDir?: boolean;
}
export interface FileEvent {
  type: FileEventType
  tree: Node
}

type NodeChildren = Map<string, FileSystemNode>

enum FileEventType {
  Create = 'create',
  DeleteFile = 'delete'
}

class FileSystemNode {
  private children?: NodeChildren;

  get child(): NodeChildren | undefined {
    return this.children;
  }

  constructor(nodes?: Node[]) {
    if (nodes) {
      this.children = new Map<string, FileSystemNode>();

      for (const node of nodes) {
        this.addChild(node);
      }
    }
  }

  private addChild(nodeData: Node): void {
    let newNode: FileSystemNode;
    if (nodeData.content) {
      newNode = new FileSystemNode(nodeData.content);
    } else {
      newNode = new FileSystemNode(nodeData.isDir ? [] : undefined);
    }
    this.children!.set(nodeData.name, newNode);
  }

  public DeleteFile(path: string): void {
    const pathParts = path.split('/');
    const currentPart = pathParts.shift();
    if (!currentPart) {
      return;
    }

    if (!this.children) {
      return;
    }

    if (pathParts.length === 0) {
      this.children.delete(currentPart);
      return;
    }

    this.children.get(currentPart)?.DeleteFile(pathParts.join('/'));
  }

  public isDirectory(path: string): boolean {
    const pathParts = path.split('/');
    const currentPart = pathParts.shift();
    if (!currentPart) {
      return false;
    }

    if (pathParts.length === 0) {
      return !!this.children?.get(currentPart)?.children;
    }

    return this.children?.get(currentPart)?.isDirectory(pathParts.join('/')) ?? false;
  }

  public AddFile(name: string, path: string, isDir: boolean): void {
    const pathParts = path.split('/');
    const currentPart = pathParts.shift();
    if (!currentPart) {
      return;
    }

    if (!this.children) {
      return;
    }

    if (pathParts.length === 0) {
      this.children.set(name, new FileSystemNode(isDir ? [] : undefined));
      return;
    }

    let nextNode = this.children.get(currentPart);
    if (!nextNode) {
      nextNode = new FileSystemNode([]);
      this.children.set(currentPart, nextNode);
    }

    path = pathParts.join('/');
    nextNode.AddFile(name, path, isDir);
  }

  public Find(path: string): FileSystemNode | undefined {
    const pathParts = path.split('/');
    const currentPart = pathParts.shift();
    if (!currentPart) {
      return;
    }

    if (pathParts.length === 0) {
      return this.children?.get(currentPart);
    }

    path = pathParts.join('/');
    return this.children?.get(currentPart)?.Find(path);
  }

  public toMonacoTreeElement(): MonacoTreeElement[] | undefined {
    if (!this.children) return;

    return Array.from(this.children.entries()).map(([name, childNode]) => ({
      name: name,
      content: childNode.toMonacoTreeElement()
    }));
  }
}

export class Tree {
  private root: FileSystemNode;
  private monacoTree: MonacoTreeElement[] = [];

  constructor(nodes: Node[]) {
    this.root = new FileSystemNode(nodes);
    this.toMonacoTreeElement();
  }

  get tree(): MonacoTreeElement[] {
    return this.monacoTree;
  }

  public DeleteFile(path: string): void {
    this.root.DeleteFile(path);
    this.toMonacoTreeElement();
  }

  public AddFile(file: Node) {
    if (!file.path) {
      return;
    }

    this.root.AddFile(file.name, file.path, file.isDir ?? false);
    this.toMonacoTreeElement();
  }

  public Find(path: string): FileSystemNode | undefined {
    return this.root.Find(path);
  }

  public isDirectory(path: string): boolean {
    return this.root.isDirectory(path);
  }

  public toMonacoTreeElement(): void {
    this.monacoTree = this.root.toMonacoTreeElement() ?? [];
  }
}