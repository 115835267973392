import { TCountryCode } from 'countries-list';
import { CartItem } from './cart-item.models';

export class Cart {
  private _planName: string;
  private _total: number;
  private _subtotal: number;
  private _tax: number;
  private _cartItems: CartItem[] = [];
  private _isVATApplicable: boolean;

  constructor(
    cart: CartResponse
  ) {
    this._subtotal = cart.subtotal;
    this._tax = cart.tax;
    this._total = cart.total;
    this._isVATApplicable = cart.is_vat_applicable;
    this._planName = cart.plan_name;

    this._cartItems = cart.exercises.map(exercise => {
      return new CartItem(
        exercise.id,
        exercise.name,
        exercise.execution_time,
        exercise.price
      );
    });
  }

  public get planName(): string {
    return this._planName;
  }

  public get total(): number {
    return this._total;
  }

  public get subtotal(): number {
    return this._subtotal;
  }

  public get tax(): number {
    return this._tax;
  }

  public get isVATApplicable(): boolean {
    return this._isVATApplicable;
  }

  public get exercises(): CartItem[] {
    return this._cartItems;
  }
}

export interface CartResponse {
  country_code: TCountryCode;
  status: string;
  error: string;
  plan_name: string;
  subtotal: number;
  tax: number;
  total: number;
  is_vat_applicable: boolean;
  exercises: ExercisePrice[];
}

interface ExercisePrice {
  id: string;
  name: string;
  price: number;
  execution_time: number
}
