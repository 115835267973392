import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentComponent } from './payment.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { UserPaymentComponent } from './components/user-payment/user-payment.component';
import { InterviewerPaymentComponent } from './components/interviewer-payment/interviewer-payment.component';
import { PaymentMessagesComponent } from './components/payment-messages/payment-messages.component';
import { DividerModule } from 'primeng/divider';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { CartComponent } from './components/cart/cart.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';


@NgModule({
  declarations: [
    PaymentComponent,
    UserPaymentComponent,
    InterviewerPaymentComponent,
    PaymentMessagesComponent,
    CartComponent,
    PaymentMethodsComponent,
    
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    FormsModule,
    BreadcrumbModule,
    DividerModule,
    VirtualScrollerModule
  ],
  exports: [
    PaymentComponent
  ]
})
export class PaymentModule { }
