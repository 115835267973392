<section class="wrapper" [style.height]="height">
  <div class="messages" #scrollContainer>
    <chat-message 
      *ngFor="let message of conversation?.messages" 
      [message]="message"
      (downloadName)="onDownload($event)">
    </chat-message>
  </div>
  
  <app-message-sender 
   [ticket]="ticket" 
   (message)="onNewMessage($event)">
  </app-message-sender>
</section>