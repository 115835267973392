
<div class="wrapper"
  [style.flex-direction]="isAuthenticated ? 'row' : 'column'"
  [style.height]="isAuthenticated ? '100%' : 'auto'">
    <ng-container *ngIf="isAuthenticated">
        <app-side-menu></app-side-menu>
        <div *ngTemplateOutlet="policy"></div>
    </ng-container>

    <ng-container *ngIf="!isAuthenticated">
        <app-header></app-header>
        <div *ngTemplateOutlet="policy"></div>
        <app-footer></app-footer>
    </ng-container>
</div>

<ng-template #policy>
    <div class="policy">
        <h1>PRIVACY POLICY</h1>

        <h3>I Purpose</h3>
        <p>The purpose of the document is to explain the principles of the Company in personal data processing to any
            individual according to the requirements of GDPR.</p>

        <h3>II Abbreviations and Terminology</h3>
        <p>
            <span class="bold">Processing</span> - any operation performed on personal data (for example, collection,
            structuring, storage, adaptation, alteration, transmission, erasure).
            <br><span class="bold">EEA</span> - European Economic Area.
            <br><span class="bold">EU </span> - European Union.
            <br><span class="bold">Personal data (data)</span> - any information relating to an identified or
            identifiable individual (data subject).
            <br><span class="bold">RL</span> - Republic of Latvia.
            <br><span class="bold">Profiling</span> - Automated processing of personal data to evaluate certain personal
            aspects, especially in the context of client due diligence and transaction monitoring.
            <br><span class="bold">Company</span> - Sabiedrība ar ierobežotu atbildību "Dev Skill", reg.No. 40203283422.
            <br><span class="bold">GDPR</span> - Regulation (EU) 2016/679 of the European Parliament and of the Council
            of 27 April 2016 on the protection of natural persons with regard to the processing of
            personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data
            Protection Regulation).
            <br><span class="bold">VID EDS</span> - Electronic declaration system of the State Revenue Service.
        </p>

        <h3>III General Provisions</h3>
        <p>
            1. The Company shall process personal data in compliance with the GDPR requirements.
            <br>2. Processing of personal data performed by the Company may have several legal grounds, for example, a
            person&#8217;s consent to data processing, contractual relationship between a person and the
            Company, performance of the legal obligation relating to the Company according to the applicable legal acts
            or ensuring of compliance with the legitimate interests of the Company.
            <br>3. When submitting a written application to the Company, a data subject has the right to access to,
            request rectification or restrict the processing of his/her data, withdraw his/her consent and object
            to processing of data performed by the Company, as well as the right to data portability.
            <br>4. In certain cases, an individual&#8217;s rights may not be exercised or may be restricted, if this is
            justified by the legitimate interests of the Company.
        </p>

        <h3>IV Main Provisions </h3>
        <h4>5. Data processing purposes</h4>
        <p>
            5.1. Data are collected for specified, explicit and legitimate purposes and not further processed in a
            manner that is incompatible with those purposes.
            <br>5.2. The Company performs processing of data for the following purposes:
            <br><span class="tab">5.2.1. compliance with legal acts and identification of individuals;</span>
            <br><span class="tab">5.2.2. management of relationship with clients, partners and other related
                persons; </span>
            <br><span class="tab">5.2.3. provision of services;</span>
            <br><span class="tab">5.2.4. ensuring physical and information security;</span>
            <br><span class="tab">5.2.5. protection of the Company&#8217;s and clients&#8217; interests;</span>
            <br><span class="tab">5.2.6. risk management;</span>
            <br><span class="tab">5.2.7. personnel management.</span>
        </p>

        <h4>6. Categories of data subjects</h4>
        <p>
            6.1. Categories of individuals whose personal data are processed by the Company:
            <br><span class="tab">6.1.1. the Company&#8217;s clients, shareholders;</span>
            <br><span class="tab">6.1.2. the Company&#8217;s employees (existing and former) and candidates;</span>
            <br><span class="tab">6.1.3. business partners, agents, suppliers and service providers, advisors and
                persons related to them (representatives, authorised persons, beneficiaries, employees, etc.).</span>
        </p>

        <h4>7. Categories of data</h4>
        <p>
            7.1. Personal data of the following categories can be provided by data subjects themselves, can be obtained
            during the use of the Company&#8217;s services by the client, as well as from third parties
            (for example, public and private registers):
            <br><span class="tab">7.1.1. an individual&#8217;s identification data (name, surname, personal identity
                number, date and place of birth, identification document data, images);</span>
            <br><span class="tab">7.1.2. an individual&#8217;s contact details (postal address, telephone number, email,
                IP address, location data, etc.);</span>
            <br><span class="tab">7.1.3. data of related persons (for example, representatives and authorised persons of
                clients);</span>
            <br><span class="tab">7.1.4. an individual&#8217;s financial and transactional information (for example,
                information on credit card details);</span>
            <br><span class="tab">7.1.5. data which are obtained under employment relationships with the Company&#8217;s
                employees (for example, information on salary, previous places of employment, education, health
                (information on the employee&#8217;s medical sick-leave certificate from VID EDS) etc.).</span>
        </p>

        <h4>8. Categories of data recipients</h4>
        <p>
            8.1. The Company may disclose personal data to the following recipients of data:
            <br><span class="tab">8.1.1. authorised representatives and employees of the Company;</span>
            <br><span class="tab">8.1.2. public institutions, public officials, investigatory authorities, courts,
                prosecutor&#8217;s office, subjects of operational activities, orphans courts, notaries, law enforcement
                officials, judicial and investigatory authorities of other member states and foreign countries, tax
                authorities, arbitration courts, out-of-court dispute resolution bodies — financial market participants
                (correspondent banks, insurance companies, payment systems, credit registers, securities registers,
                agency companies, stock exchanges, depositories, business partners of the Company or clients, financial
                service
                intermediaries etc.);</span>
            <br><span class="tab">8.1.3. the Company&#8217;s cooperation partners, agents, suppliers and service
                providers, auditors, advisors.</span>
            <br><span class="tab">8.1.4. the Company&#8217;s products users</span>
        </p>

        <h4>9. Automated processing and decision-making</h4>
        <p>
            9.1. In certain cases, the Company performs profiling and decision-making if this is stipulated by
            applicable legal acts, or this is necessary to perform a previously concluded agreement with the client, or
            such processing of personal data is justified by the legitimate interests of the Company.
        </p>

        <h4>10. Data storage periods</h4>
        <p>
            10.1. The Company shall store personal data no longer than it is reasonably required for the purposes for
            which particular personal data are processed. Personal data storage periods shall be determined based on
            applicable legal acts (e.g., the data of former clients-individuals are stored for 10 years from the date
            the business relationship was terminated; the data of authorised persons of former clients-legal entities —
            for 5 years from the date the business relationship was terminated) or the legitimate interests of the
            Company. 10.2. The Company reserves the right to erase specific information before the expiry of the set
            period, if this is not prohibited by the applicable legal acts.
        </p>

        <h4>11. Rights of data subjects</h4>
        <p>
            11.1. The Company shall ensure the following rights of data subjects:
            <br><span class="tab">11.1.1. a subject&#8217;s data may be processed on the basis of consent of this data
                subject or some other legitimate basis;</span>
            <br><span class="tab">11.1.2. a data subject has the right to receive information on the processing of
                personal data performed by the Company and exercise of data subjects&#8217; rights;</span>
            <br><span class="tab">11.1.3. a data subject has the right to receive a confirmation if the data of this
                data subject are not processed;</span>
            <br><span class="tab">11.1.4. a data subject has the right to access his/her data and receive information on
                the purpose and legal basis of data processing, category of data, recipient of data, storage period,
                information on other sources of data, if personal data are obtained from third parties, set profile (in
                case of profiling), and guarantees, if the data have been sent to a third party or international
                organisation;</span>
            <br><span class="tab">11.1.5. a subject has the right to receive information on whether the provision of
                personal data is related to the law or an agreement, whether the provision of data is a precondition
                for the conclusion of an agreement, as well as information that the subject is required to provide
                personal data, and consequences in case such data are not provided;</span>
            <br><span class="tab">11.1.6. a subject has the right to be informed about automated decision-making,
                including profiling, and its consequences;</span>
            <br><span class="tab">11.1.7. a subject has the right to be informed about a new purpose of data processing
                in advance;</span>
            <br><span class="tab">11.1.8. a subject has the right to object to data processing and withdraw his/her
                consent to data processing; 11.1.9. a subject has the right to request rectification of data if data are
                incorrect;</span>
            <br><span class="tab">11.1.9. a subject has the right to data portability</span>
            <br><span class="tab">11.1.10. a subject has the right to request erasure of data if this does not
                contradict the RL and EU laws</span>
            <br><span class="tab">11.1.11. a subject has the right to lodge a complaint with the supervisory
                authority.</span>
        </p>

        <h4>12. Data residency</h4>
        <p>
            12.1. The Company shall process data in the territory of RL.
            <br>12.2. Transmission of personal data to third parties (irrespective of the data recipient&#8217;s
            residency — RL, EU, EEA or outside it) is regulated by the legal acts of RL or an agreement between the
            Company and a third party, which includes nondisclosure and secure exchange provisions.
            <br>12.3. Transfer of data to third countries and international organisations is possible based on:
            <br><span class="tab">12.3.1. the decision made by the European Commission regarding the level of protection
                of a third country&#8217;s data;</span>
            <br><span class="tab">12.3.2. relevant guarantees (for example, applying binding corporate rules or standard
                data protection clauses adopted by the European Commission);</span>
            <br><span class="tab">12.3.3. exceptional legal grounds.</span>
        </p>

        <h4>13. Contact details</h4>
        <p>
            13.1. A data subject may submit his/her questions, requests and complaints by email to <a class="email-link"
                href="mailto: aleksei@dev-skill.com">aleksei@dev-skill.com</a>.
            <br>13.2. A data subject has the right to file a complaint on the processing of personal data performed by
            the Company with the Data State Inspectorate of RL.
        </p>

        <h4>14. Security of personal data</h4>
        <p>
            14.1. The Company shall ensure security of data storage and processing in accordance with legal acts, as
            well as the best international practice for information systems security, maintenance and development.
            <br>14.2. The Company shall ensure centralised storage of personal data and automatic data masking / erasure
            after expiry of the mandatory storage period according to the RL and EU laws. If there are obstacles for
            these actions (e.g., order on the prohibition of data erasure), data storage shall be ensured until the
            elimination or cancellation of these obstacles.
        </p>

        <h4>15. Amendments</h4>
        <p>
            15.1. The Company may unilaterally amend the Policy. The Company shall publicly inform about amendments to
            the Principles on the Company&#8217;s website.
            <br>15.2. The Company has the right to additionally inform a client about amendments to the Policy on an
            individual basis by sending a relevant notice via email or using other means of communication.
        </p>
    </div>
</ng-template>
