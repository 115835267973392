<div class="wrapper">
    <p-fileUpload 
      mode="basic" 
      [auto]="true" 
      [multiple]="true"
      chooseLabel="Add"
      (onSelect)="onSelect($event)"
      [customUpload]="true">
    </p-fileUpload>
    <textarea
      pInputTextarea 
      [autoResize]="true"
      [rows]="1"
      [formControl]="text"
      (keydown.enter)="onEnter($event)">
    </textarea>
      <button
       pButton 
       pRipple 
       type="button" 
       icon="pi pi-send" 
       class="p-button-rounded send-button"
       (click)="submit()">
      </button>
</div>
<div class="attachments">
  <div *ngFor="let attachment of loadedFiles" class="attachment">
    <p class="attachment-name" > 
      {{ attachment.name }}
    </p>
    <button type="button" (click)="deleteAttachment(attachment.name)" class="close-button">
      <i class="material-icons">close</i>
    </button>
  </div>
</div>


