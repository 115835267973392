<h5>Need help?</h5>
<div class="container">
    <div class="square" (click)="closeDialog()" routerLink="/documentation">
        <i class="material-icons-outlined">help_outline</i>
        <span>Documentation</span>
    </div>

    <div class="square" (click)="closeDialog()" routerLink="/contact">
        <i class="material-icons-outlined">mail</i>
        <span>Our Contacts</span>
    </div>

    <div class="square" (click)="openSubmit()">
        <i class="material-icons-outlined">support</i>
        <span>Submit a Ticket</span>
    </div>

    <div class="square" (click)="closeDialog()" routerLink="/tickets">
        <i class="material-icons-outlined">help_outline</i>
        <span>View Tickets</span>
    </div>
</div>