import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorsService {
  public GetBooleanFormControl(): FormControl {
    return new FormControl(false);
  }

  public GetPhoneFormControl(): FormControl {
    return new FormControl('', [Validators.pattern('[- +()0-9]{5,20}')]);
  }

  public GetEmailFormControl(): FormControl {
    return new FormControl('', [Validators.required, Validators.email]);
  }

  public GetRequiredFormControl(): FormControl {
    return new FormControl('', [Validators.required]);
  }

  public GetEmptyFormControl(): FormControl {
    return new FormControl('');
  }

  public GetCheckboxFormControl(): FormControl {
    return new FormControl(false, Validators.requiredTrue);
  }

  public GetUsernameFormControl(): FormControl {
    return new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(20),
      Validators.pattern(/^[a-zA-Z0-9](?!.*--)[a-zA-Z0-9-]+[a-zA-Z0-9]$/),
      CustomValiditors.containSpace
    ]);
  }

  public GetPasswordFormControl(): FormControl {
    return new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32),
      CustomValiditors.notContainOneNumber,
      CustomValiditors.notContainUppercaseLetter,
      CustomValiditors.notContainLowercaseLetter,
      CustomValiditors.notContainSpecialCharacter,
      CustomValiditors.containSpace,
    ]);
  }
}

export class CustomValiditors {
  static containSpace(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    if ((control.value as string).indexOf(' ') >= 0) {
      return {
        containSpace: true
      };
    } else {
      return null;
    }
  }

  static notContainOneNumber(control: AbstractControl): ValidationErrors | null {
    if (!/\d/.test(control.value as string)) {
      return {
        notContainOneNumber: true
      };
    } else {
      return null;
    }
  }

  static notContainUppercaseLetter(control: AbstractControl): ValidationErrors | null {
    if (!/[A-Z]/.test(control.value as string)) {
      return {
        notContainUppercaseLetter: true
      };
    } else {
      return null;
    }
  }

  static notContainLowercaseLetter(control: AbstractControl): ValidationErrors | null {
    if (!/[a-z]/.test(control.value as string)) {
      return {
        notContainLowercaseLetter: true
      };
    } else {
      return null;
    }
  }

  static notContainSpecialCharacter(control: AbstractControl): ValidationErrors | null {
    if (!/[^\w\s]/.test(control.value as string)) {
      return {
        notContainSpecialCharacter: true
      };
    } else {
      return null;
    }
  }
}
