import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExerciseDataService } from '../../../../services/exercise-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit, OnDestroy {
  public dynamicDescription: string | undefined;
  private websocketMessageSub: Subscription = new Subscription();

  constructor(
    public _data: ExerciseDataService
  ) { }

  public ngOnInit(): void {
    this.handleWebsocketMessage();
  }

  public ngOnDestroy(): void {
    this.websocketMessageSub.unsubscribe();
  }

  private handleWebsocketMessage(): void {
    this.websocketMessageSub = this._data.ws.messages$.subscribe(resp => {
      if (resp.description) {
        this.dynamicDescription = resp.description;
      }
    });
  }
}
