
<div class="wrapper"
  [style.flex-direction]="isAuthenticated ? 'row' : 'column'"
  [style.height]="isAuthenticated ? '100%' : 'auto'">
    <ng-container *ngIf="isAuthenticated">
        <app-side-menu></app-side-menu>
        <div *ngTemplateOutlet="page"></div>
    </ng-container>

    <ng-container *ngIf="!isAuthenticated">
        <app-header></app-header>
        <div *ngTemplateOutlet="page"></div>
        <app-footer></app-footer>
    </ng-container>
</div>

<ng-template #page>
    <main id="main" class="main">
        <div class="price-block">
            <span id="price-currency">$</span><span id="price">0.0011</span>
            <span id="price-info"><br>per second of running a task</span>
            <span class="text-600 font-light"><br>(VAT is not included in the price)</span>
        </div>
    
        <div class="model-desc">
            <p id="model-desc-title">Our pay-as-you-go pricing model is simple, transparent,<br> and tailored to your needs
            </p>
            <p class="model-list-title">Pay only for what you use</p>
            <p class="model-list-info">Our pricing model ensures that you only pay for the time your task is processed on
                our platform.<br>There are no unnecessary fees.</p>
            <p class="model-list-title">Transparent pricing</p>
            <p class="model-list-info">We charge $0.0011 per second of running task, and our platform bills in real-time.
                <br>This means you always know exactly how much you're being charged.
            </p>
            <p class="model-list-title">Flexibility</p>
            <p class="model-list-info">The pricing model allows you to easily increase or decrease your usage as
                needed.<br>You don't have to worry about long-term contracts or commitments.</p>
        </div>
    
        <div id="instruction-title">Assessment workflow</div>
        <div class="instruction">
            <span><img src="../../assets/img/prices-page/list.png" alt="list">Create a plan, <br>add different
                exercises</span>
            <span><img src="../../assets/img/prices-page/user-logo.png" alt="user-logo">Assign a plan <br>to a
                candidate</span>
            <span><img src="../../assets/img/prices-page/flag.png" alt="flag">Candidate completes <br>the exercises</span>
            <span><img src="../../assets/img/prices-page/credit-card.png" alt="credit-card">Pay only for time <br>spent
                running a task</span>
        </div>
    
        <div class="examples">
            <p id="examples-title">Examples of cost calculations</p>
            <ol class="examples-list">
                <li>Completed task: </li>
                <p>For a task successfully completed after a run time of 1 hour and 30 minutes, you will be charged for the
                    full duration of the task, <br>1.5 hours x $5.04 = $7.56</p>
                <li>Canceled task:</li>
                <p> If a candidate starts an exercise in a plan and then cancels after 20 minutes, you will still be charged
                    for the time the exercise was running, <br>0.33 hours x $5.04 = $1.67.
                </p>
                <li>Abandoned task:</li>
                <p> Each task has a time limit. If after running a task on our platform for 45 minutes the task has not been
                    completed and the deadline has expired, the task is considered abandoned and the cost still reflects the
                    time it was being processed, <br>0.75 hours x $5.04 = $3.75</p>
            </ol>
        </div>
    </main>    
</ng-template>

