<section>
    <div class="hints-box">
        <div class="hints-button">
            <exercise-button
             [disabled]="isButtonDisabled || isLoading"
             (click)="getHint()">
              <span *ngIf="isButtonDisabled">No more hints available</span>
              <span *ngIf="!isButtonDisabled">Get a hint</span>
            </exercise-button>
        </div>
        <div 
          class="hints"
          *ngFor="let hint of hints" 
          [innerHTML]="hint | markdown">
        </div>
    </div>
</section>