import { environment } from 'src/environments/environment';
import { ServiceLocator } from '../service-locator.service';
import { S3Service } from '../s3.service';
import { BehaviorSubject } from 'rxjs';

export const s3UrlPattern = /^https:\/\/[a-zA-Z0-9-]+\.s3\.[a-zA-Z0-9-]+\.amazonaws\.com\//;

export class Picture {
  constructor(pictureURL?: string) {
    this._originalURL = pictureURL ?? '';
    this._s3Service = ServiceLocator.injector.get(S3Service);
    this.initUrl();
  }

  private _originalURL: string;
  private _url: BehaviorSubject<string> = new BehaviorSubject<string>(environment.DefaultMemberPicture);

  private _s3Service: S3Service;

  public get url(): string {
    return this._url.value;
  }

  public set url(newUrl: string) {
    this._url.next(newUrl);
  }

  public get originalURL(): string {
    return this._originalURL;
  }

  public set originalURL(newUrl: string) {
    this._originalURL = newUrl;
    this.initUrl();
  }

  private async initUrl(): Promise<void> {
    if (s3UrlPattern.test(this._originalURL)) {
      try {
        const key = this.extractS3Key(this._originalURL);
        const output = await this._s3Service.fetchUserAvatar(key);
        const readableStream = output.Body as ReadableStream;
        const url = await this.readPicture(readableStream, output.ContentType);
        this._url.next(url);
        return;
      } catch {
        this._url.next(environment.DefaultMemberPicture);
        return;
      }
    }

    if (this._originalURL) {
      this._url.next(this._originalURL);
      return;
    }

    this._url.next(environment.DefaultMemberPicture);
  }

  private extractS3Key(s3Url: string): string {
    const key = s3Url.replace(s3UrlPattern, '');
    return decodeURIComponent(key);
  }

  private async readPicture(readableStream: ReadableStream, pictureType?: string): Promise<string> {
    const blob = await new Response(readableStream).blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        const base64String = result instanceof ArrayBuffer ? new TextDecoder().decode(result) : result;
        return base64String ? resolve(`data:${pictureType};base64, ${base64String.split(',')[1]}`) : reject();
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
}