import { Component } from '@angular/core';
import { AssignedPlansDataService } from './data-service/assigned-plans-data.service';
import { ActivatedRoute } from '@angular/router';
import { GetQueryParam } from '../shared/service/route';

@Component({
  selector: 'app-assigned-plans',
  templateUrl: './assigned-plans.component.html',
  styleUrls: ['./assigned-plans.component.scss']
})
export class AssignedPlansComponent {
  constructor(
    public assignedPlansData: AssignedPlansDataService,
    private activatedRoute: ActivatedRoute
  ) { 
    const planId = GetQueryParam(activatedRoute, 'id');
    this.assignedPlansData.selectAssignedPlanById(planId);
  }
}