import { ServiceLocator } from '../service-locator.service';
import { S3Service } from '../s3.service';
import { HttpClient } from '@angular/common/http';


export class Attachment {
  constructor(attribute: AttachmentAttribute) {
    this._size = attribute.size;
    this._key = attribute.key;
    this._bucketName = attribute.bucketName;
    this._prefix = attribute.prefix + '/';

    this._s3Service = ServiceLocator.injector.get(S3Service);
    this._httpClient = ServiceLocator.injector.get(HttpClient);
  }

  private _size: number | undefined;
  private _key: string | undefined;
  private _bucketName: string | undefined;
  private _prefix: string | undefined;
    
  private _name: string | undefined;
  private _URL: Promise<string> | undefined;

  private _s3Service: S3Service;
  private _httpClient: HttpClient;

  get size(): number | undefined {
    return this._size;
  }

  get url(): Promise<string> | undefined {
    if (!this._URL && this._key && this._bucketName) {
      this._URL = this._s3Service.getAttachmentURL(this._key, this._bucketName);
    }
    return this._URL;
  }

  get name(): string | undefined{
    if (!this._name && this._prefix) {
      this._name = this._key?.replace(this._prefix, '');
    }
    return this._name;
  }

  public async download(): Promise<void> {
    if (!this.url || !this.name) 
      throw new Error('Failed to download file');

    const url = await this.url;
    this._httpClient.get(url, { responseType: 'blob' }).subscribe((blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = this.name!;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
}

interface AttachmentAttribute {
    key: string | undefined
    size: number | undefined
    bucketName: string | undefined
    prefix: string | undefined
}