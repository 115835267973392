<section>
    <div class="code-editor-bar">
        <span>{{ lastOpenedFile?.name }}</span>
        <i class="material-icons-round" 
            (click)="closeEditor()">
            close
        </i>
    </div>

    <ngx-monaco-editor
     [options]="editorOptions" 
     [(ngModel)]="fileText" 
     (onInit)="onEditorInit($event)">
    </ngx-monaco-editor>

    <p-confirmDialog #cd 
      [style]="{width: '30vw'}"
      [breakpoints]="{'1440px': '50vw'}"
      [key]=dialogKey>
        <ng-template pTemplate="footer">
            <div>
                <exercise-button class="mr-3" (click)="cd.reject()">No</exercise-button>
                <exercise-button (click)="cd.accept()">Yes</exercise-button>
            </div>
        </ng-template>
    </p-confirmDialog>
</section>
