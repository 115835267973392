import { Pipe, PipeTransform } from '@angular/core';
import HumanaizeDuration from 'humanize-duration';
import moment from 'moment-mini';

@Pipe({
  name: 'humanaizeMinutes'
})
export class HumanaizeMinutes implements PipeTransform {
  transform(minutes?: number) {
    return MinutesToHumanReadable(minutes ?? 0);
  }
}

@Pipe({
  name: 'humanaizeSeconds'
})
export class HumanaizeSeconds implements PipeTransform {
  transform(minutes?: number) {
    return SecondsToHumanReadable(minutes ?? 0);
  }
}

@Pipe({
  name: 'totalTime'
})
export class TotalTimePipe implements PipeTransform {
  transform(time: Map<string, number> | number[]) {
    const totalTime = (Array.isArray(time) ? time : Array.from(time.values()))
      .reduce((total, val) => Number(total) + Number(val), 0);
    return SecondsToHumanReadable(totalTime);
  }
}

export function MinutesToHumanReadable(minutes: number, round = true): string {
  return HumanaizeDuration(minutes * 60000, { largest: 2, round });
}

export function GetCurrentTime(): string {
  return moment.utc().format();
}

export function SecondsToHumanReadable(seconds: number, round = true): string {
  return HumanaizeDuration(seconds * 1000, { largest: 2, round });
}

export function GetTimeRemaining(date: string, outputFormat: TimeFormat): number {
  const countDownDate = new Date(date).getTime();
  const now = new Date().getTime();
  const remainingTime = countDownDate - now;

  if (remainingTime < 0) {
    return 0;
  }

  switch (outputFormat) {
  case 'milliseconds': return remainingTime;
  case 'seconds': return remainingTime / 1000;
  case 'minutes': return remainingTime / (1000 * 60);
  case 'hours': return remainingTime / (1000 * 60 * 60);
  }
}

export function GetTimestamp(): string {
  return moment().toISOString();
}

export type TimeFormat = 'seconds' | 'milliseconds' | 'minutes' | 'hours'