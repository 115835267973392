<div class="wrapper">
    <button 
      class="apply-btn"
      *ngIf="hasBackButton"
      (click)="closeExerciseManager.emit()">
        <i class="material-icons-outlined">chevron_left</i>  
        <span>Apply</span>
    </button>
    <span class="exercise-info">
        Loaded: {{exerciseData.filteredExercises.length > 0 ? 1 : 0}}-
        {{exerciseData.filteredExercises.length}} out of {{exerciseData.exercises.length}}
    </span>
    <span class="exercise-info" *ngIf="hasBackButton">
        Selected: {{selectedExerciseCount}} out of {{exerciseData.exercises.length}}
    </span>
</div>