import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { Exercise } from 'src/app/exercise.module';
import { PlanDataService } from 'src/app/plans-management/plan-data-service/plan-data.service';
import { Subscription } from 'rxjs';
import { ExerciseDataService } from '../exercise-data-service/exercise-data.service';
import { ResizedEvent } from 'angular-resize-event';

@Component({
  selector: 'app-exercise-list',
  templateUrl: './exercise-list.component.html',
  styleUrls: ['./exercise-list.component.scss'],
})
export class ExerciseListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isSelectableModeActive = false;
  @Output() selectedExercise = new EventEmitter<Exercise>();
  @Output() closeExerciseManager = new EventEmitter<boolean>();

  private subscription: Subscription = new Subscription();
  private readonly PADDING: number = 20;
  private readonly GAP: number = 25;
  private readonly MIN_SPLIT_WIDTH: number = 725;

  constructor(
    private planData: PlanDataService,
    public exerciseData: ExerciseDataService,
    private renderer: Renderer2,
    private el: ElementRef
  ) { }


  ngOnInit(): void {
    if (this.isSelectableModeActive) {
      this.subscribeToSelectedExercise();
    } else {
      this.exerciseData.removeExerciseSelection();
    }
  }

  ngOnDestroy(): void {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isSelectableModeActive && changes.exercises) {
      this.synchronizeExerciseSelection();
    }
  }

  get selectedExercisesCount(): number {
    return this.planData.selectedPlan?.exerciseIDs.length ?? 0;
  }

  private subscribeToSelectedExercise(): void {
    this.subscription.add(this.planData.selectedPlan$.subscribe(() => {
      this.synchronizeExerciseSelection();
    }));
  }

  public selectExercise(exercise: Exercise): void {
    this.selectedExercise.emit(exercise);
  }

  public hasSelectedExercises(): boolean {
    return this.exerciseData.filteredExercises.some(exercise => exercise.selected);
  }

  public hasOtherExercises(): boolean {
    return this.exerciseData.filteredExercises.some(exercise => !exercise.selected);
  }

  public calculateExerciseWidth(exercisesList: ResizedEvent): void {
    const splitParts = this.calculateSplitParts(this.MIN_SPLIT_WIDTH, exercisesList.newRect.width);
    const gapPercent = this.calculateGapPercent(splitParts, exercisesList.newRect.width);
    this.applyWidthToElements(splitParts, gapPercent);
  }

  private calculateSplitParts(minSplitWidth: number, totalWidth: number): number {
    return Math.floor((totalWidth - this.PADDING) / minSplitWidth);
  }

  private calculateGapPercent(splitParts: number, totalWidth: number): number {
    return splitParts > 0 ? (((splitParts - 1) * this.GAP) / (totalWidth - this.PADDING)) * 100 : 0;
  }

  private applyWidthToElements(splitParts: number, gapPercent: number): void {
    const elements = this.el.nativeElement.querySelectorAll('.exercise-container');
    elements.forEach((e: Element) => {
      if (splitParts > 0) {
        this.renderer.setStyle(e, 'width', `${(100 / splitParts) - (gapPercent / splitParts)}%`);
      } else {
        this.renderer.setStyle(e, 'width', '100%');
      }
    });
  }

  public toggleSelection(exercise: Exercise): void {
    exercise.selected = !exercise.selected;
    exercise.selected
      ? this.addSelection(exercise)
      : this.deleteSelection(exercise);
  }

  private addSelection(exercise: Exercise): void {
    if (this.planData.selectedPlan) {
      this.planData.selectedPlan.exerciseIDs.push(exercise.id);
      this.planData.selectedPlan.exerciseNames.push(exercise.userFriendlyName);
      this.planData.selectedPlan.executionTime += exercise.executionTime;
    }
  }

  private deleteSelection(exercise: Exercise): void {
    if (!this.planData.selectedPlan) {
      return; 
    }

    let index = this.planData.selectedPlan?.exerciseIDs.findIndex(id => id === exercise.id) ?? -1;
    if (index !== -1) {
      this.planData.selectedPlan?.exerciseIDs.splice(index, 1);
    }

    index = this.planData.selectedPlan?.exerciseNames.findIndex(id => id === exercise.name) ?? -1;
    if (index !== -1) {
      this.planData.selectedPlan?.exerciseNames.splice(index, 1);
    }

    this.planData.selectedPlan.executionTime -= exercise.executionTime;
  }

  private synchronizeExerciseSelection(): void {
    const selectedExerciseIds = new Set(this.planData.selectedPlan?.exerciseIDs);
    this.exerciseData.filteredExercises.forEach(exercise => {
      exercise.selected = selectedExerciseIds.has(exercise.id);
      if (exercise.selected) {
        this.updatePlanDataExercise(exercise);
      }
    });
  }

  private updatePlanDataExercise(exercise: Exercise): void {
    const selectedExercise = this.planData.selectedPlan?.exerciseIDs.find(id => id === exercise.id) ?? undefined;
    if (!selectedExercise) return;
    Object.assign(selectedExercise, exercise);
  }
}
