<ng-container *ngIf="screenSize === 'small'; then smallScreen"></ng-container>
<ng-container *ngIf="screenSize === 'medium'; then mediumScreen"></ng-container>
<ng-container *ngIf="screenSize === 'large'; then largeScreen"></ng-container>

<ng-template #smallScreen>
  <app-small-screen></app-small-screen>
</ng-template>

<ng-template #mediumScreen>
  <app-medium-screen></app-medium-screen>
</ng-template>

<ng-template #largeScreen>
  <app-large-screen></app-large-screen>
</ng-template>
