<div class="wrapper"
    *ngIf="data !== null; else skeleton">
    <a class="assigned-plan-container"
      (click)="navigate(plan.redirectLink, plan.id); $event.stopPropagation()"
      *ngFor="let plan of data!.slice(0,3)">
        <img class="user-img" [src]='plan.picture.url'>
        <div class="assigned-plan-info">
            <span class="highlight-text">{{ plan.header }}</span>
            <span class="medium-text">{{ plan.name }}</span>
            <span class="small-text">Status: {{ plan.status }}</span>
        </div>
        <circle-progress
            *ngIf="user.isInterviewer" 
            class="progress"
            [title]="plan.progressPercentage + '%'"
            [showUnits]="false"
            [showSubtitle]="false"
            [percent]="plan.progressPercentage!" 
            [radius]="23.5"
            [animation]="true" 
            [startFromZero]="true"
            [space]="-3"
            [animationDuration]="300"
            [outerStrokeColor]="'#AB8DFF'"
            [outerStrokeWidth]="3"
            [innerStrokeColor]="'#F1ECF3'"
            [clockwise]="true"
            [backgroundPadding]="0"
            [titleFontSize]="'14px'"
            [titleFontWeight]="'700'">
        </circle-progress>
        <i class="material-icons-round progress"
          *ngIf="user.isUser"
          (click)="navigate(plan.buttonRedirectLink!); $event.stopPropagation()">
            play_circle_outline
        </i>
    </a>
 
    <div *ngIf="data.length <= 3" class="assigned-plan-container default-cursor">
        <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    </div>
    <div *ngIf="data.length <= 2" class="assigned-plan-container default-cursor">
        <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    </div>
    <div *ngIf="data.length <= 1" class="assigned-plan-container default-cursor">
        <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    </div>
    <div *ngIf="data.length == 0" class="assigned-plan-container default-cursor">
        <ng-container *ngTemplateOutlet="defaultContent"></ng-container>
    </div>

    <div *ngIf="data.length >= 4" class="assigned-plan-container last">
        <h5 *ngIf="user.isInterviewer" class="highlight-text">
            You Have {{dataService.totalDataCount-3}} More Assigned plans
        </h5>
        <h5 *ngIf="user.isUser" class="highlight-text">
            There are {{dataService.totalDataCount-3}} Uncompleted Exercises Remaining
        </h5>
        <p-avatarGroup>
            <p-avatar *ngFor="let plan of data.slice(3,6)" [image]="plan.picture.url" shape="circle"></p-avatar>
            <p-avatar *ngIf="dataService.totalDataCount -6 > 0" label="+{{dataService.totalDataCount-6}}" shape="circle"></p-avatar>
        </p-avatarGroup>
        <span *ngIf="user.isInterviewer" class="redirect-link medium-text" routerLink="/assigned-plans">See all assigned plans</span>
        <span *ngIf="user.isUser" class="redirect-link medium-text" routerLink="/plans">See all exercises</span>
    </div>
</div>

<ng-template #skeleton>
    <div class="wrapper">
        <div class="assigned-plan-container"
            *ngFor="let _ of skeletonArray">
            <p-skeleton class="user-img" width="50px" height="50px"></p-skeleton>
            <div class="assigned-plan-info">
                <div><p-skeleton width="70px" height="14px"></p-skeleton></div>
                <div><p-skeleton width="110px" height="12px"></p-skeleton></div>
                <div><p-skeleton width="90px" height="12px"></p-skeleton></div>
            </div>
            <p-skeleton class="progress" width="50px" height="50px" shape="circle"></p-skeleton>
        </div>
    </div>
</ng-template>

<ng-template #defaultContent>
    <div class="no-content-template-container"
      *ngIf="user.isInterviewer">
        <h4 class="medium-text">No more assigned plans</h4>
        <span class="small-text">
            Assign an exercise plan to some user and you will see the user's plan status here
        </span>
    </div>
    <div class="no-content-template-container"
      *ngIf="user.isUser">
        <h4 class="medium-text">You don't have any uncompleted exercises</h4>
    </div>
</ng-template>