<p-confirmDialog #cd 
      [style]="{width: '30vw'}"
      [breakpoints]="{'1440px': '50vw'}"
      [key]=dialogKey>
        <ng-template pTemplate="footer">
            <div>
                <exercise-button class="mr-3" (click)="cd.reject()">Exit</exercise-button>
                <exercise-button (click)="cd.accept()">Support Us</exercise-button>
            </div>
        </ng-template>
</p-confirmDialog>