import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { InputSwitchOnChangeEvent } from 'primeng/inputswitch';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/user.module';

@Component({
  selector: 'role-toggler',
  templateUrl: './role-toggler.component.html',
  styleUrls: ['./role-toggler.component.scss']
})
export class RoleTogglerComponent implements OnInit {
  public user!: User;
  public checked = false;

  constructor(
    private confirmationService: ConfirmationService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.user;
    this.checked = this.user.isDemoModeOn;
  }

  public onRoleChange(e: InputSwitchOnChangeEvent): void {
    this.confirmationService.confirm({
      header: 'Change profile role?',
      message:
        this.checked ?
          'Are you sure you want to change your role to user?<br>You can change it back at any time. The user role allows you to test exercises plans assigned to you.' :
          'Are you sure you want to change your role to Interviewer?<br>You can change it back at any time.',
      accept: () => {
        this.user.testMode = e.checked;
        this.checked = e.checked;
        window.location.reload();
      },
      reject: () => {
        this.checked = !e.checked;
      }
    });
  }

}
