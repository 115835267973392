<div class="title">Become a {{data.companyName}} Member</div>
<div class="content">
    <span>
        Congratulations! You have been invited to become a member of {{data.companyName}}. Please press the access
        button below to officially accept the invitation and become a member!
    </span>
</div>

<app-loading-animation *ngIf="isRequestSent"></app-loading-animation>

<div *ngIf="!isRequestSent" class="df fd-c ai-c">
    <button class="accept-button" (click)="becomeMember()">
        Accept
    </button>
    <a class="cancel-button" (click)="closeDialog(false)">
        Cancel
    </a>
</div>