<app-header></app-header>

<div class="wrapper">
    <div class="registration-container" *ngIf="!isVerification">
        <div class="logo-container">
            <img src="../../assets/img/template/logo.svg">
            <h1>Dev Skill</h1>
        </div>

        <div class="form">
            <div class="input-form">
                <span class="form-title">Email</span>
                <app-input-field 
                  [inputControl]="accountFormGroup | formControl:'email'"
                  type="email"
                  autocomplete="email"
                  name="email"
                  [isRequired]="true"
                  [error]="accountFormGroup.controls.email.invalid ? 'Please enter a valid email address' : ''">
                </app-input-field>
            </div>

            <div class="input-form">
                <span class="form-title">Password</span>
                <app-input-field 
                  [inputControl]="accountFormGroup | formControl:'password'"
                  type="password"
                  name="password"
                  autocomplete="new-password"
                  [isRequired]="true"
                  [error]="passwordErrorHandler(accountFormGroup.controls.password.errors)">
                </app-input-field>
            </div>

            <div class="input-form">
                <span class="form-title">Password Confirmation</span>
                <app-input-field 
                  [inputControl]="accountFormGroup | formControl:'passwordConfirmation'"
                  type="password"
                  name="passwordConfirmation"
                  autocomplete="new-password"
                  [isRequired]="true"
                  [error]="accountFormGroup.controls.passwordConfirmation.errors?.passwordMismatch  ? 'Passwords do not match' : ''">
                </app-input-field>
            </div>

            <div class="checkbox-container">
                <p-checkbox 
                  name="termsCheckbox" 
                  id="termsCheckbox-input"
                  [binary]="true" 
                  [formControl]="(accountFormGroup | formControl:'termsCheckbox')!">
                </p-checkbox>
                <label>
                    <span>I agree with the&nbsp;</span>
                    <a routerLink="/terms" [queryParams]="queryParams">
                        <span>Terms & Conditions</span>
                    </a>
                </label>
            </div>
            <small 
                class="p-error m-fadeOut checkbox-error"
                [class.m-fadeIn]="!accountFormGroup.value.termsCheckbox">
                You must agree to the Terms & Conditions
            </small>
        </div>

        <div *ngIf="!isRequestSent">
            <button class="signup-button" id="signup-button" (click)="register()">Sign Up</button>
            <div class="external-providers">
                <h2><span>Or</span></h2>
                <button class="google-btn" (click)="googleLogin()">
                    <img src="../../assets/img/google/google_logo.svg" alt="Google Logo" class="google-logo">
                    <span>Sign in with Google</span>
                </button>
                <button class="linkedin-btn" (click)="linedInLogin()">
                    <img src="../../assets/img/linkedIn/linkedin_logo.svg" alt="LinkedIn Logo" class="linkedin-logo">
                    <span>Sign in with LinkedIn</span>
                </button>
            </div>
        </div>

        <app-loading-animation *ngIf="isRequestSent"></app-loading-animation>
    </div>

    <div class="verification-container" *ngIf="isVerification">
        <div class="verification-title">
            <h1>Verify Email</h1>
            <p>To complete your registration, please check your email for the verification code we've sent you. </p>
        </div>

        <form>
            <span>Verification Code</span>
            <app-input-field 
                [inputControl]="verificationFormGroup | formControl:'verificationCode'"
                type="code"
                name="code"
                [isRequired]="true"
                [error]="verifyError">
            </app-input-field>
        </form>

        <button class="signup-button" id="signup-button" (click)="confirmRegistration()" *ngIf="!isLoading">Verify</button>
        <app-loading-animation *ngIf="isLoading"></app-loading-animation>
    </div>

    <div class="registration-text-container">
        <h1 align="center">Embrace Your Tech Journey with DevSkill</h1>
        <div class="registration-text">
            <i class="material-icons-outlined">code</i>
            <p>
                Challenge yourself with DevSkill's tech exercises, covering areas like Terraform, GIT, Bash, and
                much more.
            </p>
        </div>
        <div class="registration-text">
            <i class="material-icons-outlined">tips_and_updates</i>
            <p>
                Monetize your expertise and earn money by creating and sharing exercises and learning content.
            </p>
        </div>
        <div class="registration-text">
            <i class="material-icons-outlined">work_outline</i>
            <p>
                Create a company account and simplify your interview process with our platform's innovative
                exercises.
            </p>
        </div>
    </div>
</div>