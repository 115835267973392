<div class="wrapper">
  <p-calendar [inline]="true" 
    [firstDayOfWeek]="1"
    (onMonthChange)="onMonthChange($event)">
    <ng-template pTemplate="date" let-date>
      <div class="calendar-date"
        [ngClass]="{'custom-date-style': isDateToHighlight(date)}"
        (mouseenter)="showOverlay(date, $event,op)" 
        (mouseleave)="hideOverlay(op)">
          {{date.day}}
      </div>
    </ng-template>
  </p-calendar>
</div>

<p-overlayPanel #op [style]="{width: '250px'}">
  <ng-template pTemplate>
    <div class="template">
      <h4> {{currentEvent?.date | date}} </h4>
      <div 
       *ngFor="let event of currentEvent?.events">
        {{event}}
      </div>
    </div>
</ng-template>
</p-overlayPanel>