import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentMethod, PaymentState } from '../../payment.component';
import { User } from 'src/app/user.module';
import { MenuItem, MessageService } from 'primeng/api';
import { LambdasService } from 'src/app/lambads.service';
import { Cart } from 'src/app/models/payment/cart.models';
import { AuthService } from 'src/app/auth/auth.service';
import { FormGroup } from '@angular/forms';
import { FormValidatorsService } from 'src/app/form-validators.service';
import { Address } from 'src/app/models/address.models';
import { GetCountryByCode, GetCountryCode } from 'src/app/shared/service/countries';

@Component({
  selector: 'user-payment',
  templateUrl: './user-payment.component.html',
  styleUrls: ['../../styles/payment.scss']
})
export class UserPaymentComponent implements OnInit {
  @Input() assignedPlanId: string | undefined;
  @Input() candidateEmail: string | undefined;
  @Input() paymentReference: string | undefined;
  @Output() initiatePaymentEvent = new EventEmitter<{ paymentMethod: PaymentMethod, address: Address }>();

  public paymentMethods: PaymentMethod[] = [];
  public selectedPaymentMethod: PaymentMethod | null = null;
  public paymentState = PaymentState;
  public user: User = this.auth.user;
  public confirm = false;

  public payerInformationFromGroup: FormGroup;
  public cart: Cart | null = null;

  public menuItem: MenuItem[] = [];

  constructor(
    private messageService: MessageService,
    private lambdas: LambdasService,
    private auth: AuthService,
    private _validatorService: FormValidatorsService,
  ) {
    this.payerInformationFromGroup = new FormGroup({
      firstName: _validatorService.GetRequiredFormControl(),
      lastName: _validatorService.GetRequiredFormControl(),
      country: _validatorService.GetRequiredFormControl(),
      state: _validatorService.GetEmptyFormControl(),
      city: _validatorService.GetRequiredFormControl(),
      street: _validatorService.GetRequiredFormControl(),
      postcode: _validatorService.GetRequiredFormControl(),
    });
  }

  public get isPayButtonActive(): boolean {
    return !!this.selectedPaymentMethod &&
      this.payerInformationFromGroup.valid &&
      this.confirm;
  }

  async ngOnInit(): Promise<void> {
    this.fillFormGroup();

    await Promise.all([
      this.fetchPlanPaymentInfo(),
      this.fetchPaymentMethods()
    ]);

    this.createMenuForBreadcrumb();
  }

  private async fillFormGroup(): Promise<void> {
    const country = GetCountryByCode(this.user.countryCode);
    this.payerInformationFromGroup.controls.firstName.setValue(this.user.given_name);
    this.payerInformationFromGroup.controls.lastName.setValue(this.user.family_name);
    this.payerInformationFromGroup.controls.country.setValue(country.name);
  }

  private async fetchPaymentMethods(): Promise<void> {
    try {
      this.paymentMethods = (await this.lambdas.getPaymentMethod(this.assignedPlanId!).toPromise()).payment_methods;
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: (err as Error).message
      });
    }
  }

  public async fetchPlanPaymentInfo(): Promise<void> {
    try {
      const response = await this.lambdas.getPlanPrice(
        this.candidateEmail!,
        this.assignedPlanId!,
        GetCountryCode(this.payerInformationFromGroup.value.country) ?? ''
      ).toPromise();
      const country = GetCountryByCode(response.country_code);
      this.payerInformationFromGroup.controls.country.setValue(country.name);
      this.cart = new Cart(response);
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: (err as Error).message
      });
    }
  }

  private createMenuForBreadcrumb(): void {
    this.menuItem = [
      { label: 'Assigned Plans', routerLink: ['/assigned-plans'] },
      { label: `Plan "${this.cart!.planName}" Assigned to ${this.candidateEmail}`, routerLink: ['/assigned-plans'], queryParams: { id: this.assignedPlanId } },
      { label: 'Payment' }
    ];
  }

  public OnPayClick() {
    const countryCode = GetCountryCode(this.payerInformationFromGroup.value.country);
    this.initiatePaymentEvent.emit({
      paymentMethod: this.selectedPaymentMethod!,
      address: new Address({
        country_code: countryCode,
        state: this.payerInformationFromGroup.value.state,
        city: this.payerInformationFromGroup.value.city,
        postcode: this.payerInformationFromGroup.value.postcode,
        street: this.payerInformationFromGroup.value.street
      })
    });
  }
}
