import { FormControl, Validators } from '@angular/forms';

export class PlanControlService {
  private inputControl: FormControl;

  constructor(
    value = '',
    disabled = false,
    validatorPatern = /.*/,
    minLength = 0,
    maxLength = 50,
  ) {
    this.inputControl = new FormControl(
      { value: value, disabled: disabled },
      [Validators.pattern(validatorPatern),
        Validators.minLength(minLength),
        Validators.maxLength(maxLength)]);
  }

  public get control(): FormControl {
    return this.inputControl;
  }

  public get value(): string {
    return this.inputControl.value ?? '';
  }

  
  public setValue(value: string): void {
    this.inputControl.setValue(value);
  }
  
  public enableInput(): void {
    this.inputControl.enable();
  }
  
  public disableInput(): void {
    this.inputControl.disable();
  }
  
  public hasError(): boolean {
    return this.inputControl.invalid;
  }
  
  public isMinLengthError(): boolean {
    return this.control.errors && this.control.errors.minlength;
  }

  public isEmpty(): boolean {
    return !this.inputControl.value;
  }

  public clearInput(): void {
    this.inputControl.reset();
  }

  public getErrorMessage(): string {
    if (this.inputControl.hasError('pattern')) {
      return 'Name can only contain letters with a space between the first and last name. It should not start or end with a space.';
    } else if (this.inputControl.hasError('minlength')) {
      return 'Name must be at least 3 characters long.';
    }
    return 'Failed to change name, please try again later :)';
  }
}
