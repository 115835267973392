import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { User } from '../../../user.module';
import { NameControlService } from '../../name-control-service/name-control.service';
import { MenuDataService } from '../../menu-data.service';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { ComponentType } from '@angular/cdk/portal';
import { DialogService } from 'primeng/dynamicdialog';
import { LambdasService } from 'src/app/lambads.service';

@Component({
  providers: [ConfirmationService],
  selector: 'app-large-screen',
  templateUrl: './large-screen.component.html',
  styleUrls: ['./large-screen.component.scss', '../../styles/side-menu.scss']
})
export class LargeScreenComponent implements OnInit{
  private readonly DEFAULT_PLACEHOLDER = 'Change Name';
  public user!: User;
  public placeholderValue: string = this.DEFAULT_PLACEHOLDER;
  public inputControl: NameControlService | null = null;

  constructor(
    public menuData: MenuDataService,
    public auth: AuthService,
    private lambdas: LambdasService,
    private messageService: MessageService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.user = this.auth.user;
    if (!this.hasUserName) {
      this.inputControl = new NameControlService();
    } 
  }

  get hasUserName(): boolean {
    return !!this.user?.full_name;
  }

  public openDialog(dialogComponent: ComponentType<unknown>, event: MouseEvent){
    this.dialogService.open(dialogComponent, {
      width: '250px',
      height: '250px',
      closable: true,
      showHeader: false,
      dismissableMask: true,
      contentStyle: {
        'border' : '1px solid rgb(202, 199, 199)',
        'border-radius' : '5px'
      },
      style:{
        'position': 'absolute',
        'left': `${event.clientX + 150}px`,
        'top': `${event.clientY - 200}px`
      }
      
    });
  }

  public removePlaceholder(): void {
    this.placeholderValue = '';
  }

  public restorePlaceholder(): void {
    this.placeholderValue = this.DEFAULT_PLACEHOLDER;
  }

  public async updateUserName(): Promise<void> {
    if (!this.inputControl || !this.inputControl.isEnabled) return;
    try {
      if (this.inputControl.hasError() || this.inputControl.isEmpty()) {
        this.inputControl.getErrorMessage();
        this.inputControl.clearInput();
        this.restorePlaceholder();
        return;
      }

      this.inputControl.disableInput();
      const [firstName, lastName] = this.getFirstAndLastName();
      await this.saveUserName(firstName, lastName);
      await this.refreshUserName();
    } catch (err) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to change name, please try again later' });
      this.inputControl.enableInput();
    }
  }

  private async refreshUserName(): Promise<void> {
    await this.auth.refreshUser(true);
    this.user = this.auth.user;
  }

  private getFirstAndLastName(): [string, string] {
    const value = this.inputControl!.getName();
    const nameParts = value.split(' ');
    return [nameParts[0], nameParts[1] ? nameParts[1] : ''];
  }

  public showControlErrorMessage(): void {
    const error = this.inputControl!.getErrorMessage();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: error });
  }

  private async saveUserName(firstName: string, lastName: string): Promise<void> {
    await this.lambdas.userDetailsSync(firstName, lastName, this.user.phoneNumber).toPromise();
  }
}
