import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './components/profile/profile.component';
import { SharedModule } from '../shared/shared.module';
import { DividerModule } from 'primeng/divider';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideMenuModule } from '../side-menu/side-menu.module';
import { CompanyProfileComponent } from './components/company-profile/company-profile.component';
import { UserListModule } from '../user-list/user-list.module';
import { RouterModule } from '@angular/router';
import { UserConnectionsComponent } from './components/user-connections/user-connections.component';
import { AvatarModule } from 'primeng/avatar';

@NgModule({
  declarations: [
    ProfileComponent,
    CompanyProfileComponent,
    UserConnectionsComponent
  ],
  providers: [
    ConfirmationService
  ],
  imports: [
    CommonModule,
    SharedModule,
    DividerModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    FormsModule,
    SideMenuModule,
    UserListModule,
    RouterModule,
    AvatarModule,
  ]
})
export class SettingsModule { }
