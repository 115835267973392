import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit{
  public isAuthenticated = false;

  constructor(
    private auth: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isAuthenticated = await this.auth.isAuthenticated();
  }
}
