<app-header></app-header>
<div class="cookies">
    <h1 class="c13"><span class="c12">Dev Skill SIA Cookies Policy</span></h1>

    <h4>
        The objective of this Policy is to explain the use of cookie files upon visiting platform.dev-skill.com website.
    </h4>

    <h4>What is a cookie file?</h4>
    <p>
        Cookie file is a small data file that is placed in the user&#8217;s browser from the website server when user
        enters the website. The cookie files are stored in the user&#8217;s device (computer or smartphone) constantly
        or temporarily, i.e., only upon visiting the website.
    </p>

    <h4>Types of cookie files and their purpose</h4>
    <p>
        In order to ensure the functionality of the website and convenience user experience at the website, we are using
        the following types of cookie files.
    </p>

    <h4>Types of used cookies </h4>
    <p> List of cookies that might be used on our website:</p>

    <table>
        <tr>
            <th class="table-cookie-files">Cookie file</th>
            <th class="table-purpose">Purpose</th>
            <th class="table-expiry">Expiry</th>
            <th class="table-name">Name</th>
        </tr>
        <tr>
            <td>Session cookies</td>
            <td>Short-term cookie file that ensures safety of the session and basic functionality of the website</td>
            <td>Session</td>
            <td>None</td>
        </tr>
        <tr>
            <td>Strictly Necessary cookies</td>
            <td>These cookies are essential in order to enable you to move around the site and use its features, such as
                accessing secure areas of the site. Without these cookies, services you have asked for cannot be
                provided. 
            </td>
            <td>1 day</td>
            <td>
                CognitoIdentityServiceProvider.clientID.username.userData
                <br>CognitoIdentityServiceProvider.clientID.username.accessToken
                <br>CognitoIdentityServiceProvider.clientID.username.clockDrift
                <br>CognitoIdentityServiceProvider.clientID.username.LastAuthUser
                <br>CognitoIdentityServiceProvider.clientID.username.idToken
                <br>CognitoIdentityServiceProvider.clientID.username.refreshToken
                <br>amplify-signin-with-hostedUI
            </td>
        </tr>
        <tr>
            <td>First party analytics cookies</td>
            <td>These cookies allow us to employ data analytics so we can measure and improve the performance of our
                site and provide more relevant content to you.
            </td>
            <td></td>
            <td>None</td>
        </tr>
        <tr>
            <td>Performance cookies</td>
            <td>Performance cookies are cookies used specifically for gathering data on how visitors use a
                website, which pages of a website are visited most often, or if they get error messages on web
                pages. These cookies monitor only the performance of the site as the user interacts with it. These
                cookies don&#8217;t collect identifiable information on visitors, which means all the data collected is
                anonymous and only used to improve the functionality of a website. 
            </td>
            <td>Session</td>
            <td>None</td>
        </tr>
        <tr>
            <td style="border-bottom: 1px solid black;">Functionality cookies </td>
            <td style="border-bottom: 1px solid black;">
                These cookies allow a site to remember choices you make (such as
                your email, language or the region you are in) and provide more enhanced, personal features.
            </td>
            <td style="border-bottom: 1px solid black;">1 year</td>
            <td style="border-bottom: 1px solid black;">cookieconsent_status</td>
        </tr>
    </table>

    <h4>Consent and withdrawal</h4>
    <p>
        By clicking “Accept all cookies”, you agree with our cookies policy. You can revoke your consent at any times by
        deleting the saved cookie files from your browser. Yet, note that without all of these cookies you will not be
        able to use the functionality of the website in full.
    </p>

    <h4>Additional information about cookie files</h4>
    <p>
        Additional information about the use and management of cookie files is available at EDAA (European Interactive
        Digital Advertising Alliance) <a href="www.youronlinechoices.com">website</a>.
    </p>
</div>
<app-footer></app-footer>