import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent {
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() error: string | null = null;
  @Input() inputControl: FormControl | null = null;
  @Input() isRequired = false;
  @Input() autocomplete = 'off';
  @Input() name: string | null = null;
  @Input() highlightOnError = false;
  @Output() onChange: EventEmitter<void> = new EventEmitter<void>();

  public get isHighlighted(): boolean {
    return this.highlightOnError && this.inputControl!.invalid && this.inputControl!.touched;
  }
}
