import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketListComponent } from './components/ticket-list/ticket-list.component';
import { SupportDialogComponent } from './components/support-dialog/support-dialog.component';
import { FileUploadModule } from 'primeng/fileupload';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideMenuModule } from '../side-menu/side-menu.module';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { TicketDialogComponent } from './components/ticket-dialog/ticket-dialog.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { HttpClientModule } from '@angular/common/http';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ChatModule } from '../chat/chat.module';
import { DividerModule } from 'primeng/divider';
import {ProgressBarModule} from 'primeng/progressbar';

@NgModule({
  declarations: [
    TicketListComponent,
    SupportDialogComponent,
    TicketDialogComponent,
  ],
  imports: [
    CommonModule,
    FileUploadModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    SideMenuModule,
    InputTextareaModule,
    TableModule,
    DynamicDialogModule,
    HttpClientModule,
    DialogModule,
    ChatModule,
    DividerModule,
    ProgressBarModule,
  ],
  exports: [
    TicketListComponent,
    SupportDialogComponent
  ],
  providers: [
    DynamicDialogRef,
    DynamicDialogConfig
  ]
})
export class SupportSystemModule { }
