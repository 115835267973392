import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LambdasService } from 'src/app/lambads.service';
import { PaymentMethod } from '../../payment.component';
import { MenuItem, MessageService } from 'primeng/api';
import { User } from 'src/app/user.module';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { Cart } from 'src/app/models/payment/cart.models';
import { AuthService } from 'src/app/auth/auth.service';
import { FormValidatorsService } from 'src/app/form-validators.service';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Address } from 'src/app/models/address.models';
import { GetCountryByCode, GetCountryCode } from 'src/app/shared/service/countries';
import { TCountryCode } from 'countries-list';

@Component({
  selector: 'interviewer-payment',
  templateUrl: './interviewer-payment.component.html',
  styleUrls: ['../../styles/payment.scss'],
})
export class InterviewerPaymentComponent implements OnInit {
  @Input() assignedPlanId: string | undefined;
  @Input() candidateEmail: string | undefined;
  @Input() paymentReference: string | undefined;
  @Output() initiatePaymentEvent = new EventEmitter<{ companyName: string, vatNumber: string, paymentMethod: PaymentMethod, address: Address }>();

  public errorMessage: string | null = null;
  public paymentMethods: PaymentMethod[] = [];
  public selectedPaymentMethod: PaymentMethod | null = null;
  public confirm = false;
  public user: User = this.auth.user;

  public size = '500px';
  public companyDetailsFormGroup: FormGroup;
  public payerInformationFromGroup: FormGroup;
  public cart: Cart | null = null;

  public menuItem: MenuItem[] = [];

  private keyChange = new Subject<string>();

  constructor(
    private dynamodb: DynamoDBService,
    private lambdas: LambdasService,
    private messageService: MessageService,
    private auth: AuthService,
    private _validatorService: FormValidatorsService,
  ) {
    this.companyDetailsFormGroup = new FormGroup({
      phoneNumber: _validatorService.GetPhoneFormControl(),
      email: _validatorService.GetEmailFormControl(),
      companyName: _validatorService.GetRequiredFormControl(),
      vatNumber: _validatorService.GetEmptyFormControl(),
      country: _validatorService.GetRequiredFormControl(),
      state: _validatorService.GetEmptyFormControl(),
      city: _validatorService.GetRequiredFormControl(),
      street: _validatorService.GetRequiredFormControl(),
      postcode: _validatorService.GetRequiredFormControl(),
    });

    this.payerInformationFromGroup = new FormGroup({
      firstName: _validatorService.GetRequiredFormControl(),
      lastName: _validatorService.GetRequiredFormControl()
    });
  }

  public get isPayButtonActive(): boolean {
    return !!this.selectedPaymentMethod &&
      this.companyDetailsFormGroup.valid &&
      this.payerInformationFromGroup.valid &&
      this.confirm;
  }

  async ngOnInit(): Promise<void> {
    await this.fillFormGroups();
    await Promise.all([
      this.fetchPlanPaymentInfo(),
      this.fetchPaymentMethods()
    ]);

    this.createMenuForBreadcrumb();
    this.fetchPlanPaymentInfoOnKeyChange();
  }

  ngOnDestroy() {
    this.keyChange.complete();
  }

  public async fetchPlanPaymentInfo(): Promise<void> {
    try {
      const response = await this.lambdas.getPlanPrice(
        this.candidateEmail!,
        this.assignedPlanId!,
        GetCountryCode(this.companyDetailsFormGroup.value.country) ?? '',
        this.companyDetailsFormGroup.value.vatNumber
      ).toPromise();
      const country = GetCountryByCode(response.country_code);
      this.companyDetailsFormGroup.controls.country.setValue(country.name);
      this.cart = new Cart(response);
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: (err as Error).message
      });
    }
  }

  private async fillFormGroups(): Promise<void> {
    try {
      this.payerInformationFromGroup.controls.firstName.setValue(this.user.given_name);
      this.payerInformationFromGroup.controls.lastName.setValue(this.user.family_name);

      const companyData = await this.dynamodb.getCompanyData(this.user.companyId);
      const country = GetCountryByCode(companyData.countryCode as TCountryCode);
      this.companyDetailsFormGroup.controls.phoneNumber.setValue(companyData?.phoneNumber);
      this.companyDetailsFormGroup.controls.email.setValue(companyData?.email);
      this.companyDetailsFormGroup.controls.companyName.setValue(companyData?.name);
      this.companyDetailsFormGroup.controls.vatNumber.setValue(companyData?.vatNumber);
      this.companyDetailsFormGroup.controls.country.setValue(country.name);
      this.companyDetailsFormGroup.controls.state.setValue(companyData?.state);
      this.companyDetailsFormGroup.controls.city.setValue(companyData?.city);
      this.companyDetailsFormGroup.controls.street.setValue(companyData?.street);
      this.companyDetailsFormGroup.controls.postcode.setValue(companyData?.postCode);
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to get company data. Please restart the page or try again later.'
      });
    }
  }

  private async fetchPaymentMethods(): Promise<void> {
    try {
      this.paymentMethods = (await this.lambdas.getPaymentMethod(this.assignedPlanId!).toPromise()).payment_methods;
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: (err as Error).message
      });
    }
  }

  public onKeyChange(value: string): void {
    this.keyChange.next(value);
  }

  private fetchPlanPaymentInfoOnKeyChange(): void {
    this.keyChange.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(async () => await this.fetchPlanPaymentInfo());
  }

  private createMenuForBreadcrumb(): void {
    this.menuItem = [
      { label: 'Assigned Plans', routerLink: ['/assigned-plans'] },
      { label: `Plan "${this.cart?.planName ?? ''}" Assigned to ${this.candidateEmail}`, routerLink: ['/assigned-plans'], queryParams: { id: this.assignedPlanId } },
      { label: 'Payment' }
    ];
  }

  OnPayClick() {
    const countryCode = GetCountryCode(this.companyDetailsFormGroup.value.country);
    this.initiatePaymentEvent.emit({
      paymentMethod: this.selectedPaymentMethod!,
      companyName: this.companyDetailsFormGroup.controls.companyName.value,
      vatNumber: this.companyDetailsFormGroup.controls.vatNumber.value,
      address: new Address({
        country_code: countryCode,
        state: this.companyDetailsFormGroup.value.state,
        city: this.companyDetailsFormGroup.value.city,
        postcode: this.companyDetailsFormGroup.value.postcode,
        street: this.companyDetailsFormGroup.value.street
      })
    });
  }
}
