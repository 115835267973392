<div class="wrapper">
    <p-virtualScroller
        *ngIf="notifications.length > 0; else empty"
        [value]="notifications"
        [itemSize]="60"
        class="full-height-scroller">

        <ng-template pTemplate="item" let-notification>
            <a class="notification" 
              href="{{notification.redirectLink}}">
                <p-avatar 
                  [image]="notification.pictureLink"
                  shape="circle">
                </p-avatar>
                <div class="text-container">
                    <span [innerHTML]="notification.message | markdown"
                        class="two-line">
                    </span>
                    <div>{{ notification.timeAgo }}</div>
                </div>
            </a>
        </ng-template>

        <ng-template let-product pTemplate="loadingItem">
            <div class="notification" >
                <p-skeleton shape="circle" width="32px" height="32px" class="mr-10"></p-skeleton>
                <div class="text-container">
                    <div class="mb-4"><p-skeleton width="90%" height="12px"></p-skeleton></div>
                    <p-skeleton width="30%" height="12px"></p-skeleton>
                </div>
            </div>
        </ng-template>
    </p-virtualScroller>

    <a class="more-notifications" 
      (click)="notificationData.fetchMore()" 
      [hidden]="notificationData.isLast">
       More Notifications
    </a>
</div>

<ng-template #empty>
    <div class="empty-notifications">
        <span>
            Currently, there are no notifications for you. We'll keep you posted when there's 
            something new!
        </span>
    </div>  
</ng-template>