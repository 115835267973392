import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './side-menu.component';
import { LargeScreenComponent } from './layouts/large-screen/large-screen.component';
import { MediumScreenComponent } from './layouts/medium-screen/medium-screen.component';
import { SmallScreenComponent } from './layouts/small-screen/small-screen.component';
import { SidebarModule } from 'primeng/sidebar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DividerModule } from 'primeng/divider';
import { AvatarModule } from 'primeng/avatar';

@NgModule({
  declarations: [
    SideMenuComponent,
    LargeScreenComponent,
    MediumScreenComponent,
    SmallScreenComponent,
  ],
  imports: [
    CommonModule,
    SidebarModule,
    InputSwitchModule,
    ConfirmDialogModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DividerModule,
    AvatarModule
  ],
  exports: [SideMenuComponent],
})
export class SideMenuModule {}
