import { Attachment } from './attachments.model';
import { S3Service } from '../s3.service';
import { ServiceLocator } from '../service-locator.service';
import { DeviceInfo } from './device-info.model';
import { Conversation } from './chat-model/conversation.model';
import moment from 'moment-mini';

export class Ticket {
  constructor(attributes: TicketAttribute) {
    this._email = attributes.email;
    this._id = attributes.id;
    this._sortKey = attributes.sortKey;
    this._date = attributes.date;
    this._subject = attributes.subject;
    this._description = attributes.description;
    this._url = attributes.url;

    if (attributes.conversationJSON) {
      this._conversation = new Conversation(attributes.conversationJSON);
    }

    if (attributes.deviceInfoJSON) {
      this._deviceInfo = new DeviceInfo(attributes.deviceInfoJSON);
    }

    this._s3Service = ServiceLocator.injector.get(S3Service);
  }

  private _email: string;
  private _id: string;
  private _sortKey: string | undefined;
  private _date: string | undefined;
  private _subject: string | undefined;
  private _description: string | undefined;
  private _url: string | undefined;

  private _userFriendlyDate: string | undefined;
  private _attachments: Promise<Attachment[]> | undefined;
  private _deviceInfo: DeviceInfo | undefined;
  private _conversation: Conversation | undefined;
  private _hasAttachments = true;
  private _isAttachmentsLoading = false;

  private _s3Service: S3Service;

  get email(): string {
    return this._email;
  }

  get id(): string {
    return this._id;
  }

  get date(): string | undefined {
    return this._date;
  }

  get subject(): string | undefined {
    return this._subject;
  }

  get description(): string | undefined {
    return this._description;
  }

  get url(): string | undefined {
    return this._url;
  }

  get conversation(): Conversation | undefined {
    return this._conversation;
  }

  get deviceInfo(): DeviceInfo | undefined {
    return this._deviceInfo;
  }

  get hasAttachments(): boolean {
    return this._hasAttachments;
  }

  get isAttachmentsLoading(): boolean {
    return this._isAttachmentsLoading;
  }

  get sortKey(): string | undefined {
    if (!this._sortKey) {
      this._sortKey = `${this._date}#${this._id}`;
    }
    return this._sortKey;
  }

  get userFriendlyDate(): string | undefined {
    if (!this._userFriendlyDate && this._date) {
      this._userFriendlyDate = moment(this._date).format('MMMM D, YYYY HH:mm');
    }
    return this._userFriendlyDate;
  }

  get attachments(): Promise<Attachment[]> | undefined {
    if (!this._attachments && this._id) {
      this._isAttachmentsLoading = true;
      this._attachments = this._s3Service.getAttachments(this._email, this._id).then(attachments => {
        this._hasAttachments = attachments.length > 0;
        this._isAttachmentsLoading = false;
        return attachments;
      });
    }
    return this._attachments;
  }

  public async addNewAttachment(files: File[]): Promise<void> {
    for (const file of files) {
      const attachment = await this._s3Service.saveTicketAttachment(
        this._email,
        this._id,
        file
      );

      if (this._attachments)
        (await this._attachments).push(attachment);
    }
  }
}


interface TicketAttribute {
  email: string;
  id: string;
  sortKey?: string;
  date: string | undefined;
  subject: string | undefined;
  description: string | undefined;
  url?: string;
  deviceInfoJSON?: string;
  conversationJSON?: string[];
}
