<app-header></app-header>

<div class="wrapper">
    <div class="login-container" *ngIf="!isPasswordReset">
        <div class="logo-container">
            <img class="logo-img" src="../../assets/img/template/logo.svg">
            <h1>Dev Skill</h1>
        </div>

        <form class="forms">
            <div class="input-form">
                <span class="form-title">Email</span>
                <app-input-field 
                  [inputControl]="loginFormGroup | formControl:'email'"
                  type="email"
                  name="email"
                  autocomplete="email"
                  [isRequired]="true">
                </app-input-field>
            </div>

            <div class="input-form">
                <span class="form-title">Password</span>
                <app-input-field 
                  [inputControl]="loginFormGroup | formControl:'password'"
                  type="password"
                  name="password"
                  autocomplete="password"
                  [isRequired]="true"
                  [error]="errorMessage"
                  (keyup.enter)="login()">
                </app-input-field>
            </div>
        </form>

        <div *ngIf="!isLoading">
            <button class="signin-button" id="signin-button" (click)="login()">Sign In</button>
            <div class="password df">
                <a (click)="forgotPassword()">Forgot Password?</a>
            </div>
            <div class="external-providers">
                <h2><span>Or</span></h2>
                <button class="google-btn" (click)="googleLogin()">
                    <img src="../../assets/img/google/google_logo.svg" alt="Google Logo" class="google-logo">
                    <span>Sign in with Google</span>
                </button>
                <button class="linkedin-btn" (click)="linkedInLogin()">
                    <img src="../../assets/img/linkedIn/linkedin_logo.svg" alt="LinkedIn Logo" class="linkedin-logo">
                    <span>Sign in with LinkedIn</span>
                </button>
                <a align="center" routerLink="/registration" [queryParams]="queryParams">
                    Don’t have an account? Sign Up
                </a>
            </div>
        </div>

        <app-loading-animation *ngIf="isLoading"></app-loading-animation>
    </div>

    <div class="password-reset-container" *ngIf="isPasswordReset && !isPasswordChange">
        <div class="password-reset-info mb-3">
            <h1 class="mb-3">
                Password Reset
            </h1>
            <span>
                Need to reset your password? Please enter your email in the field below. We will send a code to
                your registered email address, which you can use to create a new password.
            </span>
        </div>

        <form class="password-reset-form" [formGroup]="loginFormGroup">
            <span>Email</span>
            <app-input-field
                class="block mb-3"
                [inputControl]="loginFormGroup | formControl:'email'"
                type="email"
                name="email"
                autocomplete="email"
                [isRequired]="true"
                [error]="errorMessage">
            </app-input-field>
            <button class="signin-button" (click)="sendResetCode()" *ngIf="!isLoading">Send Code</button>
            <app-loading-animation *ngIf="isLoading"></app-loading-animation>
        </form>
    </div>

    <div class="password-change-container" *ngIf="isPasswordChange">
        <div class="password-reset-info">
            <h1 class="mb-3">
                Password Reset
            </h1>
        </div>
        <form class="forms">
            <div class="input-form mb-3">
                <span class="form-title">Code</span>
                <app-input-field 
                  [inputControl]="resetPasswordFormGroup | formControl:'code'"
                  type="text"
                  autocomplete="code"
                  name="code"
                  [isRequired]="true">
                </app-input-field>
            </div>

            <div class="input-form mb-3">
                <span class="form-title">Password</span>
                <app-input-field 
                  [inputControl]="resetPasswordFormGroup | formControl:'password'"
                  type="password"
                  name="password"
                  autocomplete="new-password"
                  [isRequired]="true"
                  [error]="passwordErrorHandler(resetPasswordFormGroup.controls.password.errors)">
                </app-input-field>
            </div>

            <div class="input-form mb-3">
                <span class="form-title">Password Confirmation</span>
                <app-input-field 
                  [inputControl]="resetPasswordFormGroup | formControl:'passwordConfirmation'"
                  type="password"
                  name="password"
                  autocomplete="new-password"
                  [isRequired]="true"
                  (keyup.enter)="login()"
                  [error]="errorMessage || resetPasswordFormGroup.controls.passwordConfirmation.errors?.passwordMismatch ? 'Passwords do not match' : '' ">
                </app-input-field>
            </div>
        </form>

        <button *ngIf="!isLoading" class="signin-button" (click)="resetPassword()">
            Change Password
        </button>
        <app-loading-animation *ngIf="isLoading"></app-loading-animation>
    </div>
</div>