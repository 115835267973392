import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from './notification-service/notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notification-widget',
  templateUrl: './notification-widget.component.html',
  styleUrls: ['./notification-widget.component.scss']
})
export class NotificationWidgetComponent implements OnInit, OnDestroy {
  private NotificationsSubscription: Subscription = new Subscription();
  public notifications: Notification[] = Array.from({ length: 5 });

  constructor(
    public notificationData: NotificationService
  ) { }

  ngOnInit(): void {
    this.subToNotificationData();
    this.notificationData.fetch();
  }

  ngOnDestroy(): void {
    this.NotificationsSubscription.unsubscribe();
  }

  private subToNotificationData():void {
    this.NotificationsSubscription.add(this.notificationData.data$.subscribe(notifications => {
      if (notifications !== null) {
        this.notifications = notifications;
      }
    }));
  }
}

export interface Notification {
  date: string;
  pictureLink: string;
  message: string;
  redirectLink: string;

  id?: string;
  timeAgo?: string;
}
