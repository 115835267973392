<div class="wrapper">
    <div class="description-container"
        *ngIf="exercise">
        <i class="material-icons-outlined cursor-pointer" 
          (click)="closeDescription()">
          close
        </i>
        <h4>Exercise</h4>
        <div class="section">
            <div class="title">
                <span>Name</span>
            </div>
            <div class="text">{{exercise.userFriendlyName}}</div>
        </div>
        <div class="section">
            <div class="title">
                <span>Task Overview</span>
            </div>
            <div [innerHTML]="exercise.description | markdown"></div>
        </div>
        <div class="section">
            <div class="title">
                <span>Tags</span>
                <app-help [helpMessage]="helpMessageEnum.Tag"></app-help>
            </div>
            <div class="text">{{exercise.tags.join(', ')}}</div>
        </div>
        <div class="section">
            <div class="title">
                <span>Difficulty</span>
                <app-help [helpMessage]="helpMessageEnum.Difficulty"></app-help>
            </div>
            <div class="text">{{exercise.difficulty}}</div>
        </div>
        <div class="section">
            <div class="title">
                <span>Execution Time</span>
                <app-help [helpMessage]="helpMessageEnum.ExecutionTime"></app-help>
            </div>
            <div class="text">{{exercise.executionTime | humanaizeMinutes}}</div>
        </div>
    </div>
</div>