<section class="wrapper" #ticketDialog>
    <div class="ticket-details">
        <div class="p-field">
            <label for="date">Submitted</label>
            <div>{{ ticket.userFriendlyDate }}</div>
        </div>
    
        <div class="p-field">
            <label for="date">URL</label>
            <div>{{ ticket.url }}</div>
        </div>
    
        <div class="p-field">
            <label for="id">Operating System</label>
            <div>{{ ticket.deviceInfo?.os }}</div>
        </div>
    
        <div class="p-field">
            <label for="id">Browser</label>
            <div>{{ ticket.deviceInfo?.browser }}</div>
        </div>
    
        <div class="p-field">
            <label for="id">Window Size</label>
            <div>{{ ticket.deviceInfo?.resolution }}</div>
        </div>
    
        <div class="p-field">
            <label for="id">Ticket ID</label>
            <div>{{ ticket.id }}</div>
        </div>
    
        <div class="p-field">
            <label for="subject">Subject</label>
            <div>{{ ticket.subject }}</div>
        </div>
    
        <div class="p-field" *ngIf="attachmentsWork && ticket.hasAttachments">
            <label for="attachments">Attachments</label>
            <p-progressBar 
              mode="indeterminate" 
              [style]="{'height': '6px'}"
              *ngIf="this.ticket.isAttachmentsLoading">
            </p-progressBar>
            <div *ngFor="let attachment of ticket.attachments | async">
                <a *ngIf="attachment.url && attachment.name"
                    (click)="downloadFile($event, attachment)">
                    {{attachment.name}}
                </a>
            </div>
        </div>
    </div>
    
    <p-divider layout="vertical"></p-divider>

    <chat-conversation *ngIf="chatHeight"
      [height]="chatHeight"
      [conversation]="ticket.conversation"
      [ticket]="ticket">
    </chat-conversation>
</section>