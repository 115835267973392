import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExerciseDataService } from '../../exercise-data-service/exercise-data.service';

@Component({
  selector: 'app-exercise-list-header',
  templateUrl: './exercise-list-header.component.html',
  styleUrls: ['./exercise-list-header.component.css']
})
export class ExerciseListHeaderComponent {
  @Input() hasBackButton = false;
  @Input() selectedExerciseCount = 0;
  @Output() closeExerciseManager = new EventEmitter<boolean>();

  constructor(
    public exerciseData: ExerciseDataService
  ) { }
}
