import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DashboardPanelData } from '../recently-assigned-plan.component';
import { AuthService } from 'src/app/auth/auth.service';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { MessageService } from 'primeng/api';
import { User } from 'src/app/user.module';
import { RemoveDuplicates } from '../../dashboard/util';
import { TaskStatus } from 'src/app/status-parser';
import { AssignedPlan } from 'src/app/models/assigned-plan.model';
import { UserPublicProfile } from 'src/app/models/user-public-profile.model';
import { ExerciseStatus } from 'src/app/models/exercise-status.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardPanelsDataServiceService {
  private user: User;
  private dataSubject: BehaviorSubject<DashboardPanelData[] | null> = new BehaviorSubject<DashboardPanelData[] | null>(null);
  public data$: Observable<DashboardPanelData[] | null> = this.dataSubject.asObservable();
  public totalDataCount = 0;

  constructor(
    private auth: AuthService,
    private dynamodb: DynamoDBService,
    private messageService: MessageService
  ) {
    this.user = auth.user;
  }

  public async fetch(): Promise<void> {
    try {
      this.user.isInterviewer
        ? await this.fetchInterviewerData()
        : await this.fetchUserData();
    } catch {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to fetch some of the data. Please try again later.' });
    }
  }

  private async fetchInterviewerData(): Promise<void> {
    this.totalDataCount = await this.dynamodb.getAssignedPlansCount(this.user.companyId);
    if (this.totalDataCount === 0) {
      this.dataSubject.next([]);
      return;
    }

    const assignedPlans = await this.dynamodb.getAssignedPlansByDate(this.user.companyId, 6);
    const users = await this.fetchUsers(assignedPlans);
    const data = this.convertToInterviewerPanelData(assignedPlans, users);
    this.dataSubject.next(data);
  }

  private async fetchUsers(plans: AssignedPlan[]): Promise<UserPublicProfile[]> {
    return await this.dynamodb.getUsersAndCompanyMembers(this.user.companyId, this.getUserEmails(plans));
  }

  private getUserEmails(plans: AssignedPlan[]): string[] {
    return RemoveDuplicates(plans.map(p => p.candidate.email));
  }

  private convertToInterviewerPanelData(plans: AssignedPlan[], users: UserPublicProfile[]): DashboardPanelData[] {
    return plans.map(plan => {
      const user = this.findUser(plan.candidate.email, users) ?? plan.candidate;
      if (!user) return;

      return {
        id: plan.id,
        header: plan.planName,
        progressPercentage: this.calculateProgressPercentage(plan.exerciseStatusesArray ?? []),
        status: plan.status,
        name: user.fullName || plan.candidate.fullNameOrEmail,
        picture: user.picture,
        redirectLink: '/assigned-plans'
      };
    }).filter(Boolean) as DashboardPanelData[];
  }

  private findUser(emailToFind: string, users: UserPublicProfile[]): UserPublicProfile | undefined {
    return users.find(user => user.email === emailToFind);
  }

  private calculateProgressPercentage(statuses: string[]): number {
    const totalTaskCount = statuses.length;
    const finishedExercises = statuses.filter(status => status != TaskStatus.RUNNING && status != TaskStatus.NOT_STARTED);
    return Math.floor((finishedExercises.length / totalTaskCount) * 100);
  }

  private async fetchUserData(): Promise<void> {
    this.totalDataCount = await this.dynamodb.getUserExerciseStatusCount(this.user.email, TaskStatus.NOT_STARTED, TaskStatus.RUNNING);
    if (this.totalDataCount === 0) {
      this.dataSubject.next([]);
      return;
    }

    const exercises = await this.dynamodb.getUserExerciseByStatus(this.user.email, 6, TaskStatus.NOT_STARTED, TaskStatus.RUNNING);
    const data = this.convertToUserPanelData(exercises);
    this.dataSubject.next(data);
  }

  private convertToUserPanelData(exercises: ExerciseStatus[]): DashboardPanelData[] {
    return exercises.map(exercise => ({
      id: exercise.assignedPlanID,
      header: exercise.planName ?? '',
      status: exercise.status ?? '',
      name: exercise.exerciseName ?? '',
      exerciseId: exercise.exerciseID,
      buttonRedirectLink: `/exercise/${exercise.urlID}/${exercise.exerciseID}/${exercise.assignedPlanID}`,
      picture: exercise.picture,
      redirectLink: '/plans'
    }));
  }
}
