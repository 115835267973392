import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaymentMethod } from '../../payment.component';

@Component({
  selector: 'payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent {
  @Input() paymentMethods: PaymentMethod[] = [];

  @Input() selectedPaymentMethod: PaymentMethod | null = null;
  @Output() selectedPaymentMethodChange = new EventEmitter<PaymentMethod>();
}
