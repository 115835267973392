import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GetQueryParam } from 'src/app/shared/service/route';
import { Subscription } from 'rxjs';
import { Ticket } from 'src/app/models/ticket.model';
import { DynamoDBService } from '../../../dynamodb.service';
import { MessageService } from 'primeng/api';
import { TicketDialogComponent } from '../../../support/components/ticket-dialog/ticket-dialog.component';

@Component({
  selector: 'app-admin-ticket-list',
  templateUrl: '../../../support/components/ticket-list/ticket-list.component.html',
  styleUrls: ['../../../support/components/ticket-list/ticket-list.component.scss']
})
export class AdminTicketListComponent implements OnInit, OnDestroy {
  private readonly ticketLimit: number = 50;
  private ref: DynamicDialogRef | undefined;
  private closeSubscription: Subscription | undefined;
  private currentPage = 0;
  public title = 'Tickets';
  public tickets: Ticket[] = [];
  public ticketCount = 1;
  public lastEvaluatedKey: string | undefined;
  public lastEvaluatedEmail: string | undefined;
  public isLoading = true;
  public cachedTickets: Map<number, Ticket[]> = new Map<number, Ticket[]>();

  constructor(
    private dynamodb: DynamoDBService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.handleParams();
  }

  public ngOnDestroy(): void {
    this.ref?.close();
    this.closeSubscription?.unsubscribe();
  }

  private get windowWidth(): number {
    return window.innerWidth;
  }

  public get nextPage(): number {
    return this.currentPage + 1;
  }

  public async fetchTickets(selectedPage: number): Promise<void> {
    this.isLoading = true;
    if (this.cachedTickets.has(selectedPage)) {
      this.tickets = this.cachedTickets.get(selectedPage) ?? [];
      this.currentPage = selectedPage;
      this.isLoading = false;
      return;
    }

    try {
      if (selectedPage > this.currentPage) {
        this.currentPage = this.nextPage;
      }

      const lazyLoading = await this.dynamodb.getAllTickets(this.ticketLimit, this.lastEvaluatedKey, this.lastEvaluatedEmail);
      this.lastEvaluatedKey = lazyLoading.lastEvaluatedKey;
      this.lastEvaluatedEmail = lazyLoading.lastEvaluatedEmail;

      for (let batch = 0, page = this.currentPage; batch < lazyLoading.tickets.length / 10; batch++, page++) {
        const ticketsForPage = lazyLoading.tickets.slice(batch * 10, (batch + 1) * 10);
        this.cachedTickets.set(page, ticketsForPage);
      }

      this.tickets = this.cachedTickets.get(selectedPage) ?? [];
      if (!lazyLoading.lastEvaluatedEmail && !lazyLoading.lastEvaluatedKey) {
        this.ticketCount += lazyLoading.tickets.length - 1;
        return;
      }

      this.ticketCount += this.ticketLimit;
      this.currentPage = selectedPage;
    } catch {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to load your tickets.',
      });
    } finally {
      this.isLoading = false;
    }
  }

  public async openTicket(ticket: Ticket): Promise<void> {
    let openedTicket: Ticket;

    try {
      openedTicket = await this.dynamodb.getTicketData(ticket.email, ticket.sortKey!);
    } catch {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to open ticket. Please try again later!',
      });
      return;
    }

    this.ref = this.dialogService.open(TicketDialogComponent, {
      data: {
        openedTicket
      },
      header: 'Ticket',
      dismissableMask: true,
      baseZIndex: 10000,
      width: this.windowWidth <= 525 ? '100%' : '70%',
      height: this.windowWidth <= 525 ? '100%' : '',
    });

    const base64TicketId = btoa(ticket.sortKey ?? '');
    const base64Email = btoa(ticket.email);
    this.router.navigate(['/admin'], {
      queryParams: { id: base64TicketId, email: base64Email },
    });

    this.closeSubscription = this.ref.onClose.subscribe(() => {
      this.router.navigate(['/admin']);
      this.closeSubscription?.unsubscribe();
    });
  }

  private async handleParams(): Promise<void> {
    const base64TicketId = GetQueryParam(this.activatedRoute, 'id');
    const base64Email = GetQueryParam(this.activatedRoute, 'email');
    if (!base64TicketId || !base64Email)
      return;

    try {
      const ticket = await this.dynamodb.getTicketData(
        atob(base64Email),
        atob(base64TicketId)
      );
      this.openTicket(ticket);
    } catch {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Ticket not found',
      });
    }
  }
}
