<div 
  *ngIf="cart"
  class="w-100 p-2 pr-3 flex flex-column gap-4 border-gray-300 lg:p-4 lg:border-1 lg:gap-0"
  style="height: fit-content;">
    <h1 class="m-0 font-bold text-5xl text-gray-800 block lg:hidden">
       Costs
    </h1>
    <p-virtualScroller 
    class="h-100 w-100"
    [value]="cart!.exercises" 
    [scrollHeight]="scrollHeight"
    [itemSize]="defaultItemHeight">
        <ng-template pTemplate="item" let-item>
            <div class="w-100 flex flex-row gap-2">
                <img
                class="border-round-sm"
                [src]="item.imagePath"
                width="50px"
                height="50px">

                <div class="w-100 flex justify-content-between gap-2 pr-3">
                    <div class="w-100">
                        <div class="mb-1 font-medium">{{item.name}}</div>
                        <div class="font-medium text-500 text-sm">{{item.executionTime}}</div>
                    </div>

                    <div 
                    class="font-medium"
                    style="min-width: fit-content;">
                        {{item.price | number: '1.2-2'}} €
                    </div>    
                </div>
            </div>
        </ng-template>
    </p-virtualScroller>

    <div class="h-100 w-100">
        <p-divider class="block lg:block sm:hidden"></p-divider>

        <div class="flex flex-column gap-2">
            <div class="w-100 flex justify-content-between">
                <div>Subtotal</div>
                <div>{{cart.subtotal}} €</div>
            </div>

            <div class="w-100 flex justify-content-between">
                <div>VAT</div>
                <div>{{cart.isVATApplicable ? (cart.tax + " €") : "reverse charge"}}</div>
            </div>

            <div class="w-100 flex justify-content-between">
                <div>Total</div>
                <div>{{cart.total}} €</div>
            </div>
        </div>

        <p-divider></p-divider>

        <div class="flex justify-content-center w-100 mb-3 gap-3">
            <checkbox
                class="cursor-pointer"
                [checked]="isPolicyConfirmed" 
                [binary]="true"
                (checkedChange)="isPolicyConfirmedChange.emit(!isPolicyConfirmed)"
                inputId="checkbox">
            </checkbox>
            <label class="font-medium text-center" for="checkbox">
                <span>I understand and agree with all the </span>
                <a target="_blank" 
                    href="/terms#p5"
                    class="no-underline text-blue-600">
                    Terms and Conditions.
                </a>
            </label>
        </div>
        <div class="flex align-items-center flex-column">
            <simple-button 
                [disabled]="!isPayButtonActive"
                (click)="OnPayClick.emit()">
                Pay now
            </simple-button>
        </div>
    </div>  
</div>