import { PaymentState } from 'src/app/payment/payment.component';
import { PaymentReference } from './payment-reference.models';

export class PaymentInformation {
  private _assignedPlanID: string;
  private _userEmail: string;
  private _paymentNotNeeded: boolean;
  private _interviewerFullName: string | undefined;
  private _planName: string | undefined;
  private _paymentReferences: PaymentReference[] = [];
  private _pricePerSecond: number | undefined;
  private _lastPaymentDate: string | undefined;
  private _amount: number | undefined;
  private _status: PaymentState | undefined;

  constructor(attributes: Attributes) {
    this._assignedPlanID = attributes.assignedPlanId;
    this._userEmail = attributes.userEmail;
    this._paymentNotNeeded = attributes.paymentNotNeeded ?? false;
    this._interviewerFullName = attributes.interviewerFullName;
    this._planName = attributes.planName;
    this._pricePerSecond = attributes.pricePerSecond;
    this._lastPaymentDate = attributes.lastPaymentDate;
    this._amount = attributes.amount;

    for (const [id, reference] of attributes.paymentReferences ?? []) {
      this._paymentReferences.push(
        new PaymentReference(reference, id)
      );
    }
  }

  public get assignedPlanID(): string {
    return this._assignedPlanID;
  }

  public get userEmail(): string {
    return this._userEmail;
  }

  public get paymentNotNeeded(): boolean {
    return this._paymentNotNeeded;
  }

  public get interviewerFullName(): string | undefined {
    return this._interviewerFullName;
  }

  public get planName(): string | undefined {
    return this._planName;
  }

  public get paymentReferences(): PaymentReference[] {
    return this._paymentReferences;
  }

  public get pricePerSecond(): number | undefined {
    return this._pricePerSecond;
  }

  public get lastPaymentDate(): string | undefined {
    return this._lastPaymentDate;
  }

  public get amount(): number | undefined {
    return this._amount;
  }

  public get status(): PaymentState {
    if (this._status) {
      return this._status;
    }

    if (this._paymentNotNeeded) {
      return (this._status = PaymentState.Done);
    }

    if (!this._paymentReferences) {
      return (this._status = PaymentState.Unknown);
    }

    const statuses = this._paymentReferences.map(ref => ref.status);

    if (statuses.includes(PaymentState.Settled)) 
      return (this._status = PaymentState.Done);

    if (statuses.includes(PaymentState.Initial))
      return (this._status = PaymentState.Initial);

    if (statuses.includes(PaymentState.WaitingForSCA) ||
      statuses.includes(PaymentState.SentForProcessing) ||
      statuses.includes(PaymentState.ChargedBack)) {
      return (this._status = PaymentState.Pending);
    }

    if (statuses.includes(PaymentState.Refunded))
      return (this._status = PaymentState.Refunded);

    if (statuses.includes(PaymentState.Failed)) 
      return (this._status = PaymentState.Failed);

    return (this._status = PaymentState.Unknown);
  }

  public get isPaymentDone(): boolean  {
    return this.status === PaymentState.Done;
  }

  public getPaymentReference(status: PaymentState): PaymentReference | null {
    for (const reference of this._paymentReferences) {
      if (reference.status == status)
        return reference;
    }
    return null;
  }
}

interface Attributes {
  assignedPlanId: string
  userEmail: string
  interviewerFullName?: string
  planName?: string
  paymentReferences?: Map<string, Map<string, string>>;
  paymentNotNeeded?: boolean;
  pricePerSecond?: number;
  lastPaymentDate?: string;
  amount?: number
}