export class DeviceInfo {
  private info: IDeviceInfo | undefined;

  constructor(jsonData: string) {
    this.info = JSON.parse(jsonData);
  }

  get resolution(): string | undefined {
    return this.info?.resolution;
  }

  get userAgent(): string | undefined {
    return this.info?.userAgent;
  }

  get browser(): string | undefined {
    return this.info?.browser;
  }

  get os(): string | undefined {
    return this.info?.os;
  }
}

export interface IDeviceInfo {
    resolution: string;
    userAgent: string;
    browser: string;
    os: string;
}