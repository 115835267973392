import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { Notification } from '../notification-widget.component';
import { User } from 'src/app/user.module';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import moment from 'moment-mini';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly NOTIFICATION_REQUEST_LIMIT: number = 5;
  private userNotificationLastDate: string | undefined = '';
  private companyNotificationLastDate: string | undefined = '';
  private user: User;
  private dataSubject: BehaviorSubject<Notification[] | null> = new BehaviorSubject<Notification[] | null>(null);
  public data$: Observable<Notification[] | null> = this.dataSubject.asObservable();


  constructor(
    private auth: AuthService,
    private dynamodb: DynamoDBService,
    private messageService: MessageService
  ) {
    this.user = this.auth.user;
  }

  get data(): Notification[] {
    return this.dataSubject.getValue() ?? [];
  }

  get isLast(): boolean {
    return !this.userNotificationLastDate && !this.companyNotificationLastDate;
  }

  public async fetchMore(): Promise<void> {
    await this.fetch();
  }

  public async fetch(): Promise<void> {
    let notifications: Notification[] = [];
    try {
      notifications = await this.fetchNotifications();
      if (notifications.length < 0) return;
      this.prepareNotification(notifications);
    } catch (err) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to fetch notifications. Please try again later.' });
    } finally {
      this.dataSubject.next([...this.data, ...notifications]);
    }
  }

  private async fetchNotifications(): Promise<Notification[]> {
    let companyNotifications: Notification[] = [];
    let userNotifications: Notification[] = [];

    if (this.user.companyId) {
      [companyNotifications, userNotifications] = await Promise.all([
        this.getNotifications('company'),
        this.getNotifications('user')
      ]);
    } else {
      userNotifications = await this.getNotifications('user');
    }
    return this.mergeNotifications(companyNotifications, userNotifications);
  }

  private async getNotifications(type: 'company' | 'user'): Promise<Notification[]> {
    const lastDate = type === 'company' ? this.companyNotificationLastDate : this.userNotificationLastDate;
    if (!this.hasValidDate(lastDate)) return [];

    const notifications = type === 'company'
      ? await this.dynamodb.getCompanyNotifications(this.user.companyId, this.NOTIFICATION_REQUEST_LIMIT, lastDate)
      : await this.dynamodb.getUserNotifications(this.user.email, this.NOTIFICATION_REQUEST_LIMIT, lastDate);

    if (notifications.length === 0) {
      if (type === 'company') {
        this.companyNotificationLastDate = undefined;
      } else {
        this.userNotificationLastDate = undefined;
      }
    }
    return notifications;
  }

  private mergeNotifications(companyNotifications: Notification[], userNotifications: Notification[]): Notification[] {
    const merged = [...companyNotifications, ...userNotifications]
      .sort((a, b) => b.date.localeCompare(a.date))
      .slice(0, this.NOTIFICATION_REQUEST_LIMIT);

    if (companyNotifications.length < this.NOTIFICATION_REQUEST_LIMIT) {
      if (this.areAllNotificationsMerged(merged, companyNotifications)) {
        this.companyNotificationLastDate = undefined;
      }
    }

    if (userNotifications.length < this.NOTIFICATION_REQUEST_LIMIT) {
      if (this.areAllNotificationsMerged(merged, userNotifications)) {
        this.userNotificationLastDate = undefined;
      }
    }

    const lastNotificationDate = merged[merged.length - 1]?.date;
    this.updateLastDate(lastNotificationDate);
    return merged;
  }

  private areAllNotificationsMerged(merged: Notification[], fetchedNotifications: Notification[]): boolean {
    return fetchedNotifications.every(notification => 
      merged.some(mergedNotif => mergedNotif.id === notification.id));
  }

  private updateLastDate(lastNotificationDate: string | undefined): void {
    if (!lastNotificationDate) {
      return;
    }

    if (this.hasValidDate(this.companyNotificationLastDate)) {
      this.companyNotificationLastDate = lastNotificationDate;
    }

    if (this.hasValidDate(this.userNotificationLastDate)) {
      this.userNotificationLastDate = lastNotificationDate;
    }
  }

  private prepareNotification(notifications: Notification[]): void {
    notifications.forEach(n => n.timeAgo = this.howLongAgo(n.date));
  }

  private hasValidDate(date: string | undefined): boolean {
    return date !== undefined;
  }

  private howLongAgo(date: string): string {
    const cleanDate = date.includes('#') ? date.split('#')[0] : date;
    return moment(cleanDate).fromNow();
  }
}