import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/user.module';
import { MenuDataService } from '../../menu-data.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SupportDialogComponent } from 'src/app/support/components/support-dialog/support-dialog.component';

@Component({
  selector: 'app-small-screen',
  templateUrl: './small-screen.component.html',
  styleUrls: ['./small-screen.component.scss', '../../styles/side-menu.scss']
})
export class SmallScreenComponent implements OnInit {
  public user!: User;
  public isSidebarOpen = false;
  public showHelp = false;

  constructor(
    public menuData: MenuDataService,
    private auth: AuthService,
    private dialogService: DialogService,
    public ref: DynamicDialogRef,  
  ) { }

  ngOnInit() {
    this.user = this.auth.user;
  }

  public async signOut(): Promise<void> {
    await this.auth.signOut();
    this.toggleNavbar();
  }

  public toggleNavbar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  openDialog(){
    this.showHelp = !this.showHelp;
  }

  openSubmit() {
    this.dialogService.open(SupportDialogComponent, {
      width: '50%',
      baseZIndex: 10000,
      showHeader: false,
      dismissableMask: true,
      
    });
    this.ref.close();
  }
}