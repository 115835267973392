<input
  *ngIf="inputControl"
  pInputText
  autocomplete='{{autocomplete}}'
  [ngClass]="{'ng-invalid ng-dirty': isHighlighted}"
  [formControl]="inputControl"
  [placeholder]="placeholder"
  [type]="type"
  [required]="isRequired"
  [name]="name"
  (input)="onChange.emit()">
<small 
  class="p-error m-fadeOut"
  [class.m-fadeIn]="error && inputControl?.touched">
    {{error}}
</small>