import { Component, EventEmitter, Output } from '@angular/core';
import { PlanDataService } from '../../plan-data-service/plan-data.service';

@Component({
  selector: 'app-delete-plan',
  templateUrl: './delete-plan.component.html',
  styleUrls: ['./delete-plan.component.scss']
})
export class DeletePlanComponent {
  @Output() deletedPlanTrigger = new EventEmitter<null>();
  public isDeleteButtonClicked = false;

  constructor(
    public planData: PlanDataService
  ) { }

  public delete(): void {
    this.deletedPlanTrigger.emit();
    this.planData.delete();
  }
}
