import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Message } from 'src/app/models/chat-model/message.model';

@Component({
  selector: 'chat-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  @Input() message: Message | undefined;
  @Output() downloadName: EventEmitter<string> = new EventEmitter;

  @HostBinding('class.right')
  public get isMineMessage(): boolean {
    return this.message?.isMine ?? false;
  }

  @HostBinding('class.linked')
  public get isLinked(): boolean {
    return this.message?.isLinked ?? false;
  }

  public onDownload(name: string): void {
    this.downloadName.emit(name);
  }
}
