import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { AuthService } from '../../../auth/auth.service';
import { MessageService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';
import { S3Service } from 'src/app/s3.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DeviceInfoService } from 'src/app/shared/device-info.service';
import { IDeviceInfo } from 'src/app/models/device-info.model';
import { IMessage } from 'src/app/models/chat-model/message.model';
import moment from 'moment-mini';

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss'],
})
export class SupportDialogComponent {
  @Input() showSidebar = true;
  @ViewChild('fileInput') fileInput!: ElementRef;

  public isLoading = false;
  public loadedFiles: File[] = [];
  public subject = new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(1000)]);
  public description = new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(2500)]);

  constructor(
    private dynamodb: DynamoDBService,
    private auth: AuthService,
    private messageService: MessageService,
    private s3Service: S3Service,
    private deviceInfo: DeviceInfoService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) { }

  get textAreaInvalid(): boolean {
    return this.description.invalid && this.description.touched;
  }

  public async onFileSelect(event: SelectEvent): Promise<void> {
    if (event.currentFiles.length >= 10) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'You can only upload a maximum of 10 files.'
      });
      event.currentFiles = event.currentFiles.slice(0, 10);
    }
    this.checkFileSize(event.currentFiles);
  }

  public checkFileSize(files: File[]): void {
    this.loadedFiles = files.filter(file => {
      if (file.size > 10000000) {
        this.messageService.add({ severity: 'error', summary: 'File Size Error', detail: `'${file.name}' size exceeds 10MB` });
        return false;
      }
      return true;
    });
  }

  public onFileRemove(event: RemoveEvent): void {
    this.loadedFiles = this.loadedFiles.filter(file => file.name !== event.file.name);
  }

  public async submitTicket(): Promise<void> {
    if (!this.subject.value || !this.description.value) {
      return;
    }
    this.isLoading = true;
    const ticketId = uuidv4();
    const deviceInfo: string = this.getDeviceInfo();
    const message: string = this.getFirstMessage();
    try {
      await this.dynamodb.saveSupportTicket(
        this.auth.user.email,
        ticketId,
        moment().toISOString(),
        this.subject.value,
        this.description.value,
        window.location.href,
        deviceInfo,
        message
      );

      for (const file of this.loadedFiles) {
        await this.s3Service.saveTicketAttachment(
          this.auth.user.email,
          ticketId,
          file
        );
      }

      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Your ticket was successfully submitted.'
      });
      this.ref.close();
    } catch {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to submit the ticket. Please try again later.',
      });
    } finally {
      this.isLoading = false;
    }
  }

  private getDeviceInfo(): string {
    const info: IDeviceInfo = {
      resolution: `${this.deviceInfo.resolution.width} x ${this.deviceInfo.resolution.height}`,
      userAgent: this.deviceInfo.userAgent ?? '',
      browser: `${this.deviceInfo.browser?.name} ${this.deviceInfo.browser?.version}`,
      os: `${this.deviceInfo.os?.name} ${this.deviceInfo.os?.version}`
    };
    return JSON.stringify(info);
  }

  private getFirstMessage(): string {
    const message: IMessage = {
      sendDate: moment().toISOString(),
      imageURL: this.auth.user.picture.originalURL,
      senderName: this.auth.user.full_name,
      senderEmail: this.auth.user.email,
      messageBody: this.description.value ?? '',
      attachmentsName: this.loadedFiles.map(file => file.name)
    };
    return JSON.stringify(message);
  }
}

interface SelectEvent {
  currentFiles: File[];
}

interface RemoveEvent {
  file: File;
}
