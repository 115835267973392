<div class="wrapper">
    <div class="main-container">
        <div class="df ai-c jc-c pr left">
            <div class="scroll-button pa" 
                #leftButton 
                (click)="triggerLeftScrollButton()"
                [@scale]="animationLeftButtonTrigger">
                &#10094;
            </div>
        </div>
        <div class="user-list-container" (wheel)="onScroll($event)">
            <div class="fade" 
                [ngStyle]="leftSideFadeStyle"
                [ngClass]="{'hide': !fadeLeftEdge}">
            </div>
            <div class="scroll-container" #userList (scroll)="updateFadeAndButtonVisibility()">
                <div class="new-user df fd-c ai-c" (click)="addUser()">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="30" cy="30" r="30" fill="#BDBDBD" />
                        <rect x="15" y="28" width="30" height="4" fill="white" />
                        <rect x="28" y="45" width="30" height="4" transform="rotate(-90 28 45)" fill="white" />
                    </svg>
                    <span class="user-name">
                        {{userType === 'member' ? 'Add Member' : 'Add Connection'}}
                    </span>
                </div>
                <div class="empty-list-message justify-content-start sm:justify-content-center" 
                   *ngIf="!users || users.length === 0">
                    <span>{{emptyListMessage}}</span>
                </div>
                <div
                  #userElement 
                  class="df fd-c ai-c"
                  *ngFor="let user of users"
                  (mouseenter)="openPopover(userElement, user)"
                  (mouseleave)="closePopover()">
                    <img class="user-img" [src]="user.picture.url">
                    <span 
                      class="user-name"
                      *ngIf="user.inviteID">
                        Unknown
                    </span>
                    <span 
                      class="user-name"
                      *ngIf="!user.inviteID">
                        {{ user.shortName ? user.shortName : (user.firstName ? user.firstName : user.email.split('@')[0]) }}
                    </span>
                </div>
            </div>
            <div class="fade" 
                [ngStyle]="rightSideFadeStyle"
                [ngClass]="{'hide': !fadeRightEdge}">
            </div>
        </div>
        <div class="df ai-c jc-c pr right">
            <div class="scroll-button pa" 
                #rightButton 
                (click)="triggerRightScrollButton()"
                [@scale]="animationRightButtonTrigger">
                &#10095;
            </div>
        </div>
    </div>
</div>

<popover *ngIf="isPopoverOpen"
  [open]="isPopoverOpen"
  [connectToElement]="selectedUser"
  [closeOnHover]="false"
  [width]="'300px'"
  #popoverRef>
    
    <div *ngIf="!isDeleteDialogOpen">
        <div class="df fd-c jc-sb">
            <div class="df fd-r jc-sb">
                <p-avatar shape="circle" size="large" [image]="currentUser?.picture?.url || defaultUserPicture"></p-avatar>
                <simple-button (click)="toggleDeleteDialog()">Delete User</simple-button>
            </div>

            <div class="df fd-c mr-10px">
                <span class="user-fullname">{{ currentUser?.fullName }}</span>
                <div class="user-details" *ngIf="currentUser && currentUser.email">
                    <i class="material-icons-outlined">mail</i>
                    <span>{{currentUser.email}}</span>
                </div>
                <div class="user-details" *ngIf="currentUser && currentUser.phoneNumber">
                    <i class="material-icons-outlined">smartphone</i>
                    <span>{{currentUser.phoneNumber}}</span>
                </div>
                <div class="user-details" *ngIf="currentUser && currentUser.location">
                    <i class="material-icons-outlined">location_on</i>
                    <span>{{currentUser.location}}</span>
                </div>
            </div>
        </div>
        <div class="user-overlay-message" *ngIf="currentUser && currentUser.inviteID">
            <span>
                No one has signed up with this invitation. Once a user registers with this invitation, you will see
                their details here.
            </span>
        </div>
    </div>

    <div class="delete-container" *ngIf="isDeleteDialogOpen">
        <div class="delete-message">
            Are you sure you want to proceed with the deletion of this user from the company?
        </div>
        <div class="df fd-r buttons">
            <i class="material-icons-outlined" (click)="deleteUser()">done</i>
            <i class="material-icons-outlined" (click)="toggleDeleteDialog()">close</i>
        </div>
    </div>
</popover>
