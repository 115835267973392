import { Component, EventEmitter, Output } from '@angular/core';
import { PlanControlService } from '../edit-plan/service/plan-control.service';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/user.module';
import { HintMessages } from '../help/help.component';
import { PlanDataService } from '../../plan-data-service/plan-data.service';
import { GetCurrentTime } from '../../../shared/service/time';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-create-plan',
  templateUrl: './create-plan.component.html',
  styleUrls: ['./create-plan.component.scss']
})
export class CreatePlanComponent {
  @Output() createdTrigger = new EventEmitter<null>();
  @Output() openExerciseManager = new EventEmitter<boolean>();

  public nameControl: PlanControlService;
  public descriptionControl: PlanControlService;
  public helpMessageEnum = HintMessages;
  public imageLink: string = environment.DefaultPlanPicture;
  private user: User;

  constructor(
    public auth: AuthService,
    private messageService: MessageService,
    public planData: PlanDataService,
  ) {
    this.user = auth.user;
    this.nameControl = new PlanControlService('', false, undefined, 5, 100);
    this.descriptionControl = new PlanControlService('', false, undefined, 0, 2000);

    this.planData.selectPlan({
      name: this.nameControl.value,
      description: this.descriptionControl.value,
      exerciseIDs: [],
      exerciseNames: [],
      imageLink: '',
      companyId: this.user.companyId,
      interviewerEmail: this.user.email,
      interviewerFullName: this.user.full_name || this.user.email,
      lastModificationTime: '',
      creationTime: '',
      executionTime: 0,
      id: uuidv4(),
    });
  }

  async openExercisePlanDialog(): Promise<void> {
    this.openExerciseManager.emit(true);
  }

  public savePlan(): void {
    if (this.nameControl.hasError() || this.nameControl.isEmpty()) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Name must be between 5 and 100 characters' });
      return;
    }
    
    if (this.descriptionControl.hasError() || this.descriptionControl.isEmpty()) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please make sure that the description is no longer than 2000 characters' });
      return;
    }
    
    if (this.planData.selectedPlan?.exerciseIDs.length == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select at least one exercise' });
      return;
    }

    this.planData.selectedPlan!.lastModificationTime = GetCurrentTime();
    this.planData.selectedPlan!.creationTime = GetCurrentTime();
    this.planData.selectedPlan!.name = this.nameControl.value;
    this.planData.selectedPlan!.description = this.descriptionControl.value;
    this.planData.createPlan(this.planData.selectedPlan!);
    this.createdTrigger.emit();
  }
}

