import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SupportDialogComponent } from 'src/app/support/components/support-dialog/support-dialog.component';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {
  constructor(
    private dialogService: DialogService
  ) { }

  public openSupportDialog(): void {
    this.dialogService.open(SupportDialogComponent, {
      width: '550px',
      baseZIndex: 10000,
      showHeader: true,
      dismissableMask: true,
      header: 'Submit Ticket'
    });
  }
}
