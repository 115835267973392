import { Component } from '@angular/core';
import { ExercisePlansDataService } from './exercise-plans-data-service/exercise-plans-data.service';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-user-exercise-plans',
  templateUrl: './user-exercise-plans.component.html',
  styleUrls: ['./user-exercise-plans.component.scss']
})
export class UserExercisePlansComponent {
  private windowSize = new BehaviorSubject<number>(window.innerWidth);
  private resizeSubscription: Subscription;

  constructor(
    public dataService: ExercisePlansDataService
  ) {
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(map(event => (event.target as Window).innerWidth))
      .subscribe(this.windowSize);
  }

  get windowsWidth(): number {
    return this.windowSize.value;
  }

  get isSmallScreen(): boolean {
    return this.windowSize.value <= 1280;
  }

  ngOnDestroy(): void {
    this.windowSize?.unsubscribe();
    this.resizeSubscription?.unsubscribe();
  }
}
