import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/user.module';
import { AuthService } from 'src/app/auth/auth.service';
import { MenuDataService } from '../../menu-data.service';
import { ComponentType } from '@angular/cdk/portal';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-medium-screen',
  templateUrl: './medium-screen.component.html',
  styleUrls: ['./medium-screen.component.scss', '../../styles/side-menu.scss']
})
export class MediumScreenComponent implements OnInit {
  public user!: User;

  constructor(
    public menuData: MenuDataService,
    public auth: AuthService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.user = this.auth.user;
  }


  openDialog(dialogComponent: ComponentType<unknown>, event: MouseEvent){
    
    this.dialogService.open(dialogComponent, {
      width: '250px',
      height: '250px',
      closable: true,
      showHeader: false,
      dismissableMask: true,
      contentStyle: {
        'border' : '1px solid rgb(202, 199, 199)',
        'border-radius' : '5px'
      },
      style:{
        'position': 'absolute',
        'left': `${event.clientX + 150}px`,
        'top': `${event.clientY - 200}px`
      }
      
    });
  }

}

