import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { animate, state, style, transition, trigger, AnimationEvent } from '@angular/animations';

@Component({
  selector: 'app-tool-panel',
  templateUrl: './tool-panel.component.html',
  styleUrls: ['./tool-panel.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateX(0%)'
      })),
      state('out', style({
        transform: 'translateX(100%)'
      })),
      transition('in => out', animate('300ms ease-in-out')),
      transition('out => in', animate('300ms ease-in-out'))
    ]),

    trigger('fade', [
      state('in', style({
        opacity: 1,
        background: 'rgba(0, 0, 0, 0.5)'
      })),
      state('out', style({
        opacity: 0,
        background: 'rgba(0, 0, 0, 0.49)'
      })),
      transition('in => out', animate('300ms ease-in-out')),
      transition('out => in', animate('300ms ease-in-out'))
    ]),
  ]
})
export class ToolPanelComponent implements OnChanges {
  @Input() openTrigger: boolean | null = null;

  public menuState = 'out';
  public makeClickable = false;

  ngOnChanges(changes: SimpleChanges) {
    const change = changes.openTrigger;
    if (!change.firstChange) {
      this.toggleMenu();
    }
  }

  public onAnimationDone(event: AnimationEvent) {
    if (event.toState === 'out' && event.triggerName === 'fade') {
      this.makeClickable = false;
    }
  }

  public toggleMenu() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    if (this.menuState === 'in') {
      this.lockScroll();
      this.makeClickable = true;
    } else {
      this.unlockScroll();
    }
  }

  private lockScroll() {
    document.body.style.overflow = 'hidden';
  }

  private unlockScroll() {
    document.body.style.overflow = '';
  }
}
