<div class="wrapper">
    <div id="scrollTo" class="container"
      *ngIf="plan; else noData">
        <h3>Plan Name: {{plan.planName}}</h3>
        <h4>Plan Status: 
            <span [ngStyle]="{'color': getStatusColor(plan.status!)}">{{plan.status}}</span>
        </h4>
        
        <div class="profile-container">
            <div class="profile">
                <img [src]="currentMember?.picture?.url">
                <span>Interviewer</span>
                <span>{{currentMember?.fullNameOrEmail}}</span>
            </div>
            <div class="profile">
                <img [src]="currentPlanConnection?.picture?.url">
                <span>Candidate</span>
                <span>{{currentPlanConnection?.fullNameOrEmail}}</span>
            </div>
        </div>

        <div *ngIf="plan.requirePayment"
          class="flex flex-column justify-content-start">
            <p-divider></p-divider>

            <h3 class="text-left mb-2">Payment Required</h3>

            <div class="mb-2">
                To continue using our service, please pay for this plan.
            </div>

            <div class="action flex">
                <button
                  class="border-1 border-blue-600 text-blue-600 pr-3 pl-3" 
                  routerLink="/payment"
                  [queryParams]="{email: plan.candidate.email, assigned_plan_id: plan.id, companyId: plan.interviewerCompanyID}">
                    <i class="material-icons-outlined">credit_card</i>
                    Pay Now 
                </button>
            </div>
        </div>

        <p-divider></p-divider>
        
        <div class="time-container">
            <div>
                Assigned Date: <span class="time">{{plan.assignedDate | date: 'MMM d, y, HH:mm'}}</span> 
            </div>
            <div>
                Deadline: <span class="time">{{plan.deadline | date: 'MMM d, y, HH:mm'}}</span>
            </div>
            <div>
                Time remaining until deadline: 
                <span class="time-remaining time"
                  [style.color]="(plan.remainingTime) < 6000 ? 'red' : 'black'">
                    {{plan.remainingTime | humanaizeSeconds}}
                </span> 
            </div>
        </div>

        <p-divider></p-divider>

        <div class="progress-container">
            <div class="circle-progress"
              *ngFor="let progress of circleProgress">
                <circle-progress
                    [title]="progress.otherTasks + ' of ' + progress.totalTasks" 
                    [showUnits]="false"
                    [showSubtitle]="false"
                    [percent]="progress.percent" 
                    [radius]="35"
                    [animation]="true" 
                    [startFromZero]="true"
                    [space]="-8"
                    [animationDuration]="500"
                    [outerStrokeColor]="progress.outerColor"
                    [outerStrokeWidth]="8"
                    [innerStrokeWidth]="8"
                    [innerStrokeColor]="progress.innerColor"
                    [clockwise]="true"
                    [backgroundPadding]="0"
                    [titleFontSize]="'14px'"
                    [titleFontWeight]="'700'">
                </circle-progress>
                <span>{{progress.name}}</span>
            </div>
        </div>

        <p-divider></p-divider>
    
        <div class="title">
            <span>Exercises</span>
            <app-help [helpMessage]="helpMessage.AssignedExercises"></app-help>
            <span>Records</span>
        </div>

        <ng-container *ngFor="let exercise of exerciseStatuses; let last = last">
            <div 
              class="exercise-container" 
              [ngClass]="{'last-exercise': last}"> 
                <img [src]="exercise.picture.url">
                <div class="exercise-info">
                    <ul>
                        <li class="exercise-title">{{exercise.exerciseName}}</li>
                        <li>
                            <span class="gray">Status: </span>
                            <span [ngStyle]="{'color': getStatusColor(exercise.status)}">
                                {{exercise.status}}
                            </span>
                        </li>
                        <li>
                            <span class="gray">Validation Count: </span>
                            <span>{{exercise.validationCount}}</span>
                        </li>
                        <li>
                            <span class="gray">Execution Time: </span>
                            <span>{{exercise.executionTime}}</span>
                        </li>
                        <li>
                            <span class="gray">Used Hints Count: </span>
                            <span>{{exercise.usedHintsCount}}</span>
                        </li>
                    </ul>
                </div>
                <button class="play-button bg-white border-none p-0"
                  [ngClass]="{'cursor-pointer': exercise.status !== taskStatus.NOT_STARTED}"
                  [disabled]="exercise.status === taskStatus.NOT_STARTED"
                  [routerLink]="['/replay', user.companyId, plan.id, exercise.exerciseID, exercise.base64UserEmail]">
                    <i class="material-icons-outlined">play_circle_outline</i>
                </button>
            </div>
        </ng-container>

        <div class="action flex">
            <button  
              class="delete mr-4" 
              (click)="cancelAssignedPlan(plan); $event.stopPropagation()"
              [disabled]="!plan.isDeletable">
                <i class="material-icons-outlined">delete_outline</i>
                {{plan.isNotStarted || plan.isFinished || plan.isRejected || plan.isDeadlinePassed ? "Delete" : "Cancel"}}
            </button>
        </div>
    </div>
</div>

<ng-template #noData>
    <div class="no-data">
        <h3>Plan Status</h3>
        <span *ngIf="assignedPlanData.data.length > 0">
            Please select an assigned plan from the list.
        </span>
        <span *ngIf="assignedPlanData.data.length === 0"> 
            You have no plans currently assigned to your connected users. To assign a plan, go to the 
            <a routerLink="/plans-management">plan selection section</a>, choose the desired plan, and 
            click the 'Assign' button.

            <button class="assign">
                <span>Assign</span>
                <i class="material-icons-outlined">send_outline</i>
            </button>
        </span>
    </div>
</ng-template>

<p-confirmDialog #cd 
    [style]="{width: '30vw'}"
    [breakpoints]="{'1440px': '50vw'}"
    [key]=dialogKey>
    <ng-template pTemplate="footer">
        <div>
            <simple-button class="mr-3" (click)="cd.reject()">No</simple-button>
            <simple-button (click)="cd.accept()">Yes</simple-button>
        </div>
    </ng-template>
</p-confirmDialog>