<div class="wrapper">
    <app-side-menu></app-side-menu>
    <div class="container">
        <ng-container *ngIf="isUserDataLoading">
            <section>
                <h2>Profile</h2>
                <p-divider></p-divider>

                <h3>Profile Details</h3>
                <avatar-uploader (onSelect)="onAvatarSelect($event)"></avatar-uploader>
                <div class="input-form">
                    <span class="tab-input-form-text">First Name</span>
                    <app-input-field
                        autocomplete="off"
                        [inputControl]="userFormGroup | formControl:'firstName'">
                    </app-input-field>
                </div>
                <div class="input-form">
                    <span class="tab-input-form-text">Last Name</span>
                    <app-input-field
                        autocomplete="off"
                        [inputControl]="userFormGroup | formControl:'lastName'">
                    </app-input-field>
                </div>
                <div class="input-form">
                    <span class="tab-input-form-text">Phone Number</span>
                    <app-input-field
                        placeholder="+1 23456789"
                        autocomplete="off"
                        [inputControl]="userFormGroup | formControl:'phoneNumber'">
                    </app-input-field>
                </div>
                <div class="input-form">
                    <span class="tab-input-form-text">Location</span>
                    <countries-dropdown
                      [inputControl]="userFormGroup | formControl:'location'">
                    </countries-dropdown>
                </div>
                <div class="text">
                    Fields are optional and removable. By entering info, you allow us to show it on your profile and
                    invoices. We respect privacy and won't share details without consent. To update, fill in the
                    fields and click "Save Changes." To remove, delete content and save changes.
                </div>
                <simple-button 
                    (click)="updateUserData()"
                    *ngIf="!isUserDataUpdating">
                    Save Changes
                </simple-button>
                <app-loading-animation *ngIf="isUserDataUpdating">
                </app-loading-animation>
            </section>
    
            <section>
                <h2>Password</h2>
                <p-divider></p-divider>
        
                <h3>Change Password</h3>
                <div class="input-form">
                    <span class="tab-input-form-text">Old Password</span>
                    <app-input-field
                        autocomplete="off"
                        type="password"
                        [inputControl]="passwordFormGroup | formControl:'oldPassword'"
                        [isRequired]="true"
                        [error]="passwordErrorHandler(passwordFormGroup.controls.oldPassword.errors)">
                    </app-input-field>
                </div>
                <div class="input-form">
                    <span class="tab-input-form-text">New Password</span>
                    <app-input-field
                        autocomplete="new-password"
                        name="new-password"
                        type="password"
                        [inputControl]="passwordFormGroup | formControl:'newPassword'"
                        [isRequired]="true"
                        [error]="passwordErrorHandler(passwordFormGroup.controls.newPassword.errors)">
                    </app-input-field>
                </div>
                <div class="text">
                    We recommend that you do not use the same password for multiple accounts to avoid potential
                    security risks
                </div>
                <simple-button 
                    *ngIf="!isPasswordUpdating"
                    [disabled]="!passwordFormGroup.valid"
                    (click)="changePassword()">
                    Update Password
                </simple-button>
                <app-loading-animation *ngIf="isPasswordUpdating">
                </app-loading-animation>
            </section>
            
            <section>
                <h2>Danger Zone</h2>
                <p-divider></p-divider>

                <h3>Delete Account</h3>
                <div class="text">
                    Attention: This action is irreversible. After your account is deleted, all of your data,
                    including your profile, exercises and company will be permanently removed.
                </div>

                <p-confirmDialog #cd 
                  [style]="{width: '30vw'}"
                  [breakpoints]="{'1440px': '50vw', '960px': '75vw', '640px': '100vw'}">
                    <ng-template pTemplate="footer">
                        <simple-button (click)="cd.reject()" class="mr-3">Cancel</simple-button>
                        <simple-button (click)="cd.accept()">Delete Account</simple-button>
                    </ng-template>
                </p-confirmDialog>
                <simple-button (click)="deleteAccount()">Delete</simple-button>
            </section>
        </ng-container>
       
        <div *ngIf="!isUserDataLoading" class="loading">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</div>