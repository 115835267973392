<div class="wrapper" [style.flex-direction]="isAuthenticated ? 'row' : 'column'">
    <ng-container *ngIf="isAuthenticated">
        <app-side-menu></app-side-menu>
        <div *ngTemplateOutlet="page"></div>
    </ng-container>

    <ng-container *ngIf="!isAuthenticated">
        <app-header></app-header>
        <div *ngTemplateOutlet="page"></div>
        <app-footer></app-footer>
    </ng-container>
</div>

<ng-template #page>
    <div class="main">
        <div class="main-content-container">
            <div class="contact-info-container">
                <div class="contact-info-box">
                    <i class="material-icons-outlined">work_outline</i>
                    <h3 class="contact-info-title">COMPANY</h3>
                    <span class="contact-info-text">SIA "Dev Skill"</span>
                    <span class="contact-info-text">Reg. No.: 40203283422</span>
                </div>
                <div class="contact-info-box">
                    <i class="material-icons-outlined">location_on</i>
                    <h3 class="contact-info-title">ADDRESS</h3>
                    <span class="contact-info-text">Latvia, Rīga, Bruņinieku iela 93C, LV-1009</span>
                </div>
                <div class="contact-info-box">
                    <i class="material-icons-outlined">mail</i>
                    <h3 class="contact-info-title">EMAIL</h3>
                    <span class="contact-info-text">support@dev-skill.com</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>