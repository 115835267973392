export class PaymentReference {
  private _id: string;
  private _status: string;
  private _date: string;
  private _payerName: string;
    
  constructor(
    paymentReference: Map<string, string>,
    id: string,
  ) {
    this._status = paymentReference.get('State') || '';
    this._date = (paymentReference.get('Time') || '');
    this._payerName = paymentReference.get('PayerName') || '';
    this._id = id;
  }

  public get status(): string {
    return this._status;
  }

  public get date(): string {
    return this._date;
  }

  public get payerName(): string {
    return this._payerName;
  }
    
  public get id(): string {
    return this._id;
  }
}