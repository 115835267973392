import { Component } from '@angular/core';
import { ExerciseDataService } from 'src/app/exercise/services/exercise-data.service';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  public currentTheme: DropdownItem;
  public currentFontSize: DropdownItem;
  public themes: DropdownItem[] = [
    { name: 'Dark', value: 'dark' },
    { name: 'Light', value: 'light' }
  ];
  public fontSize: DropdownItem[] = [
    { name: '10', value: '10' },
    { name: '12', value: '12' },
    { name: '14', value: '14' },
    { name: '16', value: '16' },
    { name: '18', value: '18' },
  ];

  constructor(
    private _data: ExerciseDataService
  ) {
    this.currentFontSize = this.fontSize.find(size => size.value == _data.fontSize) ?? { name: '14', value: '14' };
    this.currentTheme = this.themes.find(theme => theme.value == _data.theme) ?? { name: 'Light', value: 'light' };
  }

  public onThemeChange(event: { originalEvent: Event, value: DropdownItem }): void {
    this._data.changeTheme(event.value.value);
  }

  public onFontSizeChange(event: { originalEvent: Event, value: DropdownItem }): void {
    this._data.changeFontSize(event.value.value);
  }
}

interface DropdownItem {
  name: string
  value: string
}