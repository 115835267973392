import { Message } from './message.model';
import moment from 'moment-mini';

export class Conversation {
  constructor(
    jsonMessages: string[]
  ) {
    this._messages = jsonMessages.map(jsonMessage => new Message(jsonMessage));

    this.sortMessagesByDate();

    for (let i = 1; i < this._messages.length; i++) {
      const currentMessage = this._messages[i];
      const previousMessage = this._messages[i - 1];

      currentMessage.isLinked = this.isSameMinute(previousMessage.sendDate, currentMessage.sendDate);
    }
  }

  private _messages: Message[];

  get messages(): Message[] {
    return this._messages;
  }

  private get messageCount(): number {
    return this._messages.length;
  }

  private get lastMessage(): Message {
    return this._messages[this.messageCount - 1];
  }

  public addNewMessage(message: Message): void {
    if (this.lastMessage.isMine) {
      message.isLinked = this.isSameMinute(this.lastMessage.sendDate, message.sendDate);
    }
    this._messages.push(message);
  }

  private isSameMinute(previousDate: string, currentDate: string): boolean {
    return moment(previousDate).isSame(currentDate, 'minute');
  }

  private sortMessagesByDate(): void {
    this._messages.sort((a, b) => {
      const dateA = moment(a.sendDate).toDate();
      const dateB = moment(b.sendDate).toDate();
      return dateA.getTime() - dateB.getTime();
    });
  }
}