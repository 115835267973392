import { ICountry, TCountryCode } from 'countries-list';
import { getCountryCode, getCountryData, getCountryDataList } from 'countries-list';

export function GetCountryNames(): string[] {
  return getCountryDataList().map(c => c.name);
}

export function GetCountries(): ICountry[] {
  return getCountryDataList().sort((a, b) => a.name.localeCompare(b.name));
}

export function GetCountryByCode(code: TCountryCode): ICountry {
  return getCountryData(code);
}

export function GetCountryCode(countryName: string): TCountryCode | undefined {
  const countryCode = getCountryCode(countryName);
  return countryCode !== false ? countryCode : undefined;
}