<div class="wrapper" #wrapper>
    <h2>Plans</h2>
    <p-virtualScroller 
      [value]="plans"
      [itemSize]="130"
      *ngIf="plans.length > 0; else noData"
      class="full-height-scroller"
      #scrollViewport>

        <ng-template pTemplate="item" let-plan>
            <a class="plan"
              (click)="selectPlan(plan)"
              [style.background]="isSelected(plan) ? 'rgb(240, 240, 240)' : ''">

                <img src="../../../assets/img/plan/default.png">
                <div class="text-info">
                    <ul>
                        <li><h4>{{plan.planName}}</h4></li>
                        <li>Interviewer: <span>{{plan.interviewer?.fullName}}</span></li>
                        <li>Exercises: <span>{{plan.exerciseCount}}</span></li>
                        <li>Time Remaining: <span>{{plan.remainingTime | humanaizeSeconds}}</span></li>
                        <li>Status: 
                            <span [ngStyle]="{'color': getStatusColor(plan.status)}">
                                {{plan.status}}
                            </span>
                        </li>
                        
                    </ul>
                </div>
            </a>
        </ng-template>

        <ng-template let-product pTemplate="loadingItem">
            <div class="plan">
                <p-skeleton height="100px" width="100px"></p-skeleton>
                <div class="text-info">
                    <ul>
                        <li><p-skeleton width="130px" height="14px"></p-skeleton></li>
                        <li><p-skeleton width="180px" height="12px"></p-skeleton></li>
                        <li><p-skeleton width="50px" height="12px"></p-skeleton></li>
                        <li><p-skeleton width="200px" height="12px"></p-skeleton></li>
                        <li><p-skeleton width="100px" height="12px"></p-skeleton></li>
                    </ul>
                </div>
            </div>
        </ng-template>
    </p-virtualScroller>
</div>

<ng-template #noData>
    <div class="no-data-container">
        <img src="../../../assets/img/template/not_found.png">
        <span> Plans not found </span>
    </div>
</ng-template>