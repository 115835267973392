import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocTemplateComponent } from './components/doc-template/doc-template.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { SharedModule } from '../shared/shared.module';
import { SideMenuModule } from '../side-menu/side-menu.module';
import { TableOfContentsComponent } from './components/table-of-contents/table-of-contents.component';
import { ScrollTopModule } from 'primeng/scrolltop';

@NgModule({
  declarations: [
    DocTemplateComponent,
    DocumentationComponent,
    TableOfContentsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SideMenuModule,
    ScrollTopModule
  ],
  exports: [
    DocumentationComponent
  ]
})
export class DocumentationModule { }
