import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DocsLoaderService } from '../../services/docs-loader.service';
import { catchError } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'doc-template',
  templateUrl: './doc-template.component.html'
})
export class DocTemplateComponent implements OnInit {
  @Input() fileName = '';

  public markdownDoc!: Observable<string>;

  constructor(
    private docsLoader: DocsLoaderService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    if (!this.fileName)
      return;

    this.markdownDoc = this.docsLoader.fetchDocumentation(this.fileName)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.status === 0 ?
              'A network error has occurred. Please try again later.' :
              'Failed to load documentation. Please try again later.',
          });
          return of('');
        })
      );
  }
}
