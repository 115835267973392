<div class="wrapper">
    <app-side-menu></app-side-menu>
    <div class="dashboard-widgets">
        <div class="widget-1">
            <app-coming-soon *ngIf="auth.user.isUser"></app-coming-soon>
            <app-user-list
                *ngIf="auth.user.isInterviewer"
                emptyListMessage="Click on “Add Member” to add a new member to your company"
                fadeColor="243,241,248"
                [users]="members"
                (userDeleted)="onMemberDelete($event)"
                (userAdded)="onMemberAdd($event)">
            </app-user-list>
            <app-exercise-plans-list-widget></app-exercise-plans-list-widget>
            <div class="user-stats-container">
                <app-stats
                    [redCirclePositionX]="-50"
                    [redCirclePositionY]="+55"
                    [yellowCirclePositionX]="+55"
                    [yellowCirclePositionY]="+20"
                    [statNumber]="this.statsData.stats[0].value"
                    [statDescription]="this.statsData.stats[0].name"
                    [routerLink]="auth.user.isInterviewer ? '/plans-management' : '/plans'">
                </app-stats>
                <app-stats
                    [redCirclePositionX]="-45"
                    [redCirclePositionY]="-35"
                    [yellowCirclePositionX]="+25"
                    [yellowCirclePositionY]="-55"
                    [statNumber]="this.statsData.stats[1].value"
                    [statDescription]="this.statsData.stats[1].name"
                    [routerLink]="auth.user.isInterviewer ? '/settings/company' : '/plans'">
                </app-stats>
                <app-stats
                    [redCirclePositionX]="-55"
                    [redCirclePositionY]="+45"
                    [yellowCirclePositionX]="+25"
                    [yellowCirclePositionY]="+50"
                    [statNumber]="this.statsData.stats[2].value"
                    [statDescription]="this.statsData.stats[2].name"
                    [routerLink]="auth.user.isInterviewer ? '/settings/connections' : '/plans'">
                </app-stats>
            </div>
            <div class="dashboard-content4">
                <h1 class="title" *ngIf="auth.user.isInterviewer">Recently Assigned Plans</h1>
                <h1 class="title" *ngIf="auth.user.isUser">Uncompleted Exercises</h1>
                <app-recent-assigned-plan></app-recent-assigned-plan>
            </div>
        </div>
        <div class="widget-2">
            <app-calendar-widget>
            </app-calendar-widget>
            <div class="notification-container">
                <h1 class="title">Recent Notification</h1>
                <app-notification-widget>
                </app-notification-widget>
            </div>
        </div>
    </div>
</div>