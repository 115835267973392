<div 
  #popover 
  class="popover" 
  [class.active]="isActive"
  [style.width]="width"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()">
  <ng-content></ng-content>
</div>

<div [class.active]="isActive" #triangle class="triangle"></div>