import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { LocalStorageItems, LocalStorageService } from './shared/local-storage.service';
import { Picture } from './models/picture.model';
import { TCountryCode } from 'countries-list';

export class User implements IUser {
  private isTestModeOn: boolean;
  private avatar: Picture | undefined;

  constructor(
    private user: IUser,
    private localStorageService: LocalStorageService
  ) {
    this.isTestModeOn = localStorageService.getItem<boolean>(LocalStorageItems.TestMode) ?? false;
  }

  set testMode(isOn: boolean) {
    this.localStorageService.setItem(LocalStorageItems.TestMode, isOn);
    this.isTestModeOn = isOn;
  }

  get isDemoModeOn(): boolean {
    return this.isTestModeOn;
  }

  get username(): string {
    return this.user.username;
  }

  get isInterviewer(): boolean {
    return this.role === 'interviewer';
  }

  get isUser(): boolean {
    return this.role === 'user';
  }

  get isSupport(): boolean {
    return this.support === 'true';
  }

  get email(): string {
    return this.user.attributes!.email;
  }

  get email_verified(): boolean {
    return this.user.attributes!.email_verified;
  }

  get picture(): Picture {
    if (!this.avatar)
      this.avatar = new Picture(this.user.attributes?.['custom:profile_picture_url']);
    return this.avatar;
  }

  get given_name(): string {
    return this.user.attributes!.given_name ?? '';
  }

  set given_name(newValue: string) {
    this.user.attributes!.given_name = newValue;
  }

  get family_name(): string {
    return this.user.attributes!.family_name ?? '';
  }

  set family_name(newValue: string) {
    this.user.attributes!.family_name = newValue;
  }

  get full_name(): string {
    if (this.user.attributes?.given_name && this.user.attributes?.family_name) {
      return `${this.user.attributes.given_name} ${this.user.attributes.family_name}`;
    }
    return '';
  }

  get full_name_or_email(): string {
    return this.full_name ? this.full_name : this.email;
  }

  get phoneNumber(): string {
    return this.user.attributes?.phone_number ?? '';
  }

  set phoneNumber(newValue: string) {
    this.user.attributes!.phone_number = newValue;
  }

  get companyName(): string {
    return this.user.attributes!['custom:company_name'];
  }

  get companyId(): string {
    return this.user.attributes!['custom:company_id'];
  }

  get role(): Role {
    return this.isTestModeOn ? 'user' : this.user.attributes!['custom:role'];
  }

  get support(): string {
    return this.user.attributes!['custom:support'];
  }

  get countryCode(): TCountryCode {
    return this.user.attributes!['custom:location'];
  }

  get signInUserSession(): CognitoUserSession {
    return this.user.signInUserSession;
  }

  get isCompanyExisting(): boolean {
    return !!(this.companyId && this.companyName && !this.isTestModeOn);
  }
}

export interface IUserAttributes {
  email: string
  email_verified: boolean
  identities: string
  picture?: string
  given_name?: string
  family_name?: string
  full_name?: string
  phone_number?: string
  'custom:reg_confirmed': boolean
  'custom:company_name': string
  'custom:role': Role
  'custom:company_id': string
  'custom:support': string
  'custom:profile_picture_url': string
  'custom:location': TCountryCode
}

export interface IUser {
  attributes?: IUserAttributes
  username: string
  signInUserSession: CognitoUserSession;
}

type Role = 'user' | 'interviewer'
