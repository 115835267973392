<ng-container *ngIf="!isAuthenticated">
    <section class="flex flex-column">
        <app-header></app-header>
        <div class="p-5">
            <ng-container [ngTemplateOutlet]="docs">
            </ng-container>
        </div>
        <app-footer></app-footer>
    </section>
</ng-container>

<ng-container *ngIf="isAuthenticated">
    <section class="flex h-100 flex-column sm:flex-row">
        <app-side-menu></app-side-menu>
        <div 
          class="flex flex-column overflow-y-auto p-5"
          style="scroll-behavior: smooth;">
            <ng-container [ngTemplateOutlet]="docs">
            </ng-container>
        </div>
    </section>
</ng-container>    

<ng-template #docs>
    <h1 class="m-0 text-4xl">
        Documentation
    </h1>

    <table-of-contents
      class="block mb-4"
      [fileInfos]="(docsInfo | async) ?? []">
    </table-of-contents>

    <ng-container *ngFor="let doc of docsInfo | async; let last = last">
        <doc-template
         class="block"
         [class.mb-6]="!last"
         [id]="doc.file_name"
         [fileName]="doc.file_name">    
        </doc-template>
    </ng-container>

    <p-scrollTop 
      target="parent"
      [threshold]="100">
    </p-scrollTop>
</ng-template>