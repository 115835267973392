<div class="flex flex-column w-100 h-100">
    <app-header></app-header>
    <div class="p-5 flex justify-content-center align-items-center flex-column">
        <i *ngIf="paymentMessage?.status === 'Success'"
           class="material-icons-round text-8xl text-green-500">
            check_circle
        </i>
        <i *ngIf="paymentMessage?.status === 'Failed'"
           class="material-icons-round text-8xl text-red-500">
            highlight_off
        </i>
        <i *ngIf="paymentMessage?.status === 'Pending'"
           class="material-icons-round text-8xl text-yellow-500">
            error_outline
        </i>
        <h2 class="m-1">
            {{paymentMessage?.header}}
        </h2>
        <div class="mb-3">
            {{paymentMessage?.text}}
        </div>
        <a *ngIf="paymentMessage?.status === 'Success'"
          class="text-red-400 cursor-pointer"
          [routerLink]="auth.user.isUser ? '/plans' : '/assigned-plans'" >
            Return to Plans
        </a>
        <a *ngIf="paymentMessage?.status === 'Failed'"
          class="text-red-400 cursor-pointer"
          routerLink="/payment"
          [queryParams]="{email : candidateEmail, assigned_plan_id: assignedPlanId}"
          (click)="returnEvent.emit()">
            Return to Payment
        </a>
    </div>
</div>