import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  public setItem(key: LocalStorageItems, value: unknown): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getItem<T>(key: LocalStorageItems): T | null {
    const item = localStorage.getItem(key);
    if (!item) return null;
    try {
      return JSON.parse(item) as T;
    } catch {
      return item as unknown as T;
    }
  }

  public removeItem(key: LocalStorageItems): void {
    localStorage.removeItem(key);
  }
}

export enum LocalStorageItems {
  TestMode = 'test_mode',
  Theme = 'theme',
  FontSize = 'font-size',
  BrowserAlertDismissed = 'browser-alert-dismissed'
}
