<h1 class="m-0 font-bold text-5xl text-gray-800">
    Payment method
</h1>

<p-divider></p-divider>

<div class="mb-3 text-xl font-bold">
    How would you like to pay?
</div>

<ng-container *ngFor="let payment of paymentMethods">
    <div class="flex justify-content-start align-items-center mb-3 gap-2">
        <input 
        class="m-0 cursor-pointer"
        type="radio"
        [ngModel]="selectedPaymentMethod"
        (ngModelChange)="selectedPaymentMethodChange.emit($event)"
        [value]="payment">
        <img 
        class="border-1 border-gray-400 border-round-sm w-6rem"
        style="height: 130%;"
        [alt]="payment.source"
        [src]="payment.logo_url">
    </div>
</ng-container>

<div class="flex flex-column mt-5">
    <div class="flex mb-2">
        <img 
        src="../../assets/img/payment/3d-mastercard-visa.png" 
        width="75px"
        height="auto"
        class="mr-2">
        <img 
        src="../../assets/img/payment/swedbank-logo.svg"
        width="75px"
        height="auto">
    </div>
    <span class="font-medium text-sm">
        Secure online shopping with Mastercard, Visa and Swedbank
    </span>
</div>

