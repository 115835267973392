import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TCountryCode } from 'countries-list';
import { remove } from 'lodash';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { FormValidatorsService } from 'src/app/form-validators.service';
import { LambdasService } from 'src/app/lambads.service';
import { UserPublicProfile } from 'src/app/models/user-public-profile.model';
import { GetCountryByCode, GetCountryCode } from 'src/app/shared/service/countries';
import { User } from 'src/app/user.module';
import { UTCDateToLocalDate } from 'src/app/utc-time-converter';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss', '../../styles/settings.scss']
})
export class CompanyProfileComponent implements OnInit {
  public members: UserPublicProfile[] = [];
  public user: User = this._auth.user;
  public isCompanyDataLoaded = false;
  public createAccount = false;
  public isCompanyDataUpdating = false;
  public companyDetailsFormGroup: FormGroup;
  public trialPeriodEndDate: string | null = null;
  public isVATSet = false;

  constructor(
    private _auth: AuthService,
    private _validatorService: FormValidatorsService,
    private _dynamodb: DynamoDBService,
    private _lambdas: LambdasService,
    private messageService: MessageService,
  ) {
    this.companyDetailsFormGroup = new FormGroup({
      phoneNumber: _validatorService.GetPhoneFormControl(),
      email: _validatorService.GetEmailFormControl(),
      companyName: _validatorService.GetRequiredFormControl(),
      registrationNumber: _validatorService.GetRequiredFormControl(),
      vatNumber: _validatorService.GetEmptyFormControl(),
      isVatValid: _validatorService.GetBooleanFormControl(),
      country: _validatorService.GetRequiredFormControl(),
      state: _validatorService.GetEmptyFormControl(),
      city: _validatorService.GetRequiredFormControl(),
      street: _validatorService.GetRequiredFormControl(),
      postcode: _validatorService.GetRequiredFormControl(),
      referralCode: _validatorService.GetEmptyFormControl(),
    });
  }

  async ngOnInit(): Promise<void> {
    if (!this.user.isCompanyExisting) {
      this.setCompanyData();
      this.isCompanyDataLoaded = true;
      return;
    }

    await this.getCompanyData();
    await this.getMembersAndInvites();
    this.moveCurrentMemberToFirstArrayElement();
    this.isCompanyDataLoaded = true;
  }

  public async onMemberDelete(member: UserPublicProfile): Promise<void> {
    try {
      await this.deleteMemberFromDB(member);
      this.removeMemberFromArray(member);
    } catch (error) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: CompanyError.DeleteMemberError });
    }
  }

  public async onMemberAdd(member: UserPublicProfile): Promise<void> {
    this.members.push(member);
  }

  private setCompanyData(): void {
    this.companyDetailsFormGroup.controls.email.setValue(this.user.email);
    this.companyDetailsFormGroup.controls.phoneNumber.setValue(this.user.phoneNumber);
    this.companyDetailsFormGroup.controls.country.setValue(this.user.countryCode);
  }

  private moveCurrentMemberToFirstArrayElement(): void {
    const currentUserEmail = this.user.email;
    const element = remove(this.members, member => member.email === currentUserEmail);
    if (element.length > 0) {
      this.members.unshift(element[0]);
    }
  }

  private async getMembersAndInvites(): Promise<void> {
    try {
      const newMembers = await this._dynamodb.getCompanyMembers(this.user.companyId);
      this.members.push(...newMembers);
      const newInvites = await this._dynamodb.getMembersInvites(this.user.companyId);
      this.members.push(...newInvites);
    } catch {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to get company members' });
    }
  }

  public async saveCompanyDetails(): Promise<void> {
    this.isCompanyDataUpdating = true;
    this.isVATSet = this.companyDetailsFormGroup.controls.vatNumber.value == '' ? false : true;

    const companyData = this.buildCompanyObject(this.companyDetailsFormGroup);
    const subscription = this._lambdas.saveCompanyData(companyData).pipe(
      finalize(() => {
        subscription?.unsubscribe();
        this.isCompanyDataUpdating = false;
      })
    ).subscribe({
      error: (error) => this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.message
      }),
      complete: () => this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'The company data has been successfully updated.'
      }),
      next: async (resp) => {
        if (resp.vat_validator_error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: CompanyError.VATVerifyError
          });
        }

        this.setFormControlValue(
          this.companyDetailsFormGroup.controls,
          'isVatValid',
          resp.is_valid
        );
      },
    });
  }

  public async registerCompanyAccount(): Promise<void> {
    this.isCompanyDataUpdating = true;
    const companyData = this.buildCompanyObject(this.companyDetailsFormGroup);
    const subscription = this._lambdas.companyRegistration(companyData).pipe(
      finalize(() => {
        subscription?.unsubscribe();
        this.isCompanyDataUpdating = false;
      })
    ).subscribe({
      error: (error) => this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.message
      }),
      complete: () => this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'The company has been successfully registered'
      }),
      next: async (resp) => {
        if (resp.error === CompanyError.WrongReferralCodeError) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: resp.error });
          return;
        }
        await this._auth.refreshUser(true);
      }
    });
  }

  private buildCompanyObject(companyFormGroup: FormGroup): CompanyBody {
    const countryCode = GetCountryCode(companyFormGroup.value.country);
    const company: CompanyBody = {
      id: this.user.companyId ?? '',
      country_code: countryCode ?? '',
      name: companyFormGroup.controls.companyName.value,
      registration_number: companyFormGroup.controls.registrationNumber.value,
      vat_number: companyFormGroup.controls.vatNumber.value,
      phone_number: companyFormGroup.controls.phoneNumber.value,
      company_email: companyFormGroup.controls.email.value,
      state: companyFormGroup.controls.state.value,
      city: companyFormGroup.controls.city.value,
      street: companyFormGroup.controls.street.value,
      postcode: companyFormGroup.controls.postcode.value,
      referral_code: companyFormGroup.controls.referralCode.value,
    };

    return company;
  }

  private async getCompanyData(): Promise<void> {
    try {
      const companyData = await this._dynamodb.getCompanyData(this.user.companyId);
      const companyControls = this.companyDetailsFormGroup.controls;
      const country = GetCountryByCode(companyData.countryCode as TCountryCode);
      this.setFormControlValue(companyControls, 'companyName', companyData.name);
      this.setFormControlValue(companyControls, 'registrationNumber', companyData.registrationNumber);
      this.setFormControlValue(companyControls, 'vatNumber', companyData.vatNumber);
      this.setFormControlValue(companyControls, 'isVatValid', companyData.isVatValid ?? false);
      this.setFormControlValue(companyControls, 'phoneNumber', companyData.phoneNumber);
      this.setFormControlValue(companyControls, 'email', companyData.email);
      this.setFormControlValue(companyControls, 'country', country.name);
      this.setFormControlValue(companyControls, 'state', companyData.state);
      this.setFormControlValue(companyControls, 'city', companyData.city);
      this.setFormControlValue(companyControls, 'street', companyData.street);
      this.setFormControlValue(companyControls, 'postcode', companyData.postCode);
      this.trialPeriodEndDate = UTCDateToLocalDate(companyData.trialEndDate);
      this.isVATSet = companyControls.vatNumber.value == '' ? false : true;
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: (err as Error).message
      });
    }
  }

  private setFormControlValue(controls: { [key: string]: AbstractControl }, controlName: string, value: string | boolean): void {
    controls[controlName].setValue(value);
  }

  private async deleteInviteFromDB(inviteId: string): Promise<void> {
    await this._dynamodb.deleteInvite(inviteId, this.user.companyId);
  }

  private async removeMemberFromDB(memberEmail: string): Promise<void> {
    await this._lambdas.deleteMember(memberEmail, this.user.companyId).toPromise();
  }

  private async deleteMemberFromDB(user: UserPublicProfile): Promise<void> {
    if (user.inviteID) {
      await this.deleteInviteFromDB(user.inviteID);
    } else {
      await this.removeMemberFromDB(user.email);
    }
  }

  private removeMemberFromArray(memberToDelete: UserPublicProfile): void {
    this.members = this.members.filter(member => member.email !== memberToDelete.email);
  }
}

enum CompanyError {
  NotFoundError = 'Company data not found! Please try again later',
  GetError = 'Failed to get company data! Please try again later',
  UpdateError = 'Failed to update company details, Please try again later!',
  CreateError = 'Failed to create company account, Please try again later!',
  WrongReferralCodeError = 'Wrong referral code',
  VATVerifyError = 'Failed to verify your VAT number. Please save your company information later to repeat the verification process.',
  DeleteMemberError = 'Failed to delete member. Please try again later!',
  InviteMemberError = 'Failed to invite member. Please try again later!',
}

export interface CompanyBody {
  id?: string;
  name: string;
  company_email: string;
  country_code: string;
  state: string;
  city: string;
  street: string;
  postcode: string;
  referral_code?: string;
  registration_number: string;
  vat_number: string;
  phone_number: string;
}
