import { Injectable } from '@angular/core';
import { InterviewerExercisePlan } from 'src/app/exercise.module';
import { BehaviorSubject } from 'rxjs';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/user.module';
import { AttributeValue } from '@aws-sdk/client-dynamodb';
import { GetCurrentTime } from 'src/app/shared/service/time';

@Injectable({
  providedIn: 'root'
})
export class PlanDataService {
  private startKey: StartKeyType = null;
  public plans: InterviewerExercisePlan[] = [];

  private selectedPlanSubject = new BehaviorSubject<InterviewerExercisePlan | null>(null);
  public selectedPlan$ = this.selectedPlanSubject.asObservable();


  public totalPlanCount = 0;

  public user: User;

  constructor(
    private dynamodb: DynamoDBService,
    private auth: AuthService,

  ) {
    this.user = auth.user;
  }

  public async fetchData(): Promise<void> {
    await Promise.all([
      this.fetchPlans(),
      this.getTotalPlansCount(),
    ]);
  }
  get selectedPlan(): InterviewerExercisePlan | null {
    return this.selectedPlanSubject.getValue();
  }

  private async getTotalPlansCount(): Promise<void> {
    this.totalPlanCount = await this.dynamodb.getCompanyPlansCount(this.user.companyId);
  }
  public async fetchPlans(): Promise<void> {
    if (this.startKey === undefined) {
      return;
    }
    const [plans, planLastKey] = await this.dynamodb.getInterviewerExercisePlans(this.user.companyId, 99, this.startKey);
    this.startKey = planLastKey;
    this.plans.push(...plans);
  }

  public async updatePlan(editedPlan: InterviewerExercisePlan): Promise<void> {
    const previousPlanVersion = this.plans.find(plan => plan.id == editedPlan.id);
    if (!previousPlanVersion) {
      return;
    }

    editedPlan.lastModificationTime = GetCurrentTime();
    await this.dynamodb.updateInterviewerExercisePlan(editedPlan.companyId, editedPlan.id, editedPlan.lastModificationTime, editedPlan.name, editedPlan.description,
      editedPlan.exerciseIDs, editedPlan.exerciseNames, editedPlan.executionTime.toString());
  }

  public async createPlan(plan: InterviewerExercisePlan): Promise<void> {
    await this.dynamodb.saveInterviewerExercisePlan(plan.exerciseIDs, plan.exerciseNames, plan.name, plan.interviewerEmail, plan.interviewerFullName, plan.companyId, plan.executionTime, plan.creationTime, plan.id, plan.description, plan.imageLink!);
    this.plans.push(plan);
    this.selectPlan(plan);
  }

  public async delete(): Promise<void> {
    this.dynamodb.deleteInterviewerExercisePlans(this.selectedPlan!.companyId, this.selectedPlan!.id);
    this.plans = this.plans.filter(plan => plan.id !== this.selectedPlan!.id);
  }

  
  public selectPlanById(planId: string): void {
    const plan = this.plans.find(plan => plan.id === planId);
    if (plan) {
      this.selectPlan(plan);
    }
  }
  
  public selectPlan(plan: InterviewerExercisePlan): void {
    this.setActivePlan(plan.id);
    this.setSelectedPlan(plan);
  }
  
  private setActivePlan(id: string): void {
    this.plans.forEach(p => {
      p.selected = p.id === id;
    });
  }

  public findPlanIndex(plan: InterviewerExercisePlan): number {
    return this.plans.findIndex(p => p.id === plan.id);
  }
  
  private setSelectedPlan(plan: InterviewerExercisePlan): void {
    this.selectedPlanSubject.next(plan);
  }
}
export type StartKeyType = { [key: string]: AttributeValue } | undefined | null;