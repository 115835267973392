import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/auth/auth.service';
import { LambdasService } from 'src/app/lambads.service';

@Component({
  selector: 'app-become-company-member',
  templateUrl: './become-company-member.component.html',
  styleUrls: ['./become-company-member.component.scss']
})
export class BecomeCompanyMemberComponent {
  public isRequestSent = false;
  public data: {inviteId: string, companyId: string, companyName: string}; 

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private lambda: LambdasService,
    private auth: AuthService,
    private messageService: MessageService
  ) {
    this.data = config.data;
    if (!this.data)  {
      this.data = {
        inviteId: '',
        companyId: '',
        companyName: ''
      };
    }
  }

  public becomeMember(): void {
    this.isRequestSent = true;
    this.lambda.addMember(
      this.auth.user.email, 
      this.data!.inviteId, 
      this.data!.companyId).subscribe({
      next: async () => this.closeDialog(true),
      error: (error) => this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message }),
      complete: () => this.isRequestSent = false
    });
  }

  public closeDialog(result: boolean): void {
    this.ref.close(result);
  }
}