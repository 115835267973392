import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CalendarEvent } from '../calendar-widget.component';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/user.module';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { MessageService } from 'primeng/api';
import { AssignedPlan } from 'src/app/models/assigned-plan.model';
import moment from 'moment-mini';

@Injectable({
  providedIn: 'root'
})
export class CalendarDataService {
  private user: User;
  private dataSubject: BehaviorSubject<CalendarEvent[] | null> = new BehaviorSubject<CalendarEvent[] | null>(null);
  public data$: Observable<CalendarEvent[] | null> = this.dataSubject.asObservable();


  constructor(
    private auth: AuthService,
    private dynamodb: DynamoDBService,
    private messageService: MessageService
  ) {
    this.user = auth.user;
  }

  public async fetch(startOfMonth?: string, endOfMonth?: string): Promise<void> {
    try {
      const data = this.user.isInterviewer
        ? await this.fetchInterviewerData(startOfMonth, endOfMonth)
        : await this.fetchUserData(startOfMonth, endOfMonth);

      const events = this.convertToCalendarEvents(data);
      this.dataSubject.next(events);
    } catch {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Unable to fetch data for calendar. Please try again later.' });
    }
  }

  private async fetchInterviewerData(startOfMonth?: string, endOfMonth?: string): Promise<AssignedPlan[]> {
    return this.dynamodb.getAssignedPlansByDeadline(this.user.companyId, startOfMonth, endOfMonth);
  }

  private async fetchUserData(startOfMonth?: string, endOfMonth?: string): Promise<AssignedPlan[]> {
    return this.dynamodb.getUserExercisesPlansForDashboard(this.user.email, startOfMonth, endOfMonth);
  }

  private convertToCalendarEvents(plans: AssignedPlan[]): CalendarEvent[] {
    const calendarEvents: CalendarEvent[] = [];

    plans.forEach(plan => {
      const deadline = moment(plan.deadline).startOf('day');
      const existingEvent = calendarEvents.find(event =>
        moment(event.date).startOf('day').isSame(deadline)
      );

      let eventDescription: string;
      if (this.user.isInterviewer) {
        eventDescription = this.getInterviewerEventDescription(plan);
      } else {
        eventDescription = this.getUserEventDescription(plan);
      }

      if (existingEvent) {
        existingEvent.events.push(eventDescription);
      } else {
        calendarEvents.push({
          date: deadline.toDate(),
          events: [eventDescription]
        });
      }
    });
    return calendarEvents;
  }

  private getInterviewerEventDescription(plan: AssignedPlan): string {
    return `Deadline for ${plan.candidate.fullName} "${plan.planName}" plan`;
  }

  private getUserEventDescription(plan: AssignedPlan): string {
    return `Deadline: '${plan.planName}' by ${plan.interviewer?.fullName}, 
    ${plan.interviewerCompanyName}`;
  }
}
