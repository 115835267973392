<div class="wrapper"> 
    <app-side-menu></app-side-menu>
    <div class="exercise-container">
        <app-exercise-list
            [isSelectableModeActive]="isSelectableModeActive"
            (selectedExercise)="openDescription($event)"
            (closeExerciseManager)="closeExerciseManager.emit(true)">
        </app-exercise-list>
        <div class="side-panel">
            <ng-container *ngTemplateOutlet="template"></ng-container>
        </div>
    </div>
</div>

<app-tool-panel [openTrigger]="toolPanelTrigger">
    <ng-container *ngTemplateOutlet="template"></ng-container>
</app-tool-panel>

<ng-template #template>
    <app-exercise-filter
        *ngIf="menuType === 'filter'">
    </app-exercise-filter>

    <app-exercise-description
        *ngIf="menuType === 'description'"
        (close)="closeDescription()"
        [exercise]="selectedExercise">
    </app-exercise-description>
</ng-template>
