import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { TaskStatus } from 'src/app/status-parser';
import { User } from 'src/app/user.module';

@Injectable({
  providedIn: 'root'
})
export class StatsDataService {
  public stats: Stats[];
  private user: User;

  constructor(
    private auth: AuthService,
    private dynamodb: DynamoDBService,
    private messageService: MessageService,
  ) {
    this.user = this.auth.user;
    this.stats = this.getEmptyStats();
  }

  public async fetch(): Promise<void> {
    try {
      this.stats = this.user.isInterviewer
        ? await this.fetchInterviewerStats()
        : await this.fetchUserStats();
    } catch {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch statuses. Please try again later!' });
    }
  }

  private getEmptyStats(): Stats[] {
    return this.user.isInterviewer
      ? this.getInterviewerStats()
      : this.getUserStats();
  }

  private getInterviewerStats(plansValue = 0, membersValue = 0, connectionsValue = 0): Stats[] {
    return [
      { name: 'Plans', value: plansValue },
      { name: 'Members', value: membersValue },
      { name: 'Connections', value: connectionsValue },
    ];
  }

  private getUserStats(plansValue = 0, incompleteValue = 0, completeValue = 0): Stats[] {
    return [
      { name: 'Plans', value: plansValue },
      { name: 'Uncompleted Tasks', value: incompleteValue },
      { name: 'Completed Tasks', value: completeValue },
    ];
  }

  private async fetchUserStats(): Promise<Stats[]> {
    const [plansCount, incompleteCount, completeCount] = await Promise.all([
      this.dynamodb.getUserExercisePlansCount(this.auth.user.email),
      this.dynamodb.getUserExerciseStatusCount(this.auth.user.email, TaskStatus.NOT_STARTED, TaskStatus.RUNNING),
      this.dynamodb.getUserExerciseStatusCount(this.auth.user.email, TaskStatus.COMPLETED),
    ]);
    return this.getUserStats(plansCount, incompleteCount, completeCount);
  }

  private async fetchInterviewerStats(): Promise<Stats[]> {
    const [plansCount, connectionsCount, membersCount] = await Promise.all([
      this.dynamodb.getCompanyPlansCount(this.auth.user.companyId),
      this.dynamodb.getCompanyConnectionsCount(this.auth.user.companyId),
      this.dynamodb.getCompanyMembersCount(this.auth.user.companyId)
    ]);
    return this.getInterviewerStats(plansCount, membersCount, connectionsCount);
  }

}

export interface Stats {
  name: string
  value: number
}