import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {

  public queryParams: Params = { ...this.route.snapshot.queryParams };

  constructor(
    private route: ActivatedRoute,
  ) { }
}
