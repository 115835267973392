import { Component, OnInit } from '@angular/core';
import { DocsLoaderService, DocumentationInfo } from '../../services/docs-loader.service';
import { catchError, shareReplay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/user.module';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit {
  public docsInfo!: Observable<DocumentationInfo[]>;
  public user!: User;
  public isAuthenticated = false;

  constructor(
    private docLoaderService: DocsLoaderService,
    private messageService: MessageService,
    private authService: AuthService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.authCheck();
    this.loadFileInfos();
  }

  private async authCheck(): Promise<void> {
    this.isAuthenticated = await this.authService.isAuthenticated();
    this.user = this.authService.user;
  }

  private loadFileInfos(): void {
    this.docsInfo = this.docLoaderService.fetchFileInfos()
      .pipe(
        shareReplay(1),
        catchError((error: HttpErrorResponse) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.status === 0 ?
              'A network error has occurred. Please try again later.' :
              'Failed to load documentation. Please try again later.',
          });
          return of([]);
        })
      );
  }
}
