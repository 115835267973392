import { Component, OnInit } from '@angular/core';
import { PaymentState } from '../payment/payment.component';
import { PaymentInformation } from '../models/payment/payment-information.models';
import { MessageService } from 'primeng/api';
import { PaymentHistoryService } from './service/payment-history.service';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
})
export class PaymentHistoryComponent implements OnInit {
  public itemsCount: number | undefined;
  public paymentInfo: PaymentInformation[] | undefined;
  public pageSize = 5;

  constructor(
    private paymentHistoryService: PaymentHistoryService,
    private messageService: MessageService
  ) { }

  public get isLoading(): boolean {
    return !this.itemsCount && !this.paymentInfo;
  }

  async ngOnInit(): Promise<void> {
    await this.fetchTotalItemsCount();
  }

  public determineSeverity(status: PaymentState): string {
    switch (status) {
    case PaymentState.ChargedBack:
    case PaymentState.Refunded:
    case PaymentState.Voided:
    case PaymentState.Failed:
      return 'danger';
    case PaymentState.WaitingForSCA:
    case PaymentState.SentForProcessing:
    case PaymentState.Pending:
    case PaymentState.Initial:
    case PaymentState.Abandoned:
    case PaymentState.Unknown:
      return 'warning';
    case PaymentState.Done:
    case PaymentState.Settled:
    case PaymentState.LowPrice:
      return 'success';
    }
  }

  private async fetchTotalItemsCount(): Promise<void> {
    try {
      this.itemsCount = await this.paymentHistoryService.fetchTotalItemsCount();
    } catch (err) {
      const error = err as Error;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
    }
  }

  public async loadPage(pageSize: number, selectedPage: number): Promise<void> {
    try {
      this.paymentInfo = await this.paymentHistoryService.loadPage(pageSize, selectedPage);
    } catch (err) {
      const error = err as Error;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
    }
  }
}