import { Injectable } from '@angular/core';
import { AttributeValue } from '@aws-sdk/client-dynamodb';
import { AuthService } from 'src/app/auth/auth.service';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { PaymentInformation } from 'src/app/models/payment/payment-information.models';

@Injectable({
  providedIn: 'root'
})
export class PaymentHistoryService {
  private cachedData: Map<string, {
    data: PaymentInformation[], lastKey: {
      [key: string]: AttributeValue;
    } | undefined
  }> = new Map();

  constructor(
    private dynamodb: DynamoDBService,
    private authService: AuthService,
  ) { }

  public async fetchTotalItemsCount(): Promise<number> {
    let result: number;
    const user = this.authService.user;

    try {
      if (user.isCompanyExisting)
        result = await this.dynamodb.getCompanyPaymentTotalItemsCount(user.companyId);
      else
        result = await this.dynamodb.getUserPaymentItemsCount(user.email);
    } catch {
      throw Error('Failed to load payment history. Please try again later!');
    }

    return result;
  }

  public async loadPage(pageSize: number, selectedPage: number): Promise<PaymentInformation[]> {
    let result: PaymentInformation[];
    try {
      result = await this.getDataFromCacheOrDynamoDB(pageSize, selectedPage);
    } catch (err) {
      console.log(err);
      throw Error('Failed to load payment history. Please try again later!');
    }

    return result;
  }

  private async getDataFromCacheOrDynamoDB(pageSize: number, pageIndex: number): Promise<PaymentInformation[]> {
    let paymentInformation: PaymentInformation[] | undefined;
    let lastEvaluatedKey: { [key: string]: AttributeValue } | undefined;
    const user = this.authService.user;

    const cacheKey = `${pageSize}-${pageIndex}`;
    const cachedData = this.cachedData.get(cacheKey);
    if (cachedData) {
      return cachedData.data;
    }

    const previousCacheKey = `${pageSize}-${pageIndex - 1}`;
    const previousData = this.cachedData.get(previousCacheKey);

    if (user.isCompanyExisting)
      [paymentInformation, lastEvaluatedKey] = await this.dynamodb.getCompanyPaymentInformation(user.companyId, pageSize, previousData?.lastKey);
    else
      [paymentInformation, lastEvaluatedKey] = await this.dynamodb.getUserPaymentsInformation(user.email, pageSize, previousData?.lastKey);


    if (!paymentInformation)
      return [];

    this.cachedData.set(cacheKey, { data: paymentInformation, lastKey: lastEvaluatedKey });
    return paymentInformation;
  }


}
