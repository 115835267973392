<meta name="viewport" content="width=device-width, initial-scale=1">

<app-header></app-header>

<main id="main">
    <section class="page1">
        <div class="page1-item1">
            <div class="page1-text-box">
                <h1 class="page1-title page-title">Interviewing Candidates for Employment</h1>
                <div class="page1-text page-text">
                    Create plans with various tasks and share them with candidates. Get full information about
                    tasks completion and make a better choice
                </div>
            </div>
            <button class="page1-button" routerLink="/registration" [queryParams]="queryParams" routerLinkActive="active">
                <span class="page1-button-text">Get Started</span>
                <div class="page1-button-arrow"></div>
            </button>
        </div>
        <div class="page1-item2">
            <div class="page1-background">
                <div class="page1-background1">
                    <div class="page1-background1-1"></div>
                    <div class="page1-background1-2"></div>
                </div>
                <div class="page1-background2">
                    <div class="page1-background2-1"></div>
                    <img class="page1-background2-2" src="../../assets/img/landingPage/4.png">
                </div>
                <div class="page1-background4"></div>
            </div>
        </div>
    </section>
    <section class="page3">
        <div class="page3-list">
            <div class="page3-list-item1">
                <h1 class="page3-title page-title">
                    We offer innovative solutions in hard skills testing
                </h1>
            </div>
            <div class="page3-list-item2">
                <div class="page3-background1"></div>
            </div>
            <div class="page3-list-item3">
                <div class="page3-content-box">
                    <div class="page3-content1">
                        <img class="page3-content1-img" src="../../assets/img/landingPage/offer-img.svg">
                        <span class="page3-content1-text page-text">Over 30 exercises with randomly generated scenarios</span>
                    </div>
                    <div class="page3-content2">
                        <img class="page3-content2-img" src="../../assets/img/landingPage/offer-img2.svg">
                        <span class="page3-content2-text page-text">You do not need to download anything, all tasks are run
                            in the cloud</span>
                    </div>
                    <div class="page3-content3">
                        <img class="page3-content3-img" src="../../assets/img/landingPage/offer-img3.svg">
                        <span class="page3-content3-text page-text">Each exercise works in a real environment</span>
                    </div>
                </div>
            </div>
            <div class="page3-list-item4">
                <div class="page3-background2"></div>
            </div>
        </div>
    </section>

    <!-- <section class="page4">
        <div class="wrapper">
            <div class="page4-list">
                <div class="page4-background1"></div>
                <div class="page4-list-item2">
                    <div class="page4-content">
                        <h1 class="page4-title page-title">
                            Develop Your Skills
                        </h1>
                        <div class="page4-text page-text">
                            Testing software engineers has never been so easy, we offer exercises to
                            test hard skills
                        </div>
                        <button class="page4-button" routerLink="/registration" [queryParams]="queryParams"
                            routerLinkActive="active">Start now</button>
                    </div>
                    <div class="page4-content2">
                        <div class="page4-background2"></div>
                    </div>
                    <img class="page4-content-img" src="../../assets/img/landingPage/2.png">
                </div>
                <div class="page4-list-item3">
                    <div class="page4-background3"></div>
                </div>
            </div>
        </div>
    </section> -->

    <section class="page2">
        <div class="wrapper">
            <div class="page2-list">
                <div class="page2-item2">
                    <div class="page2-info-box">
                        <img class="page2-info-img" src="../../assets/img/landingPage/3.png">
                        <div class="page2-text-box">
                            <h1 class="page2-title page-title">Improve Your Knowledge</h1>
                            <p class="page2-text page-text text-center"> 
                                A new feature will be available on our platform soon to enhance your learning
                                experience. Master Git, Kubernetes, AWS, and more to become a top DevOps engineer, or
                                boost your existing skills. Stay tuned!
                            </p>
                        </div>
                        <div class="page2-background2"></div>
                    </div>
                </div>
                <div class="page2-background3"></div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>
