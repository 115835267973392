import { Component } from '@angular/core';
import { AssignedPlansDataService } from '../data-service/assigned-plans-data.service';
import { GetStatusColor } from 'src/app/shared/service/ui';
import { environment } from 'src/environments/environment';
import { AssignedPlan, PlanStatus } from 'src/app/models/assigned-plan.model';

@Component({
  selector: 'app-assigned-plans-list',
  templateUrl: './assigned-plans-list.component.html',
  styleUrls: ['./assigned-plans-list.component.scss']
})
export class AssignedPlansListComponent {
  public planStatus = PlanStatus;

  constructor(
    public assignedPlansData: AssignedPlansDataService,
  ) { }

  public getUserImage(plan: AssignedPlan): string {
    return this.assignedPlansData.companyMembersAndConnections?.find(user => user.email === plan.candidate.email)?.picture.url ?? environment.DefaultMemberPicture;
  }

  public getStatusColor(status: PlanStatus): string {
    return GetStatusColor(status);
  }

  public isSelected(plan: AssignedPlan): boolean {
    return plan.id === this.assignedPlansData.selectedPlan?.id;
  }
}
