import { Injectable } from '@angular/core';
import { UAParser, IResult, IBrowser, IOS, IDevice } from 'ua-parser-js';

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {
  private result: IResult | undefined;

  constructor() {
    const parser = new UAParser();
    this.result = parser.getResult();
  }

  get browser(): IBrowser | undefined {
    return this.result?.browser;
  }

  get os(): IOS | undefined {
    return this.result?.os;
  }

  get device(): IDevice | undefined {
    return this.result?.device;
  }

  get userAgent(): string | undefined {
    return this.result?.ua;
  }

  get resolution(): Resolution {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  }
}

interface Resolution {
  width: number
  height: number
}
