import { Injectable } from '@angular/core';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { Exercise } from 'src/app/exercise.module';

@Injectable({
  providedIn: 'root'
})
export class ExerciseDataService {
  private allExercises: Exercise[] = [];
  public filteredExercises: Exercise[] = [];

  constructor(
    private dynamodb: DynamoDBService
  ) {
    this.fetchExercises();
  }

  get exercises(): Exercise[] {
    return this.allExercises;
  }

  public async fetchExercises(): Promise<void> {
    this.allExercises = await this.dynamodb.getAllExercises();
    this.filteredExercises = this.exercises;
  }

  public clearSortedExercises(): void {
    this.filteredExercises = this.exercises;
  }

  public removeExerciseSelection(): void {
    this.filteredExercises.forEach(exercise => {
      exercise.selected = false;
    });
  }

}
