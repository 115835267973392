import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, asyncScheduler, from, of, scheduled } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { DynamoDBService } from 'src/app/dynamodb.service';

@Injectable({
  providedIn: 'root'
})
export class ExerciseAccessGuard implements CanActivate {
  constructor(
    private _dynamoDBService: DynamoDBService,
    private _auth: AuthService,
    private _messageService: MessageService,
    private _router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this._auth.user) {
      return from(this._auth.isAuthenticated()).pipe(
        switchMap(isAuthenticated => {
          return isAuthenticated ? this.isAccessAllowed(route) : of(this._router.parseUrl('/login'));
        })
      );
    }
    return this.isAccessAllowed(route);
  }

  private isAccessAllowed(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const assignedPlanID = route.paramMap.get('planId');
    const exerciseID = route.paramMap.get('exerciseId');
    if (!assignedPlanID || !exerciseID) {
      return scheduled([this.denyAccess(assignedPlanID, 'Exercise not found')], asyncScheduler);
    }

    return from(this._dynamoDBService.getUserExerciseStatus(assignedPlanID, exerciseID, this._auth.user.email)).pipe(
      map(exercise => {
        if (exercise.isDeadlinePassed)
          return this.denyAccess(assignedPlanID, 'Unable to start exercise because the plan has expired');

        if (exercise.isCanceled)
          return this.denyAccess(assignedPlanID, 'Unable to start exercise because the exercise has been canceled');

        if (exercise.isCompleted)
          return this.denyAccess(assignedPlanID, 'Unable to start exercise because the exercise has been completed');
        
        if (exercise.isTimeIsOver)
          return this.denyAccess(assignedPlanID, 'Unable to start exercise because the exercise time has expired');

        if (exercise.isPlanRejected)
          return this.denyAccess(assignedPlanID, 'Unable to start exercise because the plan has been rejected');
        
        if (exercise.isPlanCanceled)
          return this.denyAccess(assignedPlanID, 'Unable to start exercise because the plan has been canceled');

        if (exercise.isPlanFinished)
          return this.denyAccess(assignedPlanID, 'Unable to start exercise because the plan has been finished');

        return true;
      }),
      catchError(error => {
        console.error(error);
        return scheduled([this.denyAccess(assignedPlanID, 'An error occurred while fetching exercise')], asyncScheduler);
      })
    );
  }

  private denyAccess(assignedPlanID: string | null, forbidMessage: string): UrlTree {
    this.showError(forbidMessage);
    return this._router.createUrlTree(['/plans'], { queryParams: { id: assignedPlanID } });
  }

  private showError(detail: string): void {
    this._messageService.add({ severity: 'error', summary: 'Error', detail: detail });
  }
}
