import { Injectable } from '@angular/core';

import Template from 'mustache';
import MarkdownIt from 'markdown-it';
import MarkdownHighlight from 'markdown-it-highlightjs';
import MarkdownAttrs from 'markdown-it-attrs';
import emoji from 'markdown-it-emoji';

@Injectable({
  providedIn: 'root'
})
export class CustomizeText {

  constructor() {
    this.markdown = MarkdownIt({
      html: false,
      linkify: true,
      typographer: true,
    });

    this.markdown.use(MarkdownHighlight, { inline: true, auto: false, code: false });
    this.markdown.use(MarkdownAttrs);
    this.markdown.use(emoji);
  }

  private markdown: MarkdownIt;

  public render(rawText: string, dynamicDescription?: string): string {
    return this.renderTemplate(rawText, dynamicDescription);
  }

  private renderTemplate(text: string, template?: string): string {
    let templateObj: string | null = null;
    if (template) {
      templateObj = JSON.parse(template);
      return this.markdownIt(Template.render(text, templateObj));
    }
    return this.markdownIt(text);
  }

  private markdownIt(text: string): string {
    const md = this.markdown.render(text);
    return `<div class="markdown-body">${md}</div>`;
  }
}
