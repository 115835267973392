import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Exercise } from 'src/app/exercise.module';
import { HintMessages } from 'src/app/plans-management/plan-editor/help/help.component';

@Component({
  selector: 'app-exercise-description',
  templateUrl: './exercise-description.component.html',
  styleUrls: ['./exercise-description.component.css']
})
export class ExerciseDescriptionComponent  {
  @Input() exercise: Exercise | null = null;
  @Output() close = new EventEmitter<boolean>();
  public helpMessageEnum = HintMessages;

  constructor(
    private router: Router
  ) { }

  public closeDescription(): void {
    this.close.emit(true);
  }
}
