import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/user.module';
import { Auth } from '@aws-amplify/auth';
import { AmplifyErrorMessage, GetPasswordErrors } from 'src/app/registration/registration.component';
import { LambdasService } from 'src/app/lambads.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormValidatorsService } from 'src/app/form-validators.service';
import { finalize } from 'rxjs/operators';
import { S3Service } from 'src/app/s3.service';
import { GetCountryByCode, GetCountryCode } from 'src/app/shared/service/countries';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['../../styles/settings.scss']
})
export class ProfileComponent implements OnInit {
  public user: User;

  public isUserDataLoading = false;
  public isUserDataUpdating = false;
  public isPasswordUpdating = false;

  public isVerifyCodeSent = false;
  public emailVerifyError: string | null = null;

  public passwordErrorHandler = GetPasswordErrors;

  public userFormGroup: FormGroup;
  public passwordFormGroup: FormGroup;
  public emailFormGroup: FormGroup;

  private selectedAvatarFile: File | undefined;

  constructor(
    private _auth: AuthService,
    private messageService: MessageService,
    private _validatorService: FormValidatorsService,
    private lambdas: LambdasService,
    private confirmationService: ConfirmationService,
    private s3Service: S3Service
  ) {

    this.user = this._auth.user;
    this.userFormGroup = new FormGroup({
      firstName: _validatorService.GetEmptyFormControl(),
      lastName: _validatorService.GetEmptyFormControl(),
      phoneNumber: _validatorService.GetEmptyFormControl(),
      location: _validatorService.GetEmptyFormControl(),
    });

    this.passwordFormGroup = new FormGroup({
      oldPassword: _validatorService.GetPasswordFormControl(),
      newPassword: _validatorService.GetPasswordFormControl(),
    });

    this.emailFormGroup = new FormGroup({
      verificationCode: _validatorService.GetRequiredFormControl()
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getUserData();
    this.isUserDataLoading = true;
  }

  public async sendVerificationCode(): Promise<void> {
    try {
      await Auth.verifyCurrentUserAttribute('email');
    } catch (error) {
      console.error('Error while sending verification code: ', error);
      return;
    }
    this.isVerifyCodeSent = true;
  }

  public async verifyEmail(): Promise<void> {
    if (this.emailFormGroup.invalid) {
      this.emailFormGroup.markAllAsTouched();
      return;
    }

    const code = this.emailFormGroup.controls.verificationCode.value as string;
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', code);
    } catch (error) {
      const err = error as AmplifyErrorMessage;
      this.emailVerifyError = err.message;
      return;
    }
    await this._auth.refreshUser(true);
  }

  public async changePassword(): Promise<void> {
    try {
      this.isPasswordUpdating = true;
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        this.passwordFormGroup.controls.oldPassword.value,
        this.passwordFormGroup.controls.newPassword.value);
    } catch (err) {
      const error = err as Error;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
    } finally {
      this.isPasswordUpdating = false;
    }
  }

  public async updateUserData(): Promise<void> {
    this.isUserDataUpdating = true;
    let url: string | undefined;

    if (this.selectedAvatarFile) {
      try {
        url = await this.s3Service.saveUserAvatar(this.user.email, this.selectedAvatarFile);
      } catch {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update the image. Please try again later' });
        this.isUserDataUpdating = false;
      }
    }

    const countryCode = GetCountryCode(this.userFormGroup.value.location);
    const sub = this.lambdas.userDetailsSync(
      this.userFormGroup.value.firstName,
      this.userFormGroup.value.lastName,
      this.userFormGroup.value.phoneNumber,
      countryCode,
      url,
    ).pipe(
      finalize(() => {
        this.isUserDataUpdating = false;
        sub?.unsubscribe();
      }))
      .subscribe({
        error: (error) => this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.message
        }),
        complete: () => this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Your data has been successfully updated.'
        }),
        next: async () => {
          this.user.given_name = this.userFormGroup.value.firstName;
          this.user.family_name = this.userFormGroup.value.lastName;
          this.user.phoneNumber = this.userFormGroup.value.phoneNumber;
          if (url)
            this.user.picture.originalURL = url;
        },
      });
  }

  private async getUserData(): Promise<void> {
    try {
      const country = GetCountryByCode(this.user.countryCode);
      const userControls = this.userFormGroup.controls;
      this.setFormControlValue(userControls, 'firstName', this.user.given_name);
      this.setFormControlValue(userControls, 'lastName', this.user.family_name);
      this.setFormControlValue(userControls, 'phoneNumber', this.user.phoneNumber);
      this.setFormControlValue(userControls, 'location', country.name);
    } catch (err) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to get user data! Please try again later' });
    }
  }

  private async setFormControlValue(controls: { [key: string]: AbstractControl }, controlName: string, value: string | boolean | null): Promise<void> {
    controls[controlName].setValue(value);
  }

  public async deleteAccount(): Promise<void> {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete your account? Your data will be deleted in compliance with GDPR regulations. If you are ready to proceed, click \'Delete Account\'. Otherwise, select \'Cancel\'.',
      header: 'Delete account?',
      accept: async () => {
        try {
          await this.lambdas.deleteAccount().toPromise();
          await this._auth.signOut();
        } catch (error) {
          const err = error as Error;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: err.message });
        }
      },
      reject: () => {
        return;
      }
    });
  }

  public onAvatarSelect(file: File): void {
    this.selectedAvatarFile = file;
  }
}