import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { MenuModule } from 'primeng/menu';
import { MarkdownPipe } from './service/markdown';
import { HumanaizeMinutes, HumanaizeSeconds } from './service/time';
import { ExerciseButtonComponent } from './components/exercise-button/exercise-button.component';
import { SimpleButtonComponent } from './components/simple-button/simple-button.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlPipe } from './pipes/form-control.pipe';
import { LoadingAnimationComponent } from './components/loading-animation/loading-animation.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CheckboxModule } from 'primeng/checkbox';
import { AvatarUploaderComponent } from './components/avatar-uploader/avatar-uploader.component';
import { CountriesDropdownComponent } from './components/countries-dropdown/countries-dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RoleTogglerComponent } from './components/role-toggler/role-toggler.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [
    HeaderComponent,
    MarkdownPipe,
    HumanaizeMinutes,
    HumanaizeSeconds,
    ExerciseButtonComponent,
    SimpleButtonComponent,
    InputFieldComponent,
    FormControlPipe,
    LoadingAnimationComponent,
    CheckboxComponent,
    AvatarUploaderComponent,
    CountriesDropdownComponent,
    FooterComponent,
    RoleTogglerComponent
  ],
  imports: [
    CommonModule,
    AvatarModule,
    AvatarGroupModule,
    MenuModule,
    InputTextModule,
    ReactiveFormsModule,
    CheckboxModule,
    FormsModule,
    NgSelectModule,
    InputSwitchModule,
    ConfirmDialogModule
  ],
  exports: [
    HeaderComponent,
    MarkdownPipe,
    HumanaizeMinutes,
    HumanaizeSeconds,
    ExerciseButtonComponent,
    SimpleButtonComponent,
    InputFieldComponent,
    FormControlPipe,
    LoadingAnimationComponent,
    CheckboxComponent,
    AvatarUploaderComponent,
    CountriesDropdownComponent,
    FooterComponent,
    RoleTogglerComponent
  ]
})
export class SharedModule { }
