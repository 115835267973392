import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Response } from '../exercise.component';
import ReconnectingWebSocket from 'reconnecting-websocket';

export class WebsocketService {
  private ws: ReconnectingWebSocket | null = null;
  private messagesSubject = new Subject<Response>();
  public messages$ = this.messagesSubject.asObservable();
  private wsOptions = {
    websocket: WebSocket,
    connectionTimeout: 1000,
    maxRetries: 10,
    maxReconnectionDelay: 1000,
  };

  public async connect(assignedPlanID: string, exerciseID: string, urlID: string): Promise<void> {
    this.ws = new ReconnectingWebSocket(
      `${environment.WebSocketEndpoint}?plan_id=${assignedPlanID}&exercise_id=${exerciseID}&url_id=${urlID}`,
      undefined, 
      this.wsOptions
    );

    this.ws.onclose = (() => {
      console.log('Socket disconnected');
    });

    this.ws.onmessage = (event) => {
      const response: Response = JSON.parse(event.data);
      this.messagesSubject.next(response);
    };

    await this.waitConnection();
  }

  private async waitConnection(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.ws!.onopen = () => {
        console.log('Socked connected');
        resolve();
      };

      this.ws!.onerror = () => {
        if (this.ws!.retryCount === this.wsOptions.maxRetries) {
          reject();
          this.disconnect();
        } else {
          this.disconnect();
          console.log('Socket error');
        }
      };
    });
  }

  getConnection(): ReconnectingWebSocket {
    if (this.isReady()) {
      return this.ws!;
    } else {
      throw new Error('Websocket connection not established or closed');
    }
  }

  isReady(): boolean {
    return !!this.ws && this.ws.readyState === 1;
  }

  sendMessage(msg: string): void {
    if (this.isReady()) {
      this.ws!.send(msg);
    }
  }

  disconnect(): void {
    if (this.isReady()) {
      this.ws!.close(1000, 'Normal Closure');
    }
  }
}
