import { AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, HostListener, Input, IterableDiffer, IterableDiffers, OnDestroy, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { NgStyle } from '@angular/common';
import { CdkPortal } from '@angular/cdk/portal';
import { UserPublicProfile } from '../models/user-public-profile.model';
import { DialogService } from 'primeng/dynamicdialog';
import { InviteDialogComponent } from './components/invite-dialog/invite-dialog.component';
import { PopoverComponent } from './components/popover/popover.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  animations: [
    trigger('scale', [
      transition('void => *', []),
      transition('* => void', []),
      transition('* => *', [
        style({ transform: 'scale(1)' }),
        animate('150ms ease-out', style({ transform: 'scale(0.9)' })),
        animate('150ms ease-out', style({ transform: 'scale(1)' }))
      ])
    ])
  ]
})

export class UserListComponent implements OnDestroy, DoCheck, AfterViewInit {
  @Input() userType: inviteType = 'member';
  @Input() users: UserPublicProfile[] = [];
  @Input() emptyListMessage = '';
  @Input() fadeColor = '256,256,256';

  @Output() userAdded = new EventEmitter<UserPublicProfile>();
  @Output() userDeleted = new EventEmitter<UserPublicProfile>();

  @ViewChildren(CdkPortal) templatePortals!: QueryList<CdkPortal>;
  @ViewChild('userList', { static: true }) userList!: ElementRef;
  @ViewChild('leftButton', { static: true }) leftButton!: ElementRef;
  @ViewChild('rightButton', { static: true }) rightButton!: ElementRef;
  @ViewChild('popoverRef') template!: PopoverComponent;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.updateContainer();
  }

  private readonly longScrollWidth: number = 360;
  private readonly shortScrollWidth: number = 240;

  public fadeRightEdge = false;
  public fadeLeftEdge = false;

  public animationRightButtonTrigger = false;
  public animationLeftButtonTrigger = false;

  public isDeleteDialogOpen = false;

  public currentUser: UserPublicProfile | null = null;

  public userOverlayWidth = 300;
  public userOverlayHeight = 150;

  private divWidth = 0;
  private userListWidth = 0;
  private scrollLeftWidth = 0;

  private scrollTimeout: NodeJS.Timeout | null = null;
  private popoverTimeout: NodeJS.Timeout | null = null;

  private iterableDiffer: IterableDiffer<UserPublicProfile>;

  public isPopoverOpen = false;
  public selectedUser: Element | null = null;

  public defaultUserPicture: string = environment.DefaultMemberPicture;

  constructor(
    private iterableDiffers: IterableDiffers,
    public dialogService: DialogService
  ) {
    this.iterableDiffer = this.iterableDiffers.find([]).create();
  }

  ngOnDestroy(): void {
    this.clearScrollTimeout();
  }

  ngDoCheck() {
    const changes = this.iterableDiffer.diff(this.users);
    if (changes) {
      this.updateContainer();
    }
  }

  ngAfterViewInit(): void {
    Promise.resolve().then(() => {
      this.updateContainer();
    });
  }

  public openPopover(userElement: HTMLElement, user: UserPublicProfile): void {
    this.clearPopoverTimeout();
    this.selectedUser = userElement;
    this.currentUser = user;
    this.isPopoverOpen = true;
  }

  private updateContainer(): void {
    requestAnimationFrame(() => {
      this.getComponentSize();
      this.updateFadeAndButtonVisibility();
    });
  }

  public async closePopover(): Promise<void> {
    this.popoverTimeout = setTimeout(() => {
      if (!this.isHovered(this.template.popoverContainer.nativeElement)) {
        this.isPopoverOpen = false;
        this.isDeleteDialogOpen = false;
      }
    }, 200);
  }

  private closePopoverWithoutTimeout(): void {
    this.isPopoverOpen = false;
  }

  private isHovered(element: HTMLElement): boolean {
    return element.parentNode?.querySelector(':hover') === element;
  }

  private clearPopoverTimeout(): void {
    if (this.popoverTimeout) {
      clearTimeout(this.popoverTimeout);
    }
  }

  private getComponentSize(): void {
    this.userListWidth = this.userList.nativeElement.scrollWidth;
    this.divWidth = this.userList.nativeElement.clientWidth;
  }

  public updateFadeAndButtonVisibility(): void {
    this.scrollLeftWidth = this.userList.nativeElement.scrollLeft;
    const isArrayEmpty = this.users.length === 0;
    const isUserListWidthEqual = this.userListWidth === this.divWidth;
    const isScrollLeftWidthZero = this.scrollLeftWidth === 0;
    const isScrollLeftWidthEqual = Math.floor(this.scrollLeftWidth) === this.userListWidth - this.divWidth || Math.round(this.scrollLeftWidth) === this.userListWidth - this.divWidth;

    this.leftButton.nativeElement.style.visibility = isScrollLeftWidthZero || isArrayEmpty ? 'hidden' : 'visible';
    this.rightButton.nativeElement.style.visibility = isUserListWidthEqual || isScrollLeftWidthEqual || isArrayEmpty ? 'hidden' : 'visible';
    this.fadeLeftEdge = !isScrollLeftWidthZero;
    this.fadeRightEdge = !(isUserListWidthEqual || isScrollLeftWidthEqual);
  }

  public onScroll(event: WheelEvent): void {
    event.preventDefault();

    if (this.scrollTimeout) {
      return;
    }

    if (event.deltaY > 0) {
      this.scrollLeft();
    } else {
      this.scrollRight();
    }

    this.scrollTimeout = setTimeout(() => {
      this.scrollTimeout = null;
    }, 500);
  }

  public triggerRightScrollButton(): void {
    this.triggerRightButtonAnimation();
    this.scrollRight();
  }

  public triggerLeftScrollButton(): void {
    this.triggerLeftButtonAnimation();
    this.scrollLeft();
  }

  private scrollRight(): void {
    const { scrollLeft, scrollWidth, clientWidth } = this.userList.nativeElement;

    if (scrollWidth - clientWidth < scrollLeft + this.longScrollWidth) {
      this.userList.nativeElement.scrollLeft += this.longScrollWidth;
    } else {
      this.userList.nativeElement.scrollLeft += this.shortScrollWidth;
    }
  }

  private scrollLeft(): void {
    const scrollLeft = this.userList.nativeElement.scrollLeft;

    if (scrollLeft < this.longScrollWidth) {
      this.userList.nativeElement.scrollLeft -= this.longScrollWidth;
    } else {
      this.userList.nativeElement.scrollLeft -= this.shortScrollWidth;
    }
  }

  private clearScrollTimeout(): void {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
  }

  private triggerLeftButtonAnimation(): void {
    this.animationLeftButtonTrigger = !this.animationLeftButtonTrigger;
  }

  private triggerRightButtonAnimation(): void {
    this.animationRightButtonTrigger = !this.animationRightButtonTrigger;
  }

  public toggleDeleteDialog(): void {
    this.isDeleteDialogOpen = !this.isDeleteDialogOpen;
  }

  public addUser(): void {
    const ref = this.dialogService.open(InviteDialogComponent, {
      data: {
        inviteType: this.userType
      },
      showHeader: false,
      width: '500px',
      contentStyle: { 'border-radius': '10px', 'padding-top': '2rem' },
      baseZIndex: 10000
    });

    ref.onClose.subscribe((user: UserPublicProfile) => {
      if (user) {
        this.userAdded.emit(user);
      }
    });
  }

  public deleteUser(): void {
    if (!this.currentUser)
      return;

    this.closePopoverWithoutTimeout();
    this.userDeleted.emit(this.currentUser);
    this.currentUser = null;
  }

  get rightSideFadeStyle(): NgStyle['ngStyle'] {
    const styleObject: NgStyle['ngStyle'] = {};
    if (this.fadeRightEdge) {
      styleObject.background = `linear-gradient(to left, rgb(${this.fadeColor}), rgba(${this.fadeColor}, 0.01))`;
      styleObject.right = '0';
    }
    return styleObject;
  }

  get leftSideFadeStyle(): NgStyle['ngStyle'] {
    const styleObject: NgStyle['ngStyle'] = {};
    if (this.fadeLeftEdge) {
      styleObject.background = `linear-gradient(to right, rgb(${this.fadeColor}), rgba(${this.fadeColor}, 0.01))`;
      styleObject.left = '0';
    }
    return styleObject;
  }
}

export type inviteType = 'connection' | 'member'