<div class="wrapper">
    <div class="title">Delete Plan</div>
    <div class="delete-container" 
        *ngIf="!isDeleteButtonClicked">
        <span class="text">
            When you delete a plan, it will be removed from the list. However, plans that have already been assigned to
            users will still be visible.
        </span>
        <button  
            class="delete-button" 
            (click)="isDeleteButtonClicked = true">
            <i class="material-icons-outlined">delete_outline</i>
            <span>Delete</span>
        </button>
    </div>

    <div class="delete-container"  
        *ngIf="isDeleteButtonClicked">
        <span class="text">
            Are you sure you want to delete the plan?
        </span>
        <div class="button-container">
            <i class="material-icons-outlined" (click)="delete();">check</i>
            <i class="material-icons-outlined" (click)="isDeleteButtonClicked = false">
                close
            </i>
        </div>
    </div>
</div>