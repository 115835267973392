<app-header></app-header>
<div class="terms">
    <h1>
        TERMS AND CONDITIONS
    </h1>

    <h2>
        TABLE OF CONTENTS
    </h2>
    <h3><a class="toc-link" href="/terms#p1">1. AGREEMENT TO TERMS</a></h3>
    <h3><a class="toc-link" href="/terms#p2">2. INTELLECTUAL PROPERTY RIGHTS</a></h3>
    <h3><a class="toc-link" href="/terms#p3">3. USER REPRESENTATIONS</a></h3>
    <h3><a class="toc-link" href="/terms#p4">4. USER REGISTRATION</a></h3>
    <h3><a class="toc-link" href="/terms#p5">5. TERMS OF PAYMENT AND REFUND POLICY</a></h3>
    <h3><a class="toc-link" href="/terms#p6">6. PROHIBITED ACTIVITIES</a></h3>
    <h3><a class="toc-link" href="/terms#p7">7. CONTRIBUTION LICENSE</a></h3>
    <h3><a class="toc-link" href="/terms#p8">8. SITE MANAGEMENT</a></h3>
    <h3><a class="toc-link" href="/terms#p9">9. PRIVACY POLICY</a></h3>
    <h3><a class="toc-link" href="/terms#p10">10. TERM AND TERMINATION</a></h3>
    <h3><a class="toc-link" href="/terms#p11">11. MODIFICATIONS AND INTERRUPTIONS</a></h3>
    <h3><a class="toc-link" href="/terms#p12">12. TERMINATION</a></h3>
    <h3><a class="toc-link" href="/terms#p13">13. GOVERNING LAW</a></h3>
    <h3><a class="toc-link" href="/terms#p14">14. DISPUTE RESOLUTION</a></h3>
    <h3><a class="toc-link" href="/terms#p15">15. CORRECTIONS</a></h3>
    <h3><a class="toc-link" href="/terms#p16">16. DISCLAIMER</a></h3>
    <h3><a class="toc-link" href="/terms#p17">17. LIMITATIONS OF LIABILITY</a></h3>
    <h3><a class="toc-link" href="/terms#p18">18. INDEMNIFICATION</a></h3>
    <h3><a class="toc-link" href="/terms#p19">19. USER DATA</a></h3>
    <h3><a class="toc-link" href="/terms#p20">20. ELECTRONIC COMMUNICATIONS</a></h3>
    <h3><a class="toc-link" href="/terms#p21">21. MISCELLANEOUS</a></h3>
    <h3><a class="toc-link" href="/terms#p22">22. CONTACT US</a></h3>

    <h3 id="p1">1. AGREEMENT TO TERMS</h3>
    <p>These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an
        entity (“you”) and Sabiedrība ar ierobežotu atbildību "Dev Skill", doing business as SIA "Dev Skill" ("SIA "Dev
        Skill"," “we," “us," or “our”), concerning your access to and use of the https://platform.dev-skill.com website
        as well as any other media form, media channel, mobile website or mobile application related, linked, or
        otherwise connected thereto (collectively, the “Site”). We are registered in Latvia and have our registered
        office at Bruņinieku iela 93C, LV-1009 . Our VAT number is LV40203283422. You agree that by accessing the Site,
        you have read, understood, and agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF
        THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE
        IMMEDIATELY. </p>
    <p>Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby
        expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or
        modifications to these Terms of Use from time to time. We will alert you about any changes by updating the “Last
        updated” date of these Terms of Use, and you waive any right to receive specific individual notice of each such
        change. Please ensure that you check the applicable Terms every time you use our Site so that you understand
        which Terms apply. You will be subject to, and will be deemed to have been made aware of and to have accepted,
        the changes in any revised Terms of Use by your continued use of the Site after the date such revised Terms of
        Use are posted. </p>
    <p>The information provided on the Site is not intended for distribution to or use by any person or entity in any
        jurisdiction or country where such distribution or use would be contrary to law or regulation or which would
        subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who
        choose to access the Site from other locations do so on their own initiative and are solely responsible for
        compliance with local laws, if and to the extent local laws are applicable.</p>
    <p>By registering for the Site you obtain a non-exclusive, non-sublicensable, non-transferable right to access and
        use the Site subject to these Terms of Use.</p>
    <p>The Site is intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to
        use or register for the Site.</p>


    <h3 id="p2">2. INTELLECTUAL PROPERTY RIGHTS</h3>
    <p>Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality,
        software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the
        “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled
        by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual
        property rights and unfair competition laws of the EU and international copyright laws, and international
        conventions. The Content and the Marks are provided on the Site “AS IS” for your information and personal use
        only. Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be
        copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated,
        transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without
        our express prior written permission.</p>
    <p>Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and
        to download or print a copy of any portion of the Content to which you have properly gained access solely for
        your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the
        Content and the Marks.</p>


    <h3 id="p3">3. USER REPRESENTATIONS</h3>
    <p>By using the Site, you represent and warrant that: (1) all registration information you submit will be true,
        accurate, current, and complete; ( 2) you will maintain the accuracy of such information and promptly update
        such registration information as necessary; (3) you have the legal capacity and you agree to comply with these
        Terms of Use; (4) you are not a minor in the jurisdiction in which you reside; ( 5) you will not access the Site
        through automated or non-human means, whether through a bot, script, or otherwise; (6 ) you will not use the
        Site for any illegal or unauthorized purpose; and (7) your use of the Site will not violate any applicable law
        or regulation; (8) you agree to these Terms of Use, Privacy Policy and Price list.</p>
    <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
        suspend or terminate your account and refuse any and all current or future use of the Site (or any portion
        thereof). </p>


    <h3 id="p4">4. USER REGISTRATION</h3>
    <p>You may be required to register with the Site. You agree to keep your password confidential and will be
        responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a
        username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or
        otherwise objectionable.</p>


    <h3 id="p5">5. TERMS OF PAYMENT AND REFUND POLICY</h3>
    <p>
        For use of the Site, the User shall pay us the fees indicated in the Price List and valid at the time of usage
        of the Site.
    </p>
    <p>
        We keep the right to unilaterally set and change the Price List, and other conditions, making them public on the
        Site. These changes shall come into effect after they are made public on the Site. The User shall check the
        prices of the Site applicable to each usage of the Site each time before he uses the Site.
    </p>
    <p>
        Payments reflecting fees for a particular use of the Site will be billed and deducted from Your authorized
        credit card. By providing credit card data, User agrees that we are authorized to charge applicable fees when
        due and any other charges User may incur in connection with User’s use of the Site.
        The card user authorizes the merchant to save card data and make future transactions without his presence.
    </p>
    <p>
        User agrees to provide us with current, accurate and complete information (which may include User’s credit card
        number, the expiration date of User’s credit card, billing address) and to maintain and properly update such
        information. User further agrees that, in providing such information, User will not knowingly omit or
        misrepresent any material facts or information and that User will promptly enter corrected or updated
        information via the Site, or otherwise advise us promptly in writing of any such changes or updates. User
        further consents and authorizes us to verify information provided as required to grant User the right of use of
        and access to the Site.
    </p>
    <p>
        For the purposes of calculation of the fee for the usage of the Site, the Usage Period shall start to run from
        Task Run and shall run until the Task is completed or the timer is up. The fee shall not be applicable for
        visiting any other parts of the Site.
    </p>
    <p>
        We shall have the right, acting at its own discretion, to assign to the User a limit of maximum time for use of
        any of the Tests and maximum debt for use of the Site. We shall have the exclusive right to change, cancel,
        reduce or increase these limits.
    </p>
    <p>
        We offer a full money-back guarantee for all payments made on our website. If you are not
        satisfied with the service that you have purchased from us, you can get your money back no
        questions asked. You are eligible for a full reimbursement within 14 calendar days of your
        payment.
    </p>
    <p>
        After the 14-day period you will no longer be eligible and won't be able to receive a refund.
        If you have any additional questions or would like to request a refund, feel free to contact us
        on billing@dev-skill.com
    </p>
    <h3 id="p6">6. PROHIBITED ACTIVITIES </h3>
    <p>
        You may not access or use the Site for any purpose other than that for which we make the Site available. The
        Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or
        approved by us.
    </p>
    <p>
        As a user of the Site, you agree not to: Systematically retrieve data or other content from the Site to create
        or compile, directly or indirectly, a collection, compilation, database, or directory without written permission
        from us. Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account
        information such as user passwords. Circumvent, disable, or otherwise interfere with security-related features
        of the Site, including features that prevent or restrict the use or copying of any Content or enforce
        limitations on the use of the Site and/or the Content contained therein. Disparage, tarnish, or otherwise harm,
        in our opinion, us and/or the Site. Use any information obtained from the Site in order to harass, abuse, or
        harm another person. Make improper use of our support services or submit false reports of abuse or misconduct.
        Use the Site in a manner inconsistent with any applicable laws or regulations. Engage in unauthorized framing of
        or linking to the Site. Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or
        other material, including excessive use of capital letters and spamming (continuous posting of repetitive text),
        that interferes with any party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts,
        alters, or interferes with the use, features, functions, operation, or maintenance of the Site. Engage in any
        automated use of the system, such as using scripts to send comments or messages, or using any data mining,
        robots, or similar data gathering and extraction tools. Delete the copyright or other proprietary rights notice
        from any Content. Attempt to impersonate another user or person or use the username of another user. Upload or
        transmit (or attempt to upload or to transmit) any material that acts as a passive or active information
        collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”),
        1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive
        collection mechanisms” or “pcms”). Interfere with, disrupt, or create an undue burden on the Site or the
        networks or services connected to the Site. Harass, annoy, intimidate, or threaten any of our employees or
        agents engaged in providing any portion of the Site to you. Attempt to bypass any measures of the Site designed
        to prevent or restrict access to the Site, or any portion of the Site. Copy or adapt the Site’s software,
        including but not limited to Flash, PHP, HTML, JavaScript, or other code. Except as permitted by applicable law,
        decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a
        part of the Site. Except as may be the result of standard search engine or Internet browser usage, use, launch,
        develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility,
        scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other
        software. Use a buying agent or purchasing agent to make purchases on the Site. Make any unauthorized use of the
        Site, including collecting usernames and/or email addresses of users by electronic or other means for the
        purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses. Use
        the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any
        revenue-generating endeavor or commercial enterprise. Use the Site to advertise or offer to sell goods and
        services. Sell or otherwise transfer your profile.
    </p>

    <h3 id="p7">7. CONTRIBUTION LICENSE</h3>
    <p>You and the Site agree that we may access, store, process, and use any information and personal data that you
        provide following the terms of the Privacy Policy and your choices (including settings).</p>
    <p>By submitting suggestions or other feedback regarding the Site, you agree that we can use and share such feedback
        for any purpose without compensation to you. </p>

    <h3 id="p8">8. SITE MANAGEMENT </h3>
    <p>We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Use; (2)
        take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of
        Use, including without limitation, reporting such user to law enforcement authorities; (3) in our sole
        discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the
        extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion
        and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content
        that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Site in a
        manner designed to protect our rights and property and to facilitate the proper functioning of the Site. </p>


    <h3 id="p9">9. PRIVACY POLICY</h3>
    <p>We care about data privacy and security. By using the Site, you agree to be bound by our <a
            href="/policy">Privacy Policy</a> posted on
        the Site, which is incorporated into these Terms of Use. Please be advised the Site is hosted in Ireland. If you
        access the Site from any other region of the world with laws or other requirements governing personal data
        collection, use, or disclosure that differ from applicable laws in Ireland, then through your continued use of
        the Site, you are transferring your data to Ireland, and you agree to have your data transferred to and
        processed in Ireland. </p>


    <h3 id="p10">10. TERM AND TERMINATION</h3>
    <p>These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER
        PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
        LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
        REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT
        CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
        PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME,
        WITHOUT WARNING, IN OUR SOLE DISCRETION. </p>
    <p>
        If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new
        account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on
        behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take
        appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
    </p>


    <h3 id="p11">11. MODIFICATIONS AND INTERRUPTIONS</h3>
    <p>We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our
        sole discretion without notice. However, we have no obligation to update any information on our Site. We also
        reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not be
        liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.
    </p>
    <p>We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other
        problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We
        reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or
        for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or
        inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the
        Site. Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or to
        supply any corrections, updates, or releases in connection therewith. </p>


    <h3 id="p12">12. TERMINATION</h3>
    <p>We reserve the right terminate this Agreement at any time, in case of Your breach of this Agreement, violation of
        applicable law. We are entitled to terminate this Agreement at any time by providing the notice to the User.
    </p>
    <p>Upon any termination of this Agreement, any amounts owed to us which accrued prior to such termination will
        become immediately due and payable.
    </p>
    <p>
        The User shall have the right to terminate the Agreement at any time for any reasons and require deactivation of
        his account at the Site, having served a written notification about that to us. We, having received the User's
        notification about the termination of the Agreement, shall terminate the Agreement and deactivate User’s account
        no later than within 10 (ten) days. Termination of the Agreement shall not release the User from performance of
        obligations having arisen before the termination.
    </p>


    <h3 id="p13">13. GOVERNING LAW</h3>
    <p>These conditions are governed by and interpreted following the laws of Latvia, and the use of the United Nations
        Convention of Contracts for the International Sale of Goods is expressly excluded. </p>


    <h3 id="p14">14. DISPUTE RESOLUTION</h3>
    <p>Informal Negotiations</p>

    <p>To expedite resolution and control the cost of any dispute, controversy, or claim related to these Terms of Use
        (each "Dispute" and collectively, the “Disputes”) brought by either you or us (individually, a “Party” and
        collectively, the “Parties”), the Parties agree to first attempt to negotiate any Dispute (except those Disputes
        expressly provided below) informally for at least thirty (30) days before initiating arbitration. Such informal
        negotiations commence upon written notice from one Party to the other Party.
    </p>
    <p>
        If any complaint with us is not satisfactorily resolved and you are a consumer within the meaning of the
        governing law you can contact the Consumer Out-of-Court Dispute Resolution Commission (Patērētāju ārpustiesas
        strīdu risināšanas komisija) in writing at Rigi, Brīvības street 55, LV-1010, by e-mail at: pasts@ptac.gov.lv
        pasts@ptac.gov.lv or by telephone at +371 65452554. Find more information at the <a
            href="http://www.ptac.gov.lv/lv/content/pateretaju-stridu-risinasanas-komisija-0">website</a>
    </p>
    <p>Binding Arbitration</p>
    <p>Any dispute arising from the relationships between the Parties to this contract shall be determined by the courts
        of Latvia. </p>

    <h3 id="p15">15. CORRECTIONS</h3>
    <p>There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including
        descriptions, pricing, availability, and various other information. We reserve the right to correct any errors,
        inaccuracies, or omissions and to change or update the information on the Site at any time, without prior
        notice.</p>


    <h3 id="p16">16. DISCLAIMER</h3>
    <p>THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES
        WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
        IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED
        TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
        CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
        ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
        PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
        TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO
        OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
        ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE
        MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
        SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR
        MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
        RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS
        WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
        JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. </p>


    <h3 id="p17">17. LIMITATIONS OF LIABILITY</h3>
    <p>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
        INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST
        REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR
        ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID,
        IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING.</p>


    <h3 id="p18">18. INDEMNIFICATION</h3>
    <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our
        respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or
        demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1)
        use of the Site; (2) breach of these Terms of Use; (3) any breach of your representations and warranties set
        forth in these Terms of Use; (4) your violation of the rights of a third party, including but not limited to
        intellectual property rights; or (5) any overt harmful act toward any other user of the Site with whom you
        connected via the Site. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the
        exclusive defense and control of any matter for which you are required to indemnify us, and you agree to
        cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any
        such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it. </p>


    <h3 id="p19">19. USER DATA</h3>
    <p>We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the
        Site, as well as data relating to your use of the Site. Although we perform regular routine backups of data, you
        are solely responsible for all data that you transmit or that relates to any activity you have undertaken using
        the Site. You agree that we shall have no liability to you for any loss or corruption of any such data, and you
        hereby waive any right of action against us arising from any such loss or corruption of such data.</p>


    <h3 id="p20">20. ELECTRONIC COMMUNICATIONS</h3>
    <p>Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You
        consent
        to receive electronic communications, and you agree that all agreements, notices, disclosures, and other
        communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that
        such
        communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
        RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
        US
        OR VIA THE SITE. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances,
        or
        other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic
        records, or to payments or the granting of credits by any means other than electronic means.</p>


    <h3 id="p22">21. MISCELLANEOUS</h3>
    <p>These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site
        constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any
        right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These Terms
        of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations
        to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act
        caused by any cause beyond our reasonable control. If any provision or part of a provision of these Terms of Use
        is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed
        severable from these Terms of Use and does not affect the validity and enforceability of any remaining
        provisions. There is no joint venture, partnership, employment or agency relationship created between you and us
        as a result of these Terms of Use or use of the Site. You agree that these Terms of Use will not be construed
        against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the
        electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of
        Use. </p>

    <h3 id="p23">22. CONTACT US</h3>
    <p>In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site,
        please contact us at:</p>

    <div>SIA "Dev Skill"</div>
    <p>Registration nr. 40203283422</p>
    <p>Legal address: Rīga, Turaidas iela 4 - 34, LV-1039</p>
    <div>Email: billing@dev-skill.com</div>
</div>
<app-footer></app-footer>
