<section>
    <div>
        <h4>Finished The Exercise?</h4>
        <p>Click the <strong>"Submit"</strong> button in the bottom panel to start the validation test.</p>
    </div>

    <div>
        <h4>Validation Tests</h4>
        <p>Validation test - is a verification process that ensures your task is completed correctly.</p>
        <p>Not Started: This status indicates that the testing process has not been started yet.</p>
        <p>
            <span style="color: green">Success: </span>
            This status means that the task has successfully passed the testing.
        </p>
        <p>
            <span style="color: red;">Failed: </span>
            This indicates that the task solution is incorrect.
        </p>
        <p>
            <span style="color: red;">Installation Error: </span>
            This status appears if errors occurred during the preparation for testing. If such an
            error occurs, try to run the test again or 
            <span 
              class="cursor-pointer text-blue-500 underline"
              (click)="openSupportDialog()">contact support</span>.
        </p>
    </div>

    <div>
        <h4>Something Going Wrong?</h4>
        <p>
            If you run into any problems with the exercise, such as an installation error or accidentally deleting
            a necessary file, click the <strong>"Restart"</strong> button. This will reset the entire exercise, but please note that 
            all your progress will be lost, although the remaining time will not reset.
        </p>

        <p>
            If you experience any visual glitches, you can refresh the page by pressing <strong>CTRL + R</strong> or clicking
            the <strong>"Exit"</strong> button. This will allow you to continue your exercise without losing your progress.
        </p>
    </div>

    <div>
        <h4>Wanna Take a Break?</h4>
        <p>
            With the <strong>"Exit"</strong> button, you can close the exercise without losing your progress. However, keep in mind that 
            the exercise will continue running in the background, so the timer will keep ticking even after you close it.
        </p>
    </div>

    <div>
        <h4>Can’t Finish the Exercise?</h4>
        <p>
            You can cancel the exercise at any time by clicking the "Cancel" button. Once canceled, the exercise will change the status to
            "Canceled," and you won’t be able to continue working on it.
        </p>
    </div>

    <div>
        <h4>File Editor Shortcut Key</h4>
        <p>Save file: CTRL + S</p>
        <p>Copy selected text: CTRL + C</p>
        <p>Paste selected text: CTRL + V</p>
    </div>

    <div>
        <h4>Terminal Shortcut Key</h4>
        <p>Copy selected text: CTRL + C</p>
        <p>Paste selected text: CTRL + V</p>
    </div>
</section>