<section 
  class="w-100">
    <div 
      class="description" 
      *ngIf="this._data.exercise"
      [innerHTML]="this._data.exercise.description | markdown:dynamicDescription">
    </div>
    <div 
      class="scroller"
      *ngIf="!this._data.exercise"> 
        <p-progressSpinner></p-progressSpinner>
    </div>
</section>
