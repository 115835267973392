import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Attachment } from 'src/app/models/attachments.model';
import { Ticket } from 'src/app/models/ticket.model';

@Component({
  selector: 'app-ticket-dialog',
  templateUrl: './ticket-dialog.component.html',
  styleUrls: ['./ticket-dialog.component.scss'],
})
export class TicketDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('ticketDialog') dialog: ElementRef | undefined;

  public ticket: Ticket;
  public chatHeight: string | undefined;
  public attachmentsWork = true;
  private mutationObserver: MutationObserver | undefined;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService
  ) {
    this.ticket = config.data.openedTicket as Ticket;
  }

  public async ngOnInit(): Promise<void> {
    await this.checkAttachments();
  }

  ngAfterViewInit(): void {
    this.chatHeight = `${this.dialog?.nativeElement.scrollHeight}px`;
    this.cdr.detectChanges();
    this.observeChildListMutation();
  }

  ngOnDestroy(): void {
    this.mutationObserver?.disconnect();
  }

  private observeChildListMutation(): void {
    this.mutationObserver = new MutationObserver(() => {
      this.chatHeight = `${this.dialog?.nativeElement.scrollHeight}px`;
    });
    this.mutationObserver.observe(this.dialog?.nativeElement, { attributes: true, childList: true, subtree: true });
  }

  private async checkAttachments(): Promise<void> {
    try {
      await this.ticket.attachments;
      this.attachmentsWork = true;
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Ticket Attachments Error',
        detail: 'Failed to load ticket attachments',
      });
      this.attachmentsWork = false;
    }
  }

  public async downloadFile(event: Event, attachment: Attachment): Promise<void> {
    event.stopPropagation();
    attachment.download();
  }
}
