<footer class="wrapper">
    <section class="content-1">
        <div class="logo">
            <img class="logo-img" src="../../assets/img/landingPage/logo.png">
            <img class="logo-img2" src="../../assets/img/landingPage/dev-skill.svg">
        </div>
        <div class="email">sales@dev-skill.com</div>
        <div class="copyright">© 2022 dev-skill.</div>
    </section>
    <section class="content-2">
        <a routerLink="/cookies">Cookies</a>
        <a routerLink="/policy">Policy</a>
        <a routerLink="/terms">Terms</a>
        <a routerLink="/contact">Contact us</a>
    </section>
    <section class="content-3">
        <span>Follow us</span>
        <div class="social-icons">
            <a 
              class="linkedin icon"
              href="https://www.linkedin.com/company/dev-skill-sia/">
            </a>    
            <a 
              class="youtube icon"
              href="https://www.youtube.com/channel/UCpCp5APUzq4APqv3Y3_rxbA">
            </a>    
        </div>
    </section>
    <section class="content-4">
        <div class="email">sales@dev-skill.com</div>
        <div class="copyright">© 2022 dev-skill.</div>
    </section>
</footer>
