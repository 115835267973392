<div class="wrapper">
  <h4>Assigned Plans</h4>
  <cdk-virtual-scroll-viewport 
  class="scroll-viewport" 
  itemSize="130"
  *ngIf="assignedPlansData.data.length > 0; else noData">
    <a *ngFor="let plan of assignedPlansData.data"
     (click)="assignedPlansData.selectNewPlan(plan)">
      <div class="assigned-plan-container"
        *ngIf="!plan.delete"
        [style.background]="isSelected(plan) ? 'rgb(240, 240, 240)' : 'white'">
          <img [src]="getUserImage(plan)">
          <ul class="info-list">
              <li *ngIf="plan.candidate.fullName">
                <span class="gray">Candidate name: </span>
                <span>{{plan.candidate.fullName}}</span>
              </li>
              <li *ngIf="!plan.candidate.fullName">
                <span class="gray">Candidate email: </span>
                <span>{{plan.candidate.email}}</span>
              </li>
              <li>
                <span class="gray">Plan Name: </span>
                <span>{{plan.planName}}</span>
              </li>
              <li>
                <span class="gray">Deadline: </span>
                <span>{{plan.deadline | date: 'MMM d, y, HH:mm'}}</span>
              </li>
              <li>
                <span class="gray">Status: </span>
                <span
                  [ngStyle]="{'color': getStatusColor(plan.status!)}">
                    {{plan.status}}
                </span>
              </li>
          </ul>
      </div>
    </a>
  </cdk-virtual-scroll-viewport>
</div>

<ng-template #noData>
  <div class="no-data-container">
    <img src="../../../assets/img/template/not_found.png">
    <span> Plans not found </span>
  </div>
</ng-template>