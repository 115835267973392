<div
  class="flex flex-column w-100 h-100" 
  *ngIf="!isLoaded">
  <app-header></app-header>
  <p-progressSpinner 
    strokeWidth="4"
    class="flex align-items-center justify-content-center h-100">
  </p-progressSpinner>
</div>

<ng-container *ngIf="isLoaded">
  <ng-container *ngIf="user && !status && !paymentState">
    <interviewer-payment 
      *ngIf="user.isInterviewer"
      [assignedPlanId]="assignedPlanId"
      [candidateEmail]="candidateEmail"
      [paymentReference]="paymentReference"
      (initiatePaymentEvent)="initiatePayment($event)">
    </interviewer-payment>
    
    <user-payment 
      *ngIf="user.isUser"
      [assignedPlanId]="assignedPlanId"
      [candidateEmail]="candidateEmail"
      [paymentReference]="paymentReference"
      (initiatePaymentEvent)="initiatePayment($event)">
    </user-payment>
  </ng-container>
  
  <ng-container *ngIf="user && (status || paymentState) ">
    <payment-messages
     [errorMessage]="status?.processing_error?.message || ''"
     [state]="status?.payment_state || paymentState"
     [assignedPlanId]="assignedPlanId"
     [candidateEmail]="candidateEmail"
     (returnEvent)="clearOldData()">
    </payment-messages>
  </ng-container>  
</ng-container>

