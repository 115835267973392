<div class="wrapper">
    <div class="title">Assign the Plan </div>
    <div class="text">
        Select a user from the connections list to assign the plan. If the list is empty, invite users using 
        <a class="redirect" routerLink="/settings/connections">
            settings.
        </a>
    </div>
    <div class="users-input-container mb-2">
        <ng-select 
            #ngSelectComponent 
            class="users-input"
            style="height: 45px;"
            notFoundText="User not found"
            appearance="outline"
            placeholder="Select user"
            [multiple]="false"
            [items]="users" 
            [(ngModel)]="selectedUser" 
            [virtualScroll]="true" 
            [searchFn]="customSearchFn"
            (close)="onDropdownClose()" 
            (focus)="onDropdownFocus()">

            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div class="user-output-container">
                    <img class="user-img" [src]="item.picture.url">
                    <div class="df fd-c w-100">
                        <div class="w-100 df fd-r jc-sb">
                            <div class="user-name">{{item.fullName}}</div>
                            <p-chip 
                              [label]="item.role" 
                              styleClass="text-xs">
                            </p-chip>
                        </div>
                        <div class="truncated-text">{{item.email}}</div>
                    </div>
                </div>
            </ng-template>

            <ng-template ng-label-tmp let-item="item">
                <div class="flex align-items-center mr-2">
                    <img class="label-user-img" [src]="item.picture.url">
                    <span class="truncated-text">{{item.fullNameOrEmail}}</span>
                </div>
            </ng-template>
        </ng-select>
    </div>

    <div class="text">
        Please set a deadline for the plan. After the deadline has passed, the user will no longer be able to perform
        exercises included in the plan
    </div>

    <div 
      class="flex w-100 mb-5"
      style="max-width: 300px; height: 45px;">
        <p-calendar
          styleClass="w-full h-full"
          class="w-full h-full"
          [showTime]="true"
          [formControl]="deadlineControl"
          [minDate]="minDate"
          [firstDayOfWeek]="1"
          appendTo="body">
        </p-calendar>
    </div>

    <simple-button 
      *ngIf="!isAssigning"
      [disabled]="!isInputsValid" 
      (click)="assignPlan()">
        Assign
    </simple-button>

    <app-loading-animation
      *ngIf="isAssigning">
    </app-loading-animation>

    <div *ngIf="isAssigned" 
      class="text">
      The plan has been assigned successfully. To track progress, open
        <a 
          class="redirect" 
          routerLink="/assigned-plans" 
          [queryParams]="{id: assignedPlanId}">
          'Assigned Plans'.
        </a>
    </div>
</div>