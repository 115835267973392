import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { Company } from 'src/app/exercise.module';
import { LambdasService, Response } from 'src/app/lambads.service';
import { PaymentState } from 'src/app/payment/payment.component';
import { MessageService } from 'primeng/api';
import { User } from 'src/app/user.module';
import { RemoveDuplicates } from 'src/app/dashboard/dashboard/util';
import { AssignedPlan } from 'src/app/models/assigned-plan.model';
import { UserPublicProfile } from 'src/app/models/user-public-profile.model';
import moment from 'moment-mini';

@Injectable({
  providedIn: 'root'
})
export class AssignedPlansDataService {
  private user: User;

  private selectEventSubject: BehaviorSubject<AssignedPlan | null> = new BehaviorSubject<AssignedPlan | null>(null);
  public selectEvent: Observable<AssignedPlan | null> = this.selectEventSubject.asObservable();

  private usersEventSubject: BehaviorSubject<UserPublicProfile[] | null> = new BehaviorSubject<UserPublicProfile[] | null>(null);
  public usersEvent: Observable<UserPublicProfile[] | null> = this.usersEventSubject.asObservable();

  private company: Company | undefined;
  private isFetched: Promise<boolean>;
  public data: AssignedPlan[] = [];

  constructor(
    private dynamodb: DynamoDBService,
    private auth: AuthService,
    private lambdas: LambdasService,
    private messageService: MessageService,
  ) {
    this.user = auth.user;
    this.isFetched = this.fetch();
  }

  get selectedPlan(): AssignedPlan | null {
    return this.selectEventSubject.value;
  }

  get companyMembersAndConnections(): UserPublicProfile[] | null {
    return this.usersEventSubject.value;
  }

  public async fetch(): Promise<boolean> {
    try {
      const plans = await this.dynamodb.getAssignedPlans(this.user.companyId);
      if (!plans || plans.length === 0) {
        return true;
      }
      this.data.push(...plans);
      this.company = await this.dynamodb.getCompanyData(this.user.companyId);
      const userAndInterviewerEmail = this.getUserAndInterviewerEmails();
      const paymentState = await this.getPaymentsInformation(this.user.companyId, plans);
      this.prepareAssignedPlans(paymentState);
      const users = await this.dynamodb.getUsersAndCompanyMembers(this.user.companyId, userAndInterviewerEmail);
      this.usersEventSubject.next(users);
      this.selectFirstPlan(plans);
    } catch (err) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch assigned plans, please refresh the page or try again later!' });
    }
    return true;
  }

  public async selectAssignedPlanById(id: string): Promise<void> {
    if (!id)
      return;

    await this.isFetched;
    const plan = this.findPlanById(id);
    this.selectNewPlan(plan);
  }

  public selectNewPlan(plan: AssignedPlan): void {
    if (this.selectEventSubject.value?.id !== plan.id) {
      this.selectEventSubject.next(plan);
    }
  }

  private selectFirstPlan(plans: AssignedPlan[]): void {
    if (plans.length > 0) {
      this.selectEventSubject.next(plans[0]);
    }
  }

  private getUserAndInterviewerEmails(): string[] {
    const emails: string[] = [];
    this.data.forEach(plan => {
      if (plan.interviewer?.email) {
        emails.push(plan.interviewer.email);
      }
      emails.push(plan.candidate.email);
    });
    return RemoveDuplicates(emails);
  }

  public findPlanById(planId: string): AssignedPlan {
    return this.data.filter(p => p.id === planId)[0];
  }

  public deleteAssignedPlan(plan: AssignedPlan): void {
    this.lambdas.cancelAssignedPlan(
      plan.candidate.email,
      plan.id
    ).subscribe({
      next: async () => {
        this.data = this.data.filter(p => p.id !== plan.id);
        this.selectEventSubject.next(this.data.length > 0 ? this.data[0] : null);
      },
      error: (error) => this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message }),
    });
  }

  public async assignPlan(email: string, deadline: string, planId: string): Promise<AssignedPlan | null> {
    let resp: Response;

    try {
      resp = await this.lambdas.saveUserPlan(email, planId, deadline).toPromise();
      if (!resp.assigned_plan_id)
        return null;
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: (err as Error).message
      });
      return null;
    }

    try {
      const plan = await this.dynamodb.getAssignedPlan(email, this.user.companyId, resp.assigned_plan_id);
      this.addAssignedPlanToArray(plan);
      return plan;
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to assign the plan, please refresh the page or try again!'
      });
      return null;
    }
  }

  public addAssignedPlanToArray(plan: AssignedPlan): void {
    this.data.push(plan);
  }

  public removeAssignedPlanFromArray(plan: AssignedPlan): void {
    const index = this.data.findIndex(p => p.id === plan.id);
    this.data.splice(index, 1);
  }

  private prepareAssignedPlans(paymentState: Map<string, boolean>): void {
    this.data.forEach(p => {
      p.onTrialAssign = this.isTrialPlan(p);
      p.isPaid = paymentState.get(`${p.candidate.email}#${p.id}`) ?? false;
    });
  }

  private isTrialPlan(plan: AssignedPlan): boolean {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss.SSSSSSSSS Z [UTC] [m=]Z';
    const parsedTrialEndDate = moment(this.company?.trialEndDate, dateFormat);
    const parsedAssignedDate = moment(plan.assignedDate, dateFormat);
    return parsedAssignedDate.isBefore(parsedTrialEndDate);
  }

  private async getPaymentsInformation(companyId: string, assignedPlans: AssignedPlan[]): Promise<Map<string, boolean>> {
    const paymentStates = new Map<string, boolean>(assignedPlans.map(plan => [`${plan.candidate.email}#${plan.id}`, plan.isPaid]));
    const notPaidPlans = assignedPlans.filter(plan => !plan.isPaid).map(plan => `${plan.candidate.email}#${plan.id}`);
    const paymentsInformation = await this.dynamodb.getPaymentsInformation(companyId, notPaidPlans);
    if (!paymentsInformation) {
      return paymentStates;
    }

    for (const payment of paymentsInformation) {
      if (!payment.paymentReferences) {
        continue;
      }

      for (const reference of payment.paymentReferences) {
        if (!reference.status ||
          reference.status !== PaymentState.WaitingForSCA &&
          reference.status !== PaymentState.SentForProcessing) {
          continue;
        }

        try {
          const response = await this.lambdas.getPaymentStatus(reference.id, payment.assignedPlanID, payment.userEmail).toPromise();
          if (response.payment_state === PaymentState.Settled) {
            paymentStates.set(payment.assignedPlanID, true);
          }
        } catch {
          break;
        }

      }
    }
    return paymentStates;
  }
}
