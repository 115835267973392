<header class="wrapper">
    <div class="header-logo">
        <img class="header-logo-img" 
          src="../../assets/img/landingPage/logo.png" 
          alt="" 
          [routerLink]="user ? '/dashboard' : '/'"
          [queryParams]="queryParams">
        <img class="header-logo-img2" 
          src="../../assets/img/landingPage/dev-skill.svg" 
          alt="" 
          [routerLink]="user ? '/dashboard' : '/'"
          [queryParams]="queryParams">
    </div>

    <ul *ngIf="!isAuthenticated">
        <li><a routerLink="/login" [queryParams]="queryParams">Sign in</a></li>
        <li><a class="button" routerLink="/registration" [queryParams]="queryParams">Sign up</a></li>
    </ul>

    <div *ngIf="isAuthenticated">
      <p-avatar
       shape="circle"
       [image]="user.picture.url"
       (click)="menu.toggle($event)">
      </p-avatar>
      <p-menu #menu [popup]="true" [model]="items"></p-menu>
    </div>

</header>