<p-inputSwitch 
  (onChange)="onRoleChange($event)" 
  [(ngModel)]="checked">
</p-inputSwitch>

<p-confirmDialog 
  #roleCD
  [style]="{width: '30vw'}"
  [breakpoints]="{'1440px': '50vw', '960px': '75vw', '640px': '100vw'}">
    <ng-template pTemplate="footer">
        <simple-button (click)="roleCD.reject()">No</simple-button>
        <simple-button (click)="roleCD.accept()">Yes</simple-button>
    </ng-template>
</p-confirmDialog>