import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExercisePlansDataService } from '../exercise-plans-data-service/exercise-plans-data.service';
import { Subscription, interval } from 'rxjs';
import { GetStatusColor } from 'src/app/shared/service/ui';
import { VirtualScroller } from 'primeng/virtualscroller';
import { AssignedPlan } from 'src/app/models/assigned-plan.model';
import { filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-user-exercise-plans-list',
  templateUrl: './user-exercise-plans-list.component.html',
  styleUrls: ['./user-exercise-plans-list.component.scss'],
})
export class UserExercisePlansListComponent implements OnInit, OnDestroy {
  @ViewChild('scrollViewport') viewport!: VirtualScroller;

  public plans: AssignedPlan[] = Array.from({ length: 10 });
  public plansCount = 0;
  private TimersSubscription: Subscription | null = null;
  private plansSubscription: Subscription | null = null;
  private selectedPlanSubscription: Subscription = new Subscription();

  constructor(
    public exercisePlansData: ExercisePlansDataService,
    private cdr: ChangeDetectorRef,
  ) { }

  async ngOnInit(): Promise<void> {
    this.subToPlans();
    this.subToSelectedPlan();
  }

  ngOnDestroy(): void {
    this.plansSubscription?.unsubscribe();
    this.selectedPlanSubscription?.unsubscribe();
    this.TimersSubscription?.unsubscribe();
  }

  public getStatusColor(statusString: string): string {
    return GetStatusColor(statusString);
  }

  public isSelected(plan: AssignedPlan): boolean {
    return plan.id === this.exercisePlansData.selectedData?.id;
  }

  public selectPlan(plan: AssignedPlan) {
    this.exercisePlansData.setSelectedData(plan);
  }

  private subToSelectedPlan(): void {
    this.selectedPlanSubscription.add(this.exercisePlansData.selectedData$.subscribe(plan => {
      if (plan) {
        this.cdr.detectChanges();
        this.scrollToSelectedPlan();
        this.selectedPlanSubscription.unsubscribe();
      }
    }));
  }

  private subToPlans(): void {
    this.plansSubscription = this.exercisePlansData.data$.pipe(
      filter(plans => plans != null),
      map(plans => {
        this.plans = plans!;
        this.startTimers();
      }),
    ).subscribe();
  }

  private startTimers(): void {
    this.TimersSubscription?.unsubscribe();
    this.TimersSubscription = interval(1000).pipe(
      tap(() => this.plans.forEach(plan => plan.remainingTime > 0 && plan.remainingTime--)),
    ).subscribe();
  }

  private scrollToSelectedPlan(): void {
    if (!this.exercisePlansData.data || !this.exercisePlansData.selectedData) {
      return;
    }
    const index = this.exercisePlansData.data.findIndex(p => this.exercisePlansData.selectedData?.id === p.id);
    this.viewport.scrollToIndex(index, 'smooth');
  }
}
