import { Injectable, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ComponentType } from '@angular/cdk/portal';
import { HelpDialogComponent } from '../dialogs/help-dialog/help-dialog.component';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuDataService implements OnInit {
  public allowedSelections: MenuItem[] = [];
  public otherAllowedSelections: MenuItem[] = [];
  public menuVisibility: { [key: string]: boolean } = {};

  constructor(
    private auth: AuthService,
    private router: Router
  ) {
    this.initializeMenu();
    this.checkRouteAndOpenMenu();
  }

  public ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.checkRouteAndOpenMenu();
    });
  }

  private initializeMenu(): void {
    const role = this.auth.user.role;
    this.allowedSelections = this.filterMenuByRole(this.mainSelections, role);
    this.otherAllowedSelections = this.filterMenuByRole(this.otherSelections, role);
    this.filterSettingsByRole(role);
  }

  private filterMenuByRole(menuItems: MenuItem[], role: string): MenuItem[] {
    return menuItems.filter(item =>
      item.role?.includes(role) || item.support === this.auth.user.isSupport
    );
  }

  private filterSettingsByRole(role: string): void {
    this.allowedSelections.forEach(s => {
      if (s.label !== 'Settings')
        return;

      s.items = this.settingsSelections.filter(item =>
        item.role?.includes(role) || item.support === this.auth.user.isSupport
      );
    });
  }

  private checkRouteAndOpenMenu(): void {
    this.settingsSelections.forEach(s => {
      if (this.router.url.includes(s.url)) {
        this.toggleSubMenu('Settings');
      }
    });
  }

  public toggleSubMenu(menuItem: string): void {
    this.menuVisibility[menuItem] = !this.menuVisibility[menuItem];
  }

  private settingsSelections: MenuItem[] = [
    { icon: 'person_outline', label: 'Profile', url: '/settings/profile', role: ['user', 'interviewer'] },
    { icon: 'work_outline', label: 'Company Profile', url: '/settings/company', role: ['user', 'interviewer'] },
    { icon: 'group_add', label: 'Connections', url: '/settings/connections', role: ['interviewer'] },
  ];

  private mainSelections: MenuItem[] = [
    { icon: 'home', label: 'Dashboard', url: '/dashboard', role: ['user', 'interviewer'] },
    { icon: 'list_alt_outline', label: 'Plans', url: '/plans', role: ['user'] },
    { icon: 'task', label: 'Exercises', url: '/exercises', role: ['user', 'interviewer'] },
    { icon: 'display_settings_outline', label: 'Plans Management', url: '/plans-management', role: ['interviewer'] },
    { icon: 'assignment', label: 'Assigned Plans', url: '/assigned-plans', role: ['interviewer'] },
    { icon: 'history_outline', label: 'Payment History', url: '/payment/history', role: ['user', 'interviewer'] },
    { icon: 'settings', label: 'Settings', url: '/settings', role: ['user', 'interviewer'] },
  ];

  public otherSelections: MenuItem[] = [
    { icon: 'sell', label: 'Price', url: '/prices', role: ['user', 'interviewer'] },
    { icon: 'policy', label: 'Policy', url: '/policy', role: ['user', 'interviewer'] },
    { icon: 'support', label: 'Help', url: '/support', dialogType: HelpDialogComponent, role: ['user', 'interviewer'] },
    { icon: 'admin_panel_settings', label: 'Support', url: '/admin', support: true },
  ];
}

export interface MenuItem {
  icon: string
  label: string
  url: string
  role?: string[]
  support?: boolean
  items?: MenuItem[]
  dialogType?: ComponentType<unknown>
}
