import { environment } from 'src/environments/environment';
import { DynamoDBService } from '../dynamodb.service';
import { ServiceLocator } from '../service-locator.service';
import { GetTimeRemaining, SecondsToHumanReadable } from '../shared/service/time';
import { CountTasksByStatus, TaskStatus } from '../status-parser';
import { UserPublicProfile } from './user-public-profile.model';
import { PaymentInformation } from './payment/payment-information.models';
import { ExerciseStatus } from './exercise-status.model';
import { AuthService } from '../auth/auth.service';

export class AssignedPlan {

  private _dynamodbService: DynamoDBService;
  private _authService: AuthService;

  private _id: string;
  private _companyId: string;
  private _companyName: string | undefined;
  private _planId: string | undefined;
  private _planName: string | undefined;
  private _planDescription: string | undefined;
  private _deadline: string | undefined;
  private _assignedDate: string | undefined;
  private _exerciseStatusesMap: Map<string, string> | undefined;
  private _exerciseStatusesArray: TaskStatus[] | undefined;
  private _exerciseExecutionTime: Map<string, number> | undefined;
  private _pricePerSecond: number;
  private _paymentLink: string | undefined;
  private _exerciseIds: string[] | undefined;
  private _status: PlanStatus | undefined;

  private _userProfile!: UserPublicProfile;
  private _userEmail: string;
  private _userFullName: string | undefined;

  private _interviewerProfile: UserPublicProfile | undefined;
  private _interviewerEmail: string | undefined;
  private _interviewerFullName: string | undefined;

  private _paymentInformation: Promise<PaymentInformation | undefined> | undefined;
  private _totalExecutionTime: string | undefined;
  private _completedTasksCount: number | undefined;
  private _remainingTime: number | undefined;

  private _isRejected: boolean;
  private _isCanceled: boolean;
  private _isWelcomePlan: boolean;
  private _isFinished: boolean;

  private _exerciseStatuses: ExerciseStatus[] | null = [];

  public deletable = true;
  public delete = false;
  public onTrialAssign = false;
  public isPaid = false;

  constructor(
    attributes: AssignedPlanAttributes
  ) {
    this._id = attributes.id;
    this._companyId = attributes.interviewerCompanyID;

    this._planId = attributes.planID;
    this._planName = attributes.planName;
    this._deadline = attributes.deadline;
    this._assignedDate = attributes.assignedDate;
    this._exerciseStatusesMap = attributes.exerciseStatusesMap;
    this._exerciseExecutionTime = attributes.exerciseExecutionTime;
    this._exerciseIds = attributes.exerciseIds;
    this._companyName = attributes.interviewerCompanyName;
    this._planDescription = attributes.planDescription;
    this._pricePerSecond = attributes.pricePerSecond ?? 0;
    this._isRejected = attributes.isRejected ?? false;
    this._isCanceled = attributes.isCanceled ?? false;
    this._isWelcomePlan = attributes.isWelcomePlan ?? false;
    this._isFinished = attributes.isFinished ?? false;
    this.isPaid = attributes.isPaid ?? false;

    this._userEmail = attributes.userEmail;
    this._userFullName = attributes.userFullName;

    this._interviewerEmail = attributes.interviewerEmail;
    this._interviewerFullName = attributes.interviewerFullName;

    this._dynamodbService = ServiceLocator.injector.get(DynamoDBService);
    this._authService = ServiceLocator.injector.get(AuthService);
  }

  public get id(): string {
    return this._id;
  }

  public get interviewerCompanyID(): string {
    return this._companyId;
  }

  public get interviewerCompanyName(): string | undefined {
    return this._companyName;
  }

  public get interviewer(): UserPublicProfile | undefined {
    if (!this._interviewerProfile && this._interviewerEmail) {
      this._interviewerProfile = new UserPublicProfile({
        email: this._interviewerEmail,
        fullName: this._interviewerFullName,
        role: 'interviewer'
      });
    }
    return this._interviewerProfile;
  }

  public get candidate(): UserPublicProfile {
    if (!this._userProfile && this._userEmail) {
      this._userProfile = new UserPublicProfile({
        email: this._userEmail,
        fullName: this._userFullName,
        role: 'user'
      });
    }
    return this._userProfile;
  }

  public get planId(): string | undefined {
    return this._planId;
  }

  public get planName(): string {
    return this._planName ?? 'unknown';
  }

  public get planDescription(): string | undefined {
    return this._planDescription;
  }

  public get deadline(): string | undefined {
    return this._deadline;
  }

  public get assignedDate(): string | undefined {
    return this._assignedDate;
  }

  public get exerciseStatusesArray(): TaskStatus[] | undefined {
    if (!this._exerciseStatusesArray && this._exerciseStatusesMap)
      this._exerciseStatusesArray = Array.from(this._exerciseStatusesMap?.values() ?? []) as TaskStatus[];

    return this._exerciseStatusesArray;
  }

  public get exerciseStatuses(): ExerciseStatus[] | null {
    return this._exerciseStatuses;
  }

  public get exerciseExecutionTime(): Map<string, number> | undefined {
    return this._exerciseExecutionTime;
  }

  public get exerciseStatusesMap(): Map<string, string> | undefined {
    return this._exerciseStatusesMap;
  }

  public get pricePerSecond(): number | undefined {
    return this._pricePerSecond;
  }

  public get exerciseCount(): number {
    return this.exerciseIds?.length ?? 0;
  }

  public get exerciseIds(): string[] | undefined {
    if (!this._exerciseIds && this._exerciseStatusesMap)
      this._exerciseIds = [...this._exerciseStatusesMap.keys()];

    if (!this._exerciseIds && this._exerciseExecutionTime)
      this._exerciseIds = [...this._exerciseExecutionTime.keys()];

    return this._exerciseIds;
  }

  public get totalExecutionTime(): string {
    if (!this._totalExecutionTime) {
      const totalTimeArray = Array.from(this._exerciseExecutionTime?.values() ?? []);
      const totalTime = totalTimeArray.reduce((total, val) => total + Number(val), 0);
      this._totalExecutionTime = SecondsToHumanReadable(totalTime);
    }
    return this._totalExecutionTime;
  }

  public get status(): PlanStatus {
    if (this._status)
      return this._status;

    if (this._isFinished)
      return (this._status = PlanStatus.FINISHED);

    if (this._isCanceled)
      return (this._status = PlanStatus.CANCELED);

    if (this._isRejected)
      return (this._status = PlanStatus.REJECTED);

    if (this._deadline && GetTimeRemaining(this._deadline, 'milliseconds') <= 0)
      return (this._status = PlanStatus.FINISHED);

    if (this.exerciseStatusesArray && CountTasksByStatus(this.exerciseStatusesArray, TaskStatus.NOT_STARTED) != this.exerciseStatusesArray.length)
      return (this._status = PlanStatus.RUNNING);

    return PlanStatus.NOT_STARTED;
  }

  public get completedTasksCount(): number {
    if (this._completedTasksCount === undefined) {
      this._completedTasksCount = CountTasksByStatus(this.exerciseStatusesArray ?? [], TaskStatus.COMPLETED);
    }
    return this._completedTasksCount;
  }

  public get paymentInformation(): Promise<PaymentInformation | undefined> | null {
    if (!this._paymentInformation) {
      this._paymentInformation = this._dynamodbService.getPaymentInformation(this._companyId, this._userProfile.email, this._id);
    }
    return this._paymentInformation;
  }

  public get paymentLink(): string | undefined {
    if (!this._paymentLink &&
      this._companyId &&
      this.candidate.email &&
      this._id) {
      this._paymentLink = `${environment.WebURL}/payment?email=${this.candidate.email}&assigned_plan_id=${this._id}`;
    }
    return this._paymentLink;
  }

  public get isNotStarted(): boolean {
    return this.status === PlanStatus.NOT_STARTED;
  }

  public get isRunning(): boolean {
    return this.status === PlanStatus.RUNNING;
  }

  public get isRejected(): boolean {
    return this._isRejected;
  }

  public get isCanceled(): boolean {
    return this._isCanceled;
  }

  public get isWelcomePlan(): boolean {
    return this._isWelcomePlan;
  }

  public get remainingTime(): number {
    if (this._remainingTime === undefined) {
      this._remainingTime = this._deadline ? GetTimeRemaining(this._deadline, 'seconds') : 0;
    }
    return this._remainingTime;
  }

  public set remainingTime(value: number) {
    this._remainingTime = value;
  }

  public get isFinished(): boolean {
    return this._isFinished;
  }

  public set isFinished(value: boolean) {
    this._isFinished = value;
  }

  public get isDeadlinePassed(): boolean {
    return this._deadline ? GetTimeRemaining(this._deadline, 'seconds') === 0 : false;
  }

  public get isDeletable(): boolean {
    if (this.isRejected)
      return true;

    if (this.isNotStarted)
      return true;

    if (this.onTrialAssign && (this.isFinished || this.isDeadlinePassed))
      return true;

    if (this.isPaid && (this.isFinished || this.isDeadlinePassed))
      return true;

    return false;
  }

  public get requirePayment(): boolean {
    if (this.onTrialAssign)
      return false;

    if (this.isFinished && !this.isPaid)
      return true;

    return false;
  }

  public async fetchExerciseStatuses(): Promise<ExerciseStatus[]> {
    this.resetCache();

    if (this._authService.user.isUser) {
      this._exerciseStatuses = await this._dynamodbService.getUserExercisesPlanStatus(this._id, this._userEmail);
      return this._exerciseStatuses;
    }

    if (this._authService.user.isInterviewer) {
      this._exerciseStatuses = await this._dynamodbService.getInterviewerExerciseStatuses(this._id, this._userEmail, this._authService.user.companyId);
      return this._exerciseStatuses;
    }

    return [];
  }

  public updateTaskStatus(exerciseId: string, newStatus: TaskStatus): void {
    if (!this._exerciseStatusesMap) {
      throw new Error('Exercise statuses map is not initialized.');
    }

    if (!this._exerciseStatusesMap.has(exerciseId)) {
      throw new Error(`Task with ID ${exerciseId} does not exist.`);
    }

    this._exerciseStatusesMap.set(exerciseId, newStatus);
    this.resetCache();
  }

  // Reset cached data 
  private resetCache(): void {
    this._exerciseStatusesArray = undefined;
    this._completedTasksCount = undefined;
    this._status = undefined;
    this._interviewerProfile = undefined;
    this._remainingTime = undefined;
    this._totalExecutionTime = undefined;
  }
}

interface AssignedPlanAttributes {
  id: string;
  userEmail: string;
  interviewerCompanyID: string;
  interviewerCompanyName?: string
  interviewerFullName?: string;
  interviewerEmail?: string;
  userFullName?: string;
  planID?: string;
  planName?: string;
  planDescription?: string;
  deadline?: string;
  assignedDate?: string
  exerciseStatusesMap?: Map<string, string>;
  exerciseExecutionTime?: Map<string, number>;
  exerciseIds?: string[];
  pricePerSecond?: number;
  isPaid?: boolean;
  isRejected?: boolean;
  isCanceled?: boolean;
  isFinished?: boolean;
  isWelcomePlan?: boolean;
}

export enum PlanStatus {
  NOT_STARTED = 'Not Started',
  RUNNING = 'Running',
  FINISHED = 'Finished',
  REJECTED = 'Rejected',
  CANCELED = 'Canceled',
}