import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { MenuItem } from 'primeng/api';
import { User } from '../../../user.module';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  public isAuthenticated = false;
  public items: MenuItem[] = [];
  public queryParams: Params;
  public user!: User;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute
  ) { 
    this.queryParams = { ...this.route.snapshot.queryParams };
  }

  async ngOnInit(): Promise<void> {
    this.isAuthenticated = await this.auth.isAuthenticated();
    if (!this.isAuthenticated){
      return;
    }
    
    this.user = this.auth.user;
    this.setMenuItems();
  }

  private setMenuItems(): void {
    this.items = [
      {
        items: [
          {
            label: `<div><div><b>Signed in as</b></div> <div>${this.user.full_name_or_email}</div></div>`,
            escape: false,
          },
          {
            label: '<i class="material-icons-outlined">home</i>Dashboard',
            escape: false,
            routerLink: '/dashboard'
          },
          {
            label: '<i class="material-icons-outlined">settings</i>Settings',
            escape: false,
            routerLink: '/settings/profile'
          },
          {
            label: '<i class="material-icons-outlined">logout</i>Sign Out',
            escape: false,
            command: (() => this.auth.signOut())
          },
        ]
      },
    ];
  }
}