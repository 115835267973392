import { Component, OnDestroy, OnInit,  } from '@angular/core';
import { ExerciseDataService } from '../../services/exercise-data.service';
import { Subscription } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SupportDialogComponent } from 'src/app/support/components/support-dialog/support-dialog.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy, OnInit {
  private exerciseSubscription: Subscription = new Subscription();
  private ref: DynamicDialogRef | undefined;
  public itemsMap: Map<string, Item> = new Map<string, Item>([
    ['description', { icon: 'description', isOpen: true, isBottom: false, tooltip: 'Description', open: (item)=>this.toggleItem(item) }],
    ['hints', { icon: 'lightbulb', isOpen: false, isBottom: false, tooltip: 'Hints', open: (item)=>this.toggleItem(item)}],
    ['help', { icon: 'help_outline', isOpen: false, isBottom: false, tooltip: 'Help', open: (item)=>this.toggleItem(item) }],
    ['support', { icon: 'support', isOpen: false, shouldOpen: true, isBottom: true, tooltip: 'Support', open: ()=>this.support() }],
    ['settings', { icon: 'settings', isOpen: false, isBottom: true, tooltip: 'Settings', open: (item)=>this.toggleItem(item) }]
  ]);

  constructor(
    public _data: ExerciseDataService,
    private dialogService: DialogService
  ) { }

  public ngOnInit() {
    this.exerciseSubscription = this._data.exercise$.subscribe(data => {
      if (!data) {
        return;
      }

      if (data.needCodeEditor) {
        this.itemsMap.set('files', { icon: 'folder_copy', isOpen: false, isBottom: false, tooltip: 'Files', open: (item)=>this.toggleItem(item) });
      }
    });
  }

  public ngOnDestroy() {
    this.exerciseSubscription.unsubscribe();
  }

  public toggleItem(selectedItem: Item): void {
    this.itemsMap.forEach(item => {
      item.isOpen = (item === selectedItem);
    });
  }

  public isOpen(itemName: string): boolean {
    const item = this.itemsMap.get(itemName);
    return item ? item.isOpen : false;
  }

  public support(){
    this.dialogService.open(SupportDialogComponent, {
      width: '550px',
      baseZIndex: 10000,
      showHeader: true,
      dismissableMask: true,
      header: 'Submit Ticket'
    });
  }
}

export interface Item {
  icon: string
  isOpen: boolean
  isBottom: boolean
  tooltip: string
  shouldOpen?: boolean
  open: (item: Item) => void
}
