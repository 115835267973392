<div class="wrapper">
    <div
    [style.display]="!isExerciseManagerOpen ? 'flex' : 'none'"
    class="plans-container" >
        <app-side-menu></app-side-menu>
        <div class="plans-management">
            <app-plan-list 
              (planEditorEvent)="changePlanEditorEvent($event)"
              (fetchPlan)="planData.fetchPlans()">
            </app-plan-list>
            <div class="plan-editor">
                <ng-container *ngTemplateOutlet="planEditor"></ng-container>
            </div>
        </div>
    </div>
    <app-exercise-management
      (closeExerciseManager)="closeExerciseMenu()"
      [style.display]="isExerciseManagerOpen ? 'inline' : 'none'"
      [isSelectableModeActive]="true">
    </app-exercise-management>
</div>

<app-tool-panel 
  [openTrigger]="toolPanelTrigger"
  [style.display]="isExerciseManagerOpen ? 'none' : 'inline'">
    <ng-container *ngTemplateOutlet="planEditor">
    </ng-container>
</app-tool-panel>

<ng-template #planEditor>
    <app-edit-plan 
      *ngIf="planEditorEvent == 'edit'"
      (openExerciseManagment)="openExerciseMenu()">
    </app-edit-plan>

    <app-delete-plan 
        *ngIf="planEditorEvent == 'delete'"
        (deletedPlanTrigger)="planEditorEvent = 'create'">
    </app-delete-plan>

    <app-create-plan
        *ngIf="planEditorEvent == 'create'"
        (openExerciseManager)="openExerciseMenu()"
        (createdTrigger)="planEditorEvent = 'edit'">
    </app-create-plan>

    <app-assign-plan
        *ngIf="planEditorEvent == 'assign'">
    </app-assign-plan>
</ng-template>