import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})
export class PricesComponent implements OnInit {
  public isAuthenticated = false;
  constructor(
    private auth: AuthService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isAuthenticated = await this.auth.isAuthenticated();
  }
}
