import { Component, Input } from '@angular/core';
import { DocumentationInfo } from '../../services/docs-loader.service';

@Component({
  selector: 'table-of-contents',
  templateUrl: './table-of-contents.component.html'
})
export class TableOfContentsComponent {
  @Input() fileInfos: DocumentationInfo[] = [];
}
