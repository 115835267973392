<section> 
    <div class="exercise-container">
        <p-splitter 
          [style]="{ height: '100%'}" 
          [panelSizes]="[20, 80]" 
          [minSizes]="[20, 65]"
          [gutterSize]="2">
            <ng-template pTemplate>
                <div class="info-container">
                    <app-sidebar></app-sidebar>
                </div>
            </ng-template>
            <ng-template pTemplate>
                <p-splitter 
                  layout="vertical" 
                  [panelSizes]="[85, 15]"
                  [minSizes]="[70, 15]"
                  [gutterSize]="2">
                    <ng-template pTemplate>
                        <div class="split-container">
                           <app-workspace></app-workspace>
                        </div>
                    </ng-template>
                    <ng-template pTemplate>
                        <div class="split-container">
                            <app-bottom-panel></app-bottom-panel>
                         </div>
                    </ng-template>
                </p-splitter>
            </ng-template>
        </p-splitter>
    </div>
</section>

