import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayModule } from '@angular/cdk/overlay';

import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { RippleModule } from 'primeng/ripple';
import { SkeletonModule } from 'primeng/skeleton';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ChipModule } from 'primeng/chip';
import { TagModule } from 'primeng/tag';

import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AngularResizeEventModule } from 'angular-resize-event';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PolicyComponent } from './policy/policy.component';
import { CookiesComponent } from './cookies/cookies.component';
import { TermsComponent } from './terms/terms.component';
import { environment } from 'src/environments/environment';
import { ReplayComponent } from './replay/replay.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { ContactComponent } from './contact/contact.component';
import { PricesComponent } from './prices/prices.component';
import { BecomeCompanyMemberComponent } from './dialogs/become-company-member/become-company-member.component';
import { AuthService } from './auth/auth.service';
import { ConnectUserDialogComponent } from './dialogs/connect-user-dialog/connect-user-dialog.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { RecentlyAssignedPlanComponent } from './dashboard/recently-assigned-plan/recently-assigned-plan.component';
import { StatsComponent } from './dashboard/stats/stats.component';
import { ExercisePlansListWidgetComponent } from './dashboard/exercise-plans-list-widget/exercise-plans-list-widget.component';
import { CalendarWidgetComponent } from './dashboard/calendar-widget/calendar-widget.component';
import { NotificationWidgetComponent } from './dashboard/notification-widget/notification-widget.component';
import { ExerciseManagementComponent } from './exercise-management/exercise-management.component';
import { ExerciseListComponent } from './exercise-management/exercise-list/exercise-list.component';
import { TotalTimePipe } from './shared/service/time';
import { ExerciseFilterComponent } from './exercise-management/exercise-filter/exercise-filter.component';
import { PlansManagementComponent } from './plans-management/plans-management.component';
import { PlanListComponent } from './plans-management/plan-list/plan-list.component';
import { EditPlanComponent } from './plans-management/plan-editor/edit-plan/edit-plan.component';
import { DeletePlanComponent } from './plans-management/plan-editor/delete-plan/delete-plan.component';
import { CreatePlanComponent } from './plans-management/plan-editor/create-plan/create-plan.component';
import { AssignPlanComponent } from './plans-management/plan-editor/assign-plan/assign-plan.component';
import { HelpComponent } from './plans-management/plan-editor/help/help.component';
import { ExerciseDescriptionComponent } from './exercise-management/exercise-description/exercise-description.component';
import { ExerciseListHeaderComponent } from './exercise-management/exercise-list/exercise-list-header/exercise-list-header.component';
import { ToolPanelComponent } from './tool-panel/tool-panel.component';
import { AssignedPlansComponent } from './assigned-plans/assigned-plans.component';
import { PlanStatusPipe } from './status-parser';
import { AssignedPlansListComponent } from './assigned-plans/assigned-plans-list/assigned-plans-list.component';
import { AssignedPlanInfoPanelComponent } from './assigned-plans/assigned-plan-info-panel/assigned-plan-info-panel.component';
import { UserExercisePlansComponent } from './user-exercise-plans/user-exercise-plans.component';
import { UserExercisePlansListComponent } from './user-exercise-plans/user-exercise-plans-list/user-exercise-plans-list.component';
import { UserExercisePlansInfoPanelComponent } from './user-exercise-plans/user-exercise-plans-info-panel/user-exercise-plans-info-panel.component';
import { ComingSoonComponent } from './dashboard/coming-soon/coming-soon.component';
import { ExerciseModule } from './exercise/exercise.module';
import { SharedModule } from './shared/shared.module';
import { ServiceLocator } from './service-locator.service';
import { SupportSystemModule } from './support/support-system.module';
import { SideMenuModule } from './side-menu/side-menu.module';
import { HelpDialogComponent } from './dialogs/help-dialog/help-dialog.component';
import { UserListModule } from './user-list/user-list.module';
import { AdminPanelModule } from './admin-panel/admin-panel.module';
import { SettingsModule } from './settings/settings.module';
import { PaymentModule } from './payment/payment.module';
import { TableModule } from 'primeng/table';
import { DocumentationModule } from './documentation/documentation.module';
import { BrowserCompatibilityWarningModule } from './browser-compatability-warning/browser-compatibility-warning.module';

export const DATE_TIME_FORMAT = {
  parse: {
    dateInput: 'l, LTS'
  },
  display: {
    dateInput: 'YYYY-MM-DD  HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LandingPageComponent,
    PolicyComponent,
    CookiesComponent,
    TermsComponent,
    ReplayComponent,
    PaymentHistoryComponent,
    ContactComponent,
    PricesComponent,
    BecomeCompanyMemberComponent,
    RegistrationComponent,
    ConnectUserDialogComponent,
    DashboardComponent,
    RecentlyAssignedPlanComponent,
    StatsComponent,
    ExercisePlansListWidgetComponent,
    CalendarWidgetComponent,
    NotificationWidgetComponent,
    ExerciseManagementComponent,
    ExerciseListComponent,
    ExerciseFilterComponent,
    PlansManagementComponent,
    PlanListComponent,
    EditPlanComponent,
    DeletePlanComponent,
    CreatePlanComponent,
    AssignPlanComponent,
    HelpComponent,
    ExerciseDescriptionComponent,
    ExerciseListHeaderComponent,
    ToolPanelComponent,
    AssignedPlansComponent,
    PlanStatusPipe,
    TotalTimePipe,
    AssignedPlansListComponent,
    AssignedPlanInfoPanelComponent,
    UserExercisePlansComponent,
    UserExercisePlansListComponent,
    UserExercisePlansInfoPanelComponent,
    ComingSoonComponent,
    HelpDialogComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    ChipModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    HttpClientModule,
    DividerModule,
    ClipboardModule,
    OverlayModule,
    NgSelectModule,
    ToastModule,
    ButtonModule,
    MessagesModule,
    MessageModule,
    RippleModule,
    SkeletonModule,
    VirtualScrollerModule,
    ConfirmDialogModule,
    AvatarModule,
    AvatarGroupModule,
    AngularResizeEventModule,
    SidebarModule,
    MenuModule,
    AvatarModule,
    AvatarGroupModule,
    CalendarModule,
    OverlayPanelModule,
    DialogModule,
    TooltipModule,
    DynamicDialogModule,
    InputTextModule,
    InputTextareaModule,
    ExerciseModule,
    DocumentationModule,
    SharedModule,
    CheckboxModule,
    SupportSystemModule,
    BrowserCompatibilityWarningModule,
    SideMenuModule,
    UserListModule,
    AdminPanelModule,
    SettingsModule,
    RadioButtonModule,
    PaymentModule,
    TableModule,
    TagModule,
    NgCircleProgressModule.forRoot({}),
    MonacoEditorModule.forRoot(),
    NgcCookieConsentModule.forRoot(environment.cookieConfig as NgcCookieConsentConfig),
  ],
  providers: [
    DatePipe,
    AuthService,
    MessageService,
    ConfirmationService,
    DialogService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}