import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './user-list.component';
import { SharedModule } from '../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PortalModule } from '@angular/cdk/portal';
import { PopoverComponent } from './components/popover/popover.component';
import { AvatarModule } from 'primeng/avatar';
import { InviteDialogComponent } from './components/invite-dialog/invite-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

@NgModule({
  declarations: [
    UserListComponent,
    PopoverComponent,
    InviteDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    BrowserAnimationsModule,
    PortalModule,
    AvatarModule,
    DialogModule,
    DynamicDialogModule
  ],
  exports: [
    UserListComponent
  ]
})
export class UserListModule { }
