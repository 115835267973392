import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { InterviewerExercisePlan } from 'src/app/exercise.module';
import { PlanControlService } from './service/plan-control.service';
import { HintMessages } from '../help/help.component';
import { delay } from 'lodash';
import { PlanDataService } from '../../plan-data-service/plan-data.service';
import { GetCurrentTime } from 'src/app/shared/service/time';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-plan',
  templateUrl: './edit-plan.component.html',
  styleUrls: ['./edit-plan.component.scss']
})
export class EditPlanComponent implements OnInit, OnDestroy {
  @Output() openExerciseManagment = new EventEmitter<boolean>();
  @ViewChild('nameInput') set nameInput(input: ElementRef) {
    this.focusInput(input);
  }
  @ViewChild('descriptionInput') set descriptionInput(input: ElementRef) {
    this.focusInput(input);
  }

  public isNameInputVisible = false;
  public isDescriptionInputVisible = false;
  public nameControl: PlanControlService;
  public descriptionControl: PlanControlService;
  public helpMessageEnum = HintMessages;
  public imageLink: string = environment.DefaultPlanPicture;
  private selectedPlanSubscription: Subscription | null = null;
  public editedPlan: InterviewerExercisePlan | null = null;

  constructor(
    private messageService: MessageService,
    public planData: PlanDataService
  ) {
    this.nameControl = new PlanControlService('', false, undefined, 5, 100);
    this.descriptionControl = new PlanControlService('', false, undefined, 0, 1000);
    this.subToSelectedPlan();
  }

  ngOnInit(): void {
    this.setControlValues();
  }

  ngOnDestroy() {
    if (this.selectedPlanSubscription) {
      this.selectedPlanSubscription.unsubscribe();
    }
  }

  private subToSelectedPlan(): void {
    this.selectedPlanSubscription = this.planData.selectedPlan$.subscribe(plan => {
      if (plan) {
        this.editedPlan = plan;
        this.isNameInputVisible = false;
        this.isDescriptionInputVisible = false;
        this.setControlValues();
      }
    });
  }

  public openNameInput(): void {
    this.isNameInputVisible = true;
  }

  public openDescriptionInput(): void {
    this.isDescriptionInputVisible = true;
  }

  public saveName(): void {
    this.editedPlan!.name = this.nameControl.value;
    delay(() => { this.isNameInputVisible = false; }, 200);
  }

  public saveDescription(): void {
    this.editedPlan!.description = this.descriptionControl.value;
    delay(() => { this.isDescriptionInputVisible = false; }, 200);
  }

  private setControlValues(): void {
    if (this.editedPlan) {
      this.nameControl.setValue(this.editedPlan.name);
      this.descriptionControl.setValue(this.editedPlan.description);
    }
  }

  public saveChanges(): void {
    const errMessage = this.getError();
    if (errMessage) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: errMessage });
      return;
    }

    if (this.editedPlan) {
      this.editedPlan.lastModificationTime = GetCurrentTime();
      this.planData.updatePlan(this.editedPlan);
    }
  }

  private getError(): string | undefined {
    if (this.nameControl.hasError()) {
      return 'Name must be between 5 and 100 characters';
    }

    if (this.descriptionControl.hasError()) {
      return 'Please make sure that the description is no longer than 2000 characters.';
    }

    if (this.editedPlan?.exerciseNames.length === 0) {
      return 'Please select at least one exercise';
    }
    return undefined;
  }

  private focusInput(element: ElementRef) {
    if (element) {
      element.nativeElement.focus();
    }
  }

  async openExercisePlanDialog(): Promise<void> {
    this.openExerciseManagment.emit(true);
  }
}
