import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Cart } from 'src/app/models/payment/cart.models';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {
  @Input() cart: Cart | null = null;
  @Input() isPayButtonActive = false;
  @Output() OnPayClick = new EventEmitter<void>();

  @Input() isPolicyConfirmed = false;
  @Output() isPolicyConfirmedChange = new EventEmitter<boolean>();

  private readonly defaultScrollHeight: number = 450;
  public readonly defaultItemHeight: number = 75;

  public get scrollHeight(): string {
    if (!this.cart)
      return `${this.defaultScrollHeight}px`;

    if (this.cart.exercises.length <= this.defaultScrollHeight / this.defaultItemHeight) {
      return `${this.cart.exercises.length * this.defaultItemHeight}px`;
    }

    return `${this.defaultScrollHeight}px`;
  }
}
