<div 
  class="w-100 h-100 flex flex-column"
  style="font-family: Roboto;">
    <app-header></app-header>

    <ng-container *ngIf="cart">
        <section class='w-100 pt-2 pb-2 pl-2 pr-2 flex flex-column align-items-center overflow-y-scroll
                    md:pl-4 md:pr-4 lg:pl-6 lg:pr-6 xl:pl-8 xl:pr-8'>
            <div 
            class="w-100 flex breadcrum"
            style="max-width: 1280px;">
                <p-breadcrumb
                    styleClass="border-none pl-2" 
                    [model]="menuItem">
                </p-breadcrumb>
            </div>

            <div 
              class="w-100 flex flex-column gap-0 lg:gap-6 lg:flex-row"
              style="max-width: 1280px;">
                <div class="w-100 h-100 p-2 flex flex-column lg:w-7">
                    <h1 class="m-0 font-bold text-5xl text-gray-800">
                        Payer Information
                    </h1>
        
                    <p-divider></p-divider>
        
                    <div class="w-100 h-100 flex flex-column gap-0 mb-3 md:flex-row md:gap-5">
                        <div class="w-100 h-100 flex flex-column">
                            <label class="mb-2">
                                First name *
                            </label>
                            <app-input-field
                            [inputControl]="payerInformationFromGroup | formControl: 'firstName'"
                            [isRequired]="true">
                            </app-input-field>
                        </div>
                        
                        <div class="w-100 h-100 flex flex-column">
                            <label class="mb-2">
                                Last name *
                            </label>
                            <app-input-field
                              [inputControl]="payerInformationFromGroup | formControl: 'lastName'"
                              [isRequired]="true">
                            </app-input-field>
                        </div>
                    </div>

                    <div class="w-100 h-100 flex flex-column gap-0 mb-3 md:flex-row md:gap-5">
                        <div class="w-100 h-100 flex flex-column">
                            <label class="mb-2">
                                Location *
                            </label>
                            <countries-dropdown
                              [inputControl]="payerInformationFromGroup | formControl: 'country'"
                              [isRequired]="true"
                              (onChange)="fetchPlanPaymentInfo()">
                            </countries-dropdown>
                        </div>
                        
                        <div class="w-100 h-100 flex flex-column">
                            <label class="mb-2">
                                State
                            </label>
                            <app-input-field
                             [inputControl]="payerInformationFromGroup | formControl: 'state'">
                            </app-input-field>
                        </div>
                    </div>
        
                    <div class="w-100 h-100 flex flex-column gap-0 mb-3 md:flex-row md:gap-5">
                        <div class="w-100 h-100 flex flex-column">
                            <label class="mb-2">
                                City *
                            </label>
                            <app-input-field
                              [inputControl]="payerInformationFromGroup | formControl: 'city'"
                              [isRequired]="true">
                            </app-input-field>
                        </div>
                        
                        <div class="w-100 h-100 flex flex-column">
                            <label class="mb-2">
                                Postal code *
                            </label>
                            <app-input-field
                              [inputControl]="payerInformationFromGroup | formControl: 'postcode'"
                              [isRequired]="true">
                            </app-input-field>
                        </div>
                    </div>
        
                    <div class="w-100 h-100 flex flex-column mb-5">
                        <label class="mb-2">
                            Street *
                        </label>
                        <app-input-field
                          [inputControl]="payerInformationFromGroup | formControl: 'street'"
                          [isRequired]="true">
                        </app-input-field>    
                    </div>

                    <payment-methods
                      class="w-100 h-100 flex flex-column mb-6"
                      [paymentMethods]="paymentMethods"
                      [(selectedPaymentMethod)]="selectedPaymentMethod">
                    </payment-methods>
                </div>
        
                <app-cart
                  class="w-100 lg:w-5"
                  [cart]="cart"
                  [(isPolicyConfirmed)]="confirm"
                  [isPayButtonActive]="isPayButtonActive"
                  (OnPayClick)="OnPayClick()">
                </app-cart>
            </div>
        </section>
    </ng-container>
<div>