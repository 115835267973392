// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  RegistrationAPIGatewayURL: 'https://platform.dev.dev-skill.com/api/registration/user/submit',
  CompanyRegistrationAPIGatewayURL: 'https://platform.dev.dev-skill.com/api/registration/company/submit',
  AddMemberAPIGatewayURL: 'https://platform.dev.dev-skill.com/api/member/add',
  DeleteMemberAPIGatewayURL: 'https://platform.dev.dev-skill.com/api/member/delete',
  InviteMemberAPIGatewayURL: 'https://platform.dev.dev-skill.com/api/member/invite',
  InviteUserAPIGatewayURL: 'https://platform.dev.dev-skill.com/api/company-connections/invite',
  DeleteUserAPIGatewayURL: 'https://platform.dev.dev-skill.com/api/company-connections/delete',
  AddUserAPIGatewayURL: 'https://platform.dev.dev-skill.com/api/company-connections/add',
  CheckInviteLinkAPIGatewayURL: 'https://platform.dev.dev-skill.com/api/invite/check',
  SaveCompanyDetailsAPIGatewayURL: 'https://platform.dev.dev-skill.com/api/company-details/save',
  SaveUserPlanAPIGatewayURL: 'https://platform.dev.dev-skill.com/api/user-exercise-plan/save',
  GetPaymentLinkAPIGatewayURL: 'https://platform.dev.dev-skill.com/api/lambdas/payment/oneoff',
  GetPaymentStatusAPIGatewayURL: 'https://platform.dev.dev-skill.com/api/lambdas/payment/status/get',
  GetPaymentMethodAPIGatewayURL: 'https://platform.dev.dev-skill.com/api/lambdas/payment/methods/get',
  DeleteAccountEndpoint: 'https://platform.dev.dev-skill.com/api/lambdas/account',
  UserDetailsSync: 'https://platform.dev.dev-skill.com/api/lambdas/user',
  WebSocketEndpoint: 'wss://platform.dev.dev-skill.com/api/ws',
  RejectAssignedPlanEndpoint: 'https://platform.dev.dev-skill.com/api/reject-assigned-plan',
  DeleteAssignedPlanEndpoint: 'https://platform.dev.dev-skill.com/api/cancel-assigned-plan',
  FinishAssignedPlanEndpoint: 'https://platform.dev.dev-skill.com/api/assigned-plan/finish',
  GetPlanPrice: 'https://platform.dev.dev-skill.com/api/plan/price',
  WebURL: 'https://platform.dev.dev-skill.com/',
  DefaultMemberPicture: '../../assets/img/members/default.svg',
  DefaultPlanPicture: '../../assets/img/plan/default.png',
  DefaultExercisePicture: '../../assets/img/exercises/default.png',
  ExercisePicturePath: '../../assets/img/exercise/',
  NewUserPicture: '../../assets/img/members/question-mark.svg',
  production: false,
  s3Bucket: {
    region: 'eu-north-1',
    endpoint: 'https://s3.eu-north-1.amazonaws.com',
    records: 'dev-skill-dev-platform-web-terminal-records',
    tickets: 'dev-skill-dev-platform-web-ticket-attachments',
    userAvatar: 'dev-skill-dev-platform-web-user-avatar',
    forcePathStyle: false
  },
  dynamodb: {
    region: 'eu-north-1',
    endpoint: 'https://dynamodb.eu-north-1.amazonaws.com'
  },
  cookieConfig: {
    cookie: {
      domain: 'dev.dev-skill.com'
    },
    position: 'bottom',
    theme: 'classic',
    palette: {
      popup: {
        background: '#000000',
        text: '#ffffff',
        link: '#ffffff'
      },
      button: {
        background: '#f1d600',
        text: '#000000',
        border: 'transparent'
      }
    },
    type: 'info',
    elements:{
      messagelink: `
      <span id="cookieconsent:desc" class="cc-message">By clicking “Accept all cookies”, you agree with our 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>.
      You can revoke your consent at any times by deleting the saved cookie files from your browser. Yet, note that without all of these cookies you will not be able to use the functionality of the website in full. 
      </span>
      `,
    },
    content:{
      message: '',
      cookiePolicyLink: 'cookies policy',
      cookiePolicyHref: '/cookies',
      dismiss: 'Accept all cookies',
    },
  },
  aws: {
    Auth: {
  
      // REQUIRED - Amazon Cognito Region
      region: 'eu-north-1',

      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'eu-north-1:c5dc1bdb-5708-4f63-8bad-d1ae6f929047',
  
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-north-1_btzWgyrJZ',
  
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '28bh4u1t6ul2a37bjlkm00h3bk',
  
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
  
      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: 'dev.dev-skill.com',
        // OPTIONAL - Cookie path
        path: '/',
        // OPTIONAL - Cookie expiration in days
        expires: 1,
        // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: 'strict',
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: false,
      },
  
      // OPTIONAL - customized storage object
      // storage: MyStorage,
  
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_SRP_AUTH',
  
      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      // clientMetadata: { myCustomKey: 'myCustomValue' },
  
      // OPTIONAL - Hosted UI configuration
      oauth: {
        domain: 'auth.platform.dev.dev-skill.com',
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://platform.dev.dev-skill.com/dashboard',
        redirectSignOut: 'https://platform.dev.dev-skill.com/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
