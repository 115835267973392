import { Component, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Calendar } from 'primeng/calendar';
import { CalendarDataService } from './calendar service/calendar-data-service.service';
import { Subscription } from 'rxjs';
import moment from 'moment-mini';


@Component({
  selector: 'app-calendar-widget',
  templateUrl: './calendar-widget.component.html',
  styleUrls: ['./calendar-widget.component.scss'],
})
export class CalendarWidgetComponent {
  @ViewChild(Calendar) calendar!: Calendar;

  private events: CalendarEvent[] | null = null;
  private dataSubscription: Subscription = new Subscription();
  public currentEvent: CalendarEvent | undefined;

  constructor(
    private calendarData: CalendarDataService
  ) { }


  ngOnInit(): void {
    this.subToEvents();
    this.calendarData.fetch();
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }

  private subToEvents(): void {
    this.dataSubscription.add(this.calendarData.data$.subscribe(event => {
      if (event !== null) {
        this.events = event;
      }
    }));
  }

  public showOverlay(date: CalendarDate, event: MouseEvent, overlayPanel: OverlayPanel) {
    const convertedDate = this.convertToDate(date);
    const calendarEvent = this.getEvent(new Date(convertedDate));

    if (calendarEvent) {
      this.currentEvent = calendarEvent;
      overlayPanel.show(event, event.target as HTMLDivElement);
    } else {
      overlayPanel.hide();
    }
  }

  public hideOverlay(overlayPanel: OverlayPanel) {
    overlayPanel.hide();
  }

  public onMonthChange(event: CalendarMonthChangeEvent): void {
    const date = new Date(event.year, event.month - 1).toISOString();
    const startOfMonth = moment(date).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment(date).add(1, 'months').startOf('month').format('YYYY-MM-DD');
    this.calendarData.fetch(startOfMonth,endOfMonth);
  }

  public isDateToHighlight(date: CalendarDate): boolean {
    const newDate = this.convertToDate(date);
    return !!this.getEvent(newDate);
  }

  private convertToDate(calendarDate: CalendarDate): Date {
    return new Date(calendarDate.year, calendarDate.month, calendarDate.day);
  }

  private getEvent(date: Date): CalendarEvent | undefined {
    return this.events?.find(event => moment(event.date).format('yyyy-MM-DD') === moment(date).format('yyyy-MM-DD'));
  }
}

export interface CalendarEvent {
  date: Date;
  events: string[];
}

export interface CalendarDate {
  day: number;
  month: number;
  year: number;
  otherMonth: boolean;
  today: boolean;
  selectable: boolean;
}

export interface CalendarMonthChangeEvent {
  month: number;
  year: number;
}