import { Component } from '@angular/core';
import { HintMessages } from 'src/app/plans-management/plan-editor/help/help.component';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent  {
  public helpEnum = HintMessages;
}
