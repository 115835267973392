<div class="wrapper">
    <app-exercise-list-header
      class="header"
      (closeExerciseManager)="closeExerciseManager.emit(true)"
      [selectedExerciseCount]="selectedExercisesCount"
      [hasBackButton]="isSelectableModeActive">
    </app-exercise-list-header>

    <ng-container *ngIf="exerciseData.filteredExercises && exerciseData.filteredExercises.length !== 0; else noDataTemplate">
        <cdk-virtual-scroll-viewport 
          class="scroll-viewport" 
          itemSize="130" 
          (resized)="calculateExerciseWidth($event)">
            <ng-container *ngIf="hasSelectedExercises()">
                <h4 class="title">Selected Exercises</h4>
                <div class="exercises-container">
                    <ng-container  *ngFor="let exercise of exerciseData.filteredExercises">
                        <ng-container 
                          *ngIf="exercise.selected" 
                          [ngTemplateOutlet]="exerciseTemplate" 
                          [ngTemplateOutletContext]="{exercise: exercise}">
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
          
            <ng-container *ngIf="hasOtherExercises()">
                <h4 class="title" *ngIf="hasSelectedExercises()">Other Exercises</h4>
                <div class="exercises-container">
                    <ng-container *ngFor="let exercise of exerciseData.filteredExercises">
                        <ng-container 
                          *ngIf="!exercise.selected" 
                          [ngTemplateOutlet]="exerciseTemplate" 
                          [ngTemplateOutletContext]="{exercise: exercise}">
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </cdk-virtual-scroll-viewport>
    </ng-container>
</div>

<ng-template #noDataTemplate>
    <div class="no-data">
      <span>
        Sorry, we couldn't find any exercises matching your current filters. 
        To view exercises, please consider removing any applied filters.
      </span>
    </div>
</ng-template>

<ng-template #exerciseTemplate let-exercise="exercise">
    <div class="exercise-container"
      (click)="selectExercise(exercise)">
        <div class="flex">
            <img [src]="exercise.imageLink">
            <div class="info">
                <h5>{{exercise.userFriendlyName}}</h5>
                <div class="text">
                    <span class="gray">Tags: </span>
                    <span>{{exercise.tags.join(', ')}}</span>
                </div>
                <div class="text">
                    <span class="gray">Difficulty: </span>
                    <span>{{exercise.difficulty}}</span>
                </div>
                <div class="text">
                    <span class="gray">Execution time: </span>
                    <span>{{exercise.executionTime | humanaizeMinutes}}</span>
                </div>
                <div class="text">
                    <span class="gray">Description: </span>
                    <span>{{exercise.shortDescription}}</span>
                </div>
            </div>
        </div>

        <ng-container *ngIf="isSelectableModeActive">
            <button 
              class="mt-2 mr-2 p-2 cursor-pointer border-round-md bg-white border-solid border-1 text-green-700 border-green-700"
              style="height: fit-content;"
              [class.border-red-700]="exercise.selected"
              [class.text-red-700]="exercise.selected"
              (click)="toggleSelection(exercise); $event.stopPropagation()">
                {{exercise.selected ? 'Deselect': 'Select'}}
            </button>
        </ng-container>
    </div>    
</ng-template>