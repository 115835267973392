<div class="wrapper">
    <div class="helper">
        <div class="loaded-data">
            Loaded: {{planData.plans.length > 0 ? 1: 0}}-{{planData.plans.length}} out of {{planData.totalPlanCount}}
        </div>
        <!-- <div class="filter">Sort by: Newest &#9662;</div> -->
    </div>
    <div 
      class="plans-container"
      [style.height]="calculateContainerHeight()">
        <cdk-virtual-scroll-viewport
          #scrollViewport
          class="plans"
          itemSize="130"
          (scroll)="onScroll($event)">
            <a
              class="plan" 
              id="{{plan.id}}"
              [style.background]="plan.selected ? 'rgb(240, 240, 240)' : 'white'"
              (click)="changeEventType('edit', plan); modifyQuery(plan)" 
              *ngFor="let plan of planData.plans">
                <img [src]="plan.imageLink ? plan.imageLink : imageLink">
                <div class="specification">
                    <div class="details">
                        <h4 class="name">{{plan.name}}</h4>
                    </div>
                    <div class="text gray">Exercises: 
                        <span class="text">{{plan.exerciseIDs.length}}</span>
                    </div>
                    <div class="text gray execution-time">Total Execution Time:
                        <span class="text">{{plan.executionTime | humanaizeMinutes}}</span>
                    </div>
                    <div class="text gray description">Description:
                        <span class="text">{{plan.description}}</span>
                    </div>
                </div>
                <div class="buttons">
                    <button class="assign"
                      (click)="changeEventType('assign', plan); $event.stopPropagation()">
                        <span>Assign</span>
                        <i class="material-icons-outlined">send_outline</i>
                    </button>
                    <button class="delete" (click)="changeEventType('delete', plan); $event.stopPropagation()">
                        <i class="material-icons-outlined">delete_outline</i>
                        <span>Delete</span>
                    </button>
                </div>  
            </a>
        </cdk-virtual-scroll-viewport>
    </div>

    <div class="no-data-message"
        *ngIf="planData.plans.length === 0">
        <span class="text">
            Create and manage exercise plans, all in one place.<br>
            To start, click the button below to create a new plan. Add a name, description, and desired
            exercises, then click 'Save.' Afterward, you can assign plans to users.
        </span>
    </div>
    
    <div class="button-container">
        <simple-button
          (click)="changeEventType('create', undefined)">
            Create Plan
        </simple-button>
    </div>
</div>