<div class="wrapper">
    <div class="container">
        <div class="dialog">
            <div class="dialog-div">
                <label for="subject">Subject</label>
                <app-input-field 
                  name="subject" 
                  type="text" 
                  [isRequired]="true" 
                  [inputControl]="subject">
                </app-input-field>
            </div>

            <div class="dialog-div">
                <label for="description">Problem Description</label>
                <textarea 
                name="description" 
                  pInputTextarea 
                  [ngClass]="{ 'invalid': textAreaInvalid }" 
                  [formControl]="description" 
                  [rows]="6" 
                  [autoResize]="true">
                </textarea>
                <small class="p-error " [class.m-fadeIn]="textAreaInvalid && description.touched">
                </small>
            </div>

            <div class="dialog-div">
                <p-fileUpload   
                  (onSelect)="onFileSelect($event)"
                  (onRemove)="onFileRemove($event)" 
                  [files]="loadedFiles" 
                  [customUpload]="true"
                  [multiple]="true" 
                  [showUploadButton]="false" 
                  [showCancelButton]="false"
                  [fileLimit]="10">
                    <ng-template pTemplate="toolbar">
                        <span>Or Drag and Drop Below </span>
                    </ng-template>
                    <ng-template pTemplate="content" *ngIf="loadedFiles.length === 0"> 
                    </ng-template>
                </p-fileUpload>
            </div>

            <div class="button-div">
                <simple-button 
                  *ngIf="!isLoading"
                  (click)="submitTicket()" 
                  [disabled]="subject.invalid || description.invalid">
                    Submit
                </simple-button>
                <app-loading-animation *ngIf="isLoading"></app-loading-animation>
            </div>
        </div>
    </div>
</div>

