<div class="wrapper">
  <div class="scroll-container">
    <span class="p-input-icon-left w-full">
      <i class="material-icons-outlined"
        style="margin-top: -0.75rem;">
        search
      </i>
      <input 
        class="w-full text-xs hover:border-900 hover:shadow-none focus:border-900 focus:shadow-none"
        type="text" 
        pInputText 
        placeholder="Search for an exercise" 
        [formControl]="inputControl.control">
    </span>
    <ng-container *ngFor="let filter of filterData.filters">
        <ng-container *ngIf="filter.isDisplayed">
            <p-divider></p-divider>
            <div class="title">
                <span>{{filter.name}}</span>
                <app-help
                  *ngIf="filter.hintMessage" 
                  [helpMessage]="filter.hintMessage">
                </app-help>
            </div>
            <div class="selections">
                <ng-container *ngFor="let selection of filter.selection; let i = index">
                    <mat-checkbox 
                      *ngIf="i <selectionsToShow || !filter.loadMore"
                      (change)="handleFilterMutation($event.checked, selection)"
                      [checked]="selection.isSelected">
                        {{selection.name}}
                    </mat-checkbox>
                </ng-container>
                <a *ngIf="filter.loadMore" 
                  class="load-button"
                  (click)="filter.loadMore = false">
                    Load more
                </a>
            </div>
        </ng-container>
    </ng-container>
  </div>
</div>