<div class="wrapper">
    <app-side-menu></app-side-menu>
    <div class="container">
        <h1>{{title}}</h1>
        <div *ngIf="ticketCount !== 0; else elseBlock">
            <p-table #table 
            [value]="tickets"
            [paginator]="true"
            [rows]="10"
            [lazy]="true"
            [loading]="isLoading"
            (onLazyLoad)="fetchTickets($event.first / $event.rows)"
            [totalRecords]="ticketCount"
            [showFirstLastIcon]="false">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Date</th>
                        <th>Subject</th>
                        <th>Description</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ticket>
                    <tr class="ticket" (click)="openTicket(ticket)">
                        <td>{{ticket.userFriendlyDate}}</td>
                        <td>{{ticket.subject}}</td>
                        <td>{{ticket.description}}</td>
                    </tr>
                </ng-template>
            </p-table>
            
        </div>
        <ng-template #elseBlock>
            <div class="noTickets">
                <span>You don't have any tickets.</span>
            </div>
        </ng-template>
    </div>
</div>

