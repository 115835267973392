import { Injectable } from '@angular/core';
import { Exercise } from 'src/app/exercise.module';
import { HintMessages } from 'src/app/plans-management/plan-editor/help/help.component';

@Injectable({
  providedIn: 'root'
})
export class FilterDataService {
  public filters: Filter[] = [
    {
      name: ExerciseFilterName.Name,
      inputValue: '',
      isDisplayed: false,
      filter: function (exercises: Exercise[]): Exercise[] {
        if (!this.inputValue || exercises.length === 0) {
          return exercises;
        }
        
        return exercises.filter(exercise => {
          return exercise.userFriendlyName.toLocaleLowerCase().includes(this.inputValue!.toLocaleLowerCase());
        });
      }
    },
    {
      name: ExerciseFilterName.Difficulty,
      selection: [
        { name: 'Hard', isSelected: false },
        { name: 'Medium', isSelected: false },
        { name: 'Easy', isSelected: false }
      ],
      hintMessage: HintMessages.Difficulty,
      loadMore: false,
      isDisplayed: true,
      filter: function (exercises: Exercise[]): Exercise[] {
        if (!this.selection || this.selection.length === 0 || exercises.length === 0) {
          return exercises;
        }

        const selectedDifficulty = this.selection
          .filter(s => s.isSelected)
          .map(s => s.name);

        if (selectedDifficulty.length === 0) {
          return exercises;
        }

        return exercises.filter(exercise =>
          selectedDifficulty.includes(exercise.difficulty)
        );
      }
    },
    {
      name: ExerciseFilterName.Tag,
      selection: [
        { name: 'AWS', isSelected: false },
        { name: 'Bash', isSelected: false },
        { name: 'Git', isSelected: false },
        { name: 'Golang', isSelected: false },
        { name: 'Grep', isSelected: false },
        { name: 'Kubernetes', isSelected: false },
        { name: 'Linux', isSelected: false },
        { name: 'Networking', isSelected: false },
        { name: 'SQL', isSelected: false },
        { name: 'Terraform', isSelected: false }
      ],
      hintMessage: HintMessages.Tag,
      loadMore: true,
      isDisplayed: true,
      filter: function (exercises: Exercise[]): Exercise[] {
        if (!this.selection || this.selection.length === 0 || exercises.length === 0) {
          return exercises;
        }

        const selectedTags = this.selection
          .filter(s => s.isSelected)
          .map(s => s.name);

        if (selectedTags.length === 0) {
          return exercises;
        }

        return exercises.filter(exercise =>
          exercise.tags.some(tag => selectedTags.includes(tag))
        );
      }
    },
    {
      name: ExerciseFilterName.DynamicExercise,
      selection: [
        { name: 'Yes', isSelected: false },
        { name: 'No', isSelected: false }
      ],
      hintMessage: HintMessages.DynamicExercise,
      loadMore: false,
      isDisplayed: true,
      filter: function (exercises: Exercise[]): Exercise[] {
        if (!this.selection || exercises.length === 0) {
          return exercises;
        }
        const yesSelected = this.selection.some(option => option.name === 'Yes' && option.isSelected);
        const noSelected = this.selection.some(option => option.name === 'No' && option.isSelected);

        if (yesSelected === noSelected) {
          return exercises;
        }
        return exercises.filter(exercise => exercise.dynamicDescription === yesSelected);
      }
    },
    {
      name: ExerciseFilterName.LinuxTerminal,
      selection: [
        { name: 'Yes', isSelected: false },
        { name: 'No', isSelected: false }
      ],
      hintMessage: HintMessages.LinuxTerminal,
      loadMore: false,
      isDisplayed: true,
      filter: function (exercises: Exercise[]): Exercise[] {
        if (!this.selection || exercises.length === 0) {
          return exercises;
        }
        const yesSelected = this.selection.some(option => option.name === 'Yes' && option.isSelected);
        const noSelected = this.selection.some(option => option.name === 'No' && option.isSelected);

        if (yesSelected === noSelected) {
          return exercises;
        }
        return exercises.filter(exercise => exercise.needTerminal === yesSelected);
      }
    }
  ];

  public getFilter(name: ExerciseFilterName): Filter | undefined {
    return this.filters.find(filter => filter.name === name);
  }
}

export interface Selection {
  name: string;
  isSelected: boolean;
}

export interface Filter {
  name: ExerciseFilterName;
  hintMessage?: HintMessages | null;
  inputValue?: string;
  selection?: Selection[];
  loadMore?: boolean;
  isDisplayed?: boolean;
  filter: (exercises: Exercise[]) => Exercise[];
}

export enum ExerciseFilterName {
  Name = 'Name',
  Tag = 'Tag',
  Difficulty = 'Difficulty',
  DynamicExercise = 'Dynamic Exercise',
  LinuxTerminal = 'Linux Terminal'
}
