import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConversationComponent } from './components/conversation/conversation.component';
import { MessageComponent } from './components/message/message.component';
import { MessageSenderComponent } from './components/message-sender/message-sender.component';
import { AvatarModule } from 'primeng/avatar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';

@NgModule({
  declarations: [
    ConversationComponent,
    MessageComponent,
    MessageSenderComponent
  ],
  imports: [
    CommonModule,
    AvatarModule,
    InputTextareaModule,
    ReactiveFormsModule,
    ButtonModule,
    FileUploadModule,
  ],
  exports: [
    ConversationComponent
  ]
})
export class ChatModule { }
