import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/user.module';

@Component({
  selector: 'avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
})

export class AvatarUploaderComponent implements OnInit {
  @Output() onSelect = new EventEmitter<File>();

  private readonly maxFileSize: number = 5000000;
  public newPicture: string | undefined;
  public user!: User;

  constructor(
    private _auth: AuthService,
    private _messageService: MessageService,
  ) { }

  public ngOnInit(): void {
    this.user = this._auth.user;
  }

  public onFileSelected($event: Event) {
    if (!$event || !$event.target)
      return;

    const target = $event.target as HTMLInputElement;
    const file = target.files ? target.files[0] : null;

    if (!file) {
      this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to upload the file' });
      return;
    }

    if (file.size > this.maxFileSize) {
      this._messageService.add({ severity: 'error', summary: 'Error', detail: 'The file is too large. The maximum file size allowed is 5MB.' });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => this.newPicture = reader.result as string;
    reader.readAsDataURL(file);

    this.onSelect.emit(file);
  }
}