import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExerciseFilterName, FilterDataService, Selection } from './filter-data.service';
import { PlanControlService } from 'src/app/plans-management/plan-editor/edit-plan/service/plan-control.service';
import { Exercise } from 'src/app/exercise.module';
import { ExerciseDataService } from '../exercise-data-service/exercise-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-exercise-filter',
  templateUrl: './exercise-filter.component.html',
  styleUrls: ['./exercise-filter.component.css']
})

export class ExerciseFilterComponent implements OnInit, OnDestroy {
  public selectionsToShow = 4;
  public inputControl: PlanControlService;
  private inputSub: Subscription | null = null;

  constructor(
    public filterData: FilterDataService,
    public exerciseData: ExerciseDataService,
  ) { 
    this.inputControl = new PlanControlService();
  }

  ngOnInit(): void {
    this.setInputValue();
    this.filterExercises();
    this.subscribeToInputControl();
  }
  
  ngOnDestroy(): void {
    if (this.inputSub && !this.inputSub.closed){
      this.inputSub.unsubscribe();
    }
  }

  private subscribeToInputControl(): void {
    this.inputSub = this.inputControl.control.valueChanges.subscribe(value => {
      this.handleInput(value);
    });
  }

  private setInputValue(): void {
    const filter = this.filterData.getFilter(ExerciseFilterName.Name);
    if (!filter) {
      return;
    }
    this.inputControl.setValue(filter.inputValue ?? '');
  }

  public handleFilterMutation(checked: boolean, selection: Selection): void {
    selection.isSelected = checked;
    this.filterExercises();
  }

  public handleInput(input: string): void {
    if (!this.inputControl) {
      return;
    }
    
    const filter = this.filterData.getFilter(ExerciseFilterName.Name);
    if (!filter) {
      return;
    }
    
    filter.inputValue = input;
    this.filterExercises();
  }

  public filterExercises(): void {
    let filteredExercises: Exercise[] = this.exerciseData.exercises;
    this.filterData.filters.forEach(filter => {
      filteredExercises = filter.filter(filteredExercises);
    });
    this.exerciseData.filteredExercises = filteredExercises;
  }
}
