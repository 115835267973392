import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AuthService } from 'src/app/auth/auth.service';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { InterviewerExercisePlan } from 'src/app/exercise.module';
import { User } from 'src/app/user.module';
import { AssignedPlansDataService } from 'src/app/assigned-plans/data-service/assigned-plans-data.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { PlanDataService } from '../../plan-data-service/plan-data.service';
import { Subscription } from 'rxjs';
import { UserPublicProfile } from 'src/app/models/user-public-profile.model';
import moment from 'moment-mini';

@Component({
  selector: 'app-assign-plan',
  templateUrl: './assign-plan.component.html',
  styleUrls: ['./assign-plan.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
              style({ height: 300, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300, opacity: 1 }),
            animate('1s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class AssignPlanComponent implements OnInit, OnDestroy {
  @ViewChild(NgSelectComponent, { static: false }) ngSelect!: NgSelectComponent;

  public deadlineControl: FormControl = new FormControl(new Date());
  public users: UserPublicProfile[] = [];
  public selectedUser: UserPublicProfile | null = null;
  public user: User;
  public minDate!: Date;
  public color: ThemePalette = 'primary';
  public isAssigned = false;
  public isAssigning = false;
  public assignedPlanId: string | null = null;
  private selectedPlanSubscription: Subscription | null = null;
  private planToAssign: InterviewerExercisePlan | null = null;
  private styleTag: HTMLStyleElement | null = null;

  constructor(
    private dynamodb: DynamoDBService,
    private auth: AuthService,
    private assignedPlanData: AssignedPlansDataService,
    private planData: PlanDataService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.user = auth.user;
    this.subToSelectedPlan();
    this.setMinDate();
  }

  public ngOnInit(): void {
    this.fetchUsers();
    this.setupCalendarStyles();
  }

  public ngOnDestroy(): void {
    this.selectedPlanSubscription?.unsubscribe();
    this.destroyCalendarStyles();
  }

  get isInputsValid(): boolean {
    if (this.deadlineControl.errors ||
      !this.deadlineControl.value ||
      !this.selectedUser) {
      return false;
    }
    return true;
  }

  private subToSelectedPlan(): void {
    this.selectedPlanSubscription = this.planData.selectedPlan$.subscribe(plan => {
      this.planToAssign = plan;
    });
  }

  private setMinDate(): void {
    const defaultDeadline = moment().add(3, 'days').set({ seconds: 0 }).toDate();
    this.deadlineControl.setValue(defaultDeadline);
    this.minDate = defaultDeadline;
  }

  public customSearchFn(input: string, item: UserPublicProfile) {
    const fieldsToSearch = [
      item.firstName,
      item.lastName,
      item.email,
      `${item.firstName} ${item.lastName}`,
    ];
    return fieldsToSearch.some(field => field?.toLowerCase().includes(input.toLocaleLowerCase()));
  }

  public onDropdownClose(): void {
    this.ngSelect.blur();
  }

  public onDropdownFocus(): void {
    this.selectedUser = null;
  }

  public async assignPlan(): Promise<void> {
    this.isAssigning = true;
    this.isAssigned = false;
    const assignedPlan = await this.assignedPlanData.assignPlan(this.selectedUser!.email.toLowerCase(), this.deadlineControl.value, this.planToAssign!.id);
    if (!assignedPlan) {
      this.isAssigning = false;
      return;
    }
    
    this.assignedPlanId = assignedPlan.id;
    this.isAssigned = true;
    this.isAssigning = false;
  }

  private async fetchUsers(): Promise<void> {
    const users = await this.dynamodb.getCompanyConnections(this.user.companyId);
    const members = await this.dynamodb.getCompanyMembers(this.user.companyId);
    this.users = [...users, ...members];
  }

  private setupCalendarStyles(): void {
    this.styleTag = this.renderer.createElement('style');
    this.renderer.setAttribute(this.styleTag, 'type', 'text/css');
    this.renderer.setProperty(this.styleTag, 'innerText', `
      .p-datepicker td>span {
          width: 25px !important;
          height: 25px !important;
      }
    `);
    this.renderer.appendChild(this.el.nativeElement.ownerDocument.head, this.styleTag);
  }

  private destroyCalendarStyles(): void {
    if (this.styleTag) {
      this.renderer.removeChild(this.el.nativeElement.ownerDocument.head, this.styleTag);
    }
  }
}
