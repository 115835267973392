import { AuthService } from '../../auth/auth.service';
import { ServiceLocator } from '../../service-locator.service';
import { Picture } from '../picture.model';
import moment from 'moment-mini';

export class Message {
  constructor(
    jsonMessage: string
  ) {
    const message: IMessage = JSON.parse(jsonMessage);

    this._sendDate = message.sendDate;
    this._senderName = message.senderName;
    this._image = new Picture(message.imageURL);
    this._messageBody = message.messageBody;
    this._senderEmail = message.senderEmail;
    this._attachmentsName = message.attachmentsName;
    this._authService = ServiceLocator.injector.get(AuthService);
  }

  private _image: Picture;
  private _sendDate: string;
  private _senderName: string;
  private _messageBody: string;
  private _senderEmail: string;
  private _attachmentsName: string[] | undefined;

  private _sendTime: string | undefined;

  private _authService: AuthService;

  public isLinked = false;
  public isSeparate = false;

  get image(): Picture {
    return this._image;
  }

  get messageBody(): string {
    return this._messageBody;
  }

  get attachmentsName(): string[] | undefined{
    return this._attachmentsName;
  }

  get senderName(): string {
    return this._senderName;
  }

  get sendDate(): string {
    return this._sendDate;
  }

  get isMine(): boolean {
    return this._authService.user.email === this._senderEmail;
  }

  get sendTime(): string | undefined {
    if (!this._sendTime && this._sendDate) {
      this._sendTime = moment(this._sendDate).format('HH:mm');
    }
    return this._sendTime;
  }
}

export interface IMessage {
    sendDate: string;
    imageURL: string;
    senderName: string;
    senderEmail: string;
    messageBody: string;
    attachmentsName?: string[];
}