<ng-select 
    *ngIf="inputControl"
    #ngSelectComponent 
    notFoundText="Country not found"
    appearance="outline"
    placeholder="Select Country/Region"
    [formControl]="inputControl"
    [required]="isRequired"
    [items]="countries" 
    [searchFn]="customSearchFn"
    [multiple]="false"
    [virtualScroll]="true" 
    [closeOnSelect]="true"
    [clearSearchOnAdd]="true"
    (close)="onDropdownClose()"
    (change)="onChange.emit()">

    <ng-template 
      ng-option-tmp 
      let-item="item">
        <div>{{item}}</div>
    </ng-template>

    <ng-template 
      ng-label-tmp
      let-item="item">
        <div>{{item}}</div>
    </ng-template>
</ng-select>