import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardPanelsDataServiceService as DashboardPanelsDataService } from './service/dashboard-panels-data-service.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/user.module';
import { Router } from '@angular/router';
import { Picture } from 'src/app/models/picture.model';

@Component({
  selector: 'app-recent-assigned-plan',
  templateUrl: './recently-assigned-plan.component.html',
  styleUrls: ['./recently-assigned-plan.component.scss']
})
export class RecentlyAssignedPlanComponent implements OnInit, OnDestroy {
  public data: DashboardPanelData[] | null = null;
  public skeletonArray: number[] = Array.from({ length: 4 });
  public user: User;
  private subscription: Subscription = new Subscription();

  constructor(
    public dataService: DashboardPanelsDataService,
    private auth: AuthService,
    private router: Router
  ) {
    this.user = this.auth.user;
  }

  async ngOnInit(): Promise<void> {
    this.subToData();
    await this.dataService.fetch();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public navigate(path: string, queryValue?: string): void {
    this.router.navigate([path], { queryParams: { id: queryValue } });
  }

  private subToData(): void {
    this.subscription.add(this.dataService.data$.subscribe(data => {
      this.data = data;
    }));
  }
}

export interface DashboardPanelData {
  id: string;
  header: string;
  status: string;
  name: string
  picture: Picture;
  redirectLink: string;
  buttonRedirectLink?: string;
  progressPercentage?: number;
}
