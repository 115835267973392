import { PlanStatus } from 'src/app/models/assigned-plan.model';
import { TaskStatus } from '../../status-parser';


export function GetWidth(element: HTMLElement): number {
  return element.offsetWidth;
}

export function GetStatusColor(status: TaskStatus | string): string {
  const colors: { [key in TaskStatus | PlanStatus]?: string } = {
    [TaskStatus.NOT_STARTED]: 'black',
    [TaskStatus.COMPLETED]: 'green',
    [TaskStatus.RUNNING]: 'blue',
    [TaskStatus.REJECTED]: 'red',
    [TaskStatus.CANCELED]: 'red',
    [TaskStatus.TIME_IS_OVER]: 'red',
    [PlanStatus.FINISHED]: 'green',
  };
  return colors[status as TaskStatus | PlanStatus] || 'black';
}

export function ScrollToTop(elementId: string): void  {
  document.getElementById(elementId)?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  });
}