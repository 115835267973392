import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { DynamoDBService } from 'src/app/dynamodb.service';
import { IMessage, Message } from 'src/app/models/chat-model/message.model';
import { MessageService } from 'primeng/api';
import { Ticket } from 'src/app/models/ticket.model';
import { FileUpload } from 'primeng/fileupload';
import moment from 'moment-mini';

@Component({
  selector: 'app-message-sender',
  templateUrl: './message-sender.component.html',
  styleUrls: ['./message-sender.component.scss'],
})
export class MessageSenderComponent {
  @Input() ticket: Ticket | undefined;
  @Output() message: EventEmitter<Message> = new EventEmitter<Message>();
  @ViewChild(FileUpload, { static: false }) fileUpload: FileUpload | undefined;

  public text: FormControl = new FormControl('', [Validators.required]);
  public loadedFiles: File[] = [];
  constructor(
    private auth: AuthService,
    private dynamodb: DynamoDBService,
    private messageService: MessageService
  ) { }

  private get isEmptyData(): boolean {
    return !this.ticket?.id || this.text.invalid;
  }

  public async onEnter(event: Event) {
    if (this.isEmptyData) {
      return;
    }

    const keyboardEvent = event as KeyboardEvent;
    keyboardEvent.preventDefault();
    await this.submit();
  }

  public async onSelect(event: SelectEvent): Promise<void> {
    this.fileUpload && this.fileUpload.clear();
    if (event.currentFiles.length > 10) {
      this.messageService.add({
        severity: 'error',
        summary: 'File Limit',
        detail: 'Can\'t add more than 10 files per message',
      });
      event.currentFiles = event.currentFiles.slice(0, 10);
    }

    for (const file of event.currentFiles) {
      const checkFile = this.loadedFiles.find((f) => f.name === file.name);

      if (checkFile) {
        this.messageService.add({
          severity: 'error',
          summary: 'File Upload',
          detail: 'This attachment has already been added',
        });
        continue;
      }

      if (file.size > 10000000) {
        this.messageService.add({
          severity: 'error',
          summary: 'File Upload',
          detail: `'${file.name}' size exceeds 10MB`,
        });
        continue;
      }

      if (this.ticket && this.ticket.attachments) {
        const attachments = await this.ticket.attachments;
        const found = attachments.find((att) => file.name === att.name);
        if (found) {
          this.messageService.add({
            severity: 'error',
            summary: 'File Upload',
            detail: 'This attachment has already been uploaded',
          });
          continue;
        }
      }
      this.loadedFiles.push(file);
    }
  }


  public async deleteAttachment(name: string): Promise<void> {
    this.loadedFiles = this.loadedFiles.filter(file => file.name !== name);
  }

  public async submit(): Promise<void> {
    if (this.isEmptyData) return;
    const jsonMessage = this.getJSONMessage();
    
    try {
      await this.dynamodb.saveNewTicketMessage(
        this.ticket!.email!,
        this.ticket!.sortKey!,
        jsonMessage
      );
      this.message.emit(new Message(jsonMessage));
      this.text.reset();
      this.ticket?.addNewAttachment(this.loadedFiles);
      this.loadedFiles = [];

    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to send message. Please try again later!',
      });
    }
  }

  private getJSONMessage(): string {
    const message: IMessage = {
      sendDate: moment().toISOString(),
      imageURL: this.auth.user.picture.originalURL,
      senderName: this.auth.user.full_name,
      senderEmail: this.auth.user.email,
      messageBody: this.text.value,
      attachmentsName: this.loadedFiles.map((file) => file.name),
    };
    return JSON.stringify(message);
  }
}

interface SelectEvent {
  currentFiles: File[];
}
