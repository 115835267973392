import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimerComponent } from './components/timer/timer.component';
import { ResultComponent } from './components/result/result.component';
import { ActionButtonsComponent } from './components/action-buttons/action-buttons.component';
import { BottomPanelComponent } from './bottom-panel.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  declarations: [ 
    TimerComponent,
    ResultComponent,
    ActionButtonsComponent,
    BottomPanelComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ConfirmDialogModule
  ],
  exports: [
    BottomPanelComponent
  ]
})
export class BottomPanelModule { }
