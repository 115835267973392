import { Pipe, PipeTransform } from '@angular/core';
import { GetTimeRemaining } from './shared/service/time';

export enum TaskStatus {
  NOT_STARTED = 'Not Started',
  RUNNING = 'Running',
  COMPLETED = 'Completed',
  REJECTED = 'Rejected',
  CANCELED = 'Canceled',
  TIME_IS_OVER = 'Time is over',
}

export function CountTasksByStatus(statuses: string[], statusToCount: TaskStatus): number {
  return statuses.filter(status => status === statusToCount).length;
}

export function GetPlanStatus(statuses: string[], deadline: string): TaskStatus {
  const statusCounts = new Map<string, number>();
  statuses.forEach(status => {
    statusCounts.set(status, (statusCounts.get(status) || 0) + 1);
  });

  if (statusCounts.get(TaskStatus.CANCELED) === statuses.length) {
    return TaskStatus.CANCELED;
  }
  if (statusCounts.get(TaskStatus.REJECTED) === statuses.length) {
    return TaskStatus.REJECTED;
  }
  if (statusCounts.get(TaskStatus.NOT_STARTED) === statuses.length) {
    return TaskStatus.NOT_STARTED;
  }
  if (statusCounts.get(TaskStatus.COMPLETED) === statuses.length) {
    return TaskStatus.COMPLETED;
  }
  if (GetTimeRemaining(deadline, 'milliseconds') <= 0) {
    return TaskStatus.TIME_IS_OVER;
  }

  return TaskStatus.RUNNING;
}

@Pipe({
  name: 'planStatus'
})
export class PlanStatusPipe implements PipeTransform {
  transform(exerciseStatuses: Map<string,string>, deadline: string): string {
    const statuses = Array.from(exerciseStatuses.values());
    return GetPlanStatus(statuses, deadline);
  }
}